import {
  MONTHS,
  MONTHS_CALENDAR,
  RADIX_PARAMETER,
  YEAR_OPTIONS,
} from '../../../../../common/helpers/constants';
import { MonthsI } from '../../../../../interfaces/app';
import { OptionInterface } from '../../Select';

export interface GetLastJobProps {
  monthAndYear: string;
  type?: string;
  onlyInit?: boolean;
  setDisabledEndCalendar?: (e: any) => void;
  setListYearsEnd?: (e: any) => void;
  setListDisabledMonths?: (e: any) => void;
}

const checkDisabledEndCalendar = (
  monthAndYear: string,
  onlyInit?: boolean,
  type?: string,
  setDisabledEndCalendar?: (e: any) => void
) => {
  if (monthAndYear === '/' && !onlyInit) {
    if (type === 'init' && setDisabledEndCalendar) setDisabledEndCalendar(true);
    return null;
  }
  return true;
};

const prepareEndCalendar = (
  onlyInit?: boolean,
  setDisabledEndCalendar?: (e: any) => void,
  setListYearsEnd?: (e: any) => void,
  setListDisabledMonths?: (e: any) => void,
  year?: string,
  month?: string
) => {
  if (!onlyInit) {
    if (setDisabledEndCalendar) setDisabledEndCalendar(false);
    if (setListYearsEnd) {
      setListYearsEnd(
        YEAR_OPTIONS.filter((yearObj: OptionInterface) => {
          return (
            parseInt(yearObj.value, RADIX_PARAMETER) >=
            parseInt(year!, RADIX_PARAMETER)
          );
        })
      );
    }
    if (setListDisabledMonths) {
      setListDisabledMonths(
        MONTHS_CALENDAR.filter((monthsObj: MonthsI) => {
          return (
            parseInt(monthsObj.key, RADIX_PARAMETER) <=
            parseInt(month!, RADIX_PARAMETER)
          );
        })
      );
    }
  }
};

export const getDateLastJob = (props: GetLastJobProps) => {
  const {
    monthAndYear,
    type,
    onlyInit,
    setDisabledEndCalendar,
    setListYearsEnd,
    setListDisabledMonths,
  } = props;
  const monthAndYearArr = monthAndYear.split('/');
  let month = monthAndYearArr[0];
  let year = monthAndYearArr[1];
  if (type === 'defaultValue')
    return prepareEndCalendar(
      false,
      undefined,
      setListYearsEnd,
      setListDisabledMonths,
      year,
      month
    );

  const result = checkDisabledEndCalendar(
    monthAndYear,
    onlyInit,
    type,
    setDisabledEndCalendar
  );

  if (month !== '' && year !== '' && result) {
    let date;

    if (type === 'init') {
      date = new Date(
        parseInt(year, RADIX_PARAMETER),
        parseInt(month, RADIX_PARAMETER),
        1
      );
      prepareEndCalendar(
        onlyInit,
        setDisabledEndCalendar,
        setListYearsEnd,
        setListDisabledMonths,
        year,
        month
      );
    } else {
      date = new Date(
        parseInt(year, RADIX_PARAMETER),
        parseInt(month, RADIX_PARAMETER) + 1,
        0
      );
    }
    let day: string
    if (date > new Date()) {
      const today = new Date()
      date.setDate(today.getDate() - 1)
    }

    day = date.getDate().toString().padStart(2, '0');
    month = (date.getMonth() + 1).toString().padStart(2, '0');

    return `${day}/${month}/${year}`;
  }

  return null;
};

export const getDefaultDate = (date: string) => {
  if (date) {
    const dateInitWork = date.split('/');
    const month = dateInitWork[1];
    const year = dateInitWork[2];
    return `${MONTHS[parseInt(month, RADIX_PARAMETER) - 1]} / ${year}`;
  }
  return '';
};
