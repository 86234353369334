import * as calculatorActions from './calculator/actions';
import * as expressRequestActions from './expressRequest/actions';
import * as siteActions from './site/actions';
import * as channelsActions from './channels/actions';
import * as loginActions from './login/actions';
import * as civilRegistrationActions from './civilRegistration/actions';
import * as callMeBackActions from './callMeBack/actions';

const actions = {
  ...calculatorActions,
  ...expressRequestActions,
  ...siteActions,
  ...channelsActions,
  ...loginActions,
  ...civilRegistrationActions,
  ...callMeBackActions,
};

export default actions;
