import { FunctionComponent } from "react";
import { connect, ConnectedProps } from "react-redux";
import actions from "../../../actions";
import { ReduxState } from "../../../common/store";
import InputField from "../../InputField/InputField";

const ENABLED_CHANNELS = [
  "kam",
  "agencia",
  "banca privada",
  "feria",
  "kam oficina",
];

const AdviserField: FunctionComponent<ReduxProps> = ({
  adviser,
  channel,
  adviserState,
  setAdviser,
}) => {
  const handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    if (value.length >= 40) {
      return;
    }
    setAdviser(value.replace(/[^a-zA-Z]/g, "").toUpperCase());
  };

  if (!ENABLED_CHANNELS.includes(channel)) {
    return null;
  }

  let classWrap = " adviser";
  let errorHelper = "";
  let state = "normal";
  let lg = "12";
  let label = "Asesor comercial (opcional)";

  if (channel === "agencia") {
    classWrap = " agency md-mb-0 agency-first-column";
    errorHelper = "Completar campo";
    state = adviserState;
    lg = "6";
    label = "Asesor comercial *";
  }

  const inputField = (
    <InputField
      id="adviser"
      testId="adviser"
      label={label}
      placeholder="Ej.: APEREIRA"
      errorHelper={errorHelper}
      normalHelper=""
      classWrap={classWrap}
      state={state}
      xs="12"
      lg={lg}
      disabled={false}
      currentValue={adviser}
      handleChange={handleChange}
    />
  );

  if (channel !== "agencia") {
    return (
      <pichincha-grid item xs="12">
        <pichincha-grid container justify="center">
          {inputField}
        </pichincha-grid>
      </pichincha-grid>
    );
  }

  return inputField;
};

const mapStateToProps = (state: ReduxState) => {
  const { channels, site } = state;
  const { adviser, adviserState } = channels;
  const { channel } = site;

  return { channel, adviser, adviserState };
};

const connector = connect(mapStateToProps, {
  setAdviser: actions.setLoanApplicationAdviser,
});
type ReduxProps = ConnectedProps<typeof connector>;
export default connector(AdviserField);
