import React, { Component } from "react";
import "./Popover.scss";
interface PopoverProps {
  children?: any;
  id: string;
  linkName: string;
  appendText?: string;
  popoverContent: any;
}
class Popover extends Component<PopoverProps> {
  handleClick(this: any) {
    const showPopover = (popover: HTMLElement) => {
      popover.classList.remove("popover-content-hidden");
      popover.classList.add("popover-content-visible");
      popover.style.zIndex = "950";
    };
    const hidePopover = (popover: HTMLElement) => {
      popover.classList.remove("popover-content-visible");
      popover.classList.add("popover-content-hidden");
      popover.style.zIndex = "0";
    };
    let popoverContent = this.nextElementSibling;
    

    if (popoverContent.classList.contains("popover-content-visible")) {
      hidePopover(popoverContent);
    } else {
      // @ts-ignore
      for (const popover of document.getElementsByClassName(
        "popover-content"
      )) {
        hidePopover(popover);
      }
      showPopover(popoverContent);
    }
  }
  componentDidMount() {
    // @ts-ignore
    document
      .querySelector(`#${this.props.id}`)
      .addEventListener("click", this.handleClick);

  }
  render() {
    return (
      <div className="popover">
        <div className="link" id={this.props.id} data-testid={this.props.id}>
          <label className="text popover-link">{this.props.linkName}</label>
          {this.props.appendText && (
            <label
              className="bp-ml-8 append-text"
              data-testid="appendTextTestId"
            >
              {" "}
              {this.props.appendText}
            </label>
          )}
        </div>
        <div
          data-testid="div-popover-content"
          className="popover-content-hidden popover-content"
        >
          <div>
            <div className="poporver-title-section">
              <div className="title-popover">{this.props.linkName}</div>

              <pichincha-icon size="21px" color="darkGrey" weight_color="500">
                close
              </pichincha-icon>
            </div>
            <div className="contain-popover">{this.props.popoverContent}</div>
          </div>
        </div>
      </div>
    );
  }
}
export default Popover;
