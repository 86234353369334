import TagManager from "react-gtm-module";

export const sendGTMClickEvent = (id: string) => {
  TagManager.dataLayer({
    dataLayer: {
      event: "gtm.click",
      "gtm.elementId": id,
    },
  });
};

export const sendGTMVisibilityEvent = (id: string) => {
  TagManager.dataLayer({
    dataLayer: {
      event: "gtm.elementVisibility",
      "gtm.elementId": id,
    },
  });
};

export const sendGTMCustomEvent = (
  event:string
) => {
  TagManager.dataLayer({
    dataLayer: {
      event: event,
    },
  });
};

export const sendGTMUserEvent = (
  channel: string,
  step: string,
  userType: string
) => {
  TagManager.dataLayer({
    dataLayer: {
      event: "userFlow",
      channel: channel,
      stepId: step,
      userType: userType,
    },
  });
};