import * as CryptoJS from 'crypto-js';

const getKeys = () => {
  const secretkey = process.env.REACT_APP_KEY_PORTAL as string
  const key = CryptoJS.enc.Utf8.parse(secretkey)
  const iv = CryptoJS.enc.Utf8.parse(secretkey.substring(0, 16))
  return {key, iv}
}

const Cipher = (text: any) => {
  const {key, iv } = getKeys()
  return CryptoJS.AES.encrypt(text, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  }).toString();
};

const Decrypted = (text: string) => {
  const {key, iv } = getKeys()
  let decrypted = CryptoJS.AES.decrypt(text, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });
  return decrypted.toString(CryptoJS.enc.Utf8);
};

export { Cipher, Decrypted };
