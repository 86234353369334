import { FunctionComponent, useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { ReduxState } from "../../common/store";

import RecaptchaDisclaimer from "../RecaptchaDisclaimer/RecaptchaDisclaimer";

const LoadingPage: FunctionComponent<ReduxProps> = (props) => {
  const [state, setState] = useState({
    currentIndex: 0,
    tips: [
      "Podrás realizar abonos y precancelaciones sin recargo si adquieres nuestro crédito.",
      "Podrás contar con nuestra asesoría a lo largo del camino para cumplir tu sueño si adquieres nuestro crédito.",
    ],
  });

  

  useEffect(() => {
    const TRANSITION_INTERVAL = 5000;
    const intervalId = setInterval(() => {
      setState((prevState) => ({
        ...prevState,
        currentIndex:
          prevState.currentIndex === prevState.tips.length - 1
            ? 0
            : prevState.currentIndex + 1,
      }));
    }, TRANSITION_INTERVAL);
    return () => clearInterval(intervalId);
  }, []);

  const { tips, currentIndex } = state;
  const { expressApplication_loading: loadingApplication } =
    props.expressRequest;
  const { loading: loadingCivilRegistration } = props.civilRegistration;
  const texts: { [key: string]: any } = {
    application: "Estamos procesando tus datos para brindarte una respuesta",
    civilRegistration: "Estamos por iniciar tu pre-calificación",
  };

  const textKey =
    (loadingApplication && "application") ||
    (loadingCivilRegistration && "civilRegistration") ||
    "";
  return (
    <div className="loading-page">
      <div>
        <div className="titleCheck loading-page-title">{texts[textKey]}</div>
        <div className="text-prelo-medium text-center subtitle">
          Agradecemos tu espera
        </div>
        <pichincha-spinner
          background="#ffdd00"
          border={4}
          color="#f3f4f7"
          size={110}
        ></pichincha-spinner>
        <div className="text-prelo-bold text-center tip-title">Sabías que</div>
        <div className="text-prelo-medium text-center tip">
          {tips[currentIndex]}
        </div>
      </div>
      <RecaptchaDisclaimer />
    </div>
  );
};

const mapStateToProps = (state: ReduxState) => {
  const { expressRequest, civilRegistration } = state;
  return {
    expressRequest,
    civilRegistration,
  };
};

const connector = connect(mapStateToProps);

type ReduxProps = ConnectedProps<typeof connector>;

export default connector(LoadingPage);
