import React, { Component } from "react";

interface CheckListElementProps {
  text: string;
  percentage?: string;
  boldStart?: string;
  customIcon?: string;
}

class CheckListElement extends Component<CheckListElementProps> {
  render() {
    const { text, percentage, boldStart, customIcon } = this.props;
    let icon = (
      <pichincha-icon size="1.5rem" color="success" weight_color="500">
        check_circle
      </pichincha-icon>
    );
    if (customIcon) {
      icon = (
        <div className="circle-container" data-testid="checkListElementCustomIconTestId">
          <div className="text-dark-grey-500">
            {customIcon}
          </div>
        </div>
      );
    }
    return (
      <div className="element">
        <div className="icon">{icon}</div>
        <div className="text text-dark-grey-500 text-prelo-medium">
          {boldStart && (
            <strong className="text-dark-grey-500">{`${boldStart} `}</strong>
          )}
          {text}
          {percentage && <strong>{` ${percentage}`}</strong>}
        </div>
      </div>
    );
  }
}

export default CheckListElement;
