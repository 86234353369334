import React, { Component, FormEvent } from "react";
import { connect, ConnectedProps } from "react-redux";
import actions from "../../../actions/";
import actionTypes from "../../../actions/actionTypes";
import * as CONSTANTS from "../../../common/helpers/constants";
import { NEW_USED_RATE } from "../../../common/helpers/constants";
import { capitalizeName, getUtmHeaders, scrollToTop, } from "../../../common/helpers/functions";
import { ReduxState } from "../../../common/store";
import LivingPlaceLoanSection from "../../Calculator/LivingPlaceLoanSection/LivingPlaceLoanSection";
import MessageBar from "../../MessageBar/MessageBar";
import Checkbox from "../../ProductsBox/Checkbox";
import StepsGuide from "../../StepsGuide/StepsGuide";
import "./CreditData.scss";
import { autocompleteData } from "./locations";

import { sendGTMUserEvent } from "../../../common/helpers/gtmEvents";
import { setEncryptedItemToCookie } from "../../../common/helpers/storage-utils";
import { buyingIntentionTimeOptions, CHANNELS_WITH_NO_LOGIN, } from "../../../common/texts/expressApplication";
import SelectField from "../../InputField/SelectField";
import List from "../../List.tsx/List";
import RadioButton from "../../RadioButton/RadioButton";
import "../PersonalData/PersonalData.scss";
import StepsButtons from "../StepsButtons.tsx/StepsButtons";
import TitlePage from "../TitlePage/TitlePage";

const classRectangle = "express-rectangle";

const titleCheck = "Vivienda nueva o usada";
const list = [
  "Crédito destinado a la compra de una casa o departamento, ya sea nuevo",
];
const lastLine = "o usado, a una tasa anual referencial de ";
const percentage = `${NEW_USED_RATE}%.`;

const titleCheck2 = "Vivienda de interés público y social";
const list2 = [
  "Créditos destinados a la compra de tu primera vivienda, nueva por ",
];
const lastLine2 = `estrenar, de hasta ${CONSTANTS.MAX_AMOUNT_VIP}, a una tasa anual referencial de `;
const percentage2 = "4.87%.";

class CreditData extends Component<ReduxProps> {
  execBtn = true;
  isClient = this.props.userType === "cliente";

  constructor(props: ReduxProps) {
    super(props);
    this.setNewLivingPlace = this.setNewLivingPlace.bind(this);
    this.setUsedLivingPlace = this.setUsedLivingPlace.bind(this);
    this.setVipVisProduct = this.setVipVisProduct.bind(this);
    this.setNewUsedProduct = this.setNewUsedProduct.bind(this);
    this.backward = this.backward.bind(this);
    this.openModal = this.openModal.bind(this);
    this.execSendExpressApiCreator = this.execSendExpressApiCreator.bind(this);
    this.closeAlert = this.closeAlert.bind(this);
  }
  componentDidMount() {
    this.props.toggleInputsStyle(false);
    sendGTMUserEvent(this.props.channel, "datosCredito", this.props.userType);
    if (
      this.props.workData.monthlyIncomeNumber +
        this.props.workData.partnerMonthlyIncomeNumber >
      this.props.product[0].basicSalary * 6.34
    ) {
      this.setNewUsedProduct();
    }
  }

  openModal(section: string) {
    this.props.toggleModal(true, section);
  }

  handleChangeBuyingIntentionTime = (e: React.FormEvent<HTMLInputElement>) => {
    const newValue = e.currentTarget.value;
    this.props.updateInputContent(
      newValue,
      actionTypes.UPDATE_INPUT_BUYING_INTENTIONS_TIME
    );
  };

  setNewLivingPlace() {
    if (
      this.props.workData.monthlyIncomeNumber +
        this.props.workData.partnerMonthlyIncomeNumber <
      this.props.product[0].basicSalary * 6.34
    ) {
      this.props.setSelectedProduct("");
    }
    this.props.setSelectedCreditProduct(actionTypes.SET_FIRST_PRODUCT);
    this.props.toggleProducts(true);
    this.props.toggleInputs(false);
    this.props.resetExpressRequest(actionTypes.RESET_VALUES);
  }

  setUsedLivingPlace() {
    this.props.setSelectedProduct("new-used");
    this.props.setSelectedCreditProduct(actionTypes.SET_SECOND_PRODUCT);
    this.props.toggleProducts(false);
    this.props.toggleInputs(true);
    this.props.resetExpressRequest(actionTypes.RESET_VALUES);
  }

  setVipVisProduct() {
    this.props.setSelectedProduct("vip-vis");
    this.props.toggleInputs(true);
  }

  setNewUsedProduct() {
    this.props.setSelectedProduct("new-used");
    if (this.props.house_price !== "") {
      this.props.updateInputContent(
        this.props.house_price,
        actionTypes.UPDATE_INPUT_HOUSE_PRICE
      );
    }
    if (this.props.requested_amount !== 0) {
      this.props.updateInputContent(
        this.props.requested_amount.toString(),
        actionTypes.UPDATE_INPUT_REQUESTED_AMOUNT
      );
    }
    this.props.toggleInputs(true);
  }

  disableButton() {
    return (
      this.props.creditData.buyingIntentionTimeStateError ||
      this.props.house_price_error ||
      this.props.requested_amount_error ||
      this.props.expressApplication_loading ||
      (this.isClient &&
        !this.props.acceptLdpd &&
        this.props.dataProtectionModalOpen)
    );
  }

  backward() {
    this.props.setStep(2);
    scrollToTop();
  }

  closeAlert() {
    this.props.updateStateValue(actionTypes.TOGGLE_ALERT, false);
  }

  payloadStructure(noLogin: boolean) {
    const filterResidence = autocompleteData.filter((data) => {
      return (
        data.city === this.props.personalData.capitalResidence &&
        data.province === this.props.personalData.provinceResidence
      );
    });
    const { idCard, phone, email, maritalStatus, idCardPartner } =
      this.props.personalData;
    const { idCardLogin, fingerprintCode } = this.props;
    const {
      workStatus,
      monthlyIncomeNumber,
      partnerWorkStatus,
      partnerMonthlyIncomeNumber,
      workSeniority,
      partnerWorkSeniority,
    } = this.props.workData;
    const {
      selected_button_product,
      selected_product_type,
      amount,
      requested_amount,
      adviser = null,
      builder = null,
      project = null,
      agencyUUID = null,
    } = this.props;
    const { buyingIntentionTime } = this.props.creditData;
    const residenceCode = filterResidence.map((data) => data.code).toString();
    return {
      customer: {
        identification: noLogin ? idCard : idCardLogin,
        identificationType: CONSTANTS.CLIENT_IDENTIFICATION_TYPE,
        cellPhone: `09${phone}`,
        email: email,
        maritalStatus: maritalStatus,
        separationOfAssets:
          this.props.personalData.separationOfProperty === "yes" ||
          this.props.personalData.separationOfProperty === "",
        workStatus: workStatus,
        monthlyIncome: monthlyIncomeNumber,
        incomeSales: 0,
        previousInitWork: this.props.workData.previousInitWork,
        previousEndWork: this.props.workData.previousEndWork,
        timeWorked: workSeniority,
        fingerprintCode,
      },
      coSigner: {
        identification: idCardPartner,
        identificationType: CONSTANTS.CLIENT_IDENTIFICATION_TYPE,
        workStatus: partnerWorkStatus,
        monthlyIncome: partnerMonthlyIncomeNumber,
        incomeSales: 0,
        previousInitWork: this.props.workData.partnerPreviousInitWork,
        previousEndWork: this.props.workData.partnerPreviousEndWork,
        timeWorked: partnerWorkSeniority,
      },
      loan: {
        propertyType: selected_button_product,
        productType: selected_product_type,
        propertyCost: amount,
        requestedAmount: requested_amount,
        loanDuration: 240,
        requestedAttentionLocation: residenceCode,
        adviser: adviser || null,
        builder: builder || null,
        project: project || null,
        agency: agencyUUID || null,
        amortizationType: "french",
        buyingIntentionTime,
      },
    };
  }

  execSendExpressApiCreator(event: FormEvent, noLogin: boolean) {
    if (!this.props.acceptLdpd && this.isClient) {
      this.props.handleDataProtectionModalOpen(true);
      return;
    }
    if (this.execBtn) {
      this.execBtn = false;
      this.sendExpressApiCreator(event, noLogin);
    }
  }

  sendExpressApiCreator(event: FormEvent, noLogin: boolean) {
    const invalidData =
      this.props.house_price_error ||
      this.props.requested_amount_error ||
      this.props.expressApplication_loading;
    if (invalidData) {
      return;
    }
    event.preventDefault();
    event.stopPropagation();
    const { idCard } = this.props.personalData;
    const {
      idCardLogin,
      otpSuccess,
      fingeprintCodeSuccess,

      sendExpressRegistration: action,
      channel,
      jwt,
    } = this.props;
    const { firstName, firstLastName } = this.props;

    const payload = this.payloadStructure(noLogin);

    if (payload.customer.separationOfAssets) {
      // @ts-ignore
      payload.coSigner = null;
    }

    let requestType = "";

    if (otpSuccess) {
      sendGTMUserEvent(
        this.props.channel,
        "verPrecalificacion",
        this.props.userType
      );

      requestType = "prequalification";
    }

    if (fingeprintCodeSuccess) {
      requestType = "prospect";
      sendGTMUserEvent(
        this.props.channel,
        "solicitarCredito",
        this.props.userType
      );
      const facephiCookies: { [key: string]: any } = {
        customer: {
          firstName: capitalizeName(firstName),
          firstLastName: capitalizeName(firstLastName),
          identification: noLogin ? idCard : idCardLogin,
          authorization: this.props.jwt,
        },
        guid: CONSTANTS.GUID,
        dff28737611dc60ef2555c94c5aefd55: CONSTANTS.PUBLIC_KEY_FACEPHI
      };
      setEncryptedItemToCookie("facephi-t", facephiCookies);
    }

    const utmHeaders = getUtmHeaders()
      ? getUtmHeaders()
      : { source: channel, campaign: "", medium: "", content: "" };

    //@ts-ignore
    grecaptcha
      .execute(CONSTANTS.REACT_APP_RECAPTCHA_TOKEN)
      .then(function (recaptchaToken: string) {
        const headers = {
          token: recaptchaToken,
          GUID: CONSTANTS.GUID,
          jwt: jwt,
          imageAuthToken: "",
        };
        action(payload, requestType, utmHeaders, channel, headers);
      });
  }

  render() {
    const {
      showAlert,
      failuresCount,
      firstName,
      product,
      failuresPrequalificationCount,
    } = this.props;
    const VIP_VIS_MAX_INCOME_ALLOWED = product[0].basicSalary * 6.34;
    const { monthlyIncomeNumber, partnerMonthlyIncomeNumber } =
      this.props.workData;

    const disableVipVis =
      monthlyIncomeNumber + partnerMonthlyIncomeNumber >
      VIP_VIS_MAX_INCOME_ALLOWED;

    const errorMessageBarProps = {
      id: "errorAtSendExpressRequest",
      message: "Los datos no se enviaron correctamente, vuelve a intentarlo",
      variant: "light",
      color: "error",
      extraClasses: "invalid-request error-message-login bp-mb-16 bp-mt-8 ",
      handleClose: this.closeAlert,
    };

    const { channel } = this.props;
    const noLogin = CHANNELS_WITH_NO_LOGIN.includes(channel);

    const buyingIntentionTimeFieldProps = {
      label: "Aproximadamente, ¿cuándo deseas comprarla?",
      currentValue: this.props.creditData.buyingIntentionTime,
      disabled: false,
      handleChange: this.handleChangeBuyingIntentionTime,
      testId: "buyingIntentionTimeSelectFieldTestId",
      options: buyingIntentionTimeOptions,
      lg: "12",
    };

    return (
      <div>
        <div
          className="data-form-express-request step-page"
          data-testid="creditDataContainerTestId"
        >
          {showAlert ? (
            <div className="message-bar-container">
              <MessageBar {...errorMessageBarProps} />
            </div>
          ) : null}

          <pichincha-grid container justify="center" spacing={4}>
            <TitlePage title="Para terminar" name={firstName} />

            <pichincha-grid item xs="12">
              <StepsGuide />
            </pichincha-grid>

            <div className="form-input bp-mt-56 express-request">
              <pichincha-grid item xs="12">
                <div className="bp-mb-12">
                  <div className="subtitle-section text-prelo-semibold">
                    Sobre la vivienda que deseas adquirir
                  </div>
                </div>
              </pichincha-grid>

              <pichincha-grid item xs="12">
                <div className="bp-mb-12">
                  <div className="text-label">
                    Cuéntanos, ¿qué tipo de vivienda estás buscando comprar?
                  </div>
                </div>
              </pichincha-grid>

              <pichincha-grid item xs="12">
                <div className="rtb-wrapper">
                  <div className="rtb-item">
                    <div className="rtb-item__general">
                      <div>
                        <RadioButton
                          testId="firstButtonCreditProduct"
                          checked={this.props.selected_button_product === "new"}
                          handleClick={this.setNewLivingPlace}
                          label="Nueva"
                        />
                      </div>
                    </div>
                    <div className="rtb-item__general">
                      <div>
                        <RadioButton
                          testId="secondButtonCreditProduct"
                          checked={
                            this.props.selected_button_product === "used"
                          }
                          handleClick={this.setUsedLivingPlace}
                          label="Usada"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </pichincha-grid>

              {(this.props.selected_button_product === "new" ||
                this.props.selected_button_product === "used") && (
                <>
                  <pichincha-grid item xs="12">
                    <pichincha-grid container>
                      <SelectField
                        {...buyingIntentionTimeFieldProps}
                        classWrap=""
                      />
                    </pichincha-grid>
                  </pichincha-grid>
                </>
              )}

              {this.props.creditData.buyingIntentionTime !== "" &&
                this.props.creditData.buyingIntentionTime !== "empty" && (
                  <pichincha-grid item xs="12">
                    <br />

                    <div className="bp-mb-12">
                      <div className="subtitle-section text-prelo-semibold">
                        Sobre el crédito que necesitas
                      </div>
                    </div>
                  </pichincha-grid>
                )}

              {this.props.creditData.buyingIntentionTime !== "" &&
                this.props.creditData.buyingIntentionTime !== "empty" &&
                this.props.selected_button_product === "new" && (
                  <pichincha-grid item xs="12">
                    <pichincha-grid container spacing={4}>
                      <pichincha-grid item xs="12">
                        <div className="bp-mb-12">
                          <div className="text-label">
                            {disableVipVis
                              ? "De acuerdo a tu perfil y tus necesidades, el crédito para ti es:"
                              : "¿Cuál de nuestros préstamos elegirás para financiar tu vivienda?"}
                          </div>
                        </div>
                      </pichincha-grid>

                      <pichincha-grid item xs="12">
                        <Checkbox
                          type="linkbox"
                          id="checkboxSetNewUsedProduct"
                          title={titleCheck}
                          list={list}
                          lastLine={lastLine}
                          percentage={percentage}
                          classRectangle={classRectangle}
                          rectangleSelected={
                            this.props.selected_product_type === "new-used"
                          }
                          handleClick={this.setNewUsedProduct}
                          fullSize
                          linkText="Ver más"
                          handleClickLink={() => this.openModal("new-used")}
                        />
                      </pichincha-grid>

                      <pichincha-grid item xs="12">
                        {!disableVipVis && (
                          <Checkbox
                            type="linkbox"
                            id="checkboxSetVipVisProduct"
                            title={titleCheck2}
                            list={list2}
                            lastLine={lastLine2}
                            percentage={percentage2}
                            classRectangle={classRectangle}
                            rectangleSelected={
                              this.props.selected_product_type === "vip-vis"
                            }
                            handleClick={
                              !disableVipVis && this.setVipVisProduct
                            }
                            fullSize
                            linkText="Ver más"
                            handleClickLink={() => this.openModal("vip-vis")}
                          />
                        )}

                        {disableVipVis && (
                          <div className="disabled-vip-vis-message">
                            <pichincha-typography
                              variant="captionText"
                              color="darkGrey"
                              weight="bold"
                              weight_color="500"
                              align="inherit"
                            >
                              Para solicitar los créditos de Vivienda de interés
                              Público y Social:
                            </pichincha-typography>
                            <hr />
                            <List
                              data={[
                                "La vivienda que buscas comprar debe ser nueva y por estrenar, no usada.",
                                `El ingreso mensual del solicitante no debe superar los ${CONSTANTS.MAX_INCOME_VIP}. En caso solicite con su cónyuge, los ingresos mensuales combinados no deberán superar dicho monto.`,
                              ]}
                              iconName="check"
                              iconColor="darkGrey"
                              id="vip-vis-disable-messages"
                            />
                          </div>
                        )}

                        <br />
                      </pichincha-grid>
                    </pichincha-grid>
                  </pichincha-grid>
                )}

              {this.props.creditData.buyingIntentionTime !== "" &&
                this.props.creditData.buyingIntentionTime !== "empty" &&
                (this.props.selected_button_product === "used" ||
                  this.props.selected_product_type === "new-used" ||
                  this.props.selected_product_type === "vip-vis") && (
                  <>
                    <LivingPlaceLoanSection />
                  </>
                )}

              <pichincha-grid item xs="12">
                <div className="button-container">
                  <StepsButtons
                    loadingContinue={this.props.expressApplication_loading}
                    currentPage="CreditData"
                    textContinue={
                      failuresCount || failuresPrequalificationCount
                        ? "Reenviar"
                        : "Ver precalificación"
                    }
                    disabledContinue={this.disableButton()}
                    textBack="Regresar"
                    idContinue="sendExpressRequestButton"
                    idBack="backButtonExpressRequestCreditData"
                    disabledBack={false}
                    continueHandler={(e: any) =>
                      this.execSendExpressApiCreator(e, noLogin)
                    }
                    backHandler={this.backward}
                  />
                </div>
              </pichincha-grid>

              <pichincha-grid item xs="12">
                <div className="last-text">
                  Al hacer clic en “Ver precalificación” autorizas a Banco
                  Pichincha a obtener tus referencias personales y/o crediticias
                  de fuentes legales de información y a contactarte utilizando
                  los datos ingresados en el formulario de solicitud.
                </div>
              </pichincha-grid>
            </div>
          </pichincha-grid>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: ReduxState) => {
  const {
    expressRequest,
    calculator,
    site,
    channels,
    civilRegistration,
    login,
  } = state;
  const {
    channel,
    jwt,
    utmHeaders,
    otpSuccess,
    fingeprintCodeSuccess,
    userType,
  } = site;
  const {
    firstName,
    firstLastName,
    secondName,
    secondLastName,
    thirdName,
    acceptLdpd,
  } = civilRegistration;
  const { idCardLogin, fingerprintCode } = login;
  const {
    currentStep,
    selected_button_product,
    selected_credit_product_type,
    title_products_enable,
    inputs_enable,
    personalData,
    workData,
    creditData,
    expressApplication_loading,
    showAlert,
    failuresCount,
    failuresPrequalificationCount,
    dataProtectionModalOpen,
  } = expressRequest;
  const {
    selected_product_type,
    house_price_error,
    requested_amount_error,
    loan_term_years_error,
    calculator_page,
    house_price,
    requested_amount,
    loan_term_years,
    amount,
    months,
    product,
  } = calculator;
  const { adviser, builder, project, agencyUUID } = channels;
  return {
    currentStep,
    selected_button_product,
    selected_credit_product_type,
    selected_product_type,
    title_products_enable,
    inputs_enable,
    personalData,
    workData,
    creditData,
    house_price_error,
    requested_amount_error,
    loan_term_years_error,
    calculator_page,
    house_price,
    requested_amount,
    loan_term_years,
    amount,
    months,
    product,
    channel,
    expressApplication_loading,
    showAlert,
    failuresCount,
    adviser,
    builder,
    project,
    agencyUUID,
    jwt,
    utmHeaders,
    firstName,
    firstLastName,
    secondName,
    secondLastName,
    thirdName,
    idCardLogin,
    otpSuccess,
    fingeprintCodeSuccess,
    failuresPrequalificationCount,
    fingerprintCode,
    userType,
    acceptLdpd,
    dataProtectionModalOpen,
  };
};

const {
  setStep,
  setSelectedCreditProduct,
  getMortgageProduct,
  setSelectedProduct,
  toggleProducts,
  toggleCompleteCreditData,
  toggleInputsStyle,
  toggleInputs,
  updateInputContent,
  resetExpressRequest,
  toggleModal,
  sendExpressRegistration,
  updateStateValue,
  handleDataProtectionModalOpen,
} = actions;

const connector = connect(mapStateToProps, {
  setStep,
  setSelectedCreditProduct,
  getMortgageProduct,
  setSelectedProduct,
  toggleProducts,
  toggleCompleteCreditData,
  toggleInputsStyle,
  toggleInputs,
  updateInputContent,
  resetExpressRequest,
  toggleModal,
  sendExpressRegistration,
  updateStateValue,
  handleDataProtectionModalOpen,
});

type ReduxProps = ConnectedProps<typeof connector>;

export default connector(CreditData);
