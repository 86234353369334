export type colorTypes = {
  [key: string]: string
}
export const COLORS: colorTypes = {
  black: '#070707',
  darkGrey50: '#f6f6f6',
  darkGrey100: '#ececed',
  darkGrey200: '#dbdbdc',
  darkGrey300: '#b7b7b9',
  darkGrey400: '#929296',
  darkGrey500: '#4a4a50',
  grayscale500: '#2C2C30',
  grayscale400: '#6E6E73',
  grayscale300: '#B7B7B9',
  white: '#ffffff',
  danger: '#d50707',
  danger50: '#fbe6e6',
  danger100: '#f7cdcd',
  danger200: '#ee9c9c',
  danger300: '#e66a6a',
  danger400: '#dd3939',
  danger500: '#D50707',
  danger600: '#ce0505',
  danger700: '#c70404',
  danger800: '#c00202',
  danger900: '#b90101',
  info: '#2f7abf',
  info50: '#eaf1f8',
  info100: '#d5e4f2',
  info200: '#accae5',
  info300: '#82afd9',
  info400: '#5995cc',
  info500: '#2F7ABF',
  info600: '#276db6',
  info700: '#1f60ac',
  info800: '#1854a2',
  info900: '#104798',
  success: '#31a451',
  success50: '#ecf6ee',
  success100: '#daeedf',
  success200: '#b4dcbe',
  success300: '#8bc99d',
  success400: '#61b679',
  success500: '#31A451',
  success600: '#299846',
  success700: '#218c3b',
  success800: '#19812f',
  success900: '#107524',
  warning: '#ffa000',
  warning50: '#fef0e5',
  warning100: '#fde1cc',
  warning200: '#fcc399',
  warning300: '#faa466',
  warning400: '#f98633',
  warning500: '#F76800',
  warning600: '#f65b00',
  warning700: '#f44f00',
  warning800: '#f34300',
  warning900: '#f13700',
  strongBlue300: '#88b7e3',
  strongBlue500: '#2f7abf',
  strongBlue700: '#003f7f',
  moderateCyan300: '#8eddd8',
  moderateCyan500: '#54d2b4',
  moderateCyan700: '#00af89',
  grayishRed300: '#e1bbb0',
  grayishRed500: '#c89696',
  grayishRed700: '#896464',
  yellowGold50: '#fffcf2',
  yellowGold100: '#fff7cd',
  yellowGold200: '#ffe69c',
  yellowGold300: '#ffda6a',
  yellowGold400: '#ffcd39',
  yellowGold500: '#ffc108',
  yellowGold600: '#ffb705',
  yellowGold700: '#ffae02',
  yellowGold800: '#ffa400',
  yellowGold900: '#ff9b00',
  darkCyan100: '#c7f9e2',
  darkCyan200: '#92f4d0',
  darkCyan300: '#59dfba',
  darkCyan400: '#30c0a5',
  darkCyan500: '#009688',
  darkCyan600: '#008180',
  darkCyan700: '#00626c',
  darkCyan800: '#004757',
  darkCyan900: '#003448',
  pureOrange100: '#fff2cc',
  pureOrange200: '#ffe299',
  pureOrange300: '#ffcd66',
  pureOrange400: '#ffb93f',
  pureOrange500: '#ff9800',
  pureOrange600: '#db7900',
  pureOrange700: '#b75e00',
  pureOrange800: '#934500',
  pureOrange900: '#7a3400',
  darkGrayishBlue50: '#F9FAFC',
  darkGrayishBlue100: '#f4f6f9',
  darkGrayishBlue200: '#e9ecf3',
  darkGrayishBlue300: '#dee3ed',
  darkGrayishBlue400: '#d3d9e7',
  darkGrayishBlue500: '#c7cfe1',
  darkGrayishBlue600: '#bec7db',
  darkGrayishBlue700: '#b5bfd6',
  darkGrayishBlue800: '#adb8d1',
  darkGrayishBlue900: '#a4b0cb',
  yellow50: '#fffbe5',
  yellow100: '#fff8cc',
  yellow200: '#fff199',
  yellow300: '#ffeb66',
  yellow400: '#ffe433',
  yellow500: '#ffdd00',
  yellow600: '#ffd700',
  yellow700: '#ffd100',
  yellow800: '#ffcb00',
  yellow900: '#ffc500',
  blue50: '#e7e9ee',
  blue100: '#cfd4de',
  blue200: '#9fa8be',
  blue300: '#6f7d9d',
  blue400: '#3f517d',
  blue500: '#0F265C',
  blue600: '#0c1f50',
  blue700: '#091944',
  blue800: '#061239',
  blue900: '#030c2d',
  error200: '#ee9c9c',
  error300: '#f27e65',
  error500: '#d50707',
  error600: '#b70514',
  error700: '#99031d',
} as const

export const FONTS = {
  PRELOSLAB_BOOK: 'preloslab-book',
  PRELOSLAB_SEMIBOLD: 'preloslab-semibold',
  PRELO_MEDIUM: 'prelo-medium',
  PRELO_BOLD: 'prelo-bold',
  PRELO_SEMIBOLD: 'prelo-semibold',
  PRELO_LIGHT: 'prelo-light',
  PRELO_EXTRALIGHT: 'prelo-extralight',
  PRELO_BOOK: 'prelo-book'
} as const
