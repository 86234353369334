import { FunctionComponent, useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { useRouteMatch } from "react-router";
import { Redirect } from "react-router-dom";
import queryString from "query-string";
import actions from "../../actions";
import { ReduxState } from "../../common/store";
import {
  CHANNEL,
  CHANNEL_ROUTES,
  ENABLED_CHANNELS,
} from "../../common/texts/expressApplication";
import { PATHS } from "../../common/helpers/paths";
import actionTypes from "../../actions/actionTypes";

interface RouteParams {
  channel: string;
}

const ChannelLoader: FunctionComponent<ReduxProps> = ({
  channel,
  jwt,
  fingeprintCodeSuccess,
  otpSuccess,
  setChannel,
  cleanSession,
  setUtmHeaders,
  resetExpressRequest,
  resetCivilRegistration,
}) => {
  const match = useRouteMatch();
  const { params } = match;
  const routeChannel =
    ((params as RouteParams).channel as CHANNEL) || CHANNEL.Portal;
  const currentChannel = CHANNEL_ROUTES[routeChannel];

  useEffect(() => {
    if (
      (channel !== currentChannel && currentChannel !== CHANNEL.Portal) ||
      !channel
    ) {
      setChannel(currentChannel);
    }
  }, [channel, currentChannel, setChannel]);

  const { url = "" } = match;

  useEffect(() => {
    if (
      !url.includes(PATHS.expressApplication) &&
      // !url.includes(PATHS.facephi) &&
      !url.includes(PATHS.login)
    ) {
      cleanSession();
      resetExpressRequest(actionTypes.EXPRESS_REQUEST_RESET_STATE);
      resetCivilRegistration();
    }
  });

  const finalValidation = otpSuccess || fingeprintCodeSuccess;
  const validSession = jwt && finalValidation;

  if (url.includes(PATHS.expressApplication) && !validSession) {
    return <Redirect to={PATHS.login} />;
  }

  if (
    url === PATHS.home ||
    url === PATHS.login ||
    url === PATHS.calculator ||
    ENABLED_CHANNELS.indexOf((params as RouteParams).channel) !== -1
  ) {
    setUtmHeaders(
      queryString.parse(window.location.search),
      document.referrer,
      currentChannel
    );
  }

  return null;
};

const mapStateToProps = (state: ReduxState) => {
  const { site } = state;

  const { channel, jwt, fingeprintCodeSuccess, otpSuccess } = site;

  return {
    channel,
    jwt,
    fingeprintCodeSuccess,
    otpSuccess,
  };
};

const connector = connect(mapStateToProps, {
  setChannel: actions.setChannel,
  cleanSession: actions.cleanSession,
  setUtmHeaders: actions.setUtmHeaders,
  resetExpressRequest: actions.resetExpressRequest,
  resetCivilRegistration: actions.resetCivilRegistration,
});
type ReduxProps = ConnectedProps<typeof connector>;
export default connector(ChannelLoader);
