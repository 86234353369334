import Title from "../Title";
import Text from "../Text";
import List from "../List.tsx/List";
import { useState } from "react";

interface LivingPlaceCard {
  listId: string;
  title: string;
  description: string;
  subtitle: string;
  percentage: string;
  benefits: Array<string>;
}

function LivingPlaceCardMobile({
  title,
  description,
  subtitle,
  percentage,
  benefits,
  listId,
}: LivingPlaceCard) {
  const [moreDetails, setMoreDetails] = useState(false);
  const benefitsTodispaly = benefits.map((benefit) => {
    return benefit;
  });
  benefitsTodispaly.shift();

  return (
    <>
      <Title content={title} />
      <Text content={description} />
      <hr />
      <pichincha-typography
        variant="legalText"
        color="darkGrey"
        weight="normal"
        weight_color="400"
        align="inherit"
      >
        {subtitle}
      </pichincha-typography>
      <div className="percentage-box">
        <pichincha-typography
          variant="h2"
          color="darkGrey"
          weight="bold"
          weight_color="600"
          align="inherit"
        >
          {percentage}
        </pichincha-typography>
        <div
          className="toggle-details"
          onClick={() => setMoreDetails(!moreDetails)}
          data-testid="togglerTestId"
        >
          <pichincha-typography
            variant="actionText"
            color="info"
            weight_color="500"
            data-testid="togglerTextTestId"
          >
            Ver detalles
          </pichincha-typography>
          <div className="arrow">
            <pichincha-icon color="info" weight_color="500">
              {moreDetails ? "arrow_drop_up" : "arrow_drop_down"}
            </pichincha-icon>
          </div>
        </div>
      </div>
      {moreDetails ? (
        <>
          <hr />
          <List
            data={benefitsTodispaly}
            iconName="check"
            iconColor="success"
            textColor="darkGrey"
            iconWeight="500"
            id={listId}
          />
        </>
      ) : (
        <></>
      )}
    </>
  );
}

export default LivingPlaceCardMobile;
