import React from "react";

import InfoIcon from "../../../assets/icons/info.svg";
import RecaptchaDisclaimer from "../../RecaptchaDisclaimer/RecaptchaDisclaimer";
import useWindowSize from "../../Shared/hooks/UseWindowSize";
import Buttons from "./Buttons";
import "./PrequalificationResponses.scss";

interface RejectedPageI {
  name: string;
}
function RejectedPage(props: RejectedPageI) {
  const [width] = useWindowSize();
  const { name } = props;
  let title = "";

  if (name) {
    title = `Con los datos que nos has proporcionado, por ahora,  no podemos ofrecerte una respuesta en línea`;
  }

  if (!name) {
    title = `Con los datos que nos has proporcionado, por ahora,  no podemos ofrecerte una respuesta en línea`;
  }

  return (
    <div className="rejected-container mobile-container" data-testid="rejectedPageTestId">
      <div className="info-icon">
        <img src={InfoIcon} alt="" />
      </div>
      <div className="header-container bp-mt-40">
        <pichincha-typography variant={width > 375 ? "h2" : "h3"} weight="bold" color="blue" weight_color="600" align="center" >{title}</pichincha-typography>
        <div className="header-container__separator" />
        <pichincha-typography variant="bodyText" weight_color="400" align="center" > Si quieres más información, te recomendamos acercarte a tu <a href="https://www.pichincha.com/portal/geolocalizacion" className="header-container__link" > agencia más cercana. </a></pichincha-typography>
        <pichincha-typography variant="bodyText" weight_color="400" align="center" > Queremos ayudarte a que logres tus metas. ¡Estamos para ti! </pichincha-typography>
      </div>
      
      <Buttons
        backText="Salir"
        backClickHandler={() => (window.location.href = "/")}
        backId="backButtonOfferPage"
        continueText="Buscar agencia"
        specialClass="justify-flex-center"
        size="extra-large"
        continueClickHandler={() =>
          (window.location.href = "https://www.pichincha.com/portal/geolocalizacion")
        }
        continueId="continueButtonOfferPage"
      />
      <div className="recaptcha bottom">
        <RecaptchaDisclaimer />
      </div>
    </div>
  );

}

export default RejectedPage;
