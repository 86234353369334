import React, { Component } from "react";

interface CheckBoxContainProps{
  title: string;
  list: Array<string>;
  lastLine: string;
  percentage: string;
}
class CheckBoxContain extends Component<CheckBoxContainProps> {
  render() {
    return (
      <div className="textBox-limit">
        <div className="box-title">{this.props.title}</div>
        <div className="list">
          <ul>
            {this.props.list.map((list, index) => (
              <li key={index}>{`${list}`}</li>
            ))}
            <li>
              {this.props.lastLine}
              <label className="">{this.props.percentage}</label>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

export default CheckBoxContain;
