import { FC } from "react";
import InfoError from "../../../../assets/icons/info-error.svg";
import InfoIcon from "../../../../assets/icons/info.svg";
import { LDPD_DOC, PATH_HOME } from "../../../../common/helpers/constants";
import CheckboxInput from "../../../CheckboxInput/CheckboxInput";
import "./auth-data-protection.scss";

export interface AuthDataProtectionI {
  exitModal: () => void;
  loadingState: boolean;
  error: boolean;
  authorizeDataProtection: boolean;
  handleCheckBoxChange: () => void;
}

export const AuthDataProtection: FC<AuthDataProtectionI> = ({
  exitModal,
  loadingState,
  error,
  authorizeDataProtection,
  handleCheckBoxChange,
}) => {
  return (
    <div className="container-lpdp">
      <div className="container-lpdp__msg">
        <img
          src={InfoIcon}
          alt="info proteccion de datos"
          height={50}
          width={50}
          className="bp-mb-32"
        />
        <div className="bp-mb-8">
          <pichincha-typography
            variant="h2"
            weight-color="600"
            color="blue"
            weight="bold"
            align="center"
          >
            Autoriza el tratamiento de tus datos personales
          </pichincha-typography>
        </div>
        <div className="bp-mb-32">
          <pichincha-typography align="center">
            Tu seguridad y privacidad es nuestra prioridad.
          </pichincha-typography>
        </div>
        <div className="bp-mb-32">
          <pichincha-typography align="center">
            Usaremos tu información para evaluar oportunidades de crédito,
            entregar ofertas personalizadas y ofrecer la mejor experiencia.
          </pichincha-typography>
        </div>
        <div className="bp-mb-32 ">
          <pichincha-typography color="info">
            <span
              className="container-lpdp__msg__link"
              onClick={() => {
                window.open(PATH_HOME + LDPD_DOC, "_blank");
              }}
            >
              Seguir leyendo la autorización
            </span>
          </pichincha-typography>
        </div>
      </div>
      <div className="container-lpdp__bottom bp-pt-24">
        <div>
          <CheckboxInput
            id="authorizeDataProtection"
            checked={authorizeDataProtection}
            handleClick={handleCheckBoxChange}
            label="He leído y comprendo la autorización para el tratamiento de datos personales."
            state={error ? "error" : "normal"}
          />
          {error && (
            <div className="info-error">
              <div className="info-error__img">
                <img src={InfoError} alt="error" height={16} width={16} />
              </div>
              <span>
                {" "}
                Para continuar debes aceptar la casilla de verificación.{" "}
              </span>
            </div>
          )}
        </div>
        <div className="container-lpdp__bottom__accept">
          <pichincha-button onClick={exitModal} loading={loadingState}>
            Autorizar y continuar
          </pichincha-button>
        </div>
      </div>
    </div>
  );
};
