import { Component } from "react";
import { Helmet } from "react-helmet";

interface HtmlMetaTagProps {
  title: string;
  description?: string;
}

export class HtmlMetaTag extends Component<HtmlMetaTagProps> {
  render() {
    const { title, description } = this.props;

    return (
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
      </Helmet>
    );
  }
}

export default HtmlMetaTag;