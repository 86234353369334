import { FunctionComponent } from "react";
import { connect, ConnectedProps } from "react-redux";
import actions from "../../../actions";
import actionTypes from "../../../actions/actionTypes";
import { ReduxState } from "../../../common/store";
import InputField from "../../InputField/InputField";

const ENABLED_CHANNELS = ["proyectos"];

const BuilderFields: FunctionComponent<ReduxProps> = ({
  builder,
  builderState,
  project,
  projectState,
  channel,
  setProjectInformation,
}) => {
  const handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    const fieldsActionTypes: any = {
      builder: actionTypes.SET_LOAN_APPLICATION_BUILDER,
      project: actionTypes.SET_LOAN_APPLICATION_PROJECT,
    };
    const { value, id } = event.currentTarget;
    if (value.length >= 50) {
      return;
    }
    setProjectInformation(
      fieldsActionTypes[id],
      value.replace(/[^A-zÀ-ÿ ]/g, "")
    );
  };

  if (!ENABLED_CHANNELS.includes(channel)) {
    return null;
  }

  return (
    <pichincha-grid item xs="12">
      <pichincha-grid container justify="center" data-testid="builderFieldsTestId">
        <InputField
          id="builder"
          testId="builder"
          label="Constructora"
          placeholder="Ej.: Constructura Pichincha"
          errorHelper="Por favor, completa el campo"
          normalHelper=""
          classWrap=" builder md-mb-0"
          state={builderState}
          xs="12"
          lg="6"
          disabled={false}
          currentValue={builder}
          handleChange={handleChange}
        />
        <InputField
          id="project"
          testId="project"
          label="Proyecto"
          placeholder="Ej.: Proyecto Pichincha"
          errorHelper="Por favor, completa el campo"
          normalHelper=""
          classWrap=" builder md-mb-0"
          state={projectState}
          xs="12"
          lg="6"
          disabled={false}
          currentValue={project}
          handleChange={handleChange}
        />
      </pichincha-grid>
    </pichincha-grid>
  );
};

const mapStateToProps = (state: ReduxState) => {
  const { channels, site } = state;
  const {
    builder,
    builderState,
    project,
    projectState,
  } = channels;
  const { channel } = site;

  return {
    channel,
    builder,
    project,
    builderState,
    projectState,
  };
};

const connector = connect(mapStateToProps, {
  setProjectInformation: actions.setProjectInformation,
});
type ReduxProps = ConnectedProps<typeof connector>;
export default connector(BuilderFields);
