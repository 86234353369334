import React, { Component } from "react";
// @ts-ignore
import { Link } from "react-router-dom";

import "./FinalButtonsSection.scss";

interface ButtonsProps {
  handleClick: () => void;
}

class FinalButtonsSection extends Component<ButtonsProps> {
  render() {
    const idStartRequestCalculator = "StartExpressRequestCalculator";
    const list = [
      `
      Las estimaciones de la calculadora son referenciales. Por tanto, no constituyen una pre-aprobación del crédito ni otorgamiento del mismo por parte de Banco Pichincha.
      `,
      `
      Los seguros son obligatorios de acuerdo a la normativa vigente y la prima mensual que se verán reflejados en la tabla de amortización.
      `,
    ];

    return (
      <div className="calculation-results-buttons">
        <div className="section-container-desktop">
          <pichincha-grid
            container
            direction="row"
            justify="center"
            align_content="center"
            align_items="center"
            wrap="wrap-reverse"
            spacing={8}
          >
            <pichincha-grid item>
              <div
                className="large-button"
                onClick={this.props.handleClick}
                data-testid="resetCalculationButtonTestId"
              >
                <pichincha-button
                  size="large"
                  id="recalculateButton"
                  color="secondary"
                >
                  Volver a calcular
                </pichincha-button>
              </div>
            </pichincha-grid>

            <pichincha-grid item>
              <pichincha-link href="/site-in-construction">
                <div className="large-button">
                  <Link to="/precalificacion">
                    <pichincha-button
                      size="large"
                      id={idStartRequestCalculator}
                      color="primary"
                    >
                      Comenzar ahora
                    </pichincha-button>
                  </Link>
                </div>
              </pichincha-link>
            </pichincha-grid>
          </pichincha-grid>
        </div>
        <div className="section-container-mobile">
          <pichincha-grid
            container
            direction="row"
            justify="center"
            align_content="center"
            align_items="center"
            wrap="wrap-reverse"
            spacing={6}
          >
            <pichincha-grid item>
              <div className="large-button" onClick={this.props.handleClick}>
                <pichincha-button
                  size="large"
                  id="RcalculateButton"
                  color="secondary"
                >
                  Volver a calcular
                </pichincha-button>
              </div>
            </pichincha-grid>

            <pichincha-grid item>
              <pichincha-link href="/precalificacion">
                <div className="large-button">
                  <Link to="/precalificacion">
                    <pichincha-button
                      size="large"
                      id={idStartRequestCalculator}
                      color="primary"
                    >
                      Comenzar ahora
                    </pichincha-button>
                  </Link>
                </div>
              </pichincha-link>
            </pichincha-grid>
          </pichincha-grid>

          <div className="list">
            <ul>
              {list.map((item, index) => (
                <li key={index}>{`${item}`}</li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export default FinalButtonsSection;
