import { useEffect, useRef } from "react";

interface ListProps {
  data: Array<string>;
  iconName: string;
  iconColor: string;
  iconWeight?: string;
  id: string;
  textColor?: string;
  textWeight?: string;
}

export default function List(props: ListProps) {
  const pichinchaList = useRef();

  useEffect(() => {
    const myList: any = pichinchaList.current;
    const list: { label: string }[] = [];
    props.data.forEach((element) => {
      list.push({ label: element });
    });
    myList.data = list;
  }, [props.data, props.id]);

  return (
    <pichincha-list
      ref={pichinchaList}
      id={props.id}
      variant={props.iconName}
      icon-color={props.iconColor}
      icon-weight-color={props.iconWeight}
      color={props.textColor}
      weight-color={props.textWeight}
      data-testid={`${props.id}-testId`}
    ></pichincha-list>
  );
}
