import { validate as validateEmail } from 'email-validator';
import { AnyAction } from 'redux';

import { INITIAL_STATE } from '.';
import { FACEPHI_INTERACTION_COOKIES } from '../../common/helpers/constants';
import {
  IdentificationValidator,
  buyingIntentionTimeRequired,
  deleteInteractionsCookies,
  getCities,
  getInputNameState,
  getPhoneState,
  getProvince,
  getTextState,
  isMarried as isMarriedFunction,
  isSingle as isSingleFunction,
} from '../../common/helpers/functions';
import { ExpressRequestState } from '../../common/helpers/integration';
import {
  AGENCY_CHANNELS,
  PUBLIC_CHANNELS,
  workSeniorityMap,
} from '../../common/texts/expressApplication';


export const getCurrentWorkSeniorityStatus = (
  personType: string,
  currentWorkStatus: string,
  initialOption: boolean,
  updatedOption: string
) => {
  if (!!!workSeniorityMap[personType][currentWorkStatus]) {
    return {
      workSeniority: '',
      workSeniorityState: 'normal',
      workSeniorityStateError: false,
      workSeniorityStateErrorHelper: '',
    };
  }

  let currentSeniorityOption: any;
  if (initialOption) {
    currentSeniorityOption =
      workSeniorityMap[personType][currentWorkStatus].options[0];
  } else {
    currentSeniorityOption = workSeniorityMap[personType][
      currentWorkStatus
    ].options.filter((option: any) => option.value === updatedOption)[0];
  }

  const workSeniority: string = currentSeniorityOption.value;
  const workSeniorityState: string = getTextState(
    !!currentSeniorityOption.invalid,
    currentSeniorityOption.errorHelper || ''
  );
  const workSeniorityStateError: boolean = !!currentSeniorityOption.invalid;
  const workSeniorityStateErrorHelper: string =
    currentSeniorityOption.errorHelper || '';
  return {
    workSeniority,
    workSeniorityState,
    workSeniorityStateError,
    workSeniorityStateErrorHelper,
  };
};

export const setStep = (action: AnyAction, state: ExpressRequestState) => {
  return {
    ...state,
    currentStep: action.newStep,
    completedPersonalData:
      action.newStep === 2 && !state.completedPersonalData
        ? true
        : state.completedPersonalData,
    completedWorkData:
      action.newStep === 3 && !state.completedWorkData
        ? true
        : state.completedWorkData,
    completedLoanData:
      action.newStep === 4 && !state.completedLoanData
        ? true
        : state.completedLoanData,
    showAlert: false,
  };
};

export const updateBuyingIntentionTime = (
  action: AnyAction,
  state: ExpressRequestState
) => {
  const buyingIntentionTimeState = buyingIntentionTimeRequired(action.newValue);

  return {
    ...state,
    creditData: {
      ...state.creditData,
      buyingIntentionTime: action.newValue,
      buyingIntentionTimeState,
      buyingIntentionTimeStateError: buyingIntentionTimeState === 'error',
    },
    completedLoanData:
      buyingIntentionTimeState === 'error' ? false : state.completedLoanData,
  };
};

export const updatePersonalDataName = (
  action: AnyAction,
  state: ExpressRequestState
) => {
  const nameState = getInputNameState(action.newValue);
  return {
    ...state,
    personalData: {
      ...state.personalData,
      name: action.newValue,
      nameState: nameState,
      nameStateError: nameState === 'normal' ? false : true,
    },
    completedPersonalData:
      nameState === 'error' ? false : state.completedPersonalData,
  };
};

export const updatePersonalDataLastName = (
  action: AnyAction,
  state: ExpressRequestState
) => {
  const lastNameState = getInputNameState(action.newValue);
  return {
    ...state,
    personalData: {
      ...state.personalData,
      lastName: action.newValue,
      lastNameState: lastNameState,
      lastNameStateError: lastNameState === 'normal' ? false : true,
    },
    completedPersonalData:
      lastNameState === 'error' ? false : state.completedPersonalData,
  };
};

export const updatePersonalDataPhone = (
  action: AnyAction,
  state: ExpressRequestState
) => {
  const phoneState = getPhoneState(action.newValue, 8);
  return {
    ...state,
    personalData: {
      ...state.personalData,
      phone: action.newValue,
      phoneState: phoneState,
      phoneStateError: phoneState === 'normal' ? false : true,
    },
    completedPersonalData:
      phoneState === 'error' ? false : state.completedPersonalData,
  };
};

export const updatePersonalDataEmail = (
  action: AnyAction,
  state: ExpressRequestState
) => {
  const emailState = validateEmail(action.newValue) ? 'normal' : 'error';
  return {
    ...state,
    personalData: {
      ...state.personalData,
      email: action.newValue,
      emailState: emailState,
      emailStateError: emailState === 'normal' ? false : true,
    },
    completedPersonalData:
      emailState === 'error' ? false : state.completedPersonalData,
  };
};

export const updatePersonalDataMaritalStatus = (
  action: AnyAction,
  state: ExpressRequestState
) => {
  const isSingle = isSingleFunction(action.newValue);
  const currentMaritalStatusIsMarried = isMarriedFunction(
    state.personalData.maritalStatus
  );
  return {
    ...state,
    personalData: {
      ...state.personalData,
      maritalStatus: action.newValue,
      maritalStatusStateError: action.newValue !== 'empty' ? false : true,
      idCardPartner: isSingle ? '' : state.personalData.idCardPartner,
      idCardPartnerState: isSingle
        ? 'normal'
        : state.personalData.idCardPartnerState,
      idCardPartnerStateError:
        isSingle || currentMaritalStatusIsMarried ? false : true,
      separationOfProperty: isSingle
        ? ''
        : state.personalData.separationOfProperty,
    },
    workData: {
      ...state.workData,
      partnerMonthlyIncome: isSingle ? '' : state.workData.partnerMonthlyIncome,
      partnerMonthlyIncomeNumber: isSingle
        ? 0
        : state.workData.partnerMonthlyIncomeNumber,
      partnerMonthlyIncomeState: isSingle
        ? 'normal'
        : state.workData.partnerMonthlyIncomeState,
      partnerMonthlyIncomeError: isSingle
        ? false
        : state.workData.partnerMonthlyIncomeError,
      partnerWorkStatusError:
        isSingle || state.personalData.separationOfProperty === 'yes'
          ? false
          : state.workData.partnerWorkStatusError,
      partnerWorkStatus: isSingle ? 'empty' : state.workData.partnerWorkStatus,
      workStatus: 'empty',
      workStatusError: true,
      workStatusState: 'normal',
      workSeniority: 'empty',
      workSeniorityState: 'normal',
      workSeniorityStateError: true,
      monthlyIncome: '',
      monthlyIncomeError: true,
      monthlyIncomeErrorHelper: '',
      monthlyIncomeState: 'normal',
    },
    completedPersonalData:
      action.newValue === 'empty' ? false : state.completedPersonalData,
  };
};

export const updatePersonalDataSeparationOfProperty = (
  action: AnyAction,
  state: ExpressRequestState
) => {
  return {
    ...state,
    personalData: {
      ...state.personalData,
      separationOfProperty: action.newValue,
      separationOfPropertyStateError: action.newValue ? false : true,
      idCardPartner:
        action.newValue === 'yes' ? '' : state.personalData.idCardPartner,
      idCardPartnerState:
        action.newValue === 'yes'
          ? 'normal'
          : state.personalData.idCardPartnerState,
      idCardPartnerStateError: action.newValue === 'yes' ? false : true,
    },
    workData: {
      ...state.workData,
      partnerMonthlyIncomeError: action.newValue === 'yes' ? false : true,
      partnerWorkStatusError: action.newValue === 'yes' ? false : true,
      workStatus: 'empty',
      workStatusError: true,
      workStatusState: 'normal',
      workSeniority: 'empty',
      workSeniorityState: 'normal',
      workSeniorityStateError: true,
      monthlyIncome: '',
      monthlyIncomeError: true,
      monthlyIncomeErrorHelper: '',
      monthlyIncomeState: 'normal',
      partnerWorkSeniorityStateError: action.newValue === 'yes' ? false : true,
    },
    completedPersonalData:
      action.newValue === '' ? false : state.completedPersonalData,
  };
};

export const updatePersonalDataIdCard = (
  action: AnyAction,
  state: ExpressRequestState
) => {
  const idCardState = !IdentificationValidator(action.newValue)
    ? 'error'
    : 'normal';
  return {
    ...state,
    personalData: {
      ...state.personalData,
      idCard: action.newValue,
      idCardState: idCardState,
      idCardStateError: idCardState === 'normal' ? false : true,
    },
    completedPersonalData:
      idCardState === 'error' ? false : state.completedPersonalData,
  };
};

export const updatePersonalDataIdCardPartner = (
  action: AnyAction,
  state: ExpressRequestState
) => {
  const idCardPartnerState = !IdentificationValidator(action.newValue)
    ? 'error'
    : 'normal';
  return {
    ...state,
    personalData: {
      ...state.personalData,
      idCardPartner: action.newValue,
      idCardPartnerState: idCardPartnerState,
      idCardPartnerStateError: idCardPartnerState === 'normal' ? false : true,
    },
    completedPersonalData:
      idCardPartnerState === 'error' ? false : state.completedPersonalData,
  };
};

export const updateWorkDataWorkStatus = (
  action: AnyAction,
  state: ExpressRequestState
) => {
  const {
    workSeniority,
    workSeniorityState,
    workSeniorityStateError,
    workSeniorityStateErrorHelper,
  } = getCurrentWorkSeniorityStatus('creditHolder', action.newValue, true, '');

  return {
    ...state,
    workData: {
      ...state.workData,
      workStatus: action.newValue,
      workStatusError: action.newValue !== 'empty' ? false : true,
      workStatusState: action.newValue !== 'empty' ? 'normal' : 'error',
      workSeniority,
      workSeniorityState,
      workSeniorityStateError,
      workSeniorityStateErrorHelper,
      monthlyIncome: '',
      monthlyIncomeError: true,
      monthlyIncomeState: 'normal',
      monthlyIncomeErrorHelper: '',
      previousEndWork: null,
      previousInitWork: null,
    },
    completedWorkData:
      action.newValue !== 'empty' ? false : state.completedWorkData,
  };
};

export const updateWorkDataPartnerWorkStatus = (
  action: AnyAction,
  state: ExpressRequestState
) => {
  const {
    workSeniority: partnerWorkSeniority,
    workSeniorityState: partnerWorkSeniorityState,
    workSeniorityStateError: partnerWorkSeniorityStateError,
  } = getCurrentWorkSeniorityStatus('partner', action.newValue, true, '');
  const validWorkStatus = action.newValue !== 'empty';
  const validMontlhyIncome = action.newValue === 'unemployed';

  return {
    ...state,
    workData: {
      ...state.workData,
      partnerWorkStatus: action.newValue,
      partnerWorkStatusError: validWorkStatus ? false : true,
      partnerWorkStatusState: validWorkStatus ? 'normal' : 'error',
      partnerWorkSeniority,
      partnerWorkSeniorityState,
      partnerWorkSeniorityStateError,
      partnerMonthlyIncome: '',
      partnerMonthlyIncomeState: 'normal',
      partnerMonthlyIncomeError: validMontlhyIncome ? false : true,
      partnerPreviousEndWork: null,
      partnerPreviousInitWork: null,
    },
    completedWorkData:
      action.newValue !== 'empty' ? false : state.completedWorkData,
  };
};

export const completedWorkData = (
  errorState: boolean,
  completedWorkDataState: boolean
) => {
  if (errorState) {
    return false;
  } else {
    return completedWorkDataState;
  }
};

export const updateProvinceResidence = (
  action: AnyAction,
  state: ExpressRequestState
) => {
  const provinceReidenceInputState = getProvince(action.newValue);
  return {
    ...state,
    personalData: {
      ...state.personalData,
      provinceResidence: action.newValue,
      provinceResidenceState: provinceReidenceInputState,
      provinceResidenceStateError:
        provinceReidenceInputState === 'normal' ? false : true,
      capitalResidence: '',
    },
    completedPersonalData:
      provinceReidenceInputState === 'error'
        ? false
        : state.completedPersonalData,
  };
};

export const updateCapitalResidence = (
  action: AnyAction,
  state: ExpressRequestState
) => {
  const capitalResidenceInputState = getCities(
    action.newValue,
    state.personalData.provinceResidence
  );
  return {
    ...state,
    personalData: {
      ...state.personalData,
      capitalResidence: action.newValue,
      capitalResidenceState: capitalResidenceInputState,
      capitalResidenceStateError:
        capitalResidenceInputState === 'normal' ? false : true,
    },
    completedPersonalData:
      capitalResidenceInputState === 'error'
        ? false
        : state.completedPersonalData,
  };
};

export const expressAplicationSuccess = (
  action: AnyAction,
  state: ExpressRequestState
) => {
  window.scrollTo(0, 0);

  if ((action.isPrequalification || action.isProspect) && !action.data) {
    return {
      ...INITIAL_STATE,
      showUnauthorizedPage: true,
    };
  }

  if (action.isProspect) {
    const { token: facephiToken } = action.data;

    return {
      ...state,
      showAlert: false,
      facephiToken,
      payload: action.payload,
    };
  }

  if (action.isPrequalification && !state.prequalificationSuccess) {
    const {
      status: offerStatus,
      totalAmount,
      monthlyFee,
      annualRate,
      paymentTerm,
      estimatedDebt,
      isMVP,
    } = action.data;
    const isOffer = offerStatus === 200;
    const isCounterOffer = offerStatus === 201;
    return {
      ...state,
      expressApplication_loading: false,
      showAlert: false,
      showCounterofferPage: isCounterOffer,
      showOfferPage: isOffer,
      prequalificationSuccess: true,
      prequalificationData: {
        totalAmount,
        monthlyFee,
        annualRate,
        paymentTerm,
        estimatedDebt,
        isMVP,
      },
      payload: action.payload || state.payload,
    };
  }

  const isAgencyChannel = AGENCY_CHANNELS.includes(action.channel);
  deleteInteractionsCookies(FACEPHI_INTERACTION_COOKIES)
  return {
    ...state,
    expressApplication_loading: false,
    expressApplication_success: !isAgencyChannel,
    expressApplicationAgencySuccess: isAgencyChannel,
    currentStep: 4,
    completedLoanData: true,
    showAlert: false,
    showCounterofferPage: false,
    showOfferPage: false,
  };
};

const requiresJWTValidation = (action: AnyAction) => {
  return action.isPrequalification || action.isProspect;
};

const lastTryValidation = (state: ExpressRequestState) => {
  return state.failuresCount >= 1 || state.failuresPrequalificationCount >= 1;
};

const showSuccessPageNotAgencyValidation = (action: AnyAction) => {
  return (
    action.requestStatus === 202 && PUBLIC_CHANNELS.includes(action.channel)
  );
};

const showRejectedPageValidation = (action: AnyAction) => {
  return (
    action.isPrequalification &&
    action.requestStatus === 202 &&
    action.data.status === 202
  );
};
const showServiceErrorPageValidation = (
  validation1: boolean,
  validation2: boolean
) => {
  if (validation1 && validation2) {
    deleteInteractionsCookies(FACEPHI_INTERACTION_COOKIES)
  }
  return validation1 && validation2;
};

export const expressAplicationFailed = (
  action: AnyAction,
  state: ExpressRequestState
) => {
  const { requestStatus } = action;
  const showUnauthorizedPage = requestStatus === 401;
  const requiresJwtValidation = requiresJWTValidation(action);

  if (
    (requiresJwtValidation && !action.data && requestStatus === 202) ||
    showUnauthorizedPage
  ) {
    deleteInteractionsCookies(FACEPHI_INTERACTION_COOKIES)
    return {
      ...INITIAL_STATE,
      showUnauthorizedPage: true,
    };
  }

  const lastTry = lastTryValidation(state);

  let prequalificationFailuresCount = 0;
  let applicationFaliresCount = 0;

  if (action.isPrequalification) {
    prequalificationFailuresCount = state.failuresPrequalificationCount + 1;
  }

  if (!action.isPrequalification || action.isProspect) {
    applicationFaliresCount = state.failuresCount + 1;
  }

  const showSuccessPageNotAgency = showSuccessPageNotAgencyValidation(action);
  const serviceError = [201, 401, 202].indexOf(requestStatus) < 0;
  window.scrollTo(0, 0);
  const showRejectedPage = showRejectedPageValidation(action);

  const showServiceErrorPage = showServiceErrorPageValidation(
    serviceError,
    lastTry
  );

  return {
    ...state,
    expressApplication_loading: false,
    expressApplication_error: true,
    showUnauthorizedPage: showUnauthorizedPage,
    showSomethingWentWrongPage: showServiceErrorPage,
    expressApplicationAgencyError: !showSuccessPageNotAgency && !serviceError,
    expressApplication_success: showSuccessPageNotAgency && !serviceError,
    currentStep:
      showSuccessPageNotAgency && !serviceError ? 4 : state.currentStep,
    error422: showSuccessPageNotAgency && !serviceError,
    failuresCount: applicationFaliresCount,
    failuresPrequalificationCount: prequalificationFailuresCount,
    showAlert: true,
    completedLoanData: showSuccessPageNotAgency,
    showRejectedPage: showRejectedPage,
    showCounterofferPage:
      showServiceErrorPage && state.showCounterofferPage
        ? false
        : state.showCounterofferPage,
    showOfferPage:
      showServiceErrorPage && state.showOfferPage ? false : state.showOfferPage,
  };
};
