import { Component } from "react";
import { connect, ConnectedProps } from "react-redux";
import actions from "../../actions";
import { logout } from "../../common/helpers/functions";
import { ReduxState } from "../../common/store";
import RecaptchaDisclaimer from "../RecaptchaDisclaimer/RecaptchaDisclaimer";
import IconWithText from "./IconWithText";
import OtpPage from "./OtpPage";
import StartPage from "./StartPage/StartPage";

class Login extends Component<ReduxProps> {
  componentDidMount() {
    if (this.props.jwt) {
      logout(this.props.logoutBack, this.props.channel, this.props.jwt);
    }
  }

  render() {
    const { stepLogin: currentStep } = this.props;
    return (
      <>
        {[0, 1, 2].includes(currentStep) && (
          <pichincha-grid container justify="center">
            <pichincha-grid class="left-block" item xl="4" xs="12">
              <div className="left-content">
                <div className="title-leftBlock">
                  El camino a tu sueño comienza aquí
                </div>
                <div className="subtitle">
                  Descubre el monto al que podrías acceder en menos de 5 minutos
                </div>
                <p className="text-label-mb">
                  Te pediremos los siguientes datos:
                </p>
                <IconWithText
                  icon="person"
                  text="Datos personales"
                  class="text-label"
                />
                <IconWithText
                  icon="work_outline"
                  text="Datos laborales"
                  class="text-label"
                />
                <IconWithText
                  icon="house"
                  text="Datos del crédito a solicitar"
                  class="text-label"
                />
              </div>
            </pichincha-grid>
            <pichincha-grid item xl="8" xs="12">
              <div className="right-content">
                <StartPage />
              </div>
              <RecaptchaDisclaimer />
            </pichincha-grid>
          </pichincha-grid>
        )}
        {currentStep === 3 && (
          <pichincha-grid container justify="center">
            <pichincha-grid item xl="12" xs="12">
              <div className="otp-align">
                <OtpPage />
              </div>
              <RecaptchaDisclaimer />
            </pichincha-grid>
          </pichincha-grid>
        )}
      </>
    );
  }
}
const mapStateToProps = (state: ReduxState) => {
  const { login, site } = state;
  const { stepLogin } = login;
  const { jwt, channel } = site;
  return { stepLogin, jwt, channel };
};

const { updateInputContent, logoutBack, cleanSession, resetExpressRequest } =
  actions;

const connector = connect(mapStateToProps, {
  updateInputContent,
  logoutBack,
  cleanSession,
  resetExpressRequest,
});

type ReduxProps = ConnectedProps<typeof connector>;

export default connector(Login);
