export const SET_LOGIN_STEP = '@@dreambuilders/SET_LOGIN_STEP';
export const UPDATE_INPUT_ID_CARD_LOGIN =
  '@@dreambuilders/UPDATE_INPUT_ID_CARD_LOGIN';
export const UPDATE_INPUT_PHONE_LOGIN =
  '@@dreambuilders/UPDATE_INPUT_PHONE_LOGIN';
export const UPDATE_INPUT_EMAIL_LOGIN =
  '@@dreambuilders/UPDATE_INPUT_EMAIL_LOGIN';
export const UPDATE_INPUT_FINGERPRINT_CODE =
  '@@dreambuilders/UPDATE_INPUT_FINGERPRINT_CODE';
export const TOGGLE_LOGIN_ID_CARD = '@@dreambuilders/TOGGLE_LOGIN_ID_CARD';

export const CIF_REQUEST = '@@dreambuilders/CIF_REQUEST';
export const CIF_SUCCESS = '@@dreambuilders/CIF_SUCCESS';
export const CIF_FAILED = '@@dreambuilders/CIF_FAILED';

export const TRANSACTIONAL_CONTACT_REQUEST =
  '@@dreambuilders/TRANSACTIONAL_CONTACT_REQUEST';
export const TRANSACTIONAL_CONTACT_SUCCESS =
  '@@dreambuilders/TRANSACTIONAL_CONTACT_SUCCESS';
export const TRANSACTIONAL_CONTACT_FAILED =
  '@@dreambuilders/TRANSACTIONAL_CONTACT_FAILED';

export const TOGGLE_ALERT_LOGIN = '@@dreambuilders/TOGGLE_ALERT_LOGIN';

export const TOGGLE_CONTACT_INFO_AUTHORIZATION =
  '@@dreambuilders/TOGGLE_CONTACT_INFO_AUTHORIZATION';

export const TOGGLE_FINGERPRINT_CODE_AUTHORIZATION =
  '@@dreambuilders/TOGGLE_FINGERPRINT_CODE_AUTHORIZATION';

export const FINGERPRINT_CODE_REQUEST =
  '@@dreambuilders/FINGERPRINT_CODE_REQUEST';
export const FINGERPRINT_CODE_SUCCESS =
  '@@dreambuilders/FINGERPRINT_CODE_SUCCESS';
export const FINGERPRINT_CODE_FAILED =
  '@@dreambuilders/FINGERPRINT_CODE_FAILED';

export const REDIRECT_TO_EXPRESS_APPLICATION =
  '@@dreambuilders/REDIRECT_TO_EXPRESS_APPLICATION';

export const CLEAR_REDIRECTION_TO_EXPRESS_APPLICATION =
  '@@dreambuilders/CLEAR_REDIRECTION_TO_EXPRESS_APPLICATION';

export const UPDATE_OTP_VALUE = '@@dreambuilders/UPDATE_OTP_VALUE';
export const UPDATE_OTP_TIME = '@@dreambuilders/UPDATE_OTP_TIME';
export const RESET_OTP_ERROR = '@@dreambuilders/RESET_OTP_ERROR';

export const OTP_REQUEST = '@@dreambuilders/OTP_REQUEST';
export const OTP_FAILED = '@@dreambuilders/OTP_FAILED';
export const OTP_SUCCESS = '@@dreambuilders/OTP_SUCCESS';

export const OTP_RESEND_REQUEST = '@@dreambuilders/OTP_RESEND_REQUEST';
export const OTP_RESEND_FAILED = '@@dreambuilders/OTP_RESEND_FAILED';
export const OTP_RESEND_SUCCESS = '@@dreambuilders/OTP_RESEND_SUCCESS';

export const LOGIN_RESET_STATE = '@@dreambuilders/LOGIN_RESET_STATE';

export const SENDING_REQUEST = '@@dreambuilders/SENDING_REQUEST';
