import React, {Component} from "react";
import {MAX_AMOUNT_VIP, MIN_AMOUNT_VIS, NEW_USED_RATE, SQUARE_METER_VAL, MAX_INCOME_VIP} from "../common/helpers/constants";
import "../components/ModalProducts.scss";

interface ModalProps {
  close: any;
  section: string
}
const list = [
  "El valor mínimo  de la vivienda debe ser $15.000.",
  "Te financiamos hasta el 80%  del valor de la vivienda que deseas adquirir incluido gastos legales, avalúos y tributo SOLCA.",
  "El monto mínimo del préstamo debe ser de $3.000",
  `Tasa de interés anual referencial de ${NEW_USED_RATE}%.`,
  "Plazo de pago de 3 a 20 años.",
];
const listVipVis = [
  "Aplica para viviendas nuevas, no para viviendas usadas.",
  `El ingreso mensual del solicitante no debe superar los ${MAX_INCOME_VIP}. En caso solicite con su cónyuge, los ingresos mensuales combinados no deberán superar dicho monto.`,
  "La vivienda debe calificar como Vivienda de Interés Público (VIP) o de interés social (VIS).",
  `El valor de la vivienda a financiar debe estar entre ${MIN_AMOUNT_VIS} y los ${MAX_AMOUNT_VIP}.`,
  "El área mínima de la vivienda debe ser 57m2.",
  `El valor del m2 debe ser menor a ${SQUARE_METER_VAL}.`,
  "Te financiamos hasta el 100% del valor de la vivienda que deseas adquirir, incluido gastos legales, avalúos y tributo SOLCA.",
  "Tasa especial de interés anual referencial de 4.87%.",
  "Plazo de pago de 20 a 25 años.",
];
class ModalProducts extends Component<ModalProps> {
  render() {
    return (
      <div
        className="backModal calculator-section-test"
        data-testid="modalContainerTestId"
        onClick={this.props.close}
      >
        <div className="position">
          <pichincha-grid
            container
            justify="center"
            align_content="center"
            align_items="center"
          >
            <pichincha-grid item>
              <div className="box-modal">
                <div className="modal-header" data-testid="icon">
                  <div className="icon-close">
                    <pichincha-icon
                      size="24px"
                      color="darkGrey"
                      weight_color="400"
                      type="--outlined"
                      onClick={this.props.close}
                      data-testid="modalCloseButtonTestId"
                    >
                      close
                    </pichincha-icon>
                  </div>
                </div>
                <div className="content-modal">
                  {
                    (this.props.section === 'new-used' ||
                      this.props.section === '')
                    &&
                    <>
                      <label className="title-product">
                        Vivienda nueva o usada*
                      </label>
                      <p className="text-blue-500 text-prelo-medium text-font-size-14">
                        Para ecuatorianos o extranjeros que trabajen al menos 5 años
                        en el país que deseen comprar una casa o departamento ya sea
                        nuevo o usado.
                      </p>
                      <ul>
                        {list.map((listNewHouse, index) => (
                          <li key={index}>{`${listNewHouse}`}</li>
                        ))}
                      </ul>
                      {
                        this.props.section === ''
                        && <div className="last-line"></div>
                      }
                      {
                        this.props.section === 'new-used'
                        && <>
                          <div className="last-line"></div>
                          <div className="last-line">*Aplican condiciones</div>
                        </>
                      }
                    </>
                  }

                  {
                    (this.props.section === 'vip-vis' ||
                      this.props.section === '')
                    && <>
                      <label className="title-product">
                        Vivienda de Interés Público y Social (VIP y VIS)*
                      </label>
                      <p className="text-blue-500 text-prelo-medium text-font-size-14">
                        Créditos destinados a la compra de tu primera vivienda.
                      </p>
                      <ul>
                        {listVipVis.map((listVipVisHouse, index) => (
                          <li key={index}>{`${listVipVisHouse}`}</li>
                        ))}
                      </ul>
                      <div className="last-line"></div>
                      <div className="last-line">*Aplican condiciones</div>
                    </>
                  }

                </div>

                {
                  this.props.section === ''
                  && <>
                    <div className="divider"></div>
                    <div className="footer-modal">
                      <div className="buttons-container">
                        <pichincha-link
                          href="/guia_hipotecaria?section=opciones_credito"
                          target="_blank"
                        >
                          <pichincha-button
                            size="large"
                            id="viewGuideModalButton"
                            color="primary"
                          >
                            Ver guía
                          </pichincha-button>
                        </pichincha-link>
                      </div>
                    </div>
                  </>
                }

              </div>
            </pichincha-grid>
          </pichincha-grid>
        </div>
      </div>
    );
  }
}
export default ModalProducts;
