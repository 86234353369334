import { FC } from 'react'
import { LABELS_CALENDAR, MSG_FORMS, YEAR_OPTIONS } from '../../../../../common/helpers/constants';
import Calendar from '../../../../Calendar'
import { getDateLastJob, getDefaultDate, GetLastJobProps } from '../use-last-job/helper'


export interface UseInitWorkProps {
  validData: boolean
  data: any
  setData: (e: any) => void
  onlyInit: boolean
  cleanDate?: boolean
  setcleanDate?: (e: any) => void
  setDisabledEndCalendar?: (e: any) => void
  setListYearsEnd?: (e: any) => void
  setListDisabledMonths?: (e: any) => void
}

const UseInitWork: FC<UseInitWorkProps> = ({
  validData,
  data,
  setData,
  onlyInit,
  cleanDate,
  setcleanDate,
  setDisabledEndCalendar,
  setListYearsEnd,
  setListDisabledMonths,
}) => {
  const handleInitWork = (item: string) => {
    const propsGetDateLasJob: GetLastJobProps = {
      monthAndYear: item,
      type: 'init',
      onlyInit: onlyInit,
      setDisabledEndCalendar,
      setListYearsEnd,
      setListDisabledMonths
    }
    setData({
      property: 'previousInitWork',
      value: getDateLastJob(propsGetDateLasJob),
    })
    if (setcleanDate) setcleanDate(false)
  }
 

  return (
    <Calendar
      onSelect={(e: string) => handleInitWork(e)}
      listYears={YEAR_OPTIONS}
      label={onlyInit ? LABELS_CALENDAR.INIT_ACTIVITIES : LABELS_CALENDAR.INIT_DATE}
      error={
        validData &&
        data.previousInitWork === null &&
        data.previousEndWork !== null
      }
      errorMsg={MSG_FORMS.INCOMPLETE_SELECT}
      cleanDate={cleanDate}
      defaultValue={getDefaultDate(data.previousInitWork)}
    />
  )
}

export default UseInitWork
