

import Navbar from '../navbar/Navbar';
import CallToAction from '../CallToAction/CallToAction';
import BenefitsBox from '../BenefitsBox/BenefitsBox';
import LivingPlaces from '../LivingPlaces/LivingPlaces';
import Footer from '../Footer/Footer';
import VerticalSteps from '../Steps/VerticalSteps';
import FooterBP from '../FooterBP/FooterBP';


const Home = () => {
  return (
    <div className="wrapper-content">
      <Navbar />
      <div className="page-content">
        <CallToAction />
        <LivingPlaces />
        <VerticalSteps />
        <BenefitsBox />
        <FooterBP />
        <Footer />
      </div>
    </div>
  );
};

export default Home;
