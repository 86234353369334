import React from "react";
import PersonPinIcon from "../../../assets/icons/person_pin.svg";
import ForumIcon from "../../../assets/icons/forum.svg";
import CameraFront from "../../../assets/icons/camera_front.svg";
import MortgageCredit from "../../../assets/icons/MortgageCredit.svg"
interface InfoCardI {
  icon: string;
  iconSize?: number;
  title: string;
  titleVariant?: string;
  titleWeight?: string;
  content: any;
  extraClassNames?: string;
}



const InfoCard = ( props: InfoCardI) => {
  const { icon, iconSize, title, titleVariant, titleWeight,  content, extraClassNames } = props;

  const icons: { [key: string]: any } = {
    person_pin: PersonPinIcon,
    forum: ForumIcon,
    camera_front: CameraFront,
    mortgage_credit: MortgageCredit
  };

  return (
    <div className={`card-info text-prelo-medium ${extraClassNames || ""}`}>
    <div className="icon bg-white prequalification">
      <img src={icons[icon]} width={iconSize} height={iconSize} alt="" />
    </div>
    <div className="content-wrapper">
      <pichincha-typography variant={titleVariant} weight={titleWeight} color={titleVariant === 'h3'? 'blue': 'grey'}>{title}</pichincha-typography>
      
      {content}
    </div>
  </div>
  )
}

export default InfoCard