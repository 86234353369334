import Cookies from "js-cookie";
import { Cipher, Decrypted } from "../utils/aes-util/use-aes";

const setItemToCookie = (key: string, value: any) => {
    Cookies.set(key, window.btoa(JSON.stringify(value)));
}

const setEncryptedItemToCookie = (key: string, value: any) => {
    Cookies.set(key, Cipher(window.btoa(JSON.stringify(value))))
    localStorage.setItem(key, Cipher(window.btoa(JSON.stringify(value))))
}

const getItemFromCookie = (key: string): any => {
  return JSON.parse(window.atob(Cookies.get(key) || '') || '{}');
};

const getEncryptedItemFromCookie = (key: string): any => {
  const decryptToken = Decrypted((Cookies.get(key) as string || localStorage.getItem(key)) || '')
  const token = window.atob(decryptToken)
  return token !== '' ? JSON.parse(token) : ''
}

const removeItemFromCookie = (key: string) => {
  Cookies.remove(key);
};

export { getEncryptedItemFromCookie, getItemFromCookie, removeItemFromCookie, setEncryptedItemToCookie, setItemToCookie };

