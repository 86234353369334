import React, { Component } from "react";

import Popover from "../../Popover";

interface MortgageContainProps {
  requestedAmount: string;
  appraisal: string;
  legalFee: string;
  solca: string;
  mainFee: string;
  firstClass: string;
  firstId: string;
  secondId: string;
  thirdId: string;
  fourthId: string;
  lastId: string;
  chosenProductPercentage: string;
  chosenProductName: string;
}

class MortgageDetailsContent extends Component<MortgageContainProps> {
  render() {
    return (
      <div
        className={this.props.firstClass}
        data-testid={this.props.firstClass}
      >
        <pichincha-grid container justify="space-between">
          <pichincha-grid item xs="8">
            <Popover
              id={this.props.firstId}
              linkName="Monto para tu vivienda"
              popoverContent={
                <div>
                  En el caso del producto{" "}
                  <strong>{this.props.chosenProductName}</strong> te financiamos
                  hasta el <strong>{this.props.chosenProductPercentage}</strong>{" "}
                  que deseas adquirir incluido gastos legales, avalúos y
                  contribución SOLCA.
                </div>
              }
            />
          </pichincha-grid>
          <pichincha-grid item xs="4">
            <div className="text-right bp-mb-8">
              <pichincha-typography
                variant="smallText"
                color="blue"
                weight_color="500"
                weight="bold"
              >
                ${this.props.requestedAmount}
              </pichincha-typography>
            </div>
          </pichincha-grid>
        </pichincha-grid>
        <pichincha-grid container justify="space-between">
          <pichincha-grid item xs="8">
            <Popover
              id={this.props.secondId}
              linkName="Gastos de avalúo"
              popoverContent=" Costo generado por el informe pericial que determina el valor
                  del inmueble (dicho costo puede variar de acuerdo a la
                  ubicación)."
            />
          </pichincha-grid>
          <pichincha-grid item xs="1">
            <div className="text-center">
              <pichincha-typography
                variant="smallText"
                color="blue"
                weight_color="500"
              >
                +
              </pichincha-typography>
            </div>
          </pichincha-grid>
          <pichincha-grid item xs="3">
            <div className="text-right bp-mb-8">
              <pichincha-typography
                variant="smallText"
                color="blue"
                weight_color="500"
              >
                ${this.props.appraisal}
              </pichincha-typography>
            </div>
          </pichincha-grid>
        </pichincha-grid>
        <pichincha-grid container justify="space-between">
          <pichincha-grid item xs="8">
            <Popover
              id={this.props.thirdId}
              linkName="Gastos legales"
              popoverContent="El trámite de compraventa e hipoteca genera costos legales
                  adicionales: impuestos de transferencia de dominio, notaría,
                  estudio jurídico, inscripciones y otros. Los valores
                  presentados son referenciales."
            />
          </pichincha-grid>
          <pichincha-grid item xs="1">
            <div className="text-center">
              <pichincha-typography
                variant="smallText"
                color="blue"
                weight_color="500"
              >
                +
              </pichincha-typography>
            </div>
          </pichincha-grid>
          <pichincha-grid item xs="3">
            <div className="text-right bp-mb-8">
              <pichincha-typography
                variant="smallText"
                color="blue"
                weight_color="500"
              >
                ${this.props.legalFee}
              </pichincha-typography>
            </div>
          </pichincha-grid>
        </pichincha-grid>
        <pichincha-grid container justify="space-between">
          <pichincha-grid item xs="8">
            <Popover
              id={this.props.fourthId}
              linkName="Contribución SOLCA"
              popoverContent="                  Impuesto obligatorio del 0.5% que se aplica al valor del monto
                  total del crédito para financiar la atención integral del
                  cáncer en Ecuador."
            />
          </pichincha-grid>
          <pichincha-grid item xs="1">
            <div className="text-center">
              <pichincha-typography
                variant="smallText"
                color="blue"
                weight_color="500"
              >
                +
              </pichincha-typography>
            </div>
          </pichincha-grid>
          <pichincha-grid item xs="3">
            <div className="text-right bp-mb-8">
              <pichincha-typography
                variant="smallText"
                color="blue"
                weight_color="500"
              >
                ${this.props.solca}
              </pichincha-typography>
            </div>
          </pichincha-grid>
        </pichincha-grid>
        <pichincha-grid container>
          <pichincha-grid item xs="12">
            <div className="has-separator bp-mb-8"></div>
          </pichincha-grid>
        </pichincha-grid>
        <pichincha-grid container justify="space-between">
          <pichincha-grid item xs="8">
            <Popover
              id={this.props.lastId}
              linkName="Monto total a financiar"
              popoverContent="Este es el monto que solicitaste, al cual se le suma la
                  contribución SOLCA, los gastos legales y de avalúo, valores
                  referenciales que son opcionales dentro del financiamiento de
                  tu crédito."
            />
          </pichincha-grid>
          <pichincha-grid item xs="1">
            <div className="text-center">
              <pichincha-typography
                variant="smallText"
                color="blue"
                weight_color="500"
              >
                =
              </pichincha-typography>
            </div>
          </pichincha-grid>
          <pichincha-grid item xs="3">
            <div className="text-right bp-mb-8">
              <pichincha-typography
                variant="smallText"
                color="blue"
                weight_color="500"
              >
                ${this.props.mainFee}
              </pichincha-typography>
            </div>
          </pichincha-grid>
        </pichincha-grid>
      </div>
    );
  }
}

export default MortgageDetailsContent;
