import React, { Component } from "react";

import Popover from "../../Popover";

import { TotalValuesProps } from "../../../common/helpers/integration";

export default class TotalValuesDesktop extends Component<TotalValuesProps> {
  render() {
    return (
      <div className="result-card desktop ">
        <button className="accordion desktop-card">
          Valores totales referenciales
        </button>
        <div className="panel d-block total-values">
          <div className="register bp-mb-8">
            <div>
              <pichincha-typography
                variant="smallText"
                color="blue"
                weight_color="500"
                weight="bold"
              >
                Monto total a financiar
              </pichincha-typography>
            </div>
            <div>
              <pichincha-typography
                variant="smallText"
                color="blue"
                weight_color="500"
                weight="bold"
              >
                ${this.props.mainFee}
              </pichincha-typography>
            </div>
          </div>
          <div className="register bp-mb-8">
            <div>
              <Popover
                id="totalInterestsPopover"
                linkName="Total intereses"
                popoverContent="Este es el valor total de intereses generados durante el
              periódo de tu crédito."
              />
            </div>
            <div>
              <pichincha-typography
                variant="smallText"
                color="blue"
                weight_color="500"
              >
                ${this.props.interests}
              </pichincha-typography>
            </div>
          </div>
          <div className="register bp-mb-8">
            <div>
              <Popover
                id="totalInsurancesPopover"
                linkName="Total seguros"
                popoverContent="Esta es la suma de los valores totales que pagarás por el
                seguro de desgravamen y el seguro contra incendio y
                terremoto"
              />
            </div>
            <div>
              <pichincha-typography
                variant="smallText"
                color="blue"
                weight_color="500"
              >
                ${this.props.insurances}
              </pichincha-typography>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
