export const EMPTY_IDENTIFICATION = 'Este dato es obligatorio';
export const INCOMPLETE_IDENTIFICATION = 'El campo debe tener 10 dígitos';
export const INVALID_IDENTIFICATION = 'Por favor, ingresa una cédula válida en Ecuador';

export const EMPTY_PHONE = 'Este dato es obligatorio';
export const INCOMPLETE_PHONE = 'Ingresa un número de celular válido. Debe tener 10 dígitos.';

export const EMPTY_EMAIL = 'Este dato es obligatorio';
export const INVALID_EMAIL = 'Por favor, ingresa un correo válido';

export const EMPTY_FINGERPRINT_CODE = 'Este dato es obligatorio';
export const INCOMPLETE_FINGERPRINT_CODE = 'Ingresa un número dáctilar completo. Debe tener 10 caracteres.';
export const INVALID_FINGERPRINT_CODE = 'Ingresa un código dactilar válido';
