import { combineReducers } from 'redux';
import calculator from './calculator';
import callMeBack from './callmeback';
import site from './site';
import expressRequest from './expressRequest';
import feedback from './feedback';
import channels from './channels';
import login from './login';
import civilRegistration from './civilRegistration';

const rootReducer = combineReducers({
  calculator,
  callMeBack,
  expressRequest,
  site,
  feedback,
  channels,
  login,
  civilRegistration,
});

export default rootReducer;
