import { FunctionComponent } from "react";
import { Redirect } from "react-router-dom";

import { connect, ConnectedProps } from "react-redux";
import { ReduxState } from "../../common/store";

import "./Login.scss";
import Footer from "../Footer/Footer";
import Navbar from "../navbar/Navbar";
import Login from "./Login";
import SomethingWentWrong from "../Errors/SomethingWentWrong";
import Blocked from "../Errors/Blocked";

const Index: FunctionComponent<ReduxProps> = ({
  showBlockPage,
  showBlockPageFingerprintCode,
  showSomethingWentWrongPage,
  redirectTo,
}) => {
  if (redirectTo) {
    return <Redirect to={redirectTo} />;
  }

  let content = <Login />;

  if (showSomethingWentWrongPage) {
    content = (
      <SomethingWentWrong handleClick={() => window.location.reload()} />
    );
  }

  if (showBlockPage || showBlockPageFingerprintCode) {
    content = (
      <div>
        <Blocked
          handleClick={() => window.location.reload()}
          blockingTimeMessage="Reinténtalo en 24 horas."
        />
      </div>
    );
  }

  return (
    <div className="wrapper-content" data-testid="login-index">
      <Navbar conecctionFailNavbar={true} currentLogin={true} />
      <div className="login-content fit-footer page-content">
        <div className="mt-5">{content}</div>
        <Footer />
      </div>
    </div>
  );
};

const mapStateToProps = (state: ReduxState) => {
  const { login } = state;
  const {
    showSomethingWentWrongPage,
    showBlockPage,
    showBlockPageFingerprintCode,
    cifFailuresCount,
    redirectTo,
  } = login;

  return {
    showSomethingWentWrongPage,
    showBlockPage,
    showBlockPageFingerprintCode,
    cifFailuresCount,
    redirectTo,
  };
};

const connector = connect(mapStateToProps, {});

type ReduxProps = ConnectedProps<typeof connector>;

export default connector(Index);
