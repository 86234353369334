import React, { Component } from "react";
import Box from "./Box";

const title = "Los beneficios que te brindamos";
const animateClass = "wow animated fadeIn";

class BenefitsBox extends Component {
  render() {
    return (
      <div className="benefits-box container-section wow animated fadeIn" data-wow-duration="3s">
        <h2 className="h2-title">{title}</h2>
        <div className="box-content">
          <Box
            icon="access_time"
            text="Proceso ágil"
            subtext="Solicita tu crédito de manera simple y rápida"
            animateClass={animateClass}
            animateDuration="2s"
          />
          <Box
            icon="people"
            text="Estarás acompañado"
            subtext="Contarás con nuestra asesoría personalizada a lo largo del camino para cumplir tu sueño"
            animateClass={animateClass}
            animateDuration="3s"
          />
          <Box
            icon="notifications"
            text="Monitorea el avance"
            subtext="Te informaremos en todo momento del estado de tu solicitud"
            animateClass={animateClass}
            animateDuration="4s"
          />
          <Box
            icon="monetization_on"
            text="Flexibilidad para pagar"
            subtext="Podrás realizar abonos y pre-cancelaciones sin recargo"
            animateClass={animateClass}
            animateDuration="5s"
          />
        </div>
      </div>
    );
  }
}

export default BenefitsBox;
