import React, { useEffect } from "react";

import { connect, ConnectedProps } from "react-redux";
import { ReduxState } from "../../../common/store";
import "./work-data.scss";
import actions from "../../../actions/";
import actionTypes from "../../../actions/actionTypes";

import {
  isMarried as isMarriedFunction,
  isNumber,
} from "../../../common/helpers/functions";

import {
  partnerWorkOptions,
  workOptions,
  workSeniorityMap,
} from "../../../common/texts/expressApplication";

import StepsGuide from "../../StepsGuide/StepsGuide";
import TitlePage from "../TitlePage/TitlePage";

import InputField from "../../InputField/InputField";
import SelectField from "../../InputField/SelectField";

import { sendGTMUserEvent } from "../../../common/helpers/gtmEvents";
import { ResponseCalendarI } from "../../../interfaces/app";
import UseInitWork from "../../Shared/Components/use-last-job/use-init-work/UseInitWork";
import { UseLastJob } from "../../Shared/Components/use-last-job/use-last-job/UseLastJob";
import "../PersonalData/PersonalData.scss";
import StepsButtons from "../StepsButtons.tsx/StepsButtons";
import { checkDates } from "./helper-work-data";

const WorkData = (props: ReduxProps) => {
  useEffect(() => {
    sendGTMUserEvent(props.channel, "datosLaborales", props.userType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeWorkStatus = (e: React.FormEvent<HTMLInputElement>) => {
    const newValue = e.currentTarget.value;
    props.updateInputContent(
      newValue,
      actionTypes.UPDATE_INPUT_WORK_DATA_WORK_STATUS
    );
  };

  const handleChangeWorkSeniority = (
    e: React.FormEvent<HTMLInputElement>,
    invalid: boolean
  ) => {
    const newValue = e.currentTarget.value;
    props.updateWorkDataWorkSeniority(
      actionTypes.UPDATE_INPUT_WORK_DATA_SENIORITY,
      newValue,
      invalid
    );
  };

  const handleChangePartnerWorkStatus = (
    e: React.FormEvent<HTMLInputElement>
  ) => {
    const newValue = e.currentTarget.value;
    props.updateInputContent(
      newValue,
      actionTypes.UPDATE_INPUT_WORK_DATA_PARTNER_WORK_STATUS
    );
  };

  const handleChangeWorkPartnerSeniority = (
    e: React.FormEvent<HTMLInputElement>,
    invalid: boolean
  ) => {
    const newValue = e.currentTarget.value;
    props.updateWorkDataWorkSeniority(
      actionTypes.UPDATE_INPUT_WORK_DATA_PARTNER_SENIORITY,
      newValue,
      invalid
    );
  };

  const handleChangeMonthlyIncome = (e: React.FormEvent<HTMLInputElement>) => {
    const newValue = e.currentTarget.value;
    if (!newValue) {
      props.updateInputContent(
        newValue,
        actionTypes.UPDATE_INPUT_WORK_DATA_MONTHLY_INCOME,
        props.basicSalary
      );
      return;
    }

    if (!isNumber(newValue[newValue.length - 1]) || newValue.length > 8) {
      return;
    }

    props.updateInputContent(
      newValue,
      actionTypes.UPDATE_INPUT_WORK_DATA_MONTHLY_INCOME,
      props.basicSalary
    );
    props.setSelectedProduct("");
  };

  const handleChangePartnerMonthlyIncome = (
    e: React.FormEvent<HTMLInputElement>
  ) => {
    const newValue = e.currentTarget.value;
    if (!newValue) {
      props.updateInputContent(
        newValue,
        actionTypes.UPDATE_INPUT_WORK_DATA_PARTNER_MONTHLY_INCOME,
        props.basicSalary
      );
      return;
    }

    if (!isNumber(newValue[newValue.length - 1]) || newValue.length > 8) {
      return;
    }

    props.updateInputContent(
      newValue,
      actionTypes.UPDATE_INPUT_WORK_DATA_PARTNER_MONTHLY_INCOME,
      props.basicSalary
    );
    props.setSelectedProduct("");
  };

  const handleContinue = (disabled: boolean) => {
    if (disabled) {
      return;
    }
    props.setStep(3);
    window.scrollTo(0, 0);
  };

  const back = () => {
    props.setStep(1);
    window.scrollTo(0, 0);
  };

  const { maritalStatus } = props.personalData;

  const {
    monthlyIncome,
    monthlyIncomeState,
    partnerMonthlyIncome,
    partnerMonthlyIncomeState,
    workStatus,
    partnerWorkStatus,
    workSeniority,
    partnerWorkSeniority,
    workSeniorityState,
    partnerWorkSeniorityState,
    workSeniorityStateError,
    partnerWorkSeniorityStateError,
    workSeniorityStateErrorHelper,
    monthlyIncomeErrorHelper,
    monthlyIncomeError,
    previousEndWork,
    previousInitWork,
    partnerPreviousEndWork,
    partnerPreviousInitWork,
  } = props.workData;

  const { firstName } = props.civilRegistration;

  const isMobile = window.screen.width < 992;
  const tooltipOrientation = isMobile ? "bottom" : "right";

  const monthlyIncomFieldProps = {
    label: "Tus ingresos netos mensuales",
    placeholder: "Ej.: $1.200",
    state: monthlyIncomeState,
    normalHelper: "",
    errorHelper: monthlyIncomeErrorHelper,
    iconOrientation: "prepend",
    disabled: false,
    currentValue: monthlyIncome,
    iconContent: "$",
    handleChange: handleChangeMonthlyIncome,
    testId: "monthlyIncomeInputTestId",
    lg: "12",
    id: "monthlyIncomeInput",
    classWrap: "",
    classIcon: "",
    tooltip: `Es el dinero que recibes cada mes entre tus diferentes fuentes de ingreso después de deducciones laborales (IESS, retenciones y descuentos)`,
    tooltipOrientation,
    customTooltipIcon: (
      <pichincha-icon
        size="16px"
        color="darkGrey"
        weight_color="500"
        type="--sharp"
      >
        help
      </pichincha-icon>
    ),
  };

  const partnerMonthlyIncomFieldProps = {
    label: "Ingresos mensuales de tu pareja",
    placeholder: "Ej.: $1.000",
    state: partnerMonthlyIncomeState,
    normalHelper:
      "¿Cuánto dinero recibes cada mes entre tus diferentes fuentes de ingreso después de deducciones laborales (IESS, retenciones y descuentos)?",
    errorHelper: "Campo incompleto",
    iconOrientation: "prepend",
    disabled: false,
    currentValue: partnerMonthlyIncome,
    iconContent: "$",
    handleChange: handleChangePartnerMonthlyIncome,
    testId: "partnerMonthlyIncomeInputTestId",
    lg: "12",
    classWrap: "",
    id: "partnerMonthlyIncomeInput",
    classIcon: "",
  };

  const workStatusFieldProps = {
    label: "Tu principal fuente de ingresos",
    currentValue: workStatus,
    disabled: false,
    handleChange: handleChangeWorkStatus,
    testId: "workStatusInputFieldTestId",
    lg: "12",
    xs: "12",
    options: workOptions,
    classWrap: "",
  };

  const workSeniorityValidOptions = ["dependent"];

  const validatedWorkSeniorityOptions = workSeniorityValidOptions.includes(
    workStatus
  )
    ? workSeniorityMap.creditHolder[workStatus].options
    : workSeniorityMap.creditHolder["empty"].options;

  const showWorkSeniorityOptions = (validWorkStatus: string) =>
    validWorkStatus !== "empty" && validWorkStatus === "dependent";

  const showDatesLastJob = (
    targetWorkStatus: string,
    targetWorkSeniority: string
  ) =>
    targetWorkStatus !== "empty" &&
    targetWorkStatus === "dependent" &&
    ["between_3_and_5_months", "between_6_and_11_months"].includes(
      targetWorkSeniority
    );

  const workSeniorityFieldProps = {
    label: workSeniorityMap.creditHolder[workStatus]
      ? workSeniorityMap.creditHolder[workStatus].questionTitle
      : "",
    currentValue: workSeniority,
    disabled: false,
    handleChange: (e: any) =>
      handleChangeWorkSeniority(e, workSeniorityStateError),
    testId: "workSeniorityInputFieldTestId",
    lg: "12",
    xs: "12",
    options: validatedWorkSeniorityOptions,
    classWrap: "",
    errorHelper: workSeniorityStateErrorHelper,
    state: workSeniorityState,
  };

  const validatedWorkSeniorityOptionsPartner =
    workSeniorityValidOptions.includes(partnerWorkStatus)
      ? workSeniorityMap.partner[partnerWorkStatus].options
      : workSeniorityMap.partner["empty"].options;

  const workSeniorityPartnerFieldProps = {
    label: workSeniorityMap.partner[partnerWorkStatus]
      ? workSeniorityMap.partner[partnerWorkStatus].questionTitle
      : "",
    currentValue: partnerWorkSeniority,
    disabled: false,
    handleChange: (e: any) =>
      handleChangeWorkPartnerSeniority(e, partnerWorkSeniorityStateError),
    testId: "partnerWorkSeniorityInputFieldTestId",
    lg: "12",
    xs: "12",
    options: validatedWorkSeniorityOptionsPartner,
    classWrap: "",
    state: partnerWorkSeniorityState,
  };

  const partnerWorkStatusFieldProps = {
    label: "¿Cuál es la principal fuente de ingresos de tu cónyuge?",
    currentValue: partnerWorkStatus,
    disabled: false,
    handleChange: handleChangePartnerWorkStatus,
    testId: "partnerWorkStatusInputFieldTestId",
    lg: "12",
    xs: "12",
    options: partnerWorkOptions,
    classWrap: "",
  };

  const isMarried = isMarriedFunction(maritalStatus);
  const isMarriedAndNoSeparationProperty =
    isMarried && props.personalData.separationOfProperty === "no";
  const showMonthlyIncomeField = workStatus !== "empty";

  const showDatesActivitiesStart = (workStatusActivity: string) =>
    workStatusActivity !== "empty" &&
    !["pensioner", "dependent", "unemployed"].includes(workStatusActivity);

  const showPartnerMonthlyIncomeField =
    partnerWorkStatus !== "empty" &&
    !["unemployed"].includes(partnerWorkStatus);

  const partnerDataToCheck = {
    workStatus: partnerWorkStatus,
    previousInitWork: partnerPreviousInitWork,
    previousEndWork: partnerPreviousEndWork,
  };

  const customerDataToCheck = {
    workStatus: workStatus,
    previousInitWork: previousInitWork,
    previousEndWork: previousEndWork,
  };

  const disableContinueButton =
    props.workData.monthlyIncomeError ||
    props.workData.partnerMonthlyIncomeError ||
    props.workData.workStatusError ||
    props.workData.partnerWorkStatusError ||
    checkDates(customerDataToCheck, workSeniority) ||
    (props.workData.partnerWorkSeniorityStateError &&
      isMarriedAndNoSeparationProperty) ||
    checkDates(partnerDataToCheck, partnerWorkSeniority);

  const setDatesWork = (responseCalendar: ResponseCalendarI) => {
    const newValue = responseCalendar.value;
    let actionType = actionTypes.UPDATE_CALENDAR_WORK_DATA_PREVIOUS_INIT_WORK;

    if (responseCalendar.property === "previousEndWork") {
      actionType = actionTypes.UPDATE_CALENDAR_WORK_DATA_PREVIOUS_END_WORK;
    }
    if (responseCalendar.value !== undefined) {
      props.updateInputContent(newValue, actionType);
    }
  };

  const setDatesWorkPartner = (responseCalendar: ResponseCalendarI) => {
    const newValue = responseCalendar.value;
    let actionType =
      actionTypes.UPDATE_CALENDAR_WORK_DATA_PARTNER_PREVIOUS_INIT_WORK;

    if (responseCalendar.property === "previousEndWork") {
      actionType =
        actionTypes.UPDATE_CALENDAR_WORK_DATA_PARTNER_PREVIOUS_END_WORK;
    }
    if (responseCalendar.value !== undefined) {
      props.updateInputContent(newValue, actionType);
    }
  };

  const getLastJob = (
    <UseLastJob
      validData={false}
      data={{
        previousInitWork: previousInitWork,
        previousEndWork: previousEndWork,
      }}
      setData={setDatesWork}
      cleanDate={false}
      setcleanDate={() => {}}
    />
  );

  const getLastJobPartner = (
    <UseLastJob
      validData={false}
      data={{
        previousInitWork: partnerPreviousInitWork,
        previousEndWork: partnerPreviousEndWork,
      }}
      setData={setDatesWorkPartner}
      cleanDate={false}
      setcleanDate={() => {}}
    />
  );

  const getStartActivities = (
    <UseInitWork
      data={{
        previousInitWork: props.workData.previousInitWork,
        previousEndWork: props.workData.previousEndWork,
      }}
      onlyInit={true}
      setData={setDatesWork}
      validData={false}
    />
  );

  const getStartActivitiesPartenr = (
    <UseInitWork
      data={{
        previousInitWork: props.workData.partnerPreviousInitWork,
        previousEndWork: props.workData.partnerPreviousEndWork,
      }}
      onlyInit={true}
      setData={setDatesWorkPartner}
      validData={false}
    />
  );

  return (
    <div
      className="data-form-express-request step-page"
      data-testid="workDataContainerTestId"
    >
      <div className="container-center">
        <TitlePage title="Cuéntanos de tu situación laboral" name={firstName} />
        <div>
          <StepsGuide />
        </div>
        <div className="form-input bp-mt-56 express-request">
          <div>
            <div className="bp-mb-12">
              <div className="subtitle-section text-prelo-semibold">
                Situación laboral actual
              </div>
            </div>
          </div>
          <div>
            <div className="container-center">
              <SelectField {...workStatusFieldProps} />
              {showWorkSeniorityOptions(workStatus) && (
                <SelectField {...workSeniorityFieldProps} />
              )}
            </div>
            <div
              className={
                showDatesLastJob(workStatus, workSeniority)
                  ? "d-block hydrated"
                  : "d-none"
              }
            >
              {getLastJob}
            </div>
            <div
              className={
                showDatesActivitiesStart(workStatus)
                  ? "d-block hydrated"
                  : "d-none"
              }
            >
              <div className="calendar-position">
                <div className="calendar-position__init">
                  {getStartActivities}
                </div>
              </div>
            </div>
            <div
              className={showMonthlyIncomeField ? "d-block hydrated" : "d-none"}
            >
              <InputField {...monthlyIncomFieldProps} />
            </div>
          </div>
          {!monthlyIncomeError &&
            !workSeniorityStateError &&
            !checkDates(props.workData, workSeniority) &&
            isMarriedAndNoSeparationProperty && (
              <>
                <div>
                  <div className="bp-mb-12 bp-mt-32">
                    <pichincha-typography
                      variant="h6"
                      color="darkGrey"
                      weight="bold"
                      align="left"
                    >
                      Situación laboral de tu cónyuge
                    </pichincha-typography>
                  </div>
                </div>
                <SelectField {...partnerWorkStatusFieldProps} />{" "}
                {showWorkSeniorityOptions(partnerWorkStatus) && (
                  <SelectField {...workSeniorityPartnerFieldProps} />
                )}
                <div
                  className={
                    showDatesLastJob(partnerWorkStatus, partnerWorkSeniority)
                      ? "d-block hydrated"
                      : "d-none"
                  }
                >
                  {getLastJobPartner}
                </div>
                <div
                  className={
                    showDatesActivitiesStart(partnerWorkStatus)
                      ? "d-block hydrated"
                      : "d-none"
                  }
                >
                  <div className="calendar-position">
                    <div className="calendar-position__init">
                      {getStartActivitiesPartenr}
                    </div>
                  </div>
                </div>
                {!partnerWorkSeniorityStateError &&
                  showPartnerMonthlyIncomeField && (
                    <InputField {...partnerMonthlyIncomFieldProps} />
                  )}
              </>
            )}
          <div>
            <div className="button-container">
              <StepsButtons
                currentPage="WorkData"
                textContinue="Continuar"
                disabledContinue={disableContinueButton}
                textBack="Regresar"
                disabledBack={false}
                continueHandler={() => handleContinue(disableContinueButton)}
                backHandler={back}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: ReduxState) => {
  const { expressRequest, civilRegistration, calculator, site } = state;
  const { currentStep, personalData, workData } = expressRequest;
  const { basicSalary } = calculator.product[0];
  const { channel, userType } = site;

  return {
    currentStep,
    personalData,
    workData,
    civilRegistration,
    basicSalary,
    userType,
    channel,
  };
};

const {
  setStep,
  updateInputContent,
  triggerAction,
  setSelectedProduct,
  updateWorkDataWorkSeniority,
} = actions;

const connector = connect(mapStateToProps, {
  setStep,
  updateInputContent,
  triggerAction,
  setSelectedProduct,
  updateWorkDataWorkSeniority,
});

type ReduxProps = ConnectedProps<typeof connector>;

export default connector(WorkData);
