import { FC, ReactNode, useEffect, useRef } from "react";
export interface PichinchaModalProps {
  primLabel: string;
  disablePrinBtn?: string;
  disableSecBtn?: string;
  secLabel: string;
  open: boolean;
  href?: any;
  size: string;
  testid?: string;
  chil: ReactNode;
  closeButton?: boolean;
  handleBtnPrimClick?: (e: any) => void;
  handleBtnSecClick?: (e: any) => void;
  hadleExitClick?: (e: any) => void;
}

export const UseModalBp: FC<PichinchaModalProps> = ({ ...props }) => {
  const { handleBtnPrimClick, handleBtnSecClick, hadleExitClick } = props;

  const pichinchaModal = useRef();
  useEffect(() => {
    const myModal: any = pichinchaModal.current;
    if (myModal) {
      myModal.addEventListener("clickSecondaryButton", handleBtnSecClick);
      myModal.addEventListener("clickPrimaryButton", handleBtnPrimClick);
      myModal.addEventListener("clickExit", hadleExitClick);
    }
    return () => {
      myModal?.removeEventListener("clickSecondaryButton", handleBtnSecClick);
      myModal?.removeEventListener("clickPrimaryButton", handleBtnPrimClick);
      myModal?.removeEventListener("clickExit", hadleExitClick);
    };
  }, [handleBtnPrimClick, handleBtnSecClick, hadleExitClick]);

  return (
    <pichincha-modal
      ref={pichinchaModal}
      secondary-button-label={props.secLabel}
      primary-button-label={props.primLabel}
      disabled-primary-button={props.disablePrinBtn}
      size={props.size}
      border-header={true}
      open={props.open}
      show-close-button={props.closeButton}
    >
      {props.chil}
    </pichincha-modal>
  );
};
