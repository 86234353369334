import { ENABLED_CHANNELS } from '../texts/expressApplication';

const channels = ENABLED_CHANNELS.join('|');

export const PATHS = {
  home: '/',
  homeWithChannel: `/:channel(${channels})?`,
  inConstruction: '/site-in-construction',
  calculator: '/calculadora',
  expressApplication: '/precalificacion',
  expressApplicationWithChannel: `/precalificacion/:channel(${channels})`,
  callMeBack: '/callmeback',
  mortgageGuide: '/guia_hipotecaria',
  error403: '/error_403',
  login: `/login`,
  loginWithPlusvaliaChannel: `/login/:channel(plusvalia)`,
  noClientValidation: '/validacion',
  progress: '/progreso',
  logout: '/logout'
};

const LOGOUT_CHANNELS = [
  'kam',
  'agencia',
  'app',
  'bancaweb',
  'portal_publico',
  'privada',
  'feria',
  'proyectos',
  'kam_oficina',
];

const PATHS_WITH_CHANNELS = LOGOUT_CHANNELS.map(
  (path) => `${PATHS.expressApplication}/${path}`
);

export const PATHS_WITH_LOGOUT = [
  ...PATHS_WITH_CHANNELS,
  PATHS.expressApplication,
];
