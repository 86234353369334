import React, { Component } from "react";

import "./InputField";

interface SelectFieldProps {
  label: string;
  currentValue: string;
  disabled: boolean;
  handleChange: any;
  testId: string;
  lg?: string;
  xs?: string;
  options: Array<{
    value: string;
    display: string;
  }>;
  classWrap?: string;
  errorHelper?: string;
  state?: string;
}

class SelectField extends Component<SelectFieldProps> {
  render() {
    const {
      label,
      disabled,
      handleChange,
      testId,
      options,
      currentValue,
      classWrap,
      state,
      errorHelper,
    } = this.props;

    let helper;

    if (state === "error") {
      helper = (
        <pichincha-typography
          variant="tinyText"
          color="danger"
          weight_color="400"
          data-testid="error-helper"
          class="typography hydrated error-message"
        >
          {errorHelper}
        </pichincha-typography>
      );
    }

    const wrapperClassName = `wrapper${classWrap} ${disabled ? "disabled" : ""} ${state || ""}`;
    let inputClassName = "large";

    if (currentValue === "empty" || !currentValue) {
      inputClassName += " placeholder-text";
    }

    const input = (
      <select
        name="select"
        id="select"
        className={inputClassName}
        disabled={disabled}
        data-testid={testId}
        onChange={handleChange}
        value={currentValue}
      >
        {options.map((option, index) => (
          <option key={index} value={option.value}>
            {option.display}
          </option>
        ))}
      </select>
    );

    return (
      <pichincha-grid item lg={this.props.lg || "3"} xs={this.props.xs || "12"}>
        <div className={wrapperClassName} data-testid={`wrapper-${testId}`}>
          <div className="label mobile">
            <pichincha-typography
              variant="smallText"
              color="darkGrey"
              weight="bold"
              align="left"
            >
              {label + " "}
            </pichincha-typography>
          </div>
          <div className="label desktop">
            <pichincha-typography
              variant="bodyText"
              color="darkGrey"
              weight="bold"
              align="left"
            >
              {label + " "}
            </pichincha-typography>
          </div>
          {input}
          {helper}
        </div>
      </pichincha-grid>
    );
  }
}

export default SelectField;
