import * as ActionTypes from './actionTypes';

export const setChannel = (channel: string) => ({
  type: ActionTypes.SET_CHANNEL,
  channel: channel,
});

export const cleanSession = () => ({
  type: ActionTypes.CLEAN_SESSION,
});


export const resetTokenSession = () => ({
  type: ActionTypes.RESET_JWT,
});

export const setCallMeBackButtonStatus = (isOpened: boolean) => ({
  type: ActionTypes.SET_CALLMEBACK_BUTTON_STATUS,
  state: { isOpened }
});

export const setUtmHeaders = (query: any, referer: string, channel: string) => ({
  type: ActionTypes.SET_UTM_HEADERS,
  state: { query, referer, channel }
});

