import React, { Component } from "react";

import { connect, ConnectedProps } from "react-redux";
import { ReduxState } from "../../../common/store";

import InputField from "../../InputField/InputField";
import actions from "../../../actions";
import actionTypes from "../../../actions/actionTypes";
import { isNumber } from "../../../common/helpers/functions";

import "./LivingPlaceLoanSection.scss";

class LivingPlaceLoanSection extends Component<ReduxProps> {
  constructor(props: any) {
    super(props);
    this.handleChangeHousePrice = this.handleChangeHousePrice.bind(this);
  }

  handleChangeHousePrice = (e: React.FormEvent<HTMLInputElement>) => {
    const newValue = e.currentTarget.value;

    if (!newValue) {
      this.props.updateInputContent(
        newValue,
        actionTypes.UPDATE_INPUT_HOUSE_PRICE
      );
      return;
    }

    if (!isNumber(newValue[newValue.length - 1]) || newValue.length > 10) {
      return;
    }

    this.props.updateInputContent(
      newValue,
      actionTypes.UPDATE_INPUT_HOUSE_PRICE
    );
    this.props.validateInput(actionTypes.VALIDATE_INPUT_HOUSE_PRICE);
    if (this.props.requested_amount_display) {
      this.props.validateInput(actionTypes.VALIDATE_INPUT_REQUESTED_AMOUNT);
    }
  };

  handleChangeRequestedAmount = (e: React.FormEvent<HTMLInputElement>) => {
    const newValue = e.currentTarget.value;
    if (!newValue) {
      this.props.updateInputContent(
        newValue,
        actionTypes.UPDATE_INPUT_REQUESTED_AMOUNT
      );
      return;
    }

    if (!isNumber(newValue[newValue.length - 1]) || newValue.length > 10) {
      return;
    }

    this.props.updateInputContent(
      newValue,
      actionTypes.UPDATE_INPUT_REQUESTED_AMOUNT
    );
    this.props.validateInput(actionTypes.VALIDATE_INPUT_REQUESTED_AMOUNT);
  };

  handleChangeLoanTerm = (e: React.FormEvent<HTMLInputElement>) => {
    const newValue = e.currentTarget.value;

    if (!newValue) {
      this.props.updateInputContent(
        newValue,
        actionTypes.UPDATE_INPUT_LOAN_TERM_YEARS
      );
      return;
    }

    if (newValue.length > 2 || !isNumber(newValue[newValue.length - 1])) {
      return;
    }

    this.props.updateInputContent(
      newValue,
      actionTypes.UPDATE_INPUT_LOAN_TERM_YEARS
    );
    this.props.validateInput(actionTypes.VALIDATE_INPUT_LOAN_TERM_YEARS);
  };

  render() {
    const inputs = [
      {
        label: "¿Cuánto cuesta la vivienda que deseas comprar?",
        placeholder: this.props.house_price_placeholder,
        state: this.props.house_price_state,
        normalHelper: this.props.house_price_normal_helper,
        errorHelper: this.props.house_price_error_helper,
        iconOrientation: "prepend",
        disabled: !this.props.selected_product_type,
        currentValue: this.props.house_price,
        iconContent: "$",
        handleChange: this.handleChangeHousePrice,
        testId: "housePriceInputTestId",
        id: "home-price-calculator",
        classIcon: "",
      },
      {
        label: "¿Cuánto dinero necesitas que te prestemos?",
        placeholder: "Ej.: $70.000",
        state: this.props.requested_amount_state,
        normalHelper: this.props.requested_amount_normal_helper,
        errorHelper: this.props.requested_amount_error_helper,
        iconOrientation: "prepend",
        disabled: !this.props.selected_product_type,
        currentValue: this.props.requested_amount_display,
        iconContent: "$",
        tooltip:
          this.props.selected_product_type === "vip-vis"
            ? "Te financiamos hasta el 100% del valor de la vivienda que deseas adquirir incluido gastos legales, avalúos y contribución SOLCA."
            : "Te financiamos hasta el 80% del valor de la vivienda que deseas adquirir incluido gastos legales, avalúos y contribución SOLCA.",
        handleChange: this.handleChangeRequestedAmount,
        testId: "requestedAmountInputTestId",
        id: "requested-amount-calculator",
        classIcon: "-express",
      },
      {
        label: "¿En cuánto tiempo deseas pagar el préstamo?",
        placeholder: "Ej.: 20 años",
        state: this.props.loan_term_years_state,
        normalHelper:
          this.props.selected_product_type === "vip-vis"
            ? "Entre 20 y 25 años"
            : this.props.loan_term_years_normal_helper,
        errorHelper: this.props.loan_term_years_error_helper,
        iconOrientation: "append",
        disabled: !this.props.selected_product_type,
        currentValue: this.props.loan_term_years,
        iconContent: "años",
        handleChange: this.handleChangeLoanTerm,
        testId: "loanTermYearsInputTestId",
        id: "loan-term-years-calculator",
        classIcon: "",
      },
    ];

    const customTooltipIcon = {
      customTooltipIcon: (
        <pichincha-icon
          size="16px"
          color="darkGrey"
          weight_color="500"
          type="--sharp"
        >
          help
        </pichincha-icon>
      ),
    };

    const sectionClassName = `section ${
      !this.props.selected_product_type && "disabled"
    }`;

    return (
      <div>
        {!this.props.calculator_page && (
          <div
            data-testid="living-div"
            className={`${sectionClassName} express`}
          >
            <pichincha-grid item xs={12}>
              <InputField
                {...inputs[0]}
                classWrap=""
                classIcon=""
                id="home-price-express"
              />
            </pichincha-grid>

            <pichincha-grid item xs={12}>
              <InputField
                {...{ ...inputs[1], ...customTooltipIcon }}
                classWrap=""
                classIcon="-express"
                id="requested-amount-express"
              />
            </pichincha-grid>
          </div>
        )}
        {this.props.calculator_page && (
          <div data-testid="living-div" className={sectionClassName}>
            <pichincha-grid container justify="space-between">
              {inputs.map((input, index) => (
                <InputField {...input} classWrap="" key={index} />
              ))}
            </pichincha-grid>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: ReduxState) => {
  const { calculator } = state;
  const {
    house_price,
    requested_amount_display,
    loan_term_years,
    selected_product_type,
    house_price_state,
    house_price_normal_helper,
    house_price_error_helper,
    requested_amount_state,
    requested_amount_error_helper,
    requested_amount_normal_helper,
    loan_term_years_normal_helper,
    loan_term_years_error_helper,
    loan_term_years_state,
    house_price_placeholder,
    calculator_page,
  } = calculator;
  return {
    house_price,
    requested_amount_display,
    loan_term_years,
    selected_product_type,
    house_price_state,
    house_price_normal_helper,
    house_price_error_helper,
    requested_amount_state,
    requested_amount_error_helper,
    requested_amount_normal_helper,
    loan_term_years_normal_helper,
    loan_term_years_error_helper,
    loan_term_years_state,
    house_price_placeholder,
    calculator_page,
  };
};

const { updateInputContent, validateInput } = actions;

const connector = connect(mapStateToProps, {
  updateInputContent,
  validateInput,
});

type ReduxProps = ConnectedProps<typeof connector>;

export default connector(LivingPlaceLoanSection);
