import React, { Component } from "react";
import { capitalizeName } from "../../../common/helpers/functions";

import { connect, ConnectedProps } from "react-redux";
import { ReduxState } from "../../../common/store";

import "./Username.scss";
export class Username extends Component<ReduxProps> {
  render() {
    const { firstName, firstLastName } = this.props;

    const initials = `${firstName[0]}${firstLastName[0]}`;
    const nameDisplay = capitalizeName(`${firstName} ${firstLastName}`);
    return (
      <div className="username-wrapper">
        <div className="initials-circle text-prelo">{initials}</div>
        <div className="fullname text-prelo-medium desktop">{nameDisplay}</div>
      </div>
    );
  }
}

const mapStateToProps = (state: ReduxState) => {
  const { civilRegistration } = state;
  const { firstName, firstLastName } = civilRegistration;
  return {
    firstName,
    firstLastName,
  };
};

const connector = connect(mapStateToProps);

type ReduxProps = ConnectedProps<typeof connector>;

export default connector(Username);
