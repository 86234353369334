import actionTypes from '../actionTypes';
import apiActionCreator from '../utils/apiActionCreator';
import { Services } from '../utils/services';
import {
  FingerprintCodeI,
  IdentificationDataI,
  RequestHeadersI,
  RequestOtpResendI,
  TransactionalContactI,
} from '../../common/helpers/integration';

export const loginResetState = () => ({
  type: actionTypes.LOGIN_RESET_STATE,
});

export const setSeindingRequest = (newState: boolean) => ({
  type: actionTypes.SENDING_REQUEST,
  newState,
});

export const setLoginStep = (newStep: number) => ({
  type: actionTypes.SET_LOGIN_STEP,
  newStep,
});

export const toggleCompleteIdCard = (newState: boolean) => ({
  type: actionTypes.TOGGLE_LOGIN_ID_CARD,
  newState,
});

export const sendCifVerification = (
  identificationData: IdentificationDataI,
  token: string,
  GUID: string,
  channel: string
) =>
  apiActionCreator({
    service: Services.LOGIN,
    endpoint: '/v1/authentication/check-user',
    method: 'POST',
    body: JSON.stringify(identificationData),
    headers: {
      'Content-Type': 'application/json',
      'X-GUID': GUID,
      'X-Recaptcha-Token': token,
      'X-Channel': channel,
    },
    onRequestActions: [{ type: actionTypes.CIF_REQUEST }],
    onSuccessActions: [
      { type: actionTypes.CIF_SUCCESS },
      { type: actionTypes.SET_USER_TYPE, userType: 'cliente' },
    ],
    onFailureActions: [
      { type: actionTypes.CIF_FAILED },
      { type: actionTypes.SET_USER_TYPE, userType: 'noCliente' },
    ],
  });

export const sendTransactionalContact = (
  payload: TransactionalContactI,
  token: string,
  GUID: string,
  channel: string
) =>
  apiActionCreator({
    service: Services.LOGIN,
    endpoint: '/v1/authentication/check-contact',
    method: 'POST',
    body: JSON.stringify(payload),
    headers: {
      'Content-Type': 'application/json',
      'X-GUID': GUID,
      'X-Recaptcha-Token': token,
      'X-Channel': channel,
    },
    onRequestActions: [{ type: actionTypes.TRANSACTIONAL_CONTACT_REQUEST }],
    onSuccessActions: [
      { type: actionTypes.TRANSACTIONAL_CONTACT_SUCCESS },
      { type: actionTypes.SET_JWT },
      { type: actionTypes.SENDING_REQUEST, newState: false },
    ],

    onFailureActions: [
      { type: actionTypes.TRANSACTIONAL_CONTACT_FAILED },
      { type: actionTypes.SENDING_REQUEST, newState: false },
    ],
  });

export const sendFingerprintCode = (
  payload: FingerprintCodeI,
  headers: RequestHeadersI
) =>
  apiActionCreator({
    service: Services.LOGIN,
    endpoint: '/v1/authentication/fingerprint-code',
    method: 'POST',
    body: JSON.stringify(payload),
    headers: {
      'Content-Type': 'application/json',
      'X-GUID': headers.GUID,
      'X-Recaptcha-Token': headers.token,
      'X-Channel': headers.channel,
    },
    onRequestActions: [{ type: actionTypes.FINGERPRINT_CODE_REQUEST }],
    onSuccessActions: [
      { type: actionTypes.FINGERPRINT_CODE_SUCCESS },
      { type: actionTypes.SET_JWT },
      { type: actionTypes.SET_FINGEPRINT_SUCCESS, state: true },
      { type: actionTypes.REDIRECT_TO_EXPRESS_APPLICATION },
      { type: actionTypes.CLEAR_REDIRECTION_TO_EXPRESS_APPLICATION },
      { type: actionTypes.SENDING_REQUEST, newState: false },
    ],
    onFailureActions: [
      { type: actionTypes.FINGERPRINT_CODE_FAILED },
      { type: actionTypes.SENDING_REQUEST, newState: false },
    ],
  });

export const sendOtpCode = (
  headers: RequestHeadersI,
  idCardLogin: string,
  otp: string
) =>
  apiActionCreator({
    service: Services.LOGIN,
    endpoint: `/v1/authentication/otp/validation`,
    method: 'POST',
    body: JSON.stringify({
      identification: idCardLogin,
      otp: otp,
    }),
    headers: {
      'Content-Type': 'application/json',
      'X-GUID': headers.GUID,
      'X-Recaptcha-Token': headers.token,
      'X-Channel': headers.channel,
      authorization: headers.auth,
    },
    onRequestActions: [{ type: actionTypes.OTP_REQUEST }],
    onSuccessActions: [
      { type: actionTypes.OTP_SUCCESS },
      { type: actionTypes.SET_OTP_SUCCESS, state: true },
      { type: actionTypes.REDIRECT_TO_EXPRESS_APPLICATION },
      { type: actionTypes.CLEAR_REDIRECTION_TO_EXPRESS_APPLICATION },
      { type: actionTypes.SENDING_REQUEST, newState: false },
    ],
    onFailureActions: [
      { type: actionTypes.OTP_FAILED },
      { type: actionTypes.SENDING_REQUEST, newState: false },
    ],
    verifyJwt: true,
    verifyState: false,
    publicKey: 'otp',
  });

export const requestOtpResend = (
  payload: RequestOtpResendI,
  headers: RequestHeadersI
) =>
  apiActionCreator({
    service: Services.LOGIN,
    endpoint: `/v1/authentication/otp/${payload.identificationNumber}`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'X-GUID': headers.GUID,
      'X-Recaptcha-Token': headers.token,
      'X-Channel': headers.channel,
      authorization: headers.auth,
    },
    onRequestActions: [{ type: actionTypes.OTP_RESEND_REQUEST }],
    onSuccessActions: [
      { type: actionTypes.OTP_RESEND_SUCCESS },
      { type: actionTypes.UPDATE_OTP_TIME },
      { type: actionTypes.SENDING_REQUEST, newState: false },
    ],
    onFailureActions: [
      { type: actionTypes.OTP_RESEND_FAILED },
      { type: actionTypes.SENDING_REQUEST, newState: false },
    ],
  });

export const resetOtpError = () => ({
  type: actionTypes.RESET_OTP_ERROR,
});
