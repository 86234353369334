import React, { Component } from "react";
import "./ButtonSection.scss";
import { ButtonCalculateProps } from "../../../common/helpers/integration";

const finalText =
  "Las estimaciones de la calculadora son referenciales. Por tanto, no constituyen una pre-aprobación del crédito ni otorgamiento del mismo por parte de Banco Pichincha.";

class ButtonSection extends Component<ButtonCalculateProps> {
  render() {

    let containerClassName = 'section-container';

    if (this.props.disabled) {
      containerClassName += ' disabled';
    }

    return (
      <div data-testid="button-sect-class" className={containerClassName}>
        <pichincha-grid
          container
          direction="column"
          justify="center"
          align_content="center"
          align_items="center"
          spacing={6}
        >
          <pichincha-grid item>
            <div className="large-button">
              <pichincha-button size="large" id="calculateButton" color="primary" disabled={this.props.disabled} loading={this.props.loading}
                data-testid="calculateButtonTestId" onClick={this.props.handleClick}>
                Calcular
              </pichincha-button>
            </div>
          </pichincha-grid>

          <pichincha-grid item>
            <div className="final-text">{finalText}</div>
          </pichincha-grid>
        </pichincha-grid>
      </div>
    );
  }
}

export default ButtonSection;
