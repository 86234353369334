import React, { Component } from "react";

import { CalculatedDataProps } from "../../../common/helpers/integration";
import CalculatedDataContent from "./CalculatedDataContent";

export default class CalculatedDataMobile extends Component<CalculatedDataProps> {
  handleClick(this: any) {
    this.classList.toggle("active");
    let panel = this.nextElementSibling;
    if (panel.style.display === "block") {
      panel.style.display = "none";
    } else {
      panel.style.display = "block";
    }
  }

  componentDidMount() {
    // @ts-ignore
    document
      .querySelector("#buttonCalculatedDataMobile")
      .addEventListener("click", this.handleClick);
  }

  render() {
    return (

      <CalculatedDataContent 
        registers={this.props.registers}
        firstClass="result-card mobile bp-mb-16 bp-mt-24"
        secondClass="accordion"
        idName="buttonCalculatedDataMobile"
        thirdClass="panel"
        popoverId="interestRatePopoverMobile"
      />

    );
  }
}
