import React, { Component } from "react";

import Button from "../Button";
import Thumbnail from "../Thumbnail";

import errorIcon from "../../assets/icons/errorIcon.svg";

interface UnauthorizedProps {
  handleClick: () => void;
}

class Unauthorized extends Component<UnauthorizedProps> {
  render() {
    const continueButtonProps = {
      color: "primary",
      size: "large",
      text: "Reintentar",
      id: `unauthorizedButtonRetry`,
      handleClick: this.props.handleClick,
      disabled: false,
      testId: "unauthorizedButtonRetryTestId"
    };

    return (
      <div className="error-page" data-testid="unauthorizedErrorPageTestId">
        <div className="icon-container">
          <Thumbnail url={errorIcon} name={"imagen"} />
        </div>
        <div className="title-container padding-wrapper">
          <h4 className="title" id="errorUnauthorized">¡Acceso no autorizado!</h4>
        </div>
        <div className="text-container padding-wrapper">
          <pichincha-typography
            variant="bodyText"
            color="darkGrey"
            align="center"
          >
            No se ha podido validar el ingreso. Por favor vuelve a intentarlo
          </pichincha-typography>
        </div>
        <div className="button-container padding-wrapper bp-mb-32">
          <Button {...continueButtonProps} />
        </div>
      </div>
    );
  }
}

export default Unauthorized;
