
import { importSPKI, jwtVerify } from 'jose';
import { AnyAction } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import {
  FACEPHI_INTERACTION_COOKIES,
  PORTAL_INTERACTION_COOKIES,
  PUBLIC_KEY_OTP,
  PUBLIC_KEY_PREQUALIFICATION
} from '../../common/helpers/constants';
import { deleteInteractionsCookies } from '../../common/helpers/functions';
import { setEncryptedItemToCookie } from '../../common/helpers/storage-utils';
import actionTypes from '../actionTypes';
import { getDecriptedData } from './helperActionCreator';
import { Services, URLS } from './services';

interface RequestInfo {
  service: Services;
  endpoint: string;
  method: string;
  headers?: any;
  body?: BodyInit;
  onRequestActions: Array<AnyAction>;
  onSuccessActions: Array<AnyAction>;
  onFailureActions: Array<AnyAction>;
  onSuccessAPICalls?: Array<ThunkAction<void, {}, unknown, AnyAction>>;
  verifyJwt?: boolean;
  verifyState?: boolean;
  publicKey?: string;
  verifyJwtByHeaders?: boolean;
  encrypted?: boolean;
}

interface RequestExtraData {
  error?: Object | string;
  data?: Object | string;
  requestStatus?: number;
  requestHeaders?: Object | string;
}

const dispatchActions = (
  dispatch: ThunkDispatch<{}, unknown, AnyAction>,
  actions: Array<AnyAction>,
  extraData?: RequestExtraData
) => {
  actions.forEach((action: AnyAction) =>
    dispatch({
      ...action,
      data: extraData?.data,
      error: extraData?.error,
      requestStatus: extraData?.requestStatus,
      requestHeaders: extraData?.requestHeaders,
    })
  );
};

const parseBody = (body: string) => {
  try {
    return JSON.parse(body);
  } catch (error) {
    return body;
  }
};

const verifyJWT = async (jwt: string, publicKey: string) => {
  const publicKeys: { [key: string]: any } = {
    otp: PUBLIC_KEY_OTP,
    prequalification: PUBLIC_KEY_PREQUALIFICATION,
  };
  try {
    const algorithm = 'RS256';
    const ecPublicKey = await importSPKI(
      window.atob(publicKeys[publicKey] || ''),
      algorithm
    );
    const { payload } = await jwtVerify(jwt, ecPublicKey);
    return payload;
  } catch (err) {
    return false;
  }
};

const checkNotAuthorized = (requestInfo: RequestInfo, request: any) => {
  if (requestInfo.verifyState && request.status === 401) {
    deleteInteractionsCookies(PORTAL_INTERACTION_COOKIES);
    deleteInteractionsCookies(FACEPHI_INTERACTION_COOKIES);
    window.location.href = '/login';
  }
}

const apiActionCreator =
  (requestInfo: RequestInfo): ThunkAction<void, {}, unknown, AnyAction> =>
    async (dispatch: ThunkDispatch<{}, unknown, AnyAction>) => {
      dispatchActions(dispatch, requestInfo.onRequestActions);
      try {
        const request = await fetch(
          `${URLS[requestInfo.service]}${requestInfo.endpoint}`,
          {
            method: requestInfo.method,
            headers: requestInfo.headers,
            body: requestInfo.body,
          }
        );
        const data = await request.text();
        let parsedData = parseBody(data);

        if (requestInfo.verifyJwt) {
          parsedData = await verifyJWT(
            parsedData.token,
            requestInfo.publicKey || ''
          );
        }

        if (requestInfo.encrypted && parsedData !== "" &&
          requestInfo.onSuccessActions[0].type ===
          actionTypes.CIVIL_REGISTRATION_NAMES_SUCCESS
        ) {
          const decryptedData: any = getDecriptedData(parsedData)
          parsedData = await verifyJWT(
            decryptedData.token,
            requestInfo.publicKey || ''
          );
        }

       checkNotAuthorized(requestInfo, request)

        if (requestInfo.verifyJwtByHeaders) {
          parsedData = await verifyJWT(
            request.headers.get('authorization') || '',
            requestInfo.publicKey || ''
          );
        }

        if (
          request.status === 204 ||
          request.status === 206 ||
          request.status === 202
        ) {
          dispatchActions(dispatch, requestInfo.onFailureActions, {
            data: parsedData,
            error: parsedData,
            requestStatus: request.status,
            requestHeaders: request.headers,
          });
          return;
        }

        if (request.ok) {
          if (
            requestInfo.onSuccessActions[0].type ===
            actionTypes.CIVIL_REGISTRATION_NAMES_SUCCESS
          ) {
            const dataBodyEncrypt = JSON.parse(requestInfo.body as string)
            const decryptedBodyData: any = getDecriptedData(dataBodyEncrypt)
            const dataToPortal: { [key: string]: any } = {
              indentification: decryptedBodyData,
              auth: requestInfo.headers['authorization'],
              channel: requestInfo.headers['X-Channel'],
              guid: requestInfo.headers['X-GUID'],
            };
            setEncryptedItemToCookie('custom', dataToPortal);
            await new Promise((resolve) => setTimeout(resolve, 3000));
          }

          dispatchActions(dispatch, requestInfo.onSuccessActions, {
            data: parsedData,
            requestStatus: request.status,
            requestHeaders: request.headers,
          });
          return;
        }

        dispatchActions(dispatch, requestInfo.onFailureActions, {
          data: parsedData,
          error: parsedData,
          requestStatus: request.status,
          requestHeaders: request.headers,
        });
      } catch (error) {
        const serviceError = error as Error;
        dispatchActions(dispatch, requestInfo.onFailureActions, {
          error: serviceError.message,
        });
      }
    };

export default apiActionCreator;
