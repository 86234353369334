import React, {Component} from "react";
import {connect, ConnectedProps} from "react-redux";
import {ReduxState} from "../../common/store";
import actions from "../../actions";
import Checkbox from "./Checkbox";
import {MAX_AMOUNT_VIP, NEW_USED_RATE} from "../../common/helpers/constants";

const titlePage = "Calcula tu cuota mensual";
const question = "¿Qué producto te interesa?";
const classRectangle = "rectangle";
const titleCheck = "Vivienda nueva o usada";
const list = ["Crédito destinado a la compra de tu casa y/o departamento."];
const lastLine = "Tasa de interés referencial ";
const percentage = `${NEW_USED_RATE}%`;
const question2 = "Más detalle sobre nuestros productos";
const titleCheck2 = "Vivienda de interés público y social";
const list2 = [`Para que adquieras tu primera vivienda de hasta ${MAX_AMOUNT_VIP}.`];
const lastLine2 = "Tasa de interés referencial ";
const percentage2 = "4.87%";
const idCheckboxSetNewUsedProduct = "setNewUsedProduct";
const idCheckboxSetVipVisProduct = "setVipVisProduct";
const idMoreDetailsAboutProduct = "moreDetailsAboutProducts";
interface ProductsProps {
  click: any;
}
class ProductsBox extends Component<ReduxProps, ProductsProps> {
  constructor(props: any) {
    super(props);
    this.setVipVisProduct = this.setVipVisProduct.bind(this);
    this.setNewUsedProduct = this.setNewUsedProduct.bind(this);
    this.openModal = this.openModal.bind(this);
  }
  setVipVisProduct() {
    this.props.setSelectedProduct("vip-vis");
  }
  setNewUsedProduct() {
    this.props.setSelectedProduct("new-used");
  }
  openModal(section: string) {
    this.props.toggleModal(true, section);
  }
  render() {
    return (
      <div className="container-products">
        <div className="titleCheck">{titlePage}</div>
        <div className="desktop-style">
          <pichincha-grid
            container
            justify="center"
            align_content="center"
            align_items="flex-end"
            spacing={8}
          >
            <pichincha-grid item>
              <pichincha-grid
                container
                justify="center"
                align_items="flex-start"
                direction="column"
                spacing={2}
              >
                <div className="titleQuestion">{question}</div>
                <Checkbox
                  id={idCheckboxSetNewUsedProduct}
                  title={titleCheck}
                  list={list}
                  lastLine={lastLine}
                  percentage={percentage}
                  classRectangle={classRectangle}
                  rectangleSelected={
                    this.props.selected_product_type === "new-used"
                  }
                  handleClick={this.setNewUsedProduct}
                />
              </pichincha-grid>
            </pichincha-grid>
            <pichincha-grid item>
              <pichincha-grid
                container
                justify="center"
                align-items="flex-end"
                direction="column"
                spacing={2}
              >
                <label
                  id={idMoreDetailsAboutProduct}
                  className="link-product"
                  onClick={() => this.openModal('')}
                >
                  {question2}
                </label>
                <Checkbox
                  id={idCheckboxSetVipVisProduct}
                  title={titleCheck2}
                  list={list2}
                  lastLine={lastLine2}
                  percentage={percentage2}
                  classRectangle={classRectangle}
                  rectangleSelected={
                    this.props.selected_product_type === "vip-vis"
                  }
                  handleClick={this.setVipVisProduct}
                />
              </pichincha-grid>
            </pichincha-grid>
          </pichincha-grid>
        </div>
        <div className="mobile-style">
          <pichincha-grid
            container
            justify="center"
            align_content="center"
            align_items="center"
            spacing={4}
          >
            <pichincha-grid item>
              <pichincha-grid
                container
                direction="column"
                align_items="flex-start"
                justify="center"
                spacing={2}
              >
                <div className="titleQuestion">{question}</div>
                <Checkbox
                  id={idCheckboxSetNewUsedProduct}
                  handleClick={this.setNewUsedProduct}
                  lastLine={lastLine}
                  percentage={percentage}
                  classRectangle={classRectangle}
                  title={titleCheck}
                  rectangleSelected={
                    this.props.selected_product_type === "new-used"
                  }
                  list={list}
                />
              </pichincha-grid>
            </pichincha-grid>
            <pichincha-grid item>
              <pichincha-grid
                container
                justify="center"
                align_items="center"
                direction="column"
                spacing={4}
              >
                <Checkbox
                  id={idCheckboxSetVipVisProduct}
                  title={titleCheck2}
                  list={list2}
                  lastLine={lastLine2}
                  percentage={percentage2}
                  classRectangle={classRectangle}
                  rectangleSelected={
                    this.props.selected_product_type === "vip-vis"
                  }
                  handleClick={this.setVipVisProduct}
                />
                <label
                  id={idMoreDetailsAboutProduct}
                  className="link-product"
                  onClick={() => this.openModal('')}
                  data-testid="openModalLinkTestId"
                >
                  {question2}
                </label>
              </pichincha-grid>
            </pichincha-grid>
          </pichincha-grid>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state: ReduxState) => {
  const { calculator } = state;
  const {
    selected_product_type,
    house_price_error,
    requested_amount_error,
    loan_term_years_error,
  } = calculator;
  return {
    selected_product_type,
    house_price_error,
    requested_amount_error,
    loan_term_years_error,
  };
};
const { setSelectedProduct, toggleModal } = actions;
const connector = connect(mapStateToProps, { setSelectedProduct, toggleModal });
type ReduxProps = ConnectedProps<typeof connector>;
export default connector(ProductsBox);
