import { FunctionComponent } from "react";
import { connect, ConnectedProps } from "react-redux";
import actions from "../../../actions";
import actionTypes from "../../../actions/actionTypes";
import { ReduxState } from "../../../common/store";
import AdviserField from "./AdviserField";
import DropdownField from "../../DropdownField/DropdownField";

const ENABLED_CHANNELS = ["agencia"];

const BuilderFields: FunctionComponent<ReduxProps> = ({
  channel,
  agency,
  agencyState,
  agencies,
  updateInputContent,
}) => {
  if (!ENABLED_CHANNELS.includes(channel)) {
    return null;
  }

  return (
    <pichincha-grid item xs="12">
      <pichincha-grid
        container
        justify="center"
        data-testid="agencyFieldsTestId"
      >
        <AdviserField />
        <DropdownField
          label="Agencia *"
          id="agency"
          placeholder="Ej.: Cumbayá"
          errorHelper="Por favor completa el campo"
          normalHelper=""
          state={agencyState}
          disabled={false}
          currentValue={agency}
          testId="agencyTestId"
          list={agencies}
          minLengthToFilter={3}
          classWrap=" agency md-mb-0 has-dropdown"
          stateUpdater={updateInputContent}
          actionTypeValue={actionTypes.SET_LOAN_APPLICATION_AGENCY}
          actionTypeId={actionTypes.SET_LOAN_APPLICATION_AGENCY_UUID}
          lg="6"
        />
      </pichincha-grid>
    </pichincha-grid>
  );
};

const mapStateToProps = (state: ReduxState) => {
  const { channels, site } = state;
  const { agency, agencyState, agencies } = channels;
  const { channel } = site;

  return {
    channel,
    agency,
    agencyState,
    agencies,
  };
};

const connector = connect(mapStateToProps, {
  updateInputContent: actions.updateInputContent,
});
type ReduxProps = ConnectedProps<typeof connector>;
export default connector(BuilderFields);
