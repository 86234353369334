import React, { Component } from "react";

import "./FloatButton.scss";

interface FloatButtonProps {
  target: string;
  icon: string;
}

export class FloatButton extends Component<FloatButtonProps> {
  render() {
    return (
      <a href={this.props.target} className="float">
        <img src={this.props.icon} alt="" />
      </a>
    );
  }
}

export default FloatButton;
