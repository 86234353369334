import styled from 'styled-components'
import { COLORS } from '../../theme'


export const SpinnerWrapper = styled.div<{ size?: number }>`
  .spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    width: ${({ size = "32" }) => `${size}px`};
    height: ${({ size = "32" }) => `${size}px`};
    border-radius: 50%;
    border-left-color: ${COLORS.blue500};
    animation: spin 1s ease infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
`
