import React, { Component } from "react";

import "./RadioButton.scss";

interface RadioButtonProps {
  checked: boolean;
  handleClick: () => void;
  label: string;
  testId?: string;
}

export class RadioButton extends Component<RadioButtonProps> {
  render() {
    return (
      <div
        id="1_container"
        className="radiobutton-container"
        onClick={this.props.handleClick}
        data-testid={this.props.testId}
      >
        <input
          type="radio"
          id="1"
          name=""
          value="1"
          className="radiobutton"
          checked={this.props.checked}
          onChange={() => {}}
        />
        <label className="radiobutton-container--slot">
          <pichincha-typography variant="bodyText">
            {this.props.label}
          </pichincha-typography>
        </label>
      </div>
    );
  }
}

export default RadioButton;
