import React from 'react'
import styled from 'styled-components'

import '../../icons.css'
import '../../fonts.css'
import { FontsInterface } from '../../../../interfaces/fonts'
import { COLORS, FONTS } from '../../theme'

interface TypographyProps extends React.HTMLAttributes<HTMLDivElement> {
  font?: FontsInterface
  fontSize?: string
  fontWeight?: string
  color?: string
  align?: string
  lineHeight?: string;
}

const Typography = styled.div<TypographyProps>`
  font-family: ${({ font }) => font || FONTS.PRELOSLAB_BOOK};
  font-size: ${({ fontSize }) => fontSize ? fontSize + 'px' : '16px'};
  font-weight: ${({ fontWeight }) => fontWeight ? fontWeight : 'inherit'};
  color:  ${({ color = "black" }) => COLORS[color]};
  text-align: ${({ align }) => align};
  line-height:  ${({ lineHeight }) => lineHeight ? lineHeight + 'px' : '24px'};
`

export default Typography
