import React, { Component } from "react";

import { MortgageDetailsProps } from "../../../common/helpers/integration";
import MortgageDetailsContent from "./MortgageDetailsContent";
const tooltip = `
  Esta simulación asume la inclusión de gastos legales, gastos de avalúo y contribución especial SOLCA. Estos valores son opcionales dentro del financiamiento de tu crédito. 
`;
export default class MortgageDetailsDesktop extends Component<MortgageDetailsProps> {
  render() {
    return (
      <div className="result-card desktop ">
        <button className="accordion desktop-card" id="buttonMortgageDesktopButton">
          Detalles de tu crédito
          <span className="bp-ml-8">
            <pichincha-tooltip position="bottom" data-testid="tooltip-helper">
              <span slot="item-text">{tooltip}</span>
              <span slot="item-body">
                <pichincha-icon
                  size="medium"
                  color="blue"
                  weight_color="500"
                  type="--sharp"
                >
                  help
                </pichincha-icon>
              </span>
            </pichincha-tooltip>
          </span>
        </button>
        <MortgageDetailsContent requestedAmount={this.props.requestedAmount} appraisal={this.props.appraisal}
        legalFee={this.props.legalFee}
        solca={this.props.solca}
        mainFee={this.props.mainFee}
        firstClass="panel d-block mortgage-detail"
        firstId="amountRequestedPopover"
        secondId="appraisalPopover"
        thirdId="legalFeesPopover"
        fourthId="solcaPopover"
        lastId="totalAmountPopover"
        chosenProductPercentage={this.props.chosenProductPercentage}
        chosenProductName={this.props.chosenProductName}  
        />
      </div>
    );
  }
}
