import React, { Component } from "react";

import { connect, ConnectedProps } from "react-redux";
import { ReduxState } from "../../common/store";

import actions from "../../actions";
import actionTypes from "../../actions/actionTypes";

import AmortizationCheckBox from "./AmortizationCheckBox";
const question = "¿Qué sistema de amortización deseas aplicar a tu crédito?";
const titleCheckFrench = "Francés";
const titleCheckGerman = "Alemán";
const descriptionFrench =
  "El valor de las cuotas que pagarás se mantendrá fijo en el tiempo";
const descriptionGerman =
  "El valor de las cuotas que pagarás será variable e irá decreciendo en el tiempo";

const idFrenchAmortization = "setFrenchAmortization";
const idGermanAmortization = "setGermanAmortization";

class AmortizationBox extends Component<ReduxProps> {
  constructor(props: ReduxProps) {
    super(props);
    this.setFrenchAmortization = this.setFrenchAmortization.bind(this);
    this.setGermanAmortization = this.setGermanAmortization.bind(this);
    this.inputHasErrors = this.inputHasErrors.bind(this);
  }

  setFrenchAmortization() {
    if (this.inputHasErrors()) {
      return;
    }
    this.props.setAmortizationType(actionTypes.SET_FRENCH_AMORTIZATION);
  }

  setGermanAmortization() {
    if (this.inputHasErrors()) {
      return;
    }
    this.props.setAmortizationType(actionTypes.SET_GERMAN_AMORTIZATION);
  }

  inputHasErrors() {
    return (
      this.props.house_price_error ||
      this.props.requested_amount_error ||
      this.props.loan_term_years_error
    );
  }

  render() {
    let sectionState = "container-amortizations";
    let disableRadios = false;
    if (this.inputHasErrors()) {
      sectionState += " disabled";
      disableRadios = true;
    }

    return (
      <div data-testid="amortization-box" className={sectionState}>
        <div className="desktop-style">
          <div className="amortization-title">
            <div className="titleQuestion">{question}</div>
            <pichincha-tooltip position="bottom" data-testid="tooltip-helper">
              <span slot="item-text">
                El reajuste en la tasa de interés puede hacer variar el monto de
                las cuotas en ambos sistemas de amortización
              </span>
              <span slot="item-body">
                <pichincha-icon
                  size="16px"
                  color="darkGrey"
                  weight_color="900"
                  type="--sharp"
                >
                  info
                </pichincha-icon>
              </span>
            </pichincha-tooltip>
          </div>
          <pichincha-grid
            container
            justify="center"
            align_content="center"
            align_items="flex-end"
            spacing={8}
          >
            <pichincha-grid item>
              <pichincha-grid
                container
                justify="center"
                align_items="flex-start"
                direction="column"
                spacing={2}
              >
                <AmortizationCheckBox
                  id={idFrenchAmortization}
                  title={titleCheckFrench}
                  description={descriptionFrench}
                  rectangleSelected={this.props.amortization_type === "french"}
                  handleClick={this.setFrenchAmortization}
                  disabled={disableRadios}
                  testId="setFrenchAmortizationTypeTestId"
                />
              </pichincha-grid>
            </pichincha-grid>

            <pichincha-grid item>
              <pichincha-grid
                container
                justify="center"
                align-items="flex-end"
                direction="column"
                spacing={2}
              >
                <AmortizationCheckBox
                  id={idGermanAmortization}
                  title={titleCheckGerman}
                  description={descriptionGerman}
                  rectangleSelected={this.props.amortization_type === "german"}
                  handleClick={this.setGermanAmortization}
                  disabled={disableRadios}
                  testId="setGermanAmortizationTypeTestId"
                />
              </pichincha-grid>
            </pichincha-grid>
          </pichincha-grid>
        </div>
        <div className="mobile-style">
          <pichincha-grid
            container
            justify="center"
            align_content="center"
            align_items="center"
            spacing={4}
          >
            <pichincha-grid item>
              <pichincha-grid
                container
                justify="center"
                align_items="flex-start"
                direction="column"
                spacing={2}
              >
                <div className="titleQuestion">
                  {question}{" "}
                  <pichincha-tooltip
                    position="bottom"
                    data-testid="tooltip-helper"
                  >
                    <span slot="item-text">
                      El reajuste en la tasa de interés puede hacer variar el
                      monto de las cuotas en ambos sistemas de amortización
                    </span>
                    <span slot="item-body">
                      <pichincha-icon
                        size="16px"
                        color="darkGrey"
                        weight_color="900"
                        type="--sharp"
                      >
                        info
                      </pichincha-icon>
                    </span>
                  </pichincha-tooltip>
                </div>
                <AmortizationCheckBox
                  id={idFrenchAmortization}
                  title={titleCheckFrench}
                  description={descriptionFrench}
                  rectangleSelected={this.props.amortization_type === "french"}
                  handleClick={this.setFrenchAmortization}
                  disabled={disableRadios}
                />
              </pichincha-grid>
            </pichincha-grid>

            <pichincha-grid item>
              <pichincha-grid
                container
                justify="center"
                align_items="center"
                direction="column"
                spacing={4}
              >
                <AmortizationCheckBox
                  id={idGermanAmortization}
                  title={titleCheckGerman}
                  description={descriptionGerman}
                  rectangleSelected={this.props.amortization_type === "german"}
                  handleClick={this.setGermanAmortization}
                  disabled={disableRadios}
                />
              </pichincha-grid>
            </pichincha-grid>
          </pichincha-grid>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: ReduxState) => {
  const { calculator } = state;
  const {
    selected_product_type,
    house_price_error,
    requested_amount_error,
    loan_term_years_error,
    amortization_type,
  } = calculator;
  return {
    selected_product_type,
    house_price_error,
    requested_amount_error,
    loan_term_years_error,
    amortization_type,
  };
};

const { setAmortizationType } = actions;

const connector = connect(mapStateToProps, { setAmortizationType });

type ReduxProps = ConnectedProps<typeof connector>;

export default connector(AmortizationBox);
