import actionTypes from '../actionTypes';
import apiActionCreator from '../utils/apiActionCreator';
import { getEncriptedData } from '../utils/helperActionCreator';
import { Services } from '../utils/services';

interface Headers {
  token: string;
  GUID: string;
  jwt: string;
  imageAuthToken: string;
}

export const setStep = (newStep: number) => ({
  type: actionTypes.SET_STEP,
  newStep,
});

export const handleDataProtectionModalOpen = (open: boolean) => ({
  type: actionTypes.UPDATE_SHOW_DATA_PROTECTION_MODAL,
  open,
});

export const handleDataProtectionModalError = (error: boolean) => ({
  type: actionTypes.UPDATE_DATA_PROTECTION_ERROR,
  error,
});

export const setSelectedCreditProduct = (type: string) => ({
  type,
});

export const toggleProducts = (newState: boolean) => ({
  type: actionTypes.TOGGLE_PRODUCTS_STATE,
  newState,
});

export const toggleInputs = (newState: boolean) => ({
  type: actionTypes.TOGGLE_INPUTS_STATE,
  newState,
});

export const toggleCompletePersonalData = (newState: boolean) => ({
  type: actionTypes.TOGGLE_PERSONAL_DATA_STATE,
  newState,
});

export const toggleCompleteCreditData = (newState: boolean) => ({
  type: actionTypes.TOGGLE_CREDIT_DATA_STATE,
  newState,
});

export const setComment = (newComment: string) => ({
  type: actionTypes.SET_COMMENT,
  newComment,
});

export const setRatingFeedback = (newRating: number) => ({
  type: actionTypes.SET_RATING,
  newRating,
});

export const resetExpressRequest = (type: string) => ({
  type,
});

export const sendExpressRegistration = (
  newCustomer: any,
  requestType: string,
  utmHeaders: any,
  channel: any,
  headers: Headers
) => {
  const requestData: { [key: string]: any } = {
    prequalification: {
      endpoint: '/v1/loan-application/prequalifications',
      actionTypes: {
        onRequest: actionTypes.PREQUALIFICATION_REQUEST,
        onSuccess: actionTypes.PREQUALIFICATION_SUCCESS,
        onFailure: actionTypes.PREQUALIFICATIONS_FAILED,
      },
      isPrequalification: true,
      publicKey: 'prequalification',
    },
    loanRequest: {
      endpoint: '/v1/loan-application/applications',
      actionTypes: {
        onRequest: actionTypes.EXPRESS_APPLICATIONS_REQUEST,
        onSuccess: actionTypes.EXPRESS_APPLICATIONS_SUCCESS,
        onFailure: actionTypes.EXPRESS_APPLICATIONS_FAILED,
      },
      isPrequalification: false,
      publicKey: '',
    },
    prospect: {
      endpoint: '/v1/loan-application/prospects-save',
      isProspect: true,
      publicKey: '',
      redirectFacephi: true,
    },
  };

  const payload =
    typeof newCustomer === 'object' ? JSON.stringify(newCustomer) : newCustomer;

  const requestConfig = {
    service: Services.EXPRESS_APPLICATIONS,
    endpoint: requestData[requestType].endpoint,
    method: 'POST',
    body: payload,
    headers: {
      'Content-Type': 'application/json',
      'X-GUID': headers.GUID,
      'X-Recaptcha-Token': headers.token,
      'X-Channel': channel,
      authorization: headers.jwt,
      'x-source': utmHeaders['source'],
      'x-campaign': utmHeaders['campaign'],
      'x-medium': utmHeaders['medium'],
      'x-content': utmHeaders['content'],
      'X-IMAGE-AUTH': headers.imageAuthToken,
    },
    onRequestActions: [{ type: actionTypes.EXPRESS_APPLICATIONS_REQUEST }],
    onSuccessActions: [
      {
        type: actionTypes.EXPRESS_APPLICATIONS_SUCCESS,
        channel: channel,
        isPrequalification: requestData[requestType].isPrequalification,
        isProspect: requestData[requestType].isProspect,
        payload,
      },
    ],
    onFailureActions: [
      {
        type: actionTypes.EXPRESS_APPLICATIONS_FAILED,
        channel: channel,
        isPrequalification: requestData[requestType].isPrequalification,
        isProspect: requestData[requestType].isProspect,
        payload,
      },
    ],
    verifyJwt: !!requestData[requestType].publicKey,
    verifyState: false,
    publicKey: requestData[requestType].publicKey,
  };

  if (requestData[requestType].redirectFacephi) {
    requestConfig.onSuccessActions.push({
      type: actionTypes.FACEPHI_REDIRECTION,
      channel: channel,
      isPrequalification: requestData[requestType].isPrequalification,
      isProspect: requestData[requestType].isProspect,
      payload,
    });
  }

  return apiActionCreator(requestConfig);
};

export const sendFeedbackRegistration = (
  newCustomer: any,
  token: string,
  GUID: string,
  channel: any
) =>
  apiActionCreator({
    service: Services.FEEDBACK_PAGE,
    endpoint: '/v1/feedback/ratings',
    method: 'POST',
    body: JSON.stringify(newCustomer),
    headers: {
      'Content-Type': 'application/json',
      'X-GUID': GUID,
      'X-Recaptcha-Token': token,
      'X-Channel': channel,
    },
    onRequestActions: [{ type: actionTypes.FEEDBACK_REGISTRATION_REQUEST }],
    onSuccessActions: [{ type: actionTypes.FEEDBACK_REGISTRATION_SUCCESS }],
    onFailureActions: [{ type: actionTypes.FEEDBACK_REGISTRATION_FAILED }],
  });

export const logoutBack = (
  token: string,
  GUID: string,
  channel: any,
  jwt: any
) =>
  apiActionCreator({
    service: Services.LOGOUT,
    endpoint: '/v1/authentication/session/logout',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-GUID': GUID,
      'X-Recaptcha-Token': token,
      'X-Channel': channel,
      authorization: jwt,
    },
    onRequestActions: [],
    onSuccessActions: [
      { type: actionTypes.CLEAN_SESSION },
      { type: actionTypes.EXPRESS_REQUEST_RESET_STATE },
    ],
    onFailureActions: [
      { type: actionTypes.CLEAN_SESSION },
      { type: actionTypes.EXPRESS_REQUEST_RESET_STATE },
    ],
  });

export const dataProtectionAuth = (
  token: string,
  GUID: string,
  channel: any,
  jwt: any,
  body: any
) => {
  const encryptedBody: any = getEncriptedData(body)
  return apiActionCreator({
    service: Services.DATA_PROTECTION,
    endpoint: '/v1/loan-application/ldpd-register',
    method: 'POST',
    body: JSON.stringify(encryptedBody),
    headers: {
      'Content-Type': 'application/json',
      'X-GUID': GUID,
      'X-Recaptcha-Token': token,
      'X-Channel': channel,
      authorization: jwt,
    },
    onRequestActions: [],
    onSuccessActions: [{ type: actionTypes.CIVIL_REGISTRATION_UPDATE_LDPD }],
    onFailureActions: [
      { type: actionTypes.UPDATE_DATA_PROTECTION_ERROR, error: true },
    ],
  });
};

export const updateStateValue = (type: string, newValue: boolean) => ({
  type,
  newValue,
});

export const updateWorkDataWorkSeniority = (
  type: string,
  newValue: string,
  invalid: boolean
) => ({
  type,
  newValue,
  invalid,
});

export const showSuccessFacephi = () => ({
  type: actionTypes.SHOW_SUCCESS_FACEPHI,
});
