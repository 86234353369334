import React, {
  ChangeEvent,
  FC,
  InputHTMLAttributes,
  useRef,
  useState,
} from 'react'
import { InputWrapper, InputMessage } from './styles'

import Icon, { IconProps } from '../Icon'
import Typography from '../Typography'


export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string
  labelColor?: string
  error?: boolean
  success?: boolean
  errorMessage?: string
  testid?: string
  icon?: IconProps
  iconLeft?: IconProps
  maxLength?: number
  hintMessage?: string
  onFocus?: () => void
  onBlur?: () => void
  onClick?: () => void
  explanation?: boolean
  labelExplanation?: string
  handleClickExplanation?: () => void
}

export interface InputIconProps {
  size: string
}
interface InputMessageContainerProps {
  error?: boolean
  errorMessage?: string
  maxLength?: number
  hintMessage?: string
  inputLength: number
}


const InputMessageContainer: FC<InputMessageContainerProps> = ({ error, errorMessage, hintMessage, inputLength, maxLength }) => {
  return (<InputMessage className="error_spacing">
    {error && errorMessage && (
      <Typography fontSize="12" font="prelo-book" color="danger500">
        {errorMessage}
      </Typography>
    )}
    {!error && hintMessage && (
      <Typography
        fontSize="12"
        font="prelo-semibold"
        color="grayscale400"
      >
        {hintMessage}
      </Typography>
    )}
    {maxLength && (
      <Typography font="prelo-medium" className="max_length">
        {inputLength} de {maxLength}
      </Typography>
    )}
  </InputMessage>)
}

const getInputClassName = (error?: boolean, disabled?: boolean, success?: boolean, iconLeft?: IconProps) =>
  `${error && 'error'} ${disabled && 'disabled'} ${success && 'success'}  ${iconLeft && 'icon_left'} `

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      label,
      error,
      success,
      disabled,
      errorMessage,
      testid,
      icon,
      iconLeft,
      maxLength,
      hintMessage,
      onFocus,
      labelColor,
      onBlur,
      onClick,
      explanation,
      labelExplanation,
      handleClickExplanation,
      ...props
    },
    ref
  ) => {

    const inputRef = useRef() as React.MutableRefObject<HTMLInputElement>
    const [focused, setFocused] = useState(false)
    const [inputValue, setInputValue] = useState<string>('')

    const valueHanlder = (eventData: ChangeEvent<HTMLInputElement>) => {
      props.onChange && props.onChange(eventData)
      maxLength &&
        setInputValue((val) => {
          let value = eventData.target.value

          if (eventData.target.value.length <= maxLength) {
            return value
          }
          return val
        })
    }


    return (
      <InputWrapper className={props.className}>
        <div className='flex-row'>
          <div className='flex-row-start'>
            <Typography fontSize="14" font="prelo-bold" color={labelColor || 'grayscale500'} fontWeight="700" lineHeight='17.5px'>
              {label}
            </Typography>
          </div>
          {explanation &&
            <div className='flex-row-end'>
              <Typography fontSize="12" font="prelo-semibold" color={'info500'} fontWeight="700" lineHeight='17.5px' onClick={handleClickExplanation} className="link">
                {labelExplanation}
              </Typography>
            </div>
          }

        </div>
        <div className="inpu__div">
          {!!icon && (
            <div className="Icon_place">
              <Icon iconName={icon.iconName} />
            </div>
          )}
          {!!iconLeft && (
            <div
              className={`Icon_place_left ${focused && !disabled ? ' focused' : ''} ${error && 'error'
                } `}
            >
              <Icon iconName={iconLeft.iconName} />
            </div>
          )}
          <input
            onCopy={(event) => {
              event.preventDefault()
            }}
            onPaste={(event) => {
              event.preventDefault()
            }}
            onCut={(event) => {
              event.preventDefault()
            }}
            autoComplete="off"
            ref={ref || inputRef}
            value={maxLength && inputValue}
            {...props}
            className={getInputClassName(error, disabled, success, iconLeft)}
            onClick={onClick}
            onBlur={() => {
              onBlur && onBlur()
              setFocused(false)
            }}
            onFocus={() => {
              onFocus && onFocus()
              setFocused(true)
            }}
            onChange={valueHanlder}
            data-testid={testid || 'id-input'}
          />
        </div>
        <InputMessageContainer error={error} errorMessage={errorMessage} hintMessage={hintMessage} maxLength={maxLength} inputLength={inputValue.length} />
      </InputWrapper>
    )
  }
)

export default Input
