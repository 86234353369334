import actionTypes from '../actionTypes';
import apiActionCreator from '../utils/apiActionCreator';
import { Services } from '../utils/services';

export const getWorkableTimeCMB = (guid: string) =>
  apiActionCreator({
    service: Services.CALL_ME_BACK,
    endpoint: `/v1/research/utils/workable`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'X-GUID': guid,
    },
    onRequestActions: [],
    onSuccessActions: [
      { type: actionTypes.CALL_ME_BACK_WORKABLE_TIME_SUCCESS },
    ],
    onFailureActions: [],
    verifyJwtByHeaders: true,
    publicKey: 'prequalification',
  });

export const resetStateCallMeBack = () => ({
  type: actionTypes.CALL_ME_BACK_RESET_STATE,
});

interface RequestCallPayloadProps {
  identification: null;
  phone: string;
  fullName: string;
}

interface RequestCallHeadersProps {
  recaptchaToken: string;
  guid: string;
  channel: string;
}

export const submitCallBackRequest = (
  payload: RequestCallPayloadProps,
  headers: RequestCallHeadersProps
) =>
  apiActionCreator({
    service: Services.CALL_ME_BACK,
    endpoint: `/v1/research/call-me-back`,
    method: 'POST',
    body: JSON.stringify(payload),
    headers: {
      'Content-Type': 'application/json',
      'X-GUID': headers.guid,
      'X-Recaptcha-Token': headers.recaptchaToken,
      'X-Channel': headers.channel,
    },
    onRequestActions: [
      { type: actionTypes.CALL_ME_BACK_SUBMIT_REQUEST },
    ],
    onSuccessActions: [
      { type: actionTypes.CALL_ME_BACK_SUBMIT_SUCCESS },
    ],
    onFailureActions: [
      { type: actionTypes.CALL_ME_BACK_SUBMIT_FAILED },
    ],
    verifyJwtByHeaders: true,
    publicKey: 'prequalification',
  });