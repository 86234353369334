import styled from 'styled-components'
import { COLORS } from '../../theme'
import { InputProps } from './index'

export const InputWrapper = styled.div<InputProps>`

.link:hover {
  cursor: pointer
}

.flex-row {
    display: flex;
    justify-content: space-between;
}
.flex-row-end {
    display: flex;
    justify-content: flex-end;
}

.flex-row-start {
  display: flex;
  justify-content: flex-start;
}

  .inpu__div {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .Icon_place {
    position: absolute;
    right: 0;
    padding-right: 14px;
    padding-top: 10px;
    pointer-events: none;
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .Icon_place_left {
    position: absolute;
    left: 0;
    font-size: 1.25rem;
    text-align: center;
    background: ${COLORS.darkGrayishBlue100};
    border-radius: 0.25rem 0 0 0.25rem;
    width: 33px;
    border-left: 1px solid ${COLORS.darkGrey300};
    border-right: 1px solid ${COLORS.darkGrey300};
    margin-top: 8px;
    display: flex;
    align-items: center;
    height: 46.5px;
    justify-content: center;
  }

  .focused {
    border-right: 1px solid ${COLORS.info};
    border-left: 1px solid ${COLORS.info};
    height: 43.5px;
    padding: 1px;
  }

  input {
    appearance: none;
    padding: 0.875rem 1rem;
    width: 100%;
    box-shadow: none;
    background-color: ${COLORS.white};
    border: 1px solid ${COLORS.darkGrey300};
    margin-top: 0.5rem;
    border-radius: 4px;
    font-size: 14px;
    font-family: prelo-medium, Helvetica, Arial, Sans, sans-serif;
    font-weight: 100;
    color: ${COLORS.darkGrey500};
    outline: none 0 !important;
    width: calc(100% - 34px);

    outline: none !important;
    outline-width: 0 !important;
    box-shadow: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    -moz-appearance: textfield;

    ::placeholder {
      color: ${COLORS.darkGrey400};
    }

    :focus {
      border: 1px solid ${COLORS.info};
    }

    &.icon_left {
      padding-left: 39px;
      width: calc(100% - 59px);
    }

    &.select {
      cursor: text !important;
    }
  }

  input[type='number']::-webkit-outer-spin-button,
  input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .error {
    border-color: ${COLORS.danger};
    color: ${COLORS.danger} !important;

    ::placeholder {
      color: ${COLORS.danger};
    }

    :hover {
      border-color: ${COLORS.danger};
    }

    :focus {
      border-color: ${COLORS.danger};
    }
  }

  .disabled {
    background-color: ${COLORS.darkGrey100};
    border: 1px solid #b7b7b9;
    pointer-events: none;
    cursor: not-allowed;

    :hover {
      border-color: ${COLORS.darkGrey300};
    }

    :focus {
      border: 1px solid #b7b7b9;
    }
  }

  .error_spacing {
    padding-top: 2px;
    display: flex;
  }
`

export const InputMessage = styled.div`
  padding-top: 2px;
  display: flex;

  .max_length {
    margin-left: auto;
    margin-right: 4px;
  }

  .error_spacing {
    padding-top: 2px;
    display: flex;
  }
`
