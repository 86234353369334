
import styled from 'styled-components'
import { COLORS } from '../Shared/theme'

export const CalendarOptions = styled.div`
  position: absolute;
  //border: 2px solid ${COLORS.info};
  right: 0;
  box-shadow: 0px 0px 4px rgba(7, 7, 7, 0.16),
    0px 8px 8px -8px rgba(7, 7, 7, 0.16);
  border-top: none;
  border-radius: 8px;
  padding: 12px;
  margin-right: 0.5px;
  margin-top: -3px;
  background: white;
  //width: calc(100% - 4px);
  z-index: 1;
  ul {
    list-style-type: none;
  }
  li {
    font-size: 14px;
    line-height: 23px;
    padding: 3px 16px 6px 16px;
    &:hover,
    &.selected {
      background: ${COLORS.info50};
    }
  }
  .options-item {
    display: flex;
    justify-content: space-between;
  }
  .calendar-options {
    display: flex;
    flex-direction: row;
    gap: 8px;
    .Icon_place {
      padding-top: 2px;
    }
    input {
      height: 0px;
      margin-top: 0;
    }
    button {
      height: 30px;
    }
    .error_spacing > div {
      font-size: 10.8px;
    }
    .years-select {
      width: 50%;
    }
    .clean-button {
      width: 50%;
    }
  }
`

export const CalendarMonth = styled.div`
  display: flex;
  justify-content: center;
  //flex-shrink: 0;
  // padding: 0 12px;
  //margin-bottom: 0.75rem;
  //max-width: 308px;
  /*  align-items: center;
  justify-content: center;
  min-width: 100%;
  @media (min-width: 800px) {
    margin-right: -8px;
  } */
  .days_container {
    /*  flex-wrap: wrap;
    display: flex;
    margin: 8px; */
    display: grid;
    grid-template-columns: repeat(3, auto);
    grid-template-rows: repeat(4, auto);
  }

  .week_day {
    height: 35px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 6px;
  }

  .day {
    height: 17px;
    width: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 6px;

    &:hover {
      border-radius: 10%;
      border: solid 1px ${COLORS.info600};
      height: 17px;
      width: 60px;
    }
  }
  .selected {
    border-radius: 100%;
    background-color: ${COLORS.info600};
    color: white;
  }
  .disabled-day {
    &:hover {
      border: solid 1px transparent;
    }
    cursor: not-allowed;
    color: ${COLORS.darkGrey300};
  }
`

export const CalendarWrapper = styled.div`
  position: relative;
  input {
    cursor: pointer;
  }
`

export const CalendarSeparator = styled.div`
  width: 100%;
  height: 6px;
  border-top: 1px solid ${COLORS.darkGrey300};
`
