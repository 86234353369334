import React from 'react'

import './SiteInConstruction.scss';


function SiteInConstruction() {
  return (
    <div className="site-in-construction">
      <div>
        <pichincha-typography
          variant="h1"
          color="blue"
          weight="normal"
          weight_color="500"
          align="inherit"
        >
          Sitio en construcción
        </pichincha-typography>
      </div>
      <div>
        <pichincha-link disabled="false" href="/" variant="h3">
          Home
        </pichincha-link>
      </div>
    </div>
  )
}

export default SiteInConstruction;
