import styled from "styled-components";
import { COLORS } from "../../theme";

export const SelectOptions = styled.div`
  position: absolute;
  border: 2px solid ${COLORS.info};
  border-top: none;
  border-radius: 0 0 4px 4px;
  margin-right: 0.5px;
  margin-top: -3px;
  background: ${COLORS.white};
  width: calc(100% - 4px);
  z-index: 1;
  cursor: pointer;

  ul {
    list-style-type: none;
    margin-bottom: 8px;
    margin-top: 0;
    padding: 0;
    height: 100px;
    overflow: auto;
  }

  li {
    font-size: 14px;
    line-height: 23px;
    padding: 3px 16px 6px 16px;

    &:hover,
    &.selected {
      background: ${COLORS.info50};
    }
  }

  .options-item {
    display: flex;
    justify-content: space-between;
    font-family: prelo-medium;
  }

  .center_icon {
    display: flex;
    align-items: center;
  }
`;

export const SelectWrapper = styled.div`
  position: relative;
`;

export const SelectSeparator = styled.div`
  width: 100%;
  height: 7px;
  border-top: 1px solid ${COLORS.darkGrey300};
`;
