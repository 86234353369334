import React, { Component } from "react";

import { CalculatedDataProps } from "../../../common/helpers/integration";
import CalculatedDataContent from "./CalculatedDataContent";

export default class CalculatedDataDesktop extends Component<CalculatedDataProps> {
  render() {
    return (
      <CalculatedDataContent 
        registers={this.props.registers}
        firstClass="result-card desktop bp-mb-24"
        secondClass="accordion desktop-card"
        idName="buttonCalculatedDataDesktop"
        thirdClass="panel d-block"
        popoverId="interestRatePopoverDesktop"
      />
      
    );
  }
}
