export const SET_CHANNEL = '@@dreambuilders/SET_CHANNEL';
export const SET_JWT = '@@dreambuilders/SET_JWT';
export const RESET_JWT = '@@dreambuilders/RESET_JWT';

export const CLEAN_SESSION = '@@dreambuilders/CLEAN_SESSION';
export const SET_FINGEPRINT_SUCCESS = '@@dreambuilders/SET_FINGEPRINT_SUCCESS';
export const SET_OTP_SUCCESS = '@@dreambuilders/SET_OTP_SUCCESS';
export const SET_USER_TYPE = '@@dreambuilders/SET_USER_TYPE';

export const FOCUS_ALERT_MESSAGE = '@@dreambuilders/FOCUS_ALERT_MESSAGE';

export const SET_CALLMEBACK_BUTTON_STATUS =
  '@@dreambuilders/SET_CALLMEBACK_BUTTON_STATUS';

export const SET_UTM_HEADERS = '@@dreambuilders/SET_UTM_HEADERS';
