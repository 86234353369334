import { AnyAction } from 'redux';
import actionTypes from '../../actions/actionTypes';
import { checkEmptyField } from '../../common/helpers/functions';

export interface CallState {
  workableTimeSuccess: boolean;
  firstName: string;
  firstNameState: string;
  firstNameStateError: boolean;
  lastName: string;
  lastNameState: string;
  lastNameStateError: boolean;
  phone: string;
  phoneState: string;
  phoneStateError: boolean;
  formSubmitted: boolean;
  loading: boolean;
  submitButtonText: string;
  submitAttempts: number;
  callMeBackButtonStatus: {
    isOpened: boolean;
  };
}

export const INITIAL_STATE = {
  workableTimeSuccess: false,
  firstName: '',
  firstNameState: 'normal',
  firstNameStateError: true,
  lastName: '',
  lastNameState: 'normal',
  lastNameStateError: true,
  phone: '',
  phoneState: 'normal',
  phoneStateError: true,
  formSubmitted: false,
  loading: false,
  submitButtonText: 'Solicitar llamada',
  submitAttempts: 0,
  callMeBackButtonStatus: {
    isOpened: false,
  },
};

const callMeBack = (
  state: CallState = INITIAL_STATE,
  action: AnyAction = { type: null }
): CallState => {
  switch (action.type) {
    case actionTypes.CALL_ME_BACK_RESET_STATE:
      return {
        ...INITIAL_STATE,
        workableTimeSuccess: state.workableTimeSuccess,
      };
    case actionTypes.CALL_ME_BACK_WORKABLE_TIME_SUCCESS:
      const { status, validation } = action.data;
      return { ...state, workableTimeSuccess: status === 200 && validation };
    case actionTypes.CALL_ME_BACK_UPDATE_FIRST_NAME:
      const firstName = action.newValue;
      const { state: firstNameState, error: firstNameStateError } =
        checkEmptyField(firstName);
      return {
        ...state,
        firstNameState,
        firstNameStateError,
        firstName,
      };
    case actionTypes.CALL_ME_BACK_UPDATE_LAST_NAME:
      const lastName = action.newValue;
      const { state: lastNameState, error: lastNameStateError } =
        checkEmptyField(lastName);
      return {
        ...state,
        lastNameState,
        lastNameStateError,
        lastName,
      };
    case actionTypes.CALL_ME_BACK_UPDATE_PHONE:
      const phone = action.newValue;
      const { state: phoneState, error: phoneStateError } = checkEmptyField(
        phone,
        true
      );
      return {
        ...state,
        phoneState,
        phoneStateError,
        phone,
      };
    case actionTypes.CALL_ME_BACK_SUBMIT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.CALL_ME_BACK_SUBMIT_SUCCESS:
      document.getElementById('callMeBackModal')?.focus();
      return {
        ...state,
        loading: false,
        formSubmitted: true,
      };
    case actionTypes.CALL_ME_BACK_SUBMIT_FAILED:
      return {
        ...state,
        submitButtonText: 'Reintentar',
        submitAttempts: state.submitAttempts + 1,
        loading: false,
      };
    case actionTypes.SET_CALLMEBACK_BUTTON_STATUS:
      return {
        ...state,
        callMeBackButtonStatus: action.state,
      };
    default:
      return state;
  }
};

export default callMeBack;
