import React from 'react'
import { SpinnerWrapper } from './styles'

const Spinner: React.FC<{ size?: number; customClass?: string }> = ({
  size,
  customClass,
  ...props
}) => {
  return (
    <SpinnerWrapper size={size}>
      <div {...props} className={`spinner ${customClass}`} />
    </SpinnerWrapper>
  )
}

export default Spinner
