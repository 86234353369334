import Title from "../Title";
import Text from "../Text";
import List from "../List.tsx/List";

interface LivingPlaceCard {
  listId: string;
  title: string;
  description: string;
  benefits: Array<string>;
}

function LivingPlaceCardDesktop({
  title,
  description,
  benefits,
  listId,
}: LivingPlaceCard) {
  return (
    <>
      <Title content={title} />
      <Text content={description} />
      <hr />
      <List
        data={benefits}
        iconName="check"
        iconColor="success"
        textColor="darkGrey"
        iconWeight="500"
        id={listId}
      />
    </>
  );
}

export default LivingPlaceCardDesktop;
