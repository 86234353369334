import { EncryptedDataAsym, decryptAsym, encryptAsym } from '@bp/encryption-web';
import { PRIVATE_KEY_PREQUALIFICATION, PUBLIC_KEY_PREQUALIFICATION } from '../../common/helpers/constants';

export const getDecriptedData = (parsedData: any) => {
  const dataEncrypt: EncryptedDataAsym = parsedData
  return decryptAsym(dataEncrypt, {
    cryptoPrivateKey: window.atob(PRIVATE_KEY_PREQUALIFICATION),
  });
}


export const getEncriptedData = (body: any) => {
  const encryptedBody: EncryptedDataAsym = encryptAsym(body, {
    cryptoPublicKey: window.atob(PUBLIC_KEY_PREQUALIFICATION),
  });
  return encryptedBody
}
