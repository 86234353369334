import React, { Component } from "react";

interface TextProps {
  content?: string;
  className?: string;
}

class Text extends Component<TextProps> {
  render() {
    return <p className={`content ${this.props.className ? this.props.className : ''}`}>{this.props.content}</p>;
  }
}

export default Text;
