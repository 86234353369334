import { FC, useEffect } from 'react'
import { ReduxState } from '../../../../common/store'
import actions from '../../../../actions'
import { connect, ConnectedProps } from "react-redux";
import { logout } from '../../../../common/helpers/functions';

type PropFromRedux = ConnectedProps<typeof connector>;

export const Logout: FC<PropFromRedux> = ({ ...props }) => {
  useEffect(() => {
    logout(
      props.logoutBack,
      props.channel,
      props.jwt,
      true
    )
  })
  return (
    <div>Cargando...</div>
  )
}

const mapStateToProps = (state: ReduxState) => {
  const { site } = state;
  const { channel, jwt } = site;
  return { jwt, channel };
};

const { cleanSession, logoutBack, resetExpressRequest } = actions;

const connector = connect(mapStateToProps, {
  cleanSession,
  logoutBack,
  resetExpressRequest,
});

export default connector(Logout);
