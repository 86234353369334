import React, { Component } from "react";
import { connect, ConnectedProps } from "react-redux";
import { ReduxState } from "../../../common/store";

import { capitalizeName } from "../../../common/helpers/functions";

import Button from "../../Button";
import Thumbnail from "../../Thumbnail";

import AgencyExpressRequestFailedIcon from "../../../assets/img/AgencyApplicationError.svg";

class AgencyExpressRequestFailed extends Component<ReduxProps> {
  render() {
    const continueButtonProps = {
      color: "primary",
      size: "large",
      text: "Finalizar",
      id: `agencyExpressRequestFailedButtonRetry`,
      handleClick: () => (window.location.href = "/"),
      disabled: false,
      testId: "agencyExpressRequestFailedId",
    };

    const { name, lastName } = this.props.personalData;

    return (
      <div className="error-page something-went-wrong">
        <div className="icon-container">
          <Thumbnail url={AgencyExpressRequestFailedIcon} name={"imagen"} />
        </div>
        <div className="title-container padding-wrapper bp-mt-12">
          <h4 className="title text-prelo-slab-semibold">{capitalizeName(`${name} ${lastName}`)}</h4>
        </div>
        <div className="text-container padding-wrapper something-went-wrong">
          <pichincha-typography
            variant="bodyText"
            color="darkGrey"
            align="center"
            id="errorAgencyExpressRequestFailed"
          >
            Hemos validado tu información y consideramos que en este momento no
            cumples con las condiciones necesarias para solicitar un crédito
            hipotecario.
          </pichincha-typography>
        </div>
        <div className="button-container padding-wrapper bp-mt-8">
          <Button {...continueButtonProps} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: ReduxState) => {
  const { expressRequest } = state;
  const { personalData } = expressRequest;
  return {
    personalData,
  };
};

const connector = connect(mapStateToProps);

type ReduxProps = ConnectedProps<typeof connector>;

export default connector(AgencyExpressRequestFailed);
