import React, { Component } from "react";
import "./Checkbox.scss";

import CheckboxInput from "../CheckboxInput/CheckboxInput";
import CheckBoxContain from "./CheckBoxContain";

interface CheckboxProps {
  type?: string;
  id: string;
  title: string;
  list: Array<string>;
  lastLine: string;
  percentage: string;
  rectangleSelected: boolean;
  classRectangle: string;
  disabled?: boolean;
  handleClick: () => void;
  fullSize?: boolean;
  linkText?: string;
  handleClickLink?: () => void;
  testId?: string;
}

class Checkbox extends Component<CheckboxProps> {
  constructor(props: CheckboxProps) {
    super(props);
    this.handleClickContainer = this.handleClickContainer.bind(this);
  }

  renderCheckbox = (wrapperClass: string) => {
    return (
      <button
        className={wrapperClass}
        data-testid={this.props.testId || "checkboxButtonId"}
        id={this.props.id}
        onClick={this.props.handleClick}
      >
        {this.props.classRectangle === "rectangle" && (
          <div className="display bp-py-16 bp-pl-24 bp-pr-16">
            <CheckBoxContain
              title={this.props.title}
              lastLine={this.props.lastLine}
              list={this.props.list}
              percentage={this.props.percentage}
            />

            <div className="check-align">
              <CheckboxInput
                id={`${this.props.id}CheckboxInput`}
                checked={this.props.rectangleSelected}
                handleClick={this.props.handleClick}
              />
            </div>
          </div>
        )}

        {this.props.classRectangle === "express-rectangle" && (
          <div className="display bp-px-16">
            <CheckBoxContain
              title={this.props.title}
              lastLine={this.props.lastLine}
              list={this.props.list}
              percentage={this.props.percentage}
            />
          </div>
        )}
      </button>
    );
  };

  handleClickContainer(e: any) {
    if (this.props.disabled) {
      return;
    }

    let clickHandler: any = this.props.handleClick;
    if (e.target.id === "linkboxLink") {
      clickHandler = this.props.handleClickLink;
    }
    clickHandler();
  }

  renderLinkbox = (wrapperClass: string) => {
    return (
      <div
        className={`${wrapperClass} linkbox`}
        id={this.props.id}
        data-testid="linkboxTestId"
        onClick={this.handleClickContainer}
      >
        <div className="linkbox-body">
          <div
            className="linkbox-info display bp-px-16"
            data-testid="checkboxButtonId"
          >
            <CheckBoxContain
              title={this.props.title}
              lastLine={this.props.lastLine}
              list={this.props.list}
              percentage={this.props.percentage}
            />
          </div>
          {this.props.linkText && (
            <div>
              <span
                className="linkbox-link"
                data-testid="linkTextTestId"
                id="linkboxLink"
              >
                {this.props.linkText}
              </span>
            </div>
          )}
        </div>
      </div>
    );
  };

  render() {
    const disabledClass = this.props.disabled ? " disabled" : "";
    let wrapperClass = `${this.props.classRectangle} ${this.props.fullSize ? "big" : ""}`;

    if (this.props.rectangleSelected) {
      wrapperClass = `${this.props.classRectangle}-selected ${this.props.fullSize ? "big" : ""}`
    }

    wrapperClass += disabledClass;

    return this.props.type === "linkbox"
      ? this.renderLinkbox(wrapperClass)
      : this.renderCheckbox(wrapperClass);
  }
}

export default Checkbox;
