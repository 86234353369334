import React, { Component } from "react";

import CheckIcon from "../../../assets/icons/State-Spot.svg";
import Button from "../../Button";

class CalificationThanksPage extends Component {
  backToHome(){
    window.location.href = "/"
  }
  render() {
    const finishButtonProps = {
      color: "primary",
      size: "large",
      text: "Volver al inicio",
      id: "finalExpressButton",
      handleClick: this.backToHome,
      testId: "finalExpressButtonTestId",
    };
    return (
      <div className="calification-container">
        <img src={CheckIcon} alt="CheckIcon" />
        <div className="title">¡Gracias!</div>
        <div className="subtitle">Queremos brindarte el mejor servicio</div>
        <div className="button">
        <Button {...finishButtonProps}></Button>

        </div>
      </div>
    );
  }
}

export default CalificationThanksPage;
