import { Component } from "react";

import Button from "../Button";
import Thumbnail from "../Thumbnail";
import Footer from "../Footer/Footer";
import Navbar from "../navbar/Navbar";

import notFoundIcon from "../../assets/icons/icon-ilustracion-not-found.svg";

interface NotFoundPageProps {
  handleClick: () => void;
}

class NotFoundPage extends Component<NotFoundPageProps> {
  render() {
    const continueButtonProps = {
      color: "primary",
      size: "large",
      text: "Reintentar",
      id: `NotFoundPageButtonRetry`,
      handleClick: this.props.handleClick,
      disabled: false,
    };

    return (
      <div className="wrapper-content">
        <Navbar />
        <div className="page-content fit-footer">
          <div className="error-page-margin with-no-margin">
            <div className="error-page">
              <div className="icon-container">
                <Thumbnail url={notFoundIcon} name={"imagen"} />
              </div>
              <div className="title-container padding-wrapper">
                <h4 className="title" id="errorNotFoundPage">¡Ups! Página no encontrada</h4>
              </div>
              <div className="text-container padding-wrapper">
                <pichincha-typography
                  variant="bodyText"
                  color="darkGrey"
                  align="center"
                >
                  Parece que la página que estás buscando no existe o no está disponible. Tal vez te interese conocer más sobre nuestro crédito hipotecario.
                </pichincha-typography>
              </div>
              <div className="button-container padding-wrapper bp-mb-32">
                <Button {...continueButtonProps} />
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}

export default NotFoundPage;

