import { FC } from 'react'

export const Footer: FC = () => {
  let year: string = new Date().getFullYear().toString()  
  return (
    <div className="footer">
      <div className="footer-content">
        <label className="text">{`© ${year} Banco Pichincha C.A. Todos los derechos reservados.`}</label>
        <label className="text-mobile">
         {`© ${year} Banco Pichincha C.A.`}
          <br />
          Todos los derechos reservados.
        </label>
      </div>
    </div>
  )
}

export default Footer
