import { AnyAction } from 'redux';
import { validate as validateEmail } from 'email-validator';
import {
  stateValidator,
  validateInputContent,
  validateOtpErrorCode,
} from '../../common/helpers/functions';
import actionTypes from '../../actions/actionTypes';
import * as texts from '../../common/texts/expressApplication';
import * as patterns from '../../common/helpers/patterns';
import * as CONSTANTS from '../../common/helpers/constants';
import {
  handleCifFailed,
  handleSetLoginStep,
  handleTransactionalContactFailed,
  handleUpdateInputIdCardLogin,
  hanldeResirectToExpressApplication,
} from './utils';
import { setEncryptedItemToCookie } from '../../common/helpers/storage-utils';
export interface LoginState {
  otpTime: number;
  showBlock: boolean;
  wrongCode: string;
  stepLogin: number;
  idCardLogin: string;
  idCardLoginState: string;
  idCardLoginStateError: boolean;
  idCardLoginErrorHelper: string;
  phoneLogin: string;
  maskedPhone: string;
  phoneLoginState: string;
  phoneLoginStateError: boolean;
  phoneLoginErrorHelper: string;
  emailLogin: string;
  maskedEmail: string;
  emailLoginState: string;
  emailLoginStateError: boolean;
  emailLoginErrorHelper: string;
  completedPhoneLogin: boolean;
  completedEmailLogin: boolean;
  completedLoginIdCard: boolean;
  completedClientData: boolean;
  completedNotClientData: boolean;
  cif_error: boolean;
  showAlertTransactional: boolean;
  showAlert: boolean;
  cifFailuresCount: number;
  transactionalRequestFailuresCount: number;
  transactionalRequestFailureMessage: string;
  showBlockPage: boolean;
  showBlockPageFingerprintCode: boolean;
  showSomethingWentWrongPage: boolean;
  isClient: boolean;
  startButtonText: string;
  clientContinueButtonText: string;
  loading: boolean;
  authorizeContactInfo: boolean;
  authorizeContactInfoState: string;
  authorizeFingerprintCode: boolean;
  authorizeFingerprintCodeState: string;
  fingerprintCode: string;
  fingerprintCodeState: string;
  fingerprintCodeStateError: boolean;
  fingerprintCodeErrorHelper: string;
  fingerprintCodeFailuresCount: number;
  fingerprintCodeFailureMessage: string;
  otp: string;
  otpStatus: string;
  otpStateButton: string;
  otpStateResend: string;
  otpFailuresCount: number;
  otpRequestsCounter: number;
  otpJWTValidated: boolean;
  fingerprintCodeValidated: boolean;
  redirectTo: string;
  idCardInputStatus: string;
  cifSuccess: boolean;
  isSendingRequest: boolean;
}

export const fingerprintCodeStepInitialState = {
  fingerprintCode: '',
  fingerprintCodeState: '',
  fingerprintCodeStateError: true,
  fingerprintCodeErrorHelper: '',
  fingerprintCodeFailureMessage: 'Error reintente nuevamente',
  authorizeFingerprintCode: true,
  authorizeFingerprintCodeState: 'normal',
  showBlockPageFingerprintCode: false,
  fingerprintCodeFailuresCount: 0,
  fingerprintCodeValidated: false,
  completedNotClientData: false,
};

export const transactionalContactStepInitialState = {
  phoneLogin: '',
  phoneLoginState: 'normal',
  phoneLoginStateError: true,
  phoneLoginErrorHelper: '',
  emailLogin: '',
  emailLoginState: 'normal',
  emailLoginStateError: true,
  emailLoginErrorHelper: '',
  authorizeContactInfo: true,
  authorizeContactInfoState: 'normal',
  completedPhoneLogin: false,
  completedEmailLogin: false,
  completedClientData: false,
};

export const INITIAL_STATE = {
  otpTime: 120,
  showBlock: false,
  wrongCode: '',
  stepLogin: 0,
  idCardLogin: '',
  idCardLoginState: 'normal',
  idCardLoginStateError: true,
  idCardLoginErrorHelper: '',
  maskedPhone: '',
  maskedEmail: '',
  ...transactionalContactStepInitialState,
  completedLoginIdCard: false,
  cif_error: false,
  showAlertTransactional: false,
  showAlert: false,
  cifFailuresCount: 0,
  transactionalRequestFailuresCount: 0,
  transactionalRequestFailureMessage: texts.GENERIC_ERROR_MESSAGE_TEXT,
  showBlockPage: false,
  showSomethingWentWrongPage: false,
  isClient: false,
  startButtonText: 'Continuar',
  clientContinueButtonText: 'Continuar',
  loading: false,
  otp: '',
  otpStatus: 'normal',
  otpStateButton: 'normal',
  otpStateResend: 'normal',
  ...fingerprintCodeStepInitialState,
  otpFailuresCount: 0,
  otpRequestsCounter: 0,
  otpJWTValidated: false,
  redirectTo: '',
  idCardInputStatus: '',
  cifSuccess: false,
  isSendingRequest: false,
};

const login = (
  state: LoginState = INITIAL_STATE,
  action: AnyAction = { type: null }
): LoginState => {
  switch (action.type) {
    case actionTypes.LOGIN_RESET_STATE:
      return INITIAL_STATE;
    case actionTypes.SET_LOGIN_STEP:
      return handleSetLoginStep(action, state);

    case actionTypes.TOGGLE_LOGIN_ID_CARD:
      return {
        ...state,
        completedLoginIdCard: action.newState,
      };
    case actionTypes.UPDATE_INPUT_ID_CARD_LOGIN:
      return handleUpdateInputIdCardLogin(action, state);

    case actionTypes.UPDATE_INPUT_PHONE_LOGIN:
      const newPhoneNumber = action.newValue.replace(patterns.NUMBERS, '');
      const {
        state: phoneLoginState,
        stateError: phoneLoginStateError,
        errorHelper: phoneLoginErrorHelper,
      } = validateInputContent(CONSTANTS.PHONE_FIELD, newPhoneNumber);
      return {
        ...state,
        emailLogin: INITIAL_STATE.emailLogin,
        emailLoginStateError: INITIAL_STATE.emailLoginStateError,
        emailLoginState: INITIAL_STATE.emailLoginState,
        phoneLogin: newPhoneNumber,
        phoneLoginState,
        phoneLoginStateError,
        phoneLoginErrorHelper,
      };
    case actionTypes.UPDATE_INPUT_EMAIL_LOGIN:
      const emailState = stateValidator(validateEmail(action.newValue));
      const {
        state: emailLoginState,
        stateError: emailLoginStateError,
        errorHelper: emailLoginErrorHelper,
      } = validateInputContent(CONSTANTS.EMAIL_FIELD, action.newValue);
      return {
        ...state,
        phoneLogin: INITIAL_STATE.phoneLogin,
        phoneLoginStateError: INITIAL_STATE.phoneLoginStateError,
        phoneLoginState: INITIAL_STATE.phoneLoginState,
        emailLogin: action.newValue,
        emailLoginState,
        emailLoginStateError,
        emailLoginErrorHelper,
        completedLoginIdCard:
          emailState === 'error' ? false : state.completedEmailLogin,
      };
    case actionTypes.TOGGLE_ALERT_LOGIN:
      return {
        ...state,
        showAlert: action.newState,
        showAlertTransactional: action.newState,
      };
    case actionTypes.CIF_REQUEST:
      return {
        ...state,
        idCardInputStatus: 'loading',
      };
    case actionTypes.TRANSACTIONAL_CONTACT_REQUEST:
    case actionTypes.FINGERPRINT_CODE_REQUEST:
      return {
        ...state,
        showAlert: false,
        showAlertTransactional: false,
        loading: true,
      };
    case actionTypes.SENDING_REQUEST:
      return {
        ...state,
        isSendingRequest: action.newState,
      };
    default:
      break;
  }

  switch (action.type) {
    case actionTypes.CIF_SUCCESS:
      return {
        ...state,
        showAlert: false,
        showAlertTransactional: false,
        stepLogin: 1,
        loading: false,
        phoneLogin: '',
        phoneLoginErrorHelper: '',
        phoneLoginState: 'normal',
        phoneLoginStateError: true,
        emailLogin: '',
        emailLoginErrorHelper: '',
        emailLoginState: 'normal',
        emailLoginStateError: true,
        authorizeContactInfo: true,
        idCardInputStatus: 'success',
        cifSuccess: true,
      };
    case actionTypes.CIF_FAILED:
      return handleCifFailed(action, state);

    case actionTypes.TOGGLE_CONTACT_INFO_AUTHORIZATION:
      return {
        ...state,
        authorizeContactInfo: action.newValue,
        authorizeContactInfoState: stateValidator(action.newValue),
      };
    case actionTypes.TOGGLE_FINGERPRINT_CODE_AUTHORIZATION:
      return {
        ...state,
        authorizeFingerprintCode: action.newValue,
        authorizeFingerprintCodeState: stateValidator(action.newValue),
      };
    case actionTypes.TRANSACTIONAL_CONTACT_SUCCESS:
      setEncryptedItemToCookie('isClient', true);
      window.scrollTo(0, 0);
      const { email: maskedEmail, phone: maskedPhone } = action.data;
      return {
        ...state,
        loading: false,
        stepLogin: 3,
        maskedEmail,
        maskedPhone,
        showAlert: false,
        showAlertTransactional: false,
      };
    case actionTypes.TRANSACTIONAL_CONTACT_FAILED:
      return handleTransactionalContactFailed(action, state);

    case actionTypes.UPDATE_INPUT_FINGERPRINT_CODE:
      const newValue = action.newValue
        .replace(patterns.LETTERS_AND_NUMBERS, '')
        .toUpperCase();
      const {
        state: fingerprintCodeState,
        stateError: fingerprintCodeStateError,
        errorHelper: fingerprintCodeErrorHelper,
      } = validateInputContent(CONSTANTS.FINGERPRINT_CODE_FIELD, newValue);
      return {
        ...state,
        fingerprintCode: newValue,
        fingerprintCodeStateError,
        fingerprintCodeState,
        fingerprintCodeErrorHelper,
      };
    case actionTypes.FINGERPRINT_CODE_SUCCESS:
      setEncryptedItemToCookie('isClient', false);
      window.scrollTo(0, 0);
      return {
        ...state,
        loading: false,
        showAlert: false,
        showAlertTransactional: false,
        fingerprintCodeValidated: true,
      };
    case actionTypes.CLEAR_REDIRECTION_TO_EXPRESS_APPLICATION:
      return { ...state, redirectTo: '' };
    case actionTypes.REDIRECT_TO_EXPRESS_APPLICATION:
      return hanldeResirectToExpressApplication(action, state);

    default:
      break;
  }

  switch (action.type) {
    case actionTypes.FINGERPRINT_CODE_FAILED:
      const lastTryServiceError = state.fingerprintCodeFailuresCount > 4;
      const isServiceError = ![423].includes(action.requestStatus);
      const isBlocked = [423].includes(action.requestStatus);
      return {
        ...state,
        showSomethingWentWrongPage: isServiceError && lastTryServiceError,
        showBlockPageFingerprintCode: isBlocked,
        fingerprintCodeFailuresCount: state.fingerprintCodeFailuresCount + 1,
        showAlert: true,
        fingerprintCode: '',
        fingerprintCodeStateError: true,
        loading: false,
        fingerprintCodeValidated: false,
      };
    case actionTypes.UPDATE_OTP_VALUE:
      return {
        ...state,
        otp: action.newValue,
        otpStatus: 'normal',
      };
    case actionTypes.UPDATE_OTP_TIME:
      return {
        ...state,
        otpTime: 120,
      };
    case actionTypes.OTP_REQUEST:
      return {
        ...state,
        showAlert: false,
        showAlertTransactional: false,

        otpStateButton: 'loading',
      };
    case actionTypes.OTP_SUCCESS:
      window.scrollTo(0, 0);
      const { otp, identification, status, flow } = action.data;
      const validResponse =
        otp === state.otp &&
        identification === state.idCardLogin &&
        status === 200 &&
        flow === 'OTP';
      return {
        ...state,
        showAlert: false,
        otpJWTValidated: validResponse,
      };
    case actionTypes.OTP_FAILED:
      const invalidOtp = validateOtpErrorCode(action.requestStatus);
      return {
        ...state,
        showBlock: action.requestStatus === 429 ? true : false,
        wrongCode: invalidOtp,
        otpStateButton: 'normal',
        showAlert: true,
        showAlertTransactional: false,
        otpStatus: 'error',
      };
    case actionTypes.OTP_RESEND_REQUEST:
      return {
        ...state,
        showAlert: false,
        showAlertTransactional: false,

        otpStateResend: 'loading',
      };
    case actionTypes.OTP_RESEND_SUCCESS:
      return {
        ...state,
        otpStateResend: 'success',
        otpStatus: 'normal',
        showAlert: true,
        otpTime: state.otpTime + 120,
      };
    case actionTypes.OTP_RESEND_FAILED:
      return {
        ...state,
        otpStateResend: 'error',
        otpTime: action.requestStatus === (404 || 204) ? 0 : state.otpTime,
        showAlert: true,
      };
    case actionTypes.RESET_OTP_ERROR:
      return {
        ...state,
        wrongCode: '',
        otpStatus: 'normal',
      };
    default:
      return state;
  }
};

export default login;
