import React from "react";

import "./CallToActionSecondary.scss";

import Thumbnail from "../Thumbnail";
import Button from "../Button";

import imageBanner from "../../assets/img/img-inicia-tu-sueno-a-tu-hogar-banco-pichincha-xl.jpg";

const title = "Inicia el camino a tu sueño, ¡ahora!";

function CallToActionSecondary() {
  return (
    <div className="cta-secondary">
      <div className="card wow animate__animated animate__fadeInUp">
        <Thumbnail url={imageBanner} name={"imagen"} />
        <div className="actions">
          <p className="title">{title}</p>
          <div className="buttons">
            <pichincha-link href="/calculadora">
              <div className="tertiary">
                <Button
                  id="taglineSimulateCreditButton"
                  size="large"
                  color="secondary"
                  text="Simula tu crédito"
                />
              </div>
            </pichincha-link>
            <pichincha-link href="/precalificacion">
              <div className="">
                <Button
                  id="taglineRequestCreditButton"
                  size="large"
                  color="primary"
                  text="Comenzar ahora"
                />
              </div>
            </pichincha-link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CallToActionSecondary;
