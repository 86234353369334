import React, { Component } from "react";
import "./AmortizationCheckBox.scss";

import CheckboxInput from "../CheckboxInput/CheckboxInput";

interface AmortizationCheckBoxProps {
  id: string;
  title: string;
  description: string;
  rectangleSelected: boolean;
  disabled: boolean;
  handleClick: () => void;
  testId?: string;
}

class AmortizationCheckBox extends Component<AmortizationCheckBoxProps> {
  render() {
    return (
      <button
        id={`${this.props.id}`}
        data-testid={
          this.props.testId ? this.props.testId : "clickable-rectangle"
        }
        className={
          this.props.rectangleSelected
            ? "rectangle-amortization-selected"
            : "rectangle-amortization"
        }
        onClick={this.props.handleClick}
      >
        <div className="display bp-py-16 bp-pl-24 bp-pr-16">
          <div className="textBox-limit">
            <div className="box-title">{this.props.title}</div>
            <div className="box-description">{this.props.description}</div>
          </div>

          <div className="check-align">
            <CheckboxInput
              id={`${this.props.id}CheckboxInput`}
              checked={this.props.rectangleSelected}
              handleClick={this.props.handleClick}
            />
          </div>
        </div>
      </button>
    );
  }
}

export default AmortizationCheckBox;
