import React, { Component } from "react";

import MortgageDetailsContent from "./MortgageDetailsContent";

import { MortgageDetailsProps } from "../../../common/helpers/integration";

export default class MortgageDetailsMobile extends Component<MortgageDetailsProps> {
  handleClick(this: any) {
    this.classList.toggle("active");
    let panel = this.nextElementSibling;
    if (panel.style.display === "block") {
      panel.style.display = "none";
    } else {
      panel.style.display = "block";
    }
  }

  componentDidMount() {
    // @ts-ignore
    document
      .querySelector("#buttonMortgageDetailsButton")
      .addEventListener("click", this.handleClick);
  }

  render() {
    return (
      <div className="result-card mobile bp-mb-16">
        <button className="accordion" id="buttonMortgageDetailsButton" data-testid="buttonMortgageDetailsButton">
          Detalles de tu credito
        </button>

        <MortgageDetailsContent
          requestedAmount={this.props.requestedAmount}
          appraisal={this.props.appraisal}
          legalFee={this.props.legalFee}
          solca={this.props.solca}
          mainFee={this.props.mainFee}
          firstClass="panel mortgage-detail"
          firstId="amountRequestedPopoverMobile"
          secondId="appraisalPopoverMobile"
          thirdId="legalFeesPopoverMobile"
          fourthId="solcaPopoverMobile"
          lastId="totalAmountPopoverMobile"
          chosenProductPercentage={this.props.chosenProductPercentage}
          chosenProductName={this.props.chosenProductName}
        />
      </div>




    );
  }
}
