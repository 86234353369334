import { validate as validateEmail } from 'email-validator';
import { CalculatorState } from '../../reducers/calculator';
import * as CONSTANTS from '../helpers/constants';
import DactilarCodeValidator from './DactilarCodeValidator';
import { initialProduct, PaymenDetailProps, ProductItem } from './integration';
import { Cities, Provinces } from './locations';
import { PHONE_FORMAT } from './patterns';

import { autocompleteData } from '../../components/ExpressRequest/CreditData/locations';
import * as errorMessages from './errorMessages';
import { PATHS } from './paths';
import { removeItemFromCookie, setEncryptedItemToCookie } from './storage-utils';

const RADIX_PARAMETER = 10;

export const formatCurrency = (number: string) => {
  if (!number) {
    return '';
  }
  const clearNumber = removeCommas(number);
  return Number(clearNumber).toLocaleString('es-ec');
};

export const numberFormatCurrency = (number: number) => {
  if (!number) {
    return '';
  }
  return Number(number).toLocaleString('es-ec', {
    maximumFractionDigits: 2,
  });
};

export const yearsToMonths = (years: string) => Number(years) * 12;

export const monthsToYears = (months: number) => months / 12;

export const removeCommas = (currencyFormat: string) => {
  const filteredCharacter = currencyFormat
    .split('')
    .filter((character) => character !== '.');
  return filteredCharacter.join('');
};

export const isNumber = (character: string) =>
  '1234567890'.indexOf(character) > -1 || character === '';

export const isLetter = (character: string) =>
  'qwertyuiopasdfghjklzxcvbnmñ QWERTYUIOPASDFGHJKLZXCVBNMÑáéíóúÁÉÍÓÚ'.indexOf(
    character
  ) > -1;

export const isValidCharacter = (character: string) =>
  'qwertyuiopasdfghjklzxcvbnmñ QWERTYUIOPASDFGHJKLZXCVBNMÑáéíóúÁÉÍÓÚ,;.0123456789'.indexOf(
    character
  ) > -1;
export const getProductIndex = (state: CalculatorState) => {
  if (state.selected_product_type === 'new-used') {
    if (state.amount > state.product[0].amountMax) {
      return 1;
    }
  }
  return 0;
};

export const capitalize = (word: string) => {
  if (!word) {
    return '';
  }
  return word[0].toUpperCase() + word.slice(1).toLowerCase();
};

export const capitalizeName = (name: string) => {
  name = name.trim();
  const capitalizedArray = name.split(' ').map((word) => capitalize(word));
  return capitalizedArray.join(' ');
};

export const getPercentageOfNumber = (percentage: number, number: number) => {
  const percentageAmount = number * (percentage / 100);
  const percentageAmountString = percentageAmount.toString();
  return parseInt(percentageAmountString, RADIX_PARAMETER);
};

export const checkEmptyField = (value: string, phone?: boolean) => {
  const props = {
    state: 'normal',
    error: false,
    errorHelper: 'Por favor, completa el campo',
  };
  if (!value || (phone && value.length < 8)) {
    props.state = 'error';
    props.error = true;
  }
  return props;
};

export const checkRatingValue = (value: number) => {
  const props = {
    error: true,
  };
  if (value >= 1 && value <= 5) {
    props.error = false;
  }
  return props;
};

export const checkRatingSelected = (value: number) => {
  const props = {
    checked: true,
  };
  if (value >= 1 && value <= 5) {
    props.checked = false;
  }
  return props;
};

export const checkEmptyInput = (value: string) => {
  const props = {
    actualValue: value,
    state: 'normal',
    error: false,
  };
  if (!value) {
    props.state = 'error';
    props.error = true;
  }
  return props;
};

export const checkEmptyPhoneInput = (value: string) => {
  const props = {
    state: 'normal',
    error: false,
    phone: value,
    phoneTotal: `09${value}`,
  };

  if (!value) {
    props.state = 'error';
    props.error = true;
    props.phone = value;
    props.phoneTotal = `09${value}`;
  }
  return props;
};

export const updateHousePriceStatus = (
  state: CalculatorState,
  productIndex: number
) => {
  const product = state.product[productIndex];
  const amountMin = state.product[0].amountMin;
  const amountMax =
    state.product.length > 1
      ? state.product[1].amountMax
      : state.product[0].amountMax;
  const minAmountString = parseInt(
    amountMin.toString(),
    RADIX_PARAMETER
  ).toString();
  const minAmountFormatted = formatCurrency(minAmountString);
  const maxAmountString = parseInt(
    product.amountMax.toString(),
    RADIX_PARAMETER
  ).toString();
  const maxAmountFormatted = formatCurrency(maxAmountString);

  const maxPercentageAmount = getPercentageOfNumber(
    product.percentMax,
    state.amount
  );
  const isVipVis = product.monthsMax === product.monthsMin;
  const maxPercentageAmountString = parseInt(
    maxPercentageAmount.toString(),
    RADIX_PARAMETER
  ).toString();
  const maxPercentageAmountFormatted = formatCurrency(
    maxPercentageAmountString
  );

  const requestedAmountMin = product.requestedAmountMin || product.amountMin;
  const requestedAmountMinString = parseInt(
    requestedAmountMin.toString(),
    RADIX_PARAMETER
  ).toString();
  const requestedAmountMinFormatted = formatCurrency(requestedAmountMinString);
  const props = {
    state: 'normal',
    normalHelper: `Min. $${minAmountFormatted}`,
    errorHelper: '',
    error: false,
    requestedAmountNormalHelper: `Min. $${requestedAmountMinFormatted} ${isVipVis ? 'Max. $' + maxPercentageAmountFormatted : ''
      }`,
    requestedAmountMax: maxPercentageAmount,
  };

  if (state.amount < amountMin) {
    props.state = 'error';
    props.errorHelper = `El valor debe ser mínimo $${minAmountFormatted}`;
    props.error = true;
  }
  if (state.amount > amountMax) {
    props.state = 'error';
    props.errorHelper = `El valor debe ser máximo $${maxAmountFormatted}`;
    props.error = true;
  }

  if (!state.house_price) {
    props.state = 'error';
    props.errorHelper = `Por favor, completa el campo`;
    props.error = true;
  }

  if (props.error && isVipVis) {
    props.requestedAmountMax = product.amountMax;
  }

  return props;
};

export const initProps = (product: ProductItem) => {
  const minAmountString = parseInt(
    product.amountMin.toString(),
    RADIX_PARAMETER
  ).toString();
  const minAmountFormatted = formatCurrency(minAmountString);

  const requestedAmountMin = product.requestedAmountMin || product.amountMin;
  const requestedAmountMinString = parseInt(
    requestedAmountMin.toString(),
    RADIX_PARAMETER
  ).toString();
  const requestedAmountMinFormatted = formatCurrency(requestedAmountMinString);

  const maxAmountString = parseInt(
    product.amountMax.toString(),
    RADIX_PARAMETER
  ).toString();
  const maxAmountFormatted = formatCurrency(maxAmountString);
  const isVipVis = product.monthsMax === product.monthsMin;

  const props = {
    housePriceNormalHelper: `Min. $${minAmountFormatted}`,
    requestedAmountNormalHelper: `Min. $${requestedAmountMinFormatted}`,
    months: 0,
    loan_term_years: '',
    loan_term_years_normal_helper: 'Entre 3 y 20 años',
    house_price_placeholder: 'Ej.: $100.000',
    loan_term_years_error: true,
  };

  if (JSON.stringify(product) === JSON.stringify(initialProduct)) {
    return props;
  }

  if (isVipVis) {
    props.months = 240;
    props.loan_term_years = '20';
    props.housePriceNormalHelper += ` Max. $${maxAmountFormatted}`;
    props.requestedAmountNormalHelper += ` Max. $${maxAmountFormatted}`;
    props.loan_term_years_normal_helper = 'Entre 20 y 25 años';
    props.house_price_placeholder = 'Ej.: $90.000';
    props.loan_term_years_error = false;
  }

  return props;
};

export const updateRequestedAmountStatus = (
  state: CalculatorState,
  productIndex: number
) => {
  const product = state.product[productIndex];
  const props = {
    state: 'normal',
    errorHelper: '',
    error: false,
  };

  const requestedAmountMin = product.requestedAmountMin || product.amountMin;

  if (
    state.requested_amount > state.requested_amount_max ||
    state.requested_amount < requestedAmountMin
  ) {
    props.state = 'error';
    props.errorHelper = `El valor debe estar entre $${formatCurrency(
      requestedAmountMin.toString()
    )} y $${formatCurrency(state.requested_amount_max.toString())}`;
    props.error = true;
  }

  if (!state.requested_amount_display) {
    props.state = 'error';
    props.errorHelper = `Por favor, completa el campo`;
    props.error = true;
  }

  if (!state.house_price) {
    props.state = 'error';
    props.errorHelper = `Primero ingresa el monto de la vivienda`;
    props.error = true;
  }

  return props;
};

export const updateLoanTermYears = (
  state: CalculatorState,
  productIndex: number
) => {
  const product = state.product[productIndex];

  const props = {
    state: 'normal',
    errorHelper: '',
    error: false,
  };

  if (state.months > product.monthsMax || state.months < product.monthsMin) {
    props.state = 'error';
    props.errorHelper = `El valor debe estar entre ${monthsToYears(
      product.monthsMin
    )} y ${monthsToYears(product.monthsMax)} años`;
    props.error = true;
  }

  if (!state.loan_term_years) {
    props.state = 'error';
    props.errorHelper = `Por favor, completa el campo`;
    props.error = true;
  }

  return props;
};

export const sumInterests = (payments: Array<PaymenDetailProps>) =>
  payments.reduce((total, payment) => total + payment.interest, 0);

export const sumInsurances = (payments: Array<PaymenDetailProps>) =>
  payments.reduce((total, payment) => total + payment.insurance, 0);

export const getInputNameState = (value: string) => {
  if (!value || value.length < 2) {
    return 'error';
  }
  return 'normal';
};

export const getProvince = (value: string) => {
  const verifyInclude = Provinces.includes(value);
  if (!value || !verifyInclude) {
    return 'error';
  }
  return 'normal';
};

export const getCities = (value: string, province: string) => {
  const regex = new RegExp(`^${value}`, 'i');
  autocompleteData.sort((a: any, b: any) => {
    if (a.city < b.city) {
      return -1;
    }
    if (a.city > b.city) {
      return 1;
    }
    return 0;
  });
  const filterByCity = autocompleteData.filter((v) => regex.test(v.city));
  const filterByProvince = filterByCity.filter((data) => {
    return data.province.match(province);
  });
  let suggestionsCities = filterByProvince.map((data) => data.city);
  let suggestionsCitiesNoRepeat = suggestionsCities.filter(
    (item, idx) => suggestionsCities.indexOf(item) === idx
  );
  const restrict = suggestionsCitiesNoRepeat.includes(value);
  const verifyInclude = Cities.includes(value);
  if (!value || !verifyInclude || !restrict) {
    return 'error';
  }
  return 'normal';
};

const handleIdentificationLoop = (number: any) => {
  let suma = 0;
  for (let index = 0; index < number.length - 1; index++) {
    if (index % 2 === 0) {
      let aux = parseInt(number.toString().charAt(index), RADIX_PARAMETER) * 2;
      if (aux > 9) aux -= 9;
      suma += aux;
    } else {
      let aux = parseInt(number.toString().charAt(index), RADIX_PARAMETER);
      if (aux > 9) aux -= 9;
      suma += aux;
    }
  }
  return suma;
};

export const IdentificationValidator = (number: any) => {
  let suma = 0;
  let residuo = 0;
  let modulo = 11;
  const provincia = number.substr(0, 2);
  if (number.length === 10) {
    if (provincia < 1 || (provincia > 24 && provincia < 30) || provincia > 30) {
      return false;
    }

    const d3 = number.substr(2, 1);
    const d10 = number.substr(9, 1);

    if (d3 <= 9) {
      suma = handleIdentificationLoop(number);
      modulo = 10;
    }

    residuo = suma % modulo;
    const digitoVerificador = residuo === 0 ? 0 : modulo - residuo;
    if (Number(digitoVerificador) !== Number(d10)) {
      return false;
    }
  } else {
    return false;
  }
  return true;
};

export const isSingle = (maritalStatus: string) =>
  ['divorced', 'widower', 'single'].indexOf(maritalStatus) > -1;

export const isMarried = (maritalStatus: string) =>
  ['married', 'free union'].indexOf(maritalStatus) > -1;

export const partnerDataRequired = (
  maritalStatus: string,
  separationOfProperty: string
) => isMarried(maritalStatus) && separationOfProperty === 'no';

export const updateMonthlyIncomeStatus = (
  amount: string,
  minAmountRequired: boolean,
  basicSalary: number
) => {
  const props = {
    state: 'normal',
    error: false,
    errorHelper: '',
  };
  const amountNumber = Number(removeCommas(amount));
  if (!amountNumber || (amountNumber < basicSalary && minAmountRequired)) {
    props.state = 'error';
    props.error = true;
    props.errorHelper = `Mínimo es $${basicSalary}`;
  }
  return props;
};

export const scrollToTop = () => window.scrollTo(0, 0);

export const getPhoneState = (phoneNumber: string, length: number) => {
  if (!phoneNumber || phoneNumber.length < length) {
    return 'error';
  }
  return 'normal';
};

export const recaptchaGenerateTokenCallback = (token: string) =>
  window.sessionStorage.setItem(CONSTANTS.RECAPTCHA_STORAGE_KEY, token);

export const getRecaptchaToken = () =>
  window.sessionStorage.getItem(CONSTANTS.RECAPTCHA_STORAGE_KEY) || '';

export const setUtmHeaders = (
  headersFromQuery: any,
  referer: string,
  channel: string
) => {
  const utmHeaders = { source: '', campaign: '', medium: '', content: '' };

  if (Object.keys(headersFromQuery).length === 0) {
    if (referer) {
      utmHeaders.source =
        referer.indexOf('pichincha.com') === -1 ? 'buscador' : 'portal_publico';
    } else {
      utmHeaders.source = channel;
    }
  } else {
    utmHeaders.source = headersFromQuery.source || '';
    utmHeaders.campaign = headersFromQuery.campaign || '';
    utmHeaders.medium = headersFromQuery.medium || '';
    utmHeaders.content = headersFromQuery.content || '';
  }

  window.sessionStorage.setItem(
    CONSTANTS.UTM_HEADERS_KEY,
    JSON.stringify(utmHeaders)
  );
};

export const getUtmHeaders = () => {
  const headers: any = window.sessionStorage.getItem(CONSTANTS.UTM_HEADERS_KEY);
  return JSON.parse(headers);
};

export const focusAlertMessage = (elementId: string): void => {
  const alertMessageElement = document?.getElementById(elementId);
  if (alertMessageElement) {
    alertMessageElement.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'start',
    });
  }
};

export const stateValidator = (value: any) => {
  return value ? 'normal' : 'error';
};

// fragment to reduce cognitive compexity of validateInputContent function
const fields: any = {
  IDENTIFICATION_FIELD: {
    empty: errorMessages.EMPTY_IDENTIFICATION,
    incomplete: errorMessages.INCOMPLETE_IDENTIFICATION,
    invalid: errorMessages.INVALID_IDENTIFICATION,
  },
  EMAIL_FIELD: {
    empty: errorMessages.EMPTY_EMAIL,
    invalid: errorMessages.INVALID_EMAIL,
  },
  PHONE_FIELD: {
    empty: errorMessages.EMPTY_PHONE,
    incomplete: errorMessages.INCOMPLETE_PHONE,
  },
  FINGERPRINT_CODE_FIELD: {
    empty: errorMessages.EMPTY_FINGERPRINT_CODE,
    incomplete: errorMessages.INCOMPLETE_FINGERPRINT_CODE,
    invalid: errorMessages.INVALID_FINGERPRINT_CODE,
  },
};

const validField = {
  state: 'normal',
  stateError: false,
  errorHelper: '',
};

let errorHelper = '';
const phoneValidator = (value: string, type: string) => {
  const fieldMessages = fields[type];

  if (value.length < CONSTANTS.PHONE_MIN_LENGTH || !value.match(PHONE_FORMAT)) {
    errorHelper = fieldMessages.incomplete;
  }
  if (!value.length) {
    errorHelper = fieldMessages.empty;
  }
};
const identificationValidator = (value: string, type: string) => {
  const fieldMessages = fields[type];

  if (!IdentificationValidator(value)) {
    errorHelper = fieldMessages.invalid;
  }
  if (value.length < CONSTANTS.IDENTIFICATION_MIN_LENGTH) {
    errorHelper = fieldMessages.incomplete;
  }
  if (!value.length) {
    errorHelper = fieldMessages.empty;
  }
};
const emailValidator = (value: string, type: string) => {
  const fieldMessages = fields[type];

  if (!validateEmail(value)) {
    errorHelper = fieldMessages.invalid;
  }
  if (!value.length) {
    errorHelper = fieldMessages.empty;
  }
};
const fingerprintCodeValidator = (value: string, type: string) => {
  const fieldMessages = fields[type];

  if (!DactilarCodeValidator(value)) {
    errorHelper = fieldMessages.invalid;
  }
  if (value.length < CONSTANTS.FINGERPRINT_CODE_MIN_LENGTH) {
    errorHelper = fieldMessages.incomplete;
  }
  if (!value.length) {
    errorHelper = fieldMessages.empty;
  }
};
// end of fragment

export const validateInputContent = (type: string, value: string) => {
  errorHelper = '';
  switch (type) {
    case CONSTANTS.PHONE_FIELD:
      phoneValidator(value, type);
      break;
    case CONSTANTS.IDENTIFICATION_FIELD:
      identificationValidator(value, type);
      break;
    case CONSTANTS.EMAIL_FIELD:
      emailValidator(value, type);
      break;
    case CONSTANTS.FINGERPRINT_CODE_FIELD:
      fingerprintCodeValidator(value, type);
      break;
    default:
      break;
  }
  if (errorHelper.length > 0) {
    return {
      state: 'error',
      stateError: true,
      errorHelper,
    };
  }
  return validField;
};

export const validateOtpErrorCode = (errorCode: number) => {
  let invalidOtp = '';

  if (
    errorCode === 400 ||
    errorCode === 500 ||
    errorCode === 404 ||
    errorCode === 204
  ) {
    invalidOtp = '1';
  }
  if (errorCode === 408) {
    invalidOtp = '2';
  }
  return invalidOtp;
};

export const getTextState = (invalid: boolean, errorText: string) => {
  if (invalid && errorText) {
    return 'error';
  }
  return 'normal';
};

export const buyingIntentionTimeRequired = (value: string) => {
  return value === 'empty' ? 'error' : '';
};

export const closeModal = (e: any, onConfirmAction: any, validIds: any) => {
  if (!onConfirmAction || !validIds) {
    return;
  }

  const confirmClose = () => {
    onConfirmAction();
    // @ts-ignore
    document.removeEventListener('keydown', closeModal);
  };

  const clickedId = e.target.id;
  if (validIds.includes(clickedId)) {
    confirmClose();
  }

  if (e.type === 'keydown' && e.key === 'Escape') {
    confirmClose();
  }
};

export const openModal = (stateUpdater: any) => {
  // @ts-ignore
  document.addEventListener('keydown', closeModal);
  stateUpdater(true);
};

export const logout = (
  logoutBack: any,
  channel: string,
  jwt: string,
  redirect?: boolean
) => {
  setTimeout(() => {
    //@ts-ignore
    grecaptcha
      .execute(CONSTANTS.REACT_APP_RECAPTCHA_TOKEN)
      .then(async (recaptchaToken: string) => {
        await logoutBack(recaptchaToken, CONSTANTS.GUID, channel, jwt);
        if (redirect) {
          deleteInteractionsCookies(CONSTANTS.PORTAL_INTERACTION_COOKIES);
          deleteInteractionsCookies(CONSTANTS.FACEPHI_INTERACTION_COOKIES);
          window.location.href = '/login';
        }
      });
  }, 500);
};

export const deleteInteractionsCookies = (cookiesList: string[]) => {
  cookiesList.forEach((cookieKey) => {
    removeItemFromCookie(cookieKey);
  })
};

export const openDataProtectionModalValidation = (
  acceptLdpd: boolean,
  dataProtectionModalOpen: boolean,
  isClient: boolean,
  currentStep: number
) => {
  if (!acceptLdpd && dataProtectionModalOpen) {
    if (isClient && (currentStep === 1 || currentStep === 3)) return true;
    if (!isClient && currentStep === 7) return true;
  }
  return false;
};

export const getIdentification = (idCardLogin: string, customerInfo: any) => {
  if (idCardLogin === '') return customerInfo.customer.identification;
  return idCardLogin;
};

export const getPhone = (phone: string) => {
  if (phone === '') return null;
  return CONSTANTS.PHONE_PREFIX + phone;
};

export const getModalSize = (smallSize: boolean) => {
  if (smallSize) return 'sm';
  return 'md';
};

export const setCookieFacephi = (facephiCookies: any) => {

  setTimeout(function () {
    if (facephiCookies.token) {
      setEncryptedItemToCookie('facephi', facephiCookies);
      window.location.href = PATHS.noClientValidation;
    }
  }, 3000);

}
