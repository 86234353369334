import React, { Component } from "react";
import { connect, ConnectedProps } from "react-redux";

import { PieChart, Pie } from "recharts";

import { ReduxState } from "../common/store";
import actions from "../actions";

import Popover from "./Popover";
import { numberFormatCurrency } from "../common/helpers/functions";

class GraphicResults extends Component<ReduxProps> {
  render() {
    const firstPayment = this.props.calculation_payment_details[0];
    const disencumbranceInsuranceFee =
      this.props.calculation_summary.creditLifeInsuranceFee;
    const fireInsuranceFee = this.props.calculation_summary.fireInsuranceFee;
    const mainFeeAndInterests = firstPayment.main + firstPayment.interest;
    const approximateMonthlyFee =
      disencumbranceInsuranceFee + fireInsuranceFee + mainFeeAndInterests;

    const data = [
      { value: disencumbranceInsuranceFee, fill: "#0F265C" },
      { value: mainFeeAndInterests, fill: "#FFDD00" },
      { value: fireInsuranceFee, fill: "#6F7D9D" },
    ];

    return (
      <div>
        <div className="graphic-display">
          <div className="size-graphic">
            <PieChart width={320} height={320}>
              <Pie
                dataKey="value"
                data={data}
                cx={155}
                cy={155}
                innerRadius={80}
                outerRadius={150}
                fill="#82ca9d"
              />
            </PieChart>
          </div>
          <div className="circle-title">
            <div data-testid="title-result-graph" className="title-result">
              Cuota mensual aproximada
            </div>
            <div className="monthly-fee">
              ${numberFormatCurrency(approximateMonthlyFee)}
            </div>
          </div>
        </div>
        <div className="totals">
          <div className="row bp-mb-8">
            <div className="bullet">
              <div className="dot yellow bp-mr-8"></div>
              <pichincha-typography
                variant="smallText"
                color="blue"
                weight_color="500"
              >
                Capital + interés
              </pichincha-typography>
            </div>
            <div>
              <pichincha-typography
                variant="smallText"
                color="blue"
                weight_color="500"
              >
                ${numberFormatCurrency(mainFeeAndInterests)}
              </pichincha-typography>
            </div>
          </div>
          <div className="row bp-mb-8">
            <div className="bullet">
              <div className="dot blue-500 bp-mr-8"></div>
              <Popover
                id="desincumbranceAssurancePopover"
                linkName="Seguro de Desgravamen"
                appendText={""}
                popoverContent="Valor que se cobra mensualmente para cubrir el saldo
                adeudado de tu crédito en caso de muerte natural o
                accidental, invalidez total y permanente por accidente o
                enfermedad."
              />
            </div>
            <div>
              <pichincha-typography
                variant="smallText"
                color="blue"
                weight_color="500"
              >
                ${numberFormatCurrency(disencumbranceInsuranceFee)}
              </pichincha-typography>
            </div>
          </div>
          <div className="row bp-mb-8">
            <div className="bullet">
              <div className="dot blue-300 bp-mr-8"></div>
              <Popover
                id="fireAssurancePopover"
                linkName="Seguro contra incendio y terremoto"
                appendText={""}
                popoverContent="Valor que se cobra mensualmente para proteger tu vivienda de
                    eventos catastróficos de acuerdo a la normativa vigente."
              />
            </div>
            <div>
              <pichincha-typography
                variant="smallText"
                color="blue"
                weight_color="500"
              >
                ${numberFormatCurrency(fireInsuranceFee)}
              </pichincha-typography>
            </div>
          </div>
        </div>

        <div className="main-total-result">
          <pichincha-grid container justify="space-between">
            <pichincha-grid item xs="9">
              <pichincha-typography
                variant="smallText"
                color="blue"
                weight_color="500"
                weight="bold"
                align="center"
              >
                Cuota mensual aproximada
              </pichincha-typography>
            </pichincha-grid>
            <pichincha-grid item xs="3">
              <pichincha-typography
                variant="smallText"
                color="blue"
                weight_color="500"
                weight="bold"
                align="right"
              >
                ${numberFormatCurrency(approximateMonthlyFee)}
              </pichincha-typography>
            </pichincha-grid>
          </pichincha-grid>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: ReduxState) => {
  const { calculator } = state;
  const {
    money_capital,
    interest,
    calculation_summary,
    calculation_payment_details,
  } = calculator;
  return {
    money_capital,
    interest,
    calculation_summary,
    calculation_payment_details,
  };
};

const { calculate } = actions;

const connector = connect(mapStateToProps, { calculate });

type ReduxProps = ConnectedProps<typeof connector>;

export default connector(GraphicResults);
