import * as calculatorActionTypes from './calculator/actionTypes';
import * as expressRequest from './expressRequest/actionTypes';
import * as siteActionTypes from './site/actionTypes';
import * as channelsActionTypes from './channels/actionTypes';
import * as loginActionTypes from './login/actionTypes';
import * as civilRegistrationActionTypes from './civilRegistration/actionTypes';
import * as callMeBackActionTypes from './callMeBack/actionTypes';


const actionTypes = {
  ...calculatorActionTypes,
  ...expressRequest,
  ...siteActionTypes,
  ...channelsActionTypes,
  ...loginActionTypes,
  ...civilRegistrationActionTypes,
  ...callMeBackActionTypes,
};

export default actionTypes;
