export const CIVIL_REGISTRATION_NAMES_REQUEST =
  '@@dreambuilders/CIVIL_REGISTRATION_NAMES_REQUEST';
export const CIVIL_REGISTRATION_NAMES_FAILED =
  '@@dreambuilders/CIVIL_REGISTRATION_NAMES_FAILED';
export const CIVIL_REGISTRATION_NAMES_SUCCESS =
  '@@dreambuilders/CIVIL_REGISTRATION_NAMES_SUCCESS';
export const CIVIL_REGISTRATION_HIDE_LOADER =
  '@@dreambuilders/CIVIL_REGISTRATION_HIDE_LOADER';
export const CIVIL_REGISTRATION_RESET_STATE =
  '@@dreambuilders/CIVIL_REGISTRATION_RESET_STATE';
export const CIVIL_REGISTRATION_UPDATE_LDPD =
  '@@dreambuilders/CIVIL_REGISTRATION_UPDATE_LDPD';
