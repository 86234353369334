import { Component } from "react";
import { connect, ConnectedProps } from "react-redux";
import actions from "../../../actions/";

import * as KEYS from "../../../common/helpers/keyCodes";

import logoutIcon from "../../../assets/icons/logout.svg";
import "./LogoutLink.scss";
import { ReduxState } from "../../../common/store";
import { logout } from "../../../common/helpers/functions";

class LogoutLink extends Component<ReduxProps> {
  constructor(props: ReduxProps) {
    super(props);
    this.state = { execBtn: true };
  }

  handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if ([KEYS.ENTER, KEYS.SPACEBAR].includes(e.key)) {
      e.currentTarget.click();
    }
  };

  render() {
    return (
      <div
        className="text-icon"
        tabIndex={0}
        onKeyPress={this.handleKeyDown}
        onClick={() =>
          logout(
            this.props.logoutBack,
            this.props.channel,
            this.props.jwt,
            true
          )
        }
        data-testid="logoutLinkTestId"
      >
        <div className="icon-container">
          <img src={logoutIcon} alt="" />
        </div>
        <label className="text-label desktop">Cerrar sesión</label>
      </div>
    );
  }
}

const mapStateToProps = (state: ReduxState) => {
  const { site } = state;
  const { channel, jwt } = site;
  return { jwt, channel };
};

const { cleanSession, logoutBack, resetExpressRequest } = actions;

const connector = connect(mapStateToProps, {
  cleanSession,
  logoutBack,
  resetExpressRequest,
});

type ReduxProps = ConnectedProps<typeof connector>;

export default connector(LogoutLink);
