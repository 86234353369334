import React, { Component } from "react";
import Button from "../Button";

interface SideDrawerProps {
  animation: string;
  currentPage?: boolean;
}

class SideDrawer extends Component<SideDrawerProps> {
  render() {
    let morgageGuideLinkClassName = "item--link";

    if (this.props.currentPage) {
      morgageGuideLinkClassName += " active";
    }
    return (
      <div className={this.props.animation}>
        <div className="item">
          <pichincha-link
            id="howToObtainCreditDrawerLink"
            disabled="false"
            weight="normal"
            href="/guia_hipotecaria"
            data-testid="mobileLinkTestId"
          >
            <span
              className={morgageGuideLinkClassName}
              data-testid="link-mortgage-guide"
            >
              Cómo obtener tu crédito
            </span>
          </pichincha-link>
        </div>
        <div className="divider"></div>
        <pichincha-link href="/calculadora" data-testid="mobileLinkTestId">
          <div className="item">
            <Button
              id="simulateCreditDrawerButton"
              size="medium"
              color="secondary"
              text="Simular crédito"
            />
          </div>
        </pichincha-link>
        <pichincha-link href="/precalificacion" data-testid="mobileLinkTestId">
          <div className="item">
            <Button
              id="requestCreditDrawerButton"
              size="medium"
              color="primary"
              text="Comenzar ahora"
            />
          </div>
        </pichincha-link>
      </div>
    );
  }
}

export default SideDrawer;
