import "./RecaptchaDisclaimer.scss";

function RecaptchaDisclaimer() {
  return (
    <div className="recaptcha-disclaimer">
      Este sitio está protegido por reCAPTCHA bajo la <a
        href="https://policies.google.com/privacy?hl=es"
        rel="noreferrer"
        target="_blank"
      >política de privacidad</a> de Google y sus <a
        href="https://policies.google.com/terms?hl=es"
        rel="noreferrer"
        target="_blank"
      >términos de servicio</a>.
    </div>
  );
}

export default RecaptchaDisclaimer;
