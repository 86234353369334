import React, { Component } from "react";

interface ButtonProps {
  color: string;
  size: string;
  text: string;
  id?: string;
  handleClick?: any;
  disabled?: boolean;
  testId?: string;
  loading?: boolean;
  tabindex?: number;
}
class Button extends Component<ButtonProps> {

  render() {
    return (
      <pichincha-button
        size={this.props.size}
        id={this.props.id}
        idelement={`${this.props.id}ButtonElement`}
        color={this.props.color}
        disabled={this.props.disabled}
        data-testid={this.props.testId}
        onClick={this.props.handleClick}
        loading={this.props.loading}
        tabIndexInner={this.props.tabindex}
      >
        {this.props.text}
      </pichincha-button>
    );
  }
}

export default Button;
