export const agencies = [
  { id: 'cd4d6583-d469-44ea-8a1d-a9fc021bf5f1', value: 'DAULE' },
  { id: 'b5bf69b3-4a24-4c3e-a636-88d58ef65b76', value: 'SAMBORONDON PLAZA' },
  { id: '7d484ef3-8aaf-4e49-a8dd-f8e535a6bc6e', value: 'LA PIAZZA VILLA CLUB' },
  { id: '8dca566c-b415-4e2c-9756-3841bedfba5c', value: 'CANTON SAMBORONDON' },
  { id: '3916f398-545f-4182-8140-2564e34a297d', value: 'RIO CENTRO SHOPPING' },
  { id: '36db33d4-543a-4876-85b0-130598dc1aaa', value: 'SHOPPING DAULE' },
  { id: 'fec4d851-506f-4120-8766-fd5e7e693759', value: 'ORQUIDEAS' },
  { id: '78dadc1a-16c6-4473-9f25-656dc1bbb173', value: 'VINCES' },
  { id: '21e8dc26-634d-47ce-ac1d-d243745f2475', value: 'VENTANAS' },
  { id: 'ab9c66a0-30f2-431c-8d43-fab32227b5d4', value: 'BABAHOYO' },
  { id: 'c07c8e6f-4d63-4794-a67d-15f8f2024a2b', value: 'CALUMA' },
  { id: 'ed806870-e1a1-4216-8318-fdf84c71f686', value: 'BALZAR' },
  { id: '386c1178-82a2-4595-ba4a-1099be085d87', value: 'SAN JUAN' },
  { id: '21805cee-38f7-4c2b-bdf6-a62d4103cd76', value: 'CENTENARIO' },
  { id: '428e112b-c603-4b43-9af8-670b08329fba', value: 'MERCADO CENTRAL' },
  { id: '712e4583-0dd7-472d-a244-5a3ba5298f52', value: 'CENTENARIO SUR' },
  { id: '2fb68f32-5d9c-400d-ac80-c5648ecca91e', value: 'AYACUCHO' },
  { id: 'cc636f1e-457b-4034-af9b-ed38784403b4', value: '9 DE OCTUBRE' },
  { id: '5c6a2711-91a2-40ed-8bcb-c8d0791767aa', value: 'EL DORADO' },
  { id: '272b0859-ae73-4dad-b033-54d7f2328791', value: 'CHILE SUR' },
  { id: 'b3b6aaff-8caf-4d28-ae82-f8a1e4e8bb13', value: 'CENTRO GUAYAQUIL' },
  { id: '7fe82edd-5cdf-4423-bfe9-e4a57faec77a', value: 'RIO CENTRO SUR' },
  { id: 'a75821c8-5958-4413-ae05-ff7ad1720fd1', value: 'TORRES PICHINCHA' },
  { id: '13870ca5-d85e-4e19-ada8-4ee80c2ec54e', value: 'LA ALBORADA' },
  { id: '1f7ced8e-0f12-4d4a-87de-5a706147f633', value: 'URDESA' },
  { id: '39d6ff9a-e8e5-4e7a-bafe-8ee467c07679', value: 'MALL DEL SOL' },
  { id: '6080e304-3ea3-48a8-9f86-69d694398a35', value: 'POLICENTRO' },
  { id: 'ab6125ae-e593-48ed-9b50-14378747e223', value: 'AEROPUERTO GUAYAQUIL' },
  { id: 'a0de888b-f18c-4706-8421-8cc91efdd6aa', value: 'CATOLICA' },
  { id: '5da590f1-98c2-4799-a696-96bd78ba5188', value: 'HYPERMARKET' },
  { id: 'ffcb093f-f08d-4107-84d0-63277b4a1923', value: 'ATARAZANA' },
  { id: 'a0e855eb-6dd1-4213-9705-a795a1b15b4c', value: 'ALBAN BORJA' },
  { id: 'f7b8ea55-8686-4d11-9b38-163634e2bb8e', value: 'KM. 6,5 VIA A DAULE' },
  { id: '9c0ede6e-5ffa-4ccc-8dcc-f34a824d4503', value: 'LIBERTAD' },
  { id: '0ae758a8-12d4-4350-8b0f-79b0fa7a7cba', value: 'LOS CEIBOS' },
  { id: '1d18cf13-59ae-4cb6-a5ae-34e7054f8bc2', value: 'PARQUE CALIFORNIA' },
  { id: 'c456eb0e-16a9-4336-83ef-c913a22cfb48', value: 'PLAYAS' },
  { id: '06e2ec03-607d-4dfc-b82b-9c60af50777b', value: 'EL FORTIN' },
  { id: 'b9c8a22e-9312-485d-9667-d49a6c2d13e0', value: 'VIA A LA COSTA' },
  { id: '54475a6b-7eb2-4756-b03c-b3708e69e43d', value: 'PASEO LA PENINSULA' },
  { id: '63b08bab-4246-4600-b1f7-276ce3f072f3', value: 'MILAGRO' },
  { id: '7140167a-3e7e-4526-ac00-eaa7db0ec8ba', value: 'BUCAY' },
  { id: '7e24f78c-cdd4-4221-8f4c-5439b63861ba', value: 'EL TRIUNFO' },
  { id: 'd1529a63-2c72-47c9-9983-dc12d2148bd4', value: 'LA TRONCAL' },
  { id: 'f5a05c60-6430-4744-8ffb-20192e1c89c0', value: 'DURAN' },
  { id: '6b0e0342-8c1d-4cd5-9dc2-8911fd603c87', value: 'DURAN OUTLET' },
  { id: '087375bf-e203-4c9f-8f13-094e67515d40', value: 'GALAPAGOS' },
  {
    id: 'f09fd0d0-37e5-454e-a9ac-54c119469d4d',
    value: 'PASEO SHOPPING MILAGRO',
  },
  { id: '0537819d-f06d-4b75-a3ee-3a031c395c00', value: 'MACHALA' },
  { id: 'e6ce532f-5486-4738-bc81-841299b57bd7', value: 'SANTA ROSA' },
  { id: '2f568116-f16e-4d97-9d41-4de47510d927', value: 'ZARUMA' },
  { id: 'ffda7c9b-e693-4448-9307-af5d6ebc72d7', value: 'PASAJE' },
  { id: '21c12961-c3e1-4c0a-8e88-11596ae48e48', value: 'HUAQUILLAS' },
  { id: 'fb6bd891-63e9-4625-af8b-01f7717d2a1d', value: 'PINAS' },
  { id: '22524d33-e0ec-4c3f-98ea-a9e7befaa3b0', value: 'NARANJAL' },
  { id: 'c68ff7d5-53eb-4c4e-87ef-88b13a522f4b', value: 'EL GUABO' },
  { id: '1e278d5e-023b-47ac-a6e9-ae1769d5913e', value: 'BAHIA' },
  {
    id: 'bf8fd858-6af8-4bb4-9b8b-d97de3e678ed',
    value: 'PASEO SHOPPING MACHALA',
  },
  { id: '7f31bfd1-6ad0-4110-bd39-71efe6b61b3e', value: 'MANTA' },
  { id: 'f23a5286-7c50-4741-ac70-c467c6188f60', value: 'PORTOVIEJO' },
  { id: 'bb65fe1b-e786-4fc8-9e92-1a1671a2ad2a', value: 'JIPIJAPA' },
  { id: 'cd5338b2-6e65-4977-b5ae-395dd33e4b40', value: 'TARQUI' },
  { id: '32f9d4c7-65bb-424b-a7bd-0f50333b5076', value: 'PEDERNALES' },
  { id: '47574f1f-24e4-4772-912d-3dc5a73cdc83', value: 'CHONE' },
  { id: 'fe0d07de-c78d-4aa4-bd18-ddd1d5a22fcb', value: 'BAHIA DE CARAQUEZ' },
  { id: '3ac724d2-03e9-488e-beb0-702da94c166e', value: 'REALES TAMARINDOS' },
  { id: '6d4eadf7-633e-46f1-a40b-610d9ea529be', value: 'CALLE 13' },
  { id: '3f7a44d6-fbea-4491-9d13-eeb02ab1795d', value: 'TOSAGUA' },
  { id: 'cecca649-5e38-4b32-9d09-84dbf16a6120', value: 'CALCETA' },
  { id: 'be2bf96c-6c10-4285-b309-3526a52379a2', value: 'MALL DEL PACIFICO' },
  { id: 'f768fa32-0bf5-476d-ace1-7cdfcb9c70bb', value: 'PUERTO LOPEZ' },
  { id: '66098a49-8bf6-4e83-9c81-460ab9d43947', value: 'QUEVEDO' },
  { id: '5547620e-963b-4b55-8b44-97543eb8639e', value: 'EL EMPALME' },
  { id: 'be44b463-1b16-4ac2-bc2b-407520652038', value: 'LA MANA' },
  { id: 'a82306da-b77b-4ff3-8b49-33c03cebdf14', value: 'BUENA FE' },
  { id: '503beb42-63e4-4c2d-af20-6c4c08367170', value: 'QUEVEDO SUR' },
  { id: 'ba83a6f3-c5a9-40fc-8d0a-4b10448b1b75', value: 'SAN CAMILO' },
  { id: 'a351a3bb-79b1-4eec-9a96-1d67ee39e0b1', value: 'SAN CARLOS' },
  {
    id: '08dd54f1-bdce-4638-918d-47cead218712',
    value: 'CANAL SERVICIOS RED OFICINAS COSTA',
  },
  { id: '6470c162-326d-4d7f-a0bb-38a3e3f0ad87', value: 'LATACUNGA' },
  { id: 'e2ddc3fb-713c-4d47-aa37-a569f91a8e12', value: 'SALCEDO' },
  { id: '9351cadb-2994-43b8-b376-3677dd732dab', value: 'EL SALTO' },
  { id: '184d8193-d4c8-4a6c-bd87-c051aa5bef55', value: 'LASSO' },
  { id: 'dce02c00-eed2-4311-876d-2c7c5fb5a7f0', value: 'LAGO AGRIO' },
  { id: '52652bf0-2f18-40e9-8aa2-13603f3b1653', value: 'JOYA DE LOS SACHAS' },
  { id: '1b2c0b06-f125-43bc-be23-a3019f4fb8f3', value: 'COCA' },
  { id: 'a8ff7250-b089-4836-8d2c-fd79c182ba33', value: 'SHUSHUFINDI' },
  { id: '8c521166-c91e-493f-8bb1-9bfa8caa10b6', value: 'AMBATO' },
  { id: '83a38d1e-9a8f-439c-a818-8371f5252f74', value: 'BAÑOS' },
  { id: 'f7f1d8b0-6224-4c1b-a933-d2b6aff99888', value: 'CUMANDA' },
  { id: '0579d884-129d-461e-b01d-b788b61071ef', value: 'PILLARO' },
  { id: 'c6146ab7-161c-4811-b6b3-3f8487828282', value: 'TENA' },
  { id: '228e7be8-8005-46fb-80ad-a0bca47db9b8', value: 'SUR AMBATO' },
  { id: '185bd7b8-0f00-418c-9e99-9b20ff06166b', value: 'PELILEO' },
  { id: 'f3bd888d-e56c-4289-bbe1-e11af52dff3a', value: 'FICOA' },
  { id: '7f366c60-f752-4cdb-8474-911b310674b7', value: 'PUYO CIRCUNVALACION' },
  { id: '22c11af7-225e-43dc-87bf-0335c59e6a33', value: 'GUARANDA' },
  { id: 'e2fac40e-b125-4c2a-9057-97db00d8a560', value: 'RIOBAMBA' },
  { id: 'fb73780e-53aa-4569-88ad-5dec68390e73', value: 'MACAS' },
  { id: 'f9bf6d39-2811-4870-ad9a-237c3c2cfac2', value: 'LA ESTACION' },
  { id: '5800eddc-10dc-425f-90d9-0bc259db0201', value: 'ALAUSI' },
  {
    id: 'b8c02124-82e6-49ce-8b69-55c327fa7491',
    value: 'SAN MIGUEL DE BOLIVAR',
  },
  {
    id: '8de2e91f-105a-4337-8a66-a1364c3f4b7c',
    value: 'MERCADO MAYORISTA RIOBAMBA',
  },
  { id: 'b31e092e-2570-4390-83a5-a1f56abadc14', value: 'ESMERALDAS' },
  { id: 'f144c4e2-053d-4f1c-8602-0a76c2b31499', value: 'SANTO DOMINGO' },
  { id: '307fb9a2-6080-43bf-9498-8694b4255ce3', value: 'LA CONCORDIA' },
  { id: 'ecd8dc1f-cf68-4914-8d33-01396c399679', value: 'SANTA MARTHA' },
  { id: '4b4742ef-cd3d-4288-af84-d22d514a7927', value: 'EL CARMEN' },
  { id: 'a1152da3-3f5a-452a-92db-4864e308e2de', value: 'ATACAMES' },
  { id: '32ea0488-666f-4633-860d-36f4cdf6bf83', value: 'QUININDE' },
  { id: '39d7493e-4a4f-4a58-b23f-e8ba17bbe735', value: 'ZARACAY' },
  {
    id: '6cfd3657-6aa0-4892-bbf0-1458360e0a67',
    value: 'PEDRO VICENTE MALDONADO',
  },
  { id: '646893e9-1efc-4bde-b88e-9522fe3352a9', value: 'SAN LORENZO' },
  { id: '4c64ffbb-973f-4e92-8f59-862461b96c80', value: 'LA TOLITA' },
  { id: 'c0f855d1-be84-45a7-834d-03683c7779fc', value: 'TSACHILAS' },
  { id: 'fdd5c914-c431-4303-ad79-a0909d173ef4', value: 'AMAZONAS' },
  { id: '87109e90-223d-492a-a0eb-27022df164d3', value: 'EL GIRON' },
  { id: '934df7eb-e8f3-4c59-bcaa-90b61b6143b2', value: '6 DE DICIEMBRE' },
  { id: 'f23bd02e-5d7c-4241-8ce2-5cb3f557cc7f', value: 'METROPOLI' },
  { id: '36c79bfe-1809-4c80-af7d-10921db8af25', value: 'GONZALEZ SUAREZ' },
  {
    id: 'b42c15b2-368a-42ff-b11b-b9db60bf9a9c',
    value: 'CENTRO FINANCIERO ORELLANA',
  },
  { id: 'a6f24529-1d4a-4d5b-a06c-30aaad066d4c', value: 'EL JARDIN' },
  { id: '16c14160-b7f2-48db-9e67-078b67db762d', value: 'PLAZA GRANDE' },
  { id: 'd88df984-8c97-4c09-a5be-28cd04a770ff', value: 'PLAZA DEL TEATRO' },
  { id: 'c6e095c9-2b07-458f-9420-0143dce4dd51', value: 'NORTE QUITO' },
  { id: '2739d3bb-7486-4522-948d-a1616856eec9', value: 'CUERO Y CAICEDO' },
  { id: '25e88a09-f507-45a9-83bc-a130817d94f8', value: 'AMERICA' },
  { id: 'c43f3191-f9a2-40fb-8caf-f6f70441ba26', value: 'ALAMEDA' },
  { id: '12748191-3462-4d4e-9e72-a38817927642', value: 'INAQUITO' },
  { id: '2de2e03f-b22e-4642-83e4-b124baaca351', value: 'PORTUGAL' },
  { id: '50d48222-4b17-4a08-821f-0713b2f71059', value: 'CENTRUM EL BOSQUE' },
  { id: '4ef80c69-6dfe-47b6-a1aa-d06efe18587b', value: 'QUICENTRO' },
  { id: '6023d184-9dd2-4427-bd5d-4735207c4520', value: 'ELOY ALFARO' },
  {
    id: '2f9b48e8-2299-405f-91c9-588b676f7e99',
    value: 'CENTRO COMERCIAL INAQUITO',
  },
  { id: 'cc249fbb-d24e-4e8d-8155-017bd3705129', value: 'BRASIL' },
  { id: '7044c617-e654-4c8d-866e-f93de701a3bf', value: 'LA PRENSA' },
  { id: '172da390-079b-4671-afcd-e016fe06d8db', value: 'PANAMERICANA NORTE' },
  { id: '9d274fed-c29d-4576-b3c6-a3389ca147ec', value: 'EL INCA' },
  { id: '7be01b4b-e145-4b72-ab97-361ac4d18663', value: 'KENEDY' },
  { id: 'dceaa4db-6a04-47fa-82b3-bbb19beb16b7', value: 'CALDERON' },
  { id: 'b5105f8c-a861-4328-9a89-cb460313bb5c', value: 'EL CONDADO' },
  { id: 'fbf2c0b9-a177-4d0b-bcd0-51a10134959b', value: 'PLAZA EQUINOCCIAL' },
  { id: '78cd41d3-a600-460e-8b0a-ca4c6726d577', value: 'VILLA FLORA' },
  { id: '1f48e321-0af1-44de-8cb9-4d462a7af0b6', value: 'PANAMERICANA SUR' },
  { id: '329927c7-5d6c-474a-b988-a35846584f15', value: 'ATAHUALPA' },
  { id: 'c15c9288-92b6-4dc0-99f3-ebf961452d66', value: 'EL RECREO' },
  { id: '3ecac37f-1e1e-46a2-9716-dfa558f07da9', value: 'PUNTO PAGO SUR' },
  { id: '0eee7f2e-a971-4a94-b0fe-f37e45a371be', value: 'QUICENTRO SUR' },
  { id: '324b98ad-73ea-4507-92c1-43f5014c0567', value: 'EL VALLE' },
  { id: '34581356-7bc6-4465-9201-86e4e325c679', value: 'CUMBAYA' },
  { id: '820b9cac-09a9-4ac6-b627-0452409294df', value: 'MACHACHI' },
  { id: '782b94c8-16cb-45e3-b96e-70bca8feedeb', value: 'SAN RAFAEL' },
  { id: '985ca059-5711-4e4c-8e5d-a272e8850b33', value: 'EL QUINCHE' },
  { id: 'fe93f783-906d-4d59-8e3d-fcdfb9a0eb90', value: 'SAN LUIS SHOPPING' },
  { id: 'fee5060c-92b1-4ba0-8958-7290071776e3', value: 'LA SCALA' },
  { id: '0c6ebb0f-d50a-48f1-b114-b0cf480d909e', value: 'TUMBACO' },
  { id: '287753ac-8341-4d5b-86c2-daccca1041e6', value: 'IBARRA' },
  { id: '2da89f30-99f9-4bf6-aaa5-c3682cc356d8', value: 'TULCAN' },
  { id: 'cb54322d-f644-4f18-bfaa-c460ce5ed74e', value: 'OTAVALO' },
  { id: 'c62ee145-004d-4eb3-87a5-7193931ea501', value: 'SAN GABRIEL' },
  { id: 'b8bb3c1a-7bac-4b1b-a852-5a7a9ec07f0e', value: 'ATAHUALPA-IBARRA' },
  { id: '39a22692-229b-4bb1-b9d6-85a436b4be07', value: 'CAYAMBE' },
  { id: 'a5a08605-c293-4ff8-b97a-98cc268d5f2d', value: 'ATUNTAQUI' },
  { id: 'd0bb3a90-ce9c-4519-9350-90b6d9eac241', value: 'LA PLAZA' },
  {
    id: '9d834e5c-2233-4266-bc2a-c09e53bd617a',
    value: 'CANAL SERVICIOS RED OFICINAS NORTE',
  },
  { id: '53f59326-ab2e-40d8-bdc6-5d187b98ccd1', value: 'CUENCA' },
  { id: '17b7dc5e-e323-49d7-ae75-e748efb85cee', value: 'HUAYNACAPAC' },
  { id: 'c8565992-0fc0-4d9b-b006-f92047c2e1ac', value: 'EL VERGEL' },
  { id: '044b4738-c6e1-48ad-9c87-aab90c9e34a7', value: 'TOTORACOCHA' },
  { id: 'eb844344-06c1-44f0-b2d3-820b0cdae51a', value: 'ORDONEZ LASSO' },
  { id: '14201cff-fde0-43a7-b924-8f75f4428faf', value: 'AZOGUES' },
  { id: '8c9f7b52-34a7-4451-baca-de8dbf970bc6', value: 'GUALACEO' },
  { id: 'e64d54fa-b3c7-418f-969f-140c1ece36d2', value: 'EL ARENAL' },
  { id: '225d2b53-aaeb-41c0-abc5-0ce242579904', value: 'CUXIBAMBA' },
  { id: '8e29f14f-5d36-4187-8ab8-65011229e81c', value: 'LOJA' },
  { id: '8f81e3af-ef4f-4fcb-91fa-5ad9053916d8', value: 'MALL DEL RIO' },
  { id: '8155ea9f-6737-47c1-a935-3390e97d4e10', value: 'CAÑAR' },
  { id: '14ebc903-b438-430c-ac55-ff84e08bfa75', value: 'ZAMORA' },
  { id: '100cf09a-cb61-4fe9-a50f-a42de5a539ff', value: 'SANTA ISABEL' },
  { id: '93921827-ae48-43e6-b788-9aaaa5d49db0', value: 'RICAURTE CUENCA' },
  { id: '98d45cf0-c993-4325-8139-a3326c432ef0', value: 'LOJA' },
  { id: 'f049e771-8b88-4a80-b75f-898f51a14725', value: 'VIRTUAL QUITO' },
  { id: '402436c6-0ba9-45b7-983e-5e46f2d7819a', value: 'VIRTUAL GUAYAQUIL' },
  { id: '3370e95c-88d8-4162-9020-c766348f78bb', value: 'AMAZONAS 2' },
  { id: '06458bd4-ec01-4d80-ae35-63d4869e5d28', value: 'BANCA PRIVADA' },
  { id: '2db02f9f-8d89-4b7a-9e3b-11030b8d8618', value: 'BANCA PRIVADA CUENCA' },
];
