import { FC } from "react";
import ErrorIcon from "../../../../assets/icons/errorIcon.svg";
import "./auth-data-protection-error.scss";

interface AuthDataProtectionErrorProps {
  buttonClick: () => void;
}

const AuthDataProtectionError: FC<AuthDataProtectionErrorProps> = ({
  buttonClick,
}) => {
  return (
    <div className="ldpd-error">
      <img
        src={ErrorIcon}
        alt="Error"
        height={50}
        width={50}
        className="bp-mb-40"
      />
      <pichincha-typography
        variant="h2"
        weight-color="600"
        color="blue"
        weight="bold"
        align="center"
        class="bp-mb-8"
      >
        No pudimos procesar tu solicitud
      </pichincha-typography>

      <pichincha-typography
        color="darkGrey"
        align="center"
        class="ldpd-error__last-text bp-pb-24"
      >
        Estamos trabajando para resolver el inconveniente lo antes posible.
        Intenta de nuevo más tarde.
      </pichincha-typography>
      <div className="ldpd-error__button bp-mt-24">
        <pichincha-button onClick={() => buttonClick()}>
          Entendido
        </pichincha-button>
      </div>
    </div>
  );
};

export default AuthDataProtectionError;
