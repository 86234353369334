import React, { Component } from "react";
import { connect, ConnectedProps } from "react-redux";
import { ReduxState } from "../../../common/store";
import Button from "../../Button";
import Thumbnail from "../../Thumbnail";

import AgencyExpressRequestSuccessIcon from "../../../assets/img/AgencyApplicationSuccess.svg";
import actions from "../../../actions";

class AgencyExpressRequestSuccess extends Component<ReduxProps> {
  render() {
    let content;
    const continueButtonProps = {
      color: "primary",
      size: "large",
      text: "Finalizar",
      id: `agencyExpressRequestSuccessButtonFinish`,
      handleClick: () => (window.location.href = "/"),
      disabled: false,
      testId: "agencyExpressRequestSuccessId",
    };
    if (this.props.nut) {
      content = (
        <pichincha-typography
          variant="bodyText"
          color="darkGrey"
          align="center"
          weight="bold"
          id="successAgencyExpressRequestSuccess"
        >
          Tu número de solicitud es: {this.props.nut}
        </pichincha-typography>
      );
    }
    return (
      <div className="error-page something-went-wrong">
        <div className="icon-container">
          <Thumbnail url={AgencyExpressRequestSuccessIcon} name={"imagen"} />
        </div>
        <div className="title-container padding-wrapper mt-0">
          <h4 className="title text-prelo-slab-semibold agency-success-page-title">
            ¡Solicitud enviada con éxito!
          </h4>
        </div>
        <div className="text-container padding-wrapper something-went-wrong bp-mt-4">
          <pichincha-typography
            variant="bodyText"
            color="darkGrey"
            align="center"
          >
            Dentro de las siguientes 48 horas un asesor especializado en
            créditos hipotecarios se contactará contigo vía telefónica y/o mail
            para guiarte en los siguientes pasos.
          </pichincha-typography>

          <div className="bp-mt-32">{content}</div>
        </div>
        <div className="button-container padding-wrapper bp-mt-8 bp-mb-48">
          <Button {...continueButtonProps} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: ReduxState) => {
  const { expressRequest } = state;

  const { nut } = expressRequest;
  return {
    nut,
  };
};

const { setComment, sendFeedbackRegistration, setStep } = actions;

const connector = connect(mapStateToProps, {
  setComment,
  setStep,
  sendFeedbackRegistration,
});

type ReduxProps = ConnectedProps<typeof connector>;

export default connector(AgencyExpressRequestSuccess);
