export const Provinces = [
  'Azuay',
  'Bolivar',
  'Cañar',
  'Pichincha',
  'Carchi',
  'Cotopaxi',
  'Chimborazo',
  'El Oro',
  'Esmeraldas',
  'Guayas',
  'Imbabura',
  'Loja',
  'Los Rios',
  'Manabi',
  'Morona Santiago',
  'Napo',
  'Pastaza',
  'Tungurahua',
  'Zamora Chinchipe',
  'Galapagos',
  'Sucumbios',
  'Orellana',
  'Santo Domingo de los Tsachilas',
  'Santa Elena',
];

export const Cities = [
  'Cuenca',
  'Asuncion',
  'Bulan',
  'Chican',
  'Chumblin',
  'Gualaceo',
  'Cochapata',
  'Cumbe',
  'Cutchil',
  'Daniel Cordova',
  'Giron',
  'Dug Dug',
  'Jima',
  'La Union',
  'Lentag',
  'Paute',
  'Nulti',
  'Paccha',
  'Palmas',
  'Principal',
  'Sigsig',
  'Quigeo',
  'San Bartolome',
  'San Gerardo',
  'San Juan',
  'Santa Isabel',
  'San Pedro De Shaglle',
  'Santa Ana',
  'Santa Teresita',
  'Guel',
  'Ludo',
  'Remigio Crespo',
  'San Cristobal',
  'San Rafael De Sharung',
  'Zapata',
  'Shidmad',
  'Tomebamba',
  'San Vicente',
  'San Jose De Raranga',
  'Progreso',
  'Mariano Moreno',
  'Jadan',
  'Guarainag',
  'Delegsol',
  'Llacao',
  'Pucara',
  'Onia',
  'San Fernando',
  'Nabon',
  'Chordeleg',
  'Sevilla De Oro',
  'El Pan',
  'Guachapala',
  'Guaranda',
  'Chimbo',
  'San Miguel',
  'La Asuncion',
  'Chillanes',
  'La Magdalena',
  'Echeandia',
  'Caluma',
  'Las Naves',
  'Azogues',
  'Caniar',
  'Suscal',
  'Cochancay',
  'San Miguel',
  'Quito',
  'Biblian',
  'La Troncal',
  'Gualleturo',
  'Honorato Vasquez',
  'Turupamba',
  'El Tambo',
  'Deleg',
  'Tulcan',
  'Julio Andrade',
  'Montufar',
  'Maldonado',
  'Espejo',
  'Urbina',
  'San Gabriel',
  'Mira',
  'La Paz',
  'Bolivar',
  'El Angel',
  'San Pedro De Huaca',
  'Latacunga',
  'Poalo',
  'Guaytacama',
  'Lasso',
  'Pujili',
  'San Juan De Pastocalle',
  'Salcedo',
  'Tanicuchi',
  'Toacazo',
  'Saquisili',
  'Juan Montalvo',
  'Pangua',
  'Pilalo',
  'Chantilin',
  'El Corazon',
  'La Mana',
  'Sigchos',
  'Chambo',
  'Riobamba',
  'Guano',
  'San Juan',
  'Colta',
  'Guamote',
  'Alausi',
  'San Andres',
  'Chunchi',
  'Penipe',
  'Cajabamba',
  'Pallatanga',
  'Cumanda',
  'Machala',
  'Paccha',
  'El Cambio',
  'Puerto Bolivar',
  'Santa Rosa',
  'Bellavista',
  'Zaruma',
  'La Avanzada',
  'Pasaje',
  'Pinias',
  'Arenillas',
  'Buenavista',
  'El Guabo',
  'Chilla',
  'Portovelo',
  'Huaquillas',
  'Atahualpa',
  'Las Lajas',
  'Marcabeli',
  'Balsas',
  'Esmeraldas',
  'Rio Verde',
  'Limones',
  'Eloy Alfaro',
  'Muisne',
  'Quininde',
  'San Lorenzo',
  'La Union',
  'Tonsupa',
  'Sua',
  'Atacames',
  'Balao',
  'Punta Carnero',
  'Cabuyal',
  'El Salitre',
  'Ayangue',
  'Posorja',
  'Palmar',
  'Tenguel',
  'Tres Postes',
  'Juan Bautista Aguirre',
  'Punta Blanca',
  'Chongon',
  'Gral Pedro Montero',
  'Colimes',
  'El Laurel',
  'Ancon',
  'Chanduy',
  'Manglaralto',
  'Ballenita',
  'Roberto Astudillo',
  'Banife',
  'Junquillal',
  'La Victoria',
  'Taura',
  'Crnel Lorenzo De Garaycoa',
  'La Troncal',
  'El Empalme',
  'Yaguachi',
  'Daule',
  'Balzar',
  'Milagro',
  'Samborondon',
  'Urbina Jado',
  'Naranjal',
  'El Triunfo',
  'Bucay',
  'Naranjito',
  'Palestina',
  'Pedro Carbo',
  'Gral Villamil Playas',
  'Simon Bolivar',
  'Duran',
  'Alfredo Baquerizo Moreno (Jujan)',
  'Santa Lucia',
  'Crnel Marcelino Mariduenias',
  'Lomas De Sargentillo',
  'Nobol Piedrahita',
  'General A Elizalde',
  'Isidro Ayora',
  'La Libertad',
  'Salinas',
  'Ibarra',
  'Otavalo',
  'San Antonio',
  'Cotacachi',
  'Antonio Ante',
  'Ambuqui',
  'Chavezpamba',
  'Pimampiro',
  'San Pablo',
  'Urcuqui',
  'Atuntaqui',
  'Tumbabiro',
  'Perucho',
  'Loja',
  'Catamayo',
  'Alamor',
  'Casanga',
  'Cariamanga',
  'Catacocha',
  'Zapotillo',
  'Changaimina',
  'Colaisaca',
  'El Cisne',
  'El Lucero',
  'El Tambo',
  'Jimbura',
  'La Victoria',
  'Mangaurco',
  'Saraguro',
  'Mercadillo',
  'Nueva Fatima',
  'Paletilla',
  'Potrerillos',
  'Paltas',
  'Olmedo',
  'Purunuma',
  'Sabanilla',
  'Sacapalca',
  'Celica',
  'San Antonio De Las Aradas',
  'San Pedro De La Bendita',
  'Urdaneta',
  'Vilcabamba',
  'Calvas',
  'Yangana',
  'Chuquiribamba',
  'Cruzpamba',
  'El Ingenio',
  'Macara',
  'El Naranjo',
  'La Ceiba',
  'Lauro Guerrero',
  'Malacatos',
  'Puyango',
  'Manu',
  'Nambacola',
  'Orianga',
  'Pozul',
  'Gonzanama',
  'Sabianco',
  'San Lucas',
  'Susudel',
  'Tacamoros',
  'Espindola',
  'Selva Alegre',
  'Torata',
  'Veinte Y Siete De Abril',
  'San Antonio',
  'Sozoranga',
  'Pindal',
  'Lalamor',
  'Quilanga',
  'Chaguarpamba',
  'Babahoyo',
  'Valencia',
  'Banepo',
  'Guare',
  'La Union',
  'Baba',
  'Pimocha',
  'Ricaurte',
  'San Juan',
  'Antonio Sotomayor',
  'Vinces',
  'Bagatela',
  'Isla De Bejucal',
  'Mata De Cacao',
  'Patricia Pilar',
  'Puebloviejo',
  'Quinsaloma',
  'San Carlos',
  'Catarama',
  'Urdaneta',
  'Quevedo',
  'Ventanas',
  'Montalvo',
  'Palenque',
  'Buena Fe',
  'Mocache',
  'Calderon',
  'Canuto',
  'Campuzano',
  'Charapoto',
  'Cojimies',
  'Crucita',
  'Calceta',
  'Portoviejo',
  'Montecristi',
  'Jaramijo',
  'Manta',
  'Jipijapa',
  'Veinte Y Cuatro De Mayo',
  'Rocafuerte',
  'Santa Ana',
  'Sucre',
  'Jama',
  'San Vicente',
  'Chone',
  'Guayaquil',
  'Junin',
  'Pajan',
  'El Carmen',
  'Tosagua',
  'Pichincha',
  'Pedernales',
  'Olmedo',
  'Puerto Lopez',
  'Bahia De Caraquez',
  'Flavio Alfaro',
  'Morona',
  'Macas',
  'Chinimbini',
  'Gral Proanio',
  'Huambi',
  'Gualaquiza',
  'Patuca',
  'Rio Blanco',
  'Sevilla Don Bosco',

  'Chiguinda',
  'Limon Indanza',
  'Gral Leonidas Plaza',

  'Plan Demilagro',
  'Rio San Jose',
  'Santa Ana',
  'Santiago De Mendez',
  'Sucua',
  'Logronio',
  'Palora',
  'Huamboya',
  'San Juan Bosco',
  'Taisha',
  'El Chaco',
  'Baeza',
  'Tena',
  'Carlos Julio Arosemena Tola',
  'Quijos',
  'Archidona',
  'Pastaza',
  'Puyo',
  'Shell',
  'Mera',
  'Santa Clara',
  'Arajuno',
  'La Independencia',
  'Valle Hermoso',
  'Alluriquin',
  'Luz De America',
  'Cayambe',
  'El Quinche',
  'Guayllabamba',
  'Pifo',
  'Mejia',
  'Machachi',
  'Pedro Moncayo',
  'Tabacundo',
  'Tumbaco',
  'Ruminiahui',
  'Sangolqui',
  'San Rafael',
  'Conocoto',
  'La Concordia',
  'Santa Martha',
  'Ventanas',
  'Cumbaya',

  'San Miguel De Los Bancos',
  'Pedro Vicente Maldonado',

  'Puerto Quito',
  'No Definido',
  'Sto Dgo De Los Colorados',
  'Tisaleo',
  'Izamba',
  'Quisapincha',
  'Ambato',
  'Pillaro Viejo',
  'Pillaro',
  'Pelileo',
  'Banios',
  'Patate',
  'Quero',
  'Cevallos',
  'Mocha',
  'Nangaritza',
  'San Carlos De Las Minas',

  'Zumba',
  'Zamora',
  'Chinchipe',
  'Palanda',
  'Yacuambi',
  'Yanzatza',
  'El Pangui',
  'Centinela Del Condor',
  'San Cristobal',
  'Santa Cruz',
  'Isabela',
  'Lago Agrio',
  'Cuyabeno',
  'Nueva Loja',
  'Sucumbios',
  'Putumayo',
  'Shushufindi',
  'Cascales',
  'Gonzalo Pizarro',
  'Aguarico',
  'Nuevo Rocafuerte',
  'Francisco De Orellana',
  'Joya De Los Sagchas',
  'Loreto',
  'Santo Domingo De Los Colorados',
  'Salinas',
  'Santa Elen',
  'La Libertad',
];