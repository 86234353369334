import { AnyAction } from 'redux';
import actionTypes from '../../actions/actionTypes';
import {
  ProductItem,
  initialProduct,
  SummaryProps,
  PaymenDetailProps,
  summaryMock,
  paymentDetailItemMock,
} from '../../common/helpers/integration';
import {
  formatCurrency,
  yearsToMonths,
  getProductIndex,
  updateHousePriceStatus,
  initProps,
  removeCommas,
  updateRequestedAmountStatus,
  checkEmptyField,
  updateLoanTermYears,
  sumInsurances,
  sumInterests,
} from '../../common/helpers/functions';

export interface ModalState {
  show: boolean;
  section: string;
}

export interface CalculatorState {
  loading_product: boolean;
  loading_product_error: boolean;
  product: Array<ProductItem>;
  selected_product_type: string;
  house_price: string;
  requested_amount_display: string;
  loan_term_years: string;
  amount: number;
  requested_amount: number;
  months: number;
  house_price_state: string;
  house_price_normal_helper: string;
  house_price_error_helper: string;
  house_price_placeholder: string;
  requested_amount_state: string;
  requested_amount_error_helper: string;
  requested_amount_normal_helper: string;
  loan_term_years_state: string;
  loan_term_years_error_helper: string;
  loan_term_years_normal_helper: string;
  requested_amount_max: number;
  house_price_error: boolean;
  requested_amount_error: boolean;
  loan_term_years_error: boolean;
  amortization_type: string;
  modal: ModalState;
  calculate_loading: boolean;
  calculate_error: boolean;
  appraisal: boolean;
  legal_expenses: boolean;
  insurance: boolean;
  solca: boolean;
  money_capital: number;
  interest: number;
  calculation_summary: SummaryProps;
  calculation_payment_details: Array<PaymenDetailProps>;
  calculation_total_interests: number;
  calculation_total_insurance: number;
  calculate_success: boolean;
  vip_vis: Array<ProductItem>;
  new_used: Array<ProductItem>;
  vip_vis_loaded: boolean;
  new_used_loaded: boolean;
  calculator_page: boolean;
  showUnauthorizedPage: boolean;
  showSomethingWentWrongPage: boolean;
  internetConnection: boolean;
}

export const INITIAL_STATE = {
  loading_product: false,
  loading_product_error: false,
  product: [initialProduct],
  selected_product_type: '',
  house_price: '',
  requested_amount_display: '',
  loan_term_years: '',
  amount: 0,
  requested_amount: 0,
  months: 0,
  house_price_state: 'normal',
  house_price_normal_helper: 'Min. $15.000',
  house_price_error_helper: 'El valor debe ser mínimo $15.000',
  house_price_placeholder: 'Ej.: $100.000',
  requested_amount_state: 'normal',
  requested_amount_normal_helper: 'Min. $3.000',
  requested_amount_error_helper: 'El valor debe ser mínimo $3.000',
  loan_term_years_state: 'normal',
  loan_term_years_error_helper: 'Min. 3 años',
  loan_term_years_normal_helper: 'Entre 3 y 20 años',
  requested_amount_max: 0,
  house_price_error: true,
  requested_amount_error: true,
  loan_term_years_error: true,
  amortization_type: '',
  modal: {
    show: false,
    section: '',
  },
  calculate_loading: false,
  calculate_error: false,
  appraisal: true,
  legal_expenses: true,
  insurance: true,
  solca: false,
  money_capital: 600,
  interest: 36.2,
  calculation_summary: summaryMock,
  calculation_payment_details: [paymentDetailItemMock],
  calculation_total_interests: 0,
  calculation_total_insurance: 0,
  calculate_success: false,
  vip_vis: [initialProduct],
  vip_vis_loaded: false,
  new_used: [initialProduct],
  new_used_loaded: false,
  calculator_page: true,
  showUnauthorizedPage: false,
  showSomethingWentWrongPage: false,
  internetConnection: true,
};

const getProduct = (action: AnyAction, state: CalculatorState) => {
  if (action.productType === 'new-used') {
    return {
      ...state,
      new_used: action.product,
      new_used_loaded: true,
    };
  }
  if (action.productType === 'vip-vis') {
    return {
      ...state,
      vip_vis: action.product,
      vip_vis_loaded: true,
    };
  }
  return state;
};

const handleShowUnauthorizedPage = (requestStatusGet: number) => {
  return (
    requestStatusGet === 401 ||
    requestStatusGet === 404 ||
    requestStatusGet === 204
  );
};
const calculator = (
  state: CalculatorState = INITIAL_STATE,
  action: AnyAction = { type: null }
): CalculatorState => {
  const productIndex = getProductIndex(state);
  switch (action.type) {
    case actionTypes.GET_PRODUCT_REQUEST:
      return { ...state, loading_product: true };
    case actionTypes.GET_PRODUCT_SUCCESS:
      return getProduct(action, state);

    case actionTypes.SET_SELECTED_PRODUCT:
      let selectedProduct = state.new_used[0];
      let product = state.new_used;
      if (action.selectedProduct === 'vip-vis') {
        selectedProduct = state.vip_vis[0];
        product = state.vip_vis;
      }
      const initialProps = initProps(selectedProduct);
      return {
        ...INITIAL_STATE,
        product: product,
        selected_product_type: action.selectedProduct,
        house_price_normal_helper: initialProps.housePriceNormalHelper,
        requested_amount_normal_helper:
          initialProps.requestedAmountNormalHelper,
        months: initialProps.months,
        loan_term_years: initialProps.loan_term_years,
        loan_term_years_normal_helper:
          initialProps.loan_term_years_normal_helper,
        house_price_placeholder: initialProps.house_price_placeholder,
        loan_term_years_error: initialProps.loan_term_years_error,
        new_used: state.new_used,
        new_used_loaded: true,
        vip_vis: state.vip_vis,
        vip_vis_loaded: true,
        calculator_page: state.calculator_page,
      };
    case actionTypes.GET_PRODUCT_FAILED:
      const { requestStatusGet } = action;
      return {
        ...state,
        loading_product: false,
        loading_product_error: true,
        loan_term_years: '',
        showUnauthorizedPage: handleShowUnauthorizedPage(requestStatusGet),
        showSomethingWentWrongPage:
          requestStatusGet !== 201 && requestStatusGet !== 401,
      };
    case actionTypes.UPDATE_INPUT_HOUSE_PRICE:
      const housePriceInputState = checkEmptyField(action.newValue);
      return {
        ...state,
        house_price: formatCurrency(action.newValue),
        amount: Number(removeCommas(action.newValue)),
        house_price_error_helper: housePriceInputState.error
          ? housePriceInputState.errorHelper
          : state.house_price_error_helper,
        house_price_state: housePriceInputState.state,
        house_price_error: housePriceInputState.error,
      };
    case actionTypes.UPDATE_INPUT_REQUESTED_AMOUNT:
      const requestedInputState = checkEmptyField(action.newValue);
      return {
        ...state,
        requested_amount_display: formatCurrency(action.newValue),
        requested_amount: Number(removeCommas(action.newValue)),
        requested_amount_error_helper: requestedInputState.error
          ? requestedInputState.errorHelper
          : state.requested_amount_error_helper,
        requested_amount_state: requestedInputState.state,
        requested_amount_error: requestedInputState.error,
      };
    case actionTypes.UPDATE_INPUT_LOAN_TERM_YEARS:
      const termInputState = checkEmptyField(action.newValue);
      return {
        ...state,
        loan_term_years: action.newValue,
        months: yearsToMonths(action.newValue),
        loan_term_years_error_helper: termInputState.error
          ? termInputState.errorHelper
          : state.loan_term_years_error_helper,
        loan_term_years_state: termInputState.state,
        loan_term_years_error: termInputState.error,
      };
    case actionTypes.VALIDATE_INPUT_HOUSE_PRICE:
      const updatedStatusHousePrice = updateHousePriceStatus(
        state,
        productIndex
      );
      return {
        ...state,
        house_price_state: updatedStatusHousePrice.state,
        house_price_normal_helper: updatedStatusHousePrice.normalHelper,
        house_price_error_helper: updatedStatusHousePrice.errorHelper,
        house_price_error: updatedStatusHousePrice.error,
        requested_amount_max: updatedStatusHousePrice.requestedAmountMax,
        requested_amount_normal_helper: updatedStatusHousePrice.error
          ? state.requested_amount_normal_helper
          : updatedStatusHousePrice.requestedAmountNormalHelper,
      };
    case actionTypes.VALIDATE_INPUT_REQUESTED_AMOUNT:
      const updatedStatusReqAmount = updateRequestedAmountStatus(
        state,
        productIndex
      );
      return {
        ...state,
        requested_amount_state: updatedStatusReqAmount.state,
        requested_amount_error_helper: updatedStatusReqAmount.errorHelper,
        requested_amount_error: updatedStatusReqAmount.error,
      };
    case actionTypes.VALIDATE_INPUT_LOAN_TERM_YEARS:
      const updatedStatusLoanTerm = updateLoanTermYears(state, productIndex);
      return {
        ...state,
        loan_term_years_state: updatedStatusLoanTerm.state,
        loan_term_years_error_helper: updatedStatusLoanTerm.errorHelper,
        loan_term_years_error: updatedStatusLoanTerm.error,
      };
    default:
      break;
  }

  switch (action.type) {
    case actionTypes.SET_GERMAN_AMORTIZATION:
      return {
        ...state,
        amortization_type: 'german',
      };
    case actionTypes.SET_FRENCH_AMORTIZATION:
      return {
        ...state,
        amortization_type: 'french',
      };
    case actionTypes.TOGGLE_MODAL_STATE:
      return {
        ...state,
        modal: {
          show: action.show,
          section: action.section,
        },
      };
    case actionTypes.TOGGLE_INPUTS_STYLE:
      return {
        ...state,
        calculator_page: action.newState,
      };
    case actionTypes.CALCULATE_REQUEST:
      return { ...state, calculate_loading: true, calculate_success: false };
    case actionTypes.CALCULATE_SUCCESS:
      return {
        ...state,
        calculate_loading: false,
        calculate_success: true,
        calculation_summary: action.data.summary,
        calculation_payment_details: action.data.details[0].paymentDetails,
        calculation_total_insurance: sumInsurances(
          action.data.details[0].paymentDetails
        ),
        calculation_total_interests: sumInterests(
          action.data.details[0].paymentDetails
        ),
      };
    case actionTypes.CALCULATE_FAILED:
      const { requestStatus } = action;
      return {
        ...state,
        calculate_loading: false,
        calculate_error: true,
        showUnauthorizedPage:
          requestStatus === 401 ||
          requestStatus === 404 ||
          requestStatus === 204,
        showSomethingWentWrongPage:
          requestStatus !== 201 && requestStatus !== 401,
      };
    case actionTypes.FOCUS_RESULTS_SECTION:
      document.getElementById('resultsSection')?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'start',
      });
      return {
        ...state,
      };
    case actionTypes.RESET_CALCULATION:
      return {
        ...INITIAL_STATE,
        vip_vis: state.vip_vis,
        vip_vis_loaded: true,
        new_used: state.new_used,
        new_used_loaded: true,
      };
    case actionTypes.TOGGLE_INTERNET_CONNECTION:
      return {
        ...state,
        internetConnection: action.status,
      };
    default:
      return state;
  }
};

export default calculator;
