import styled from 'styled-components'

import { HTMLAttributes } from 'react'
import { COLORS } from '../../theme'

export interface IconWrapperProps extends HTMLAttributes<HTMLElement> {
  size?: string
  color?: string
}

export const IconWrapper = styled.i<IconWrapperProps>`
  font-size: ${({ size }) => (size ? size : '16px')};
  color: ${({ color = 'blue500' }) => COLORS[color]};
`
