import React, { Component } from "react";
import { capitalizeName } from "../../../common/helpers/functions";

interface TitlePageProps {
  title: string;
  name: string;
  startingSymbol?: string;
  endingSymbol?: string;
}

class TitlePage extends Component<TitlePageProps> {
  render() {
    const { title, name, startingSymbol, endingSymbol } = this.props;
    const complement = name ? `, ${capitalizeName(name)}${endingSymbol || ""}`:`${endingSymbol || ""}`
    return (
      <pichincha-grid item xs="12">
        <div className="titleCheck">
          {`${startingSymbol || ""}${title}${complement}`}
        </div>
      </pichincha-grid>
    );
  }
}

export default TitlePage;
