import { AnyAction } from 'redux';
import actionTypes from '../../actions/actionTypes';
import {
  checkEmptyInput,
  checkRatingValue,
} from '../../common/helpers/functions';

export interface FeedState {
  rating: number;
  comment: string;
  section: string;
  ratingStatusError: boolean;
  commentStatus:string;
  commentStatusError: boolean;
  feedbackRequest_loading:boolean;
  feedbackRequest_success:boolean;
  feedbackRequest_error:boolean;

}

export const INITIAL_STATE = {
  rating: 0,
  comment: "",
  section: 'solicitud express',
  ratingStatusError: true,
  commentStatus:'normal',
  commentStatusError: true,
  feedbackRequest_error: false,
  feedbackRequest_success: false,
  feedbackRequest_loading: false,
}
const feedbackPage = (
  state: FeedState = INITIAL_STATE,
  action: AnyAction = { type: null }
): FeedState => {
  switch (action.type) {
    case actionTypes.SET_RATING:
      const ratingState = checkRatingValue(action.newRating);
      return {
        ...state,
        rating: action.newRating,
        ratingStatusError: ratingState.error,
      };
    case actionTypes.SET_COMMENT:
      const commentState = checkEmptyInput(action.newComment);
      return {
        ...state,
        comment: commentState.actualValue,
        commentStatus: commentState.state,
        commentStatusError: commentState.error,
      };

    case actionTypes.RESET_FEEDBACK:
      return {
        ...INITIAL_STATE,
      };
    
      case actionTypes.FEEDBACK_REGISTRATION_REQUEST:
      return {
        ...state,
        feedbackRequest_loading: true,
        feedbackRequest_success: false,
      };
    case actionTypes.FEEDBACK_REGISTRATION_SUCCESS:
      window.scrollTo(0, 0);
      return {
        ...state,
        feedbackRequest_loading: false,
        feedbackRequest_success: true,
      };
    case actionTypes.FEEDBACK_REGISTRATION_FAILED:
      return {
        ...state,
        feedbackRequest_loading: false,
        feedbackRequest_error: true,
      };
    default:
      return state;
  }
};

export default feedbackPage;
