import React, { Component } from "react";

//@ts-ignore
import ScrollspyNav from "react-scrollspy-nav";

class SideMenu extends Component {
  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get("section") === "opciones_credito") {
      const loanOptionsLink = document.getElementById("loanOptionsLink");
      setTimeout(() => loanOptionsLink?.click(), 1500);
    }
  }

  render() {
    return (
      <div>
        <div className="sidebar-container">
          <ScrollspyNav
            scrollTargetIds={[
              "introduccion",
              "opciones_credito",
              "requisitos",
              "amortizaciones",
              "gastos_valores",
            ]}
            activeNavClass="active"
            offset={-80}
          >
            <ul>
              <li className="item-sidebar">
                <a id="guideIntroductionLink" href="#introduccion">
                  Introducción
                </a>
              </li>
              <li className="item-sidebar">
                <a id="loanOptionsLink" href="#opciones_credito" data-testid="loanOptionsLinkTestId">
                  Nuestras opciones de crédito
                </a>
              </li>
              <li className="item-sidebar">
                <a id="guideRequirementsLink" href="#requisitos">
                  Los requisitos
                </a>
              </li>
              <li className="item-sidebar">
                <a id="guideChooseAmortizationLink" href="#amortizaciones">
                  Elige cómo amortizar tu deuda
                </a>
              </li>
              <li className="item-sidebar">
                <a id="guideGesturesAndValuesLink" href="#gastos_valores">
                  Gastos y valores a considerar
                </a>
              </li>
            </ul>
          </ScrollspyNav>
        </div>
      </div>
    );
  }
}

export default SideMenu;
