const DactilarCodeValidator = (string: string) => {
  let firstIsALetter = false;
  let sixthIsALetter = false;
  let restAreValids = true;
  if (string.length === 10) {
    string.split('').forEach((char, pos) => {
      // eslint-disable-line array-callback-return
      if (pos === 0) {
        firstIsALetter = isAllowedCharacter(char);
      } else if (pos === 5) {
        sixthIsALetter = isAllowedCharacter(char);
      } else {
        if (char !== 'X' && isNaN(parseInt(char, 10))) {
          restAreValids = false;
        }
      }
      return null;
    });
    return firstIsALetter && sixthIsALetter && restAreValids;
  } else {
    return false;
  }
  function isAllowedCharacter(letter: string) {
    let allowedLetter = false;
    let allowedCharacters = [
      '0',
      'A',
      'B',
      'C',
      'D',
      'E',
      'F',
      'G',
      'I',
      'V',
      'X',
    ]; //carecteres permitidos
    if (allowedCharacters.indexOf(letter) !== -1) {
      allowedLetter = true;
    }
    return allowedLetter;
  }
};
export default DactilarCodeValidator;
