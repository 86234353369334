export interface ProductItem {
  interestRate: number;
  amountMax: number;
  amountMin: number;
  requestedAmountMin: number;
  percentMax: number;
  monthsMax: number;
  monthsMin: number;
  basicSalary: number;
}

export const initialProduct = {
  interestRate: 0,
  amountMax: 0,
  amountMin: 0,
  requestedAmountMin: 0,
  percentMax: 0,
  monthsMax: 0,
  monthsMin: 0,
  basicSalary: 0,
};

export interface InputItem {
  placeholder: string;
  state: string;
  normalHelper: string;
  errorHelper: string;
  tooltip?: string;
}

export interface ButtonCalculateProps {
  loading: boolean;
  disabled: boolean;
  handleClick: () => void;
}

export interface NewCustomer {
  fullName: string;
  identification?: string;
  phone: string;
  contactHoursStart: string;
  contactHoursEnd: string;
}

export const newCustomerPayload = {
  identification: '3655456',
  fullName: 'Joe Dane',
  phone: '12313513',
  contactHoursStart: '15:00:00',
  contactHoursEnd: '17:00:00',
};
export interface PayloadCalculationProps {
  months: number;
  amount: number;
  requestedAmount: number;
  type: string;
  houseType: string;
  appraisal: boolean;
  legalExpenses: boolean;
  insurance: boolean;
  solca: boolean;
}

export const calculationPayload = {
  months: 100,
  amount: 95000,
  requestedAmount: 75000,
  type: 'french',
  houseType: 'new-used',
  appraisal: true,
  legalExpenses: true,
  insurance: true,
  solca: false,
};

export const summaryMock = {
  interestRate: 0,
  mainFee: 0,
  totalFee: 0,
  legalFee: 0,
  solca: 0,
  fireInsuranceFee: 0,
  creditLifeInsuranceFee: 0,
  insurance: 0,
  appraisal: 0,
  months: 0,
  requestedAmount: 0,
  adjusted: false,
};

export interface SummaryProps {
  interestRate: number;
  mainFee: number;
  totalFee: number;
  legalFee: number;
  solca: number;
  fireInsuranceFee: number;
  creditLifeInsuranceFee: number;
  insurance: number;
  appraisal: number;
  months: number;
  adjusted: boolean;
  requestedAmount: number;
}

export const paymentDetailItemMock = {
  date: '',
  payment: 0,
  main: 0,
  interest: 0,
  balance: 0,
  insurance: 0,
  paymentNumber: 0,
};

export interface PaymenDetailProps {
  date: string;
  payment: number;
  main: number;
  interest: number;
  balance: number;
  insurance: number;
  paymentNumber: number;
}

export const paymentDetailsMock = {
  paymentDetails: [paymentDetailItemMock],
};

export interface PaymentDetailsProps {
  paymentDetails: Array<PaymenDetailProps>;
}

export const detailsMock = [
  {
    type: 'German',
    paymentDetails: paymentDetailsMock,
  },
];

export interface DetailsProps {
  type: string;
  paymentDetails: PaymentDetailsProps;
}

export const calculationResultMock = {
  summary: summaryMock,
  details: detailsMock,
};

export interface CalculationResultProps {
  summary: SummaryProps;
  details: DetailsProps;
}

export interface CalculatedDataProps {
  registers: Array<{
    hasPopover: boolean;
    key: string;
    value: string;
  }>;
  firstClass?: string;
  secondClass?: string;
  idName?: string;
  thirdClass?: string;
}

export interface MortgageDetailsProps {
  requestedAmount: string;
  appraisal: string;
  legalFee: string;
  solca: string;
  mainFee: string;
  chosenProductPercentage: string;
  chosenProductName: string;
}

export interface TotalValuesProps {
  mainFee: string;
  interests: string;
  insurances: string;
}

export interface NewCustomerProps {
  identification?: string;
  phone: string;
  fullName: string;
  contactHoursStart: string;
  contactHoursEnd: string;
}

export interface PersonalData {
  name: string;
  lastName: string;
  phone: string;
  email: string;
  provinceResidence: string;
  capitalResidence: string;
  maritalStatus: string;
  nameState: string;
  lastNameState: string;
  phoneState: string;
  emailState: string;
  provinceResidenceState: string;
  capitalResidenceState: string;
  separationOfProperty: string;
  idCard: string;
  idCardState: string;
  idCardPartner: string;
  idCardPartnerState: string;
  nameStateError: boolean;
  lastNameStateError: boolean;
  phoneStateError: boolean;
  emailStateError: boolean;
  provinceResidenceStateError: boolean;
  capitalResidenceStateError: boolean;
  idCardStateError: boolean;
  idCardPartnerStateError: boolean;
  maritalStatusStateError: boolean;
  separationOfPropertyStateError: boolean;
}

export interface CreditData {
  buyingIntentionTime: string;
  buyingIntentionTimeState: string;
  buyingIntentionTimeStateError: boolean;
}

export interface WorkData {
  workStatus: string;
  partnerWorkStatus: string;
  monthlyIncome: string;
  monthlyIncomeNumber: number;
  partnerMonthlyIncome: string;
  partnerMonthlyIncomeNumber: number;
  workStatusState: string;
  partnerWorkStatusState: string;
  monthlyIncomeState: string;
  monthlyIncomeErrorHelper: string;
  partnerMonthlyIncomeState: string;
  workStatusError: boolean;
  partnerWorkStatusError: boolean;
  monthlyIncomeError: boolean;
  partnerMonthlyIncomeError: boolean;
  workSeniority: string;
  partnerWorkSeniority: string;
  workSeniorityState: string;
  partnerWorkSeniorityState: string;
  workSeniorityStateError: boolean;
  partnerWorkSeniorityStateError: boolean;
  workSeniorityStateErrorHelper: string;
  partnerMonthlyIncomeErrorHelper: string;
  previousEndWork: string | null;
  previousInitWork: string | null;
  partnerPreviousEndWork: string | null;
  partnerPreviousInitWork: string | null;
}

export interface PrequalificationI {
  totalAmount: number;
  monthlyFee: number;
  annualRate: number;
  paymentTerm: string;
  estimatedDebt: number;
  isMVP: boolean;
}

export interface ExpressRequestState {
  showUnauthorizedPage: boolean;
  showSomethingWentWrongPage: boolean;
  expressApplication_error: boolean;
  expressApplication_success: boolean;
  prequalificationSuccess: boolean;
  expressApplication_loading: boolean;
  expressApplicationAgencyError: boolean;
  expressApplicationAgencySuccess: boolean;
  currentStep: number;
  completedPersonalData: boolean;
  completedWorkData: boolean;
  completedLoanData: boolean;
  dataProtectionModalOpen: boolean;
  ldpdError:boolean;
  personalData: PersonalData;
  creditData: CreditData;
  selected_button_product: string;
  selected_credit_product_type: string;
  title_products_enable: boolean;
  inputs_enable: boolean;
  workData: WorkData;
  failuresCount: number;
  failuresPrequalificationCount: number;
  showAlert: boolean;
  nut: string;
  error422: boolean;
  showOfferPage: boolean;
  showCounterofferPage: boolean;
  showRejectedPage: boolean;
  prequalificationData: PrequalificationI;
  payload: string;
  facephiToken: string;
}

export interface IdentificationDataI {
  identificationNumber: string;
  identificationType: string;
}

export interface TransactionalContactI {
  email: any;
  phone: any;
  identificationNumber: string;
  identificationType: string;
}

export interface FingerprintCodeI {
  identificationNumber: string;
  identificationType: string;
  fingerPrintCode: string;
}
export interface OtpCodeI {
  identificationNumber: string;
  identificationType: string;
  otp: string;
}

export interface RequestOtpResendI {
  identificationNumber: string;
  identificationType: string;
}

export interface RequestHeadersI {
  token: string;
  GUID: string;
  channel?: string;
  auth?: string;
}

export interface ApiCreatorRequestHeadersI {
  'Content-Type': string;
  'X-GUID': string;
  'X-Recaptcha-Token': string;
  'X-Channel'?: string;
  'X-Auth'?: string;
}
