import { AnyAction } from 'redux';
import actionTypes from '../../actions/actionTypes';
import { stateValidator } from '../../common/helpers/functions';
import { agencies } from './agencies';

const AGENCY_INPUT_MAX_LENGTH = 40;

interface ChannelsState {
  adviser: string;
  adviserState: string;
  adviserStateError: boolean;
  builder: string;
  builderState: string;
  builderStateError: boolean;
  project: string;
  projectState: string;
  projectStateError: boolean;
  agency: string;
  agencyUUID: string;
  agencyState: string;
  agencyStateError: boolean;
  agencies: Array<any>;
}

export const INITIAL_STATE = {
  adviser: '',
  adviserState: 'normal',
  adviserStateError: true,
  builder: '',
  builderState: 'normal',
  builderStateError: true,
  project: '',
  projectState: 'normal',
  projectStateError: true,
  agency: '',
  agencyUUID: '',
  agencyState: 'normal',
  agencyStateError: true,
  agencies: agencies,
};

const channels = (
  state: ChannelsState = INITIAL_STATE,
  action: AnyAction = { type: null }
): ChannelsState => {
  switch (action.type) {
    case actionTypes.SET_LOAN_APPLICATION_ADVISER:
      return {
        ...state,
        adviser: action.value,
        adviserState: stateValidator(action.value),
        adviserStateError: action.value ? false : true,
      };
    case actionTypes.SET_LOAN_APPLICATION_BUILDER:
      return {
        ...state,
        builder: action.value,
        builderState: stateValidator(action.value),
        builderStateError: action.value ? false : true,
      };
    case actionTypes.SET_LOAN_APPLICATION_PROJECT:
      return {
        ...state,
        project: action.value,
        projectState: stateValidator(action.value),
        projectStateError: action.value ? false : true,
      };
    case actionTypes.SET_LOAN_APPLICATION_AGENCY:
      //eslint-disable-next-line
      const newValue = action.newValue.replace(/[^A-zÀ-ÿ0-9 \-\,\.]/g, '').toUpperCase();
      if (newValue.length > AGENCY_INPUT_MAX_LENGTH) {
        return state;
      }
      const filteredAgencies = state.agencies.filter(
        (agency) => agency.value === newValue
      );
      const validAgency = filteredAgencies.length > 0;
      return {
        ...state,
        agency: newValue,
        agencyState: validAgency ? 'normal' : 'error',
        agencyStateError: validAgency ? false : true,
      };
    case actionTypes.SET_LOAN_APPLICATION_AGENCY_UUID:
      return {
        ...state,
        agencyUUID: action.newValue,
      };
    default:
      return state;
  }
};

export default channels;
