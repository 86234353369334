import React, { Component } from "react";

import Button from "../Button";
import Thumbnail from "../Thumbnail";

import somethingWentWrongIcon from "../../assets/icons/somethingWentWrongIcon.svg";

interface SomethingWentWrongProps {
  handleClick: () => void;
}

class SomethingWentWrong extends Component<SomethingWentWrongProps> {
  render() {
    const continueButtonProps = {
      color: "primary",
      size: "large",
      text: "Reintentar",
      id: `SomethingWentWrongButtonRetry`,
      handleClick: this.props.handleClick,
      disabled: false,
      testId: "SomethingWentWrongId",
    };

    return (
      <div className="error-page something-went-wrong " data-testid="serviceErrorPageTestId">
        <div className="icon-container">
          <Thumbnail url={somethingWentWrongIcon} name={"imagen"} />
        </div>
        <div className="title-container padding-wrapper">
          <h4 className="title" id="serviceError">Servicio no disponible</h4>
        </div>
        <div className="text-container padding-wrapper something-went-wrong">
          <pichincha-typography
            variant="bodyText"
            color="darkGrey"
            align="center"
          >
            Nada de qué preocuparse, nos tomará unos minutos corregirlo.
          </pichincha-typography>
        </div>
        <div className="button-container padding-wrapper bp-mb-32">
          <Button {...continueButtonProps} />
        </div>
      </div>
    );
  }
}

export default SomethingWentWrong;
