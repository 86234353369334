import React, { Component } from "react";

import Button from "../Button";
import Thumbnail from "../Thumbnail";

import errorIcon from "../../assets/icons/errorIcon.svg";

interface BlockedProps {
  handleClick: () => void;
  blockingTimeMessage: string;
}

class Blocked extends Component<BlockedProps> {
  render() {
    const continueButtonProps = {
      color: "primary",
      size: "large",
      text: "Reintentar",
      id: `BlockedButtonRetry`,
      handleClick: this.props.handleClick,
      disabled: false,
      testId: "blockedButtonMessage",
    };

    return (
      <div className="error-page blocked-page">
        <div className="icon-container">
          <Thumbnail url={errorIcon} name={"imagen"} />
        </div>
        <div className="title-container padding-wrapper">
          <h4 className="title text-prelo-book text-blue-500" id="errorBlocked">
            Acceso no autorizado
          </h4>
        </div>
        <div className="text-container padding-wrapper">
          <pichincha-typography
            variant="bodyText"
            color="darkGrey"
            align="center"
          >
            Hemos detectado varios ingresos de código incorrecto, por tal motivo
            el acceso ha sido bloqueado temporalmente.
          </pichincha-typography>
        </div>
        <div className="text-container padding-wrapper">
          <pichincha-typography
            variant="bodyText"
            color="darkGrey"
            align="center"
          >
            {this.props.blockingTimeMessage}
          </pichincha-typography>
        </div>
        <div className="button-container padding-wrapper">
          <Button {...continueButtonProps} />
        </div>
      </div>
    );
  }
}

export default Blocked;
