import React, { Component } from "react";

import Popover from "../../Popover";

import { TotalValuesProps } from "../../../common/helpers/integration";

export default class TotalValuesMobile extends Component<TotalValuesProps> {
  handleClick(this: any) {
    this.classList.toggle("active");
    let panel = this.nextElementSibling;
    if (panel.style.display === "block") {
      panel.style.display = "none";
    } else {
      panel.style.display = "block";
    }
  }

  componentDidMount() {
    // @ts-ignore
    document
      .querySelector("#buttonTotalValuesMobile")
      .addEventListener("click", this.handleClick);
  }

  render() {
    return (
      <div className="result-card mobile bp-mb-16">
        <button
          className="accordion"
          id="buttonTotalValuesMobile"
          data-testid="buttonTotalValuesMobile"
        >
          Valores totales referenciales
        </button>
        <div className="panel total-values" data-testid="panel total-values">
          <div className="register bp-mb-8">
            <div>
              <pichincha-typography
                variant="smallText"
                color="blue"
                weight_color="500"
                weight="bold"
              >
                Monto total a financiar
              </pichincha-typography>
            </div>
            <div>
              <pichincha-typography
                variant="smallText"
                color="blue"
                weight_color="500"
                weight="bold"
              >
                ${this.props.mainFee}
              </pichincha-typography>
            </div>
          </div>
          <div className="register bp-mb-8">
            <div>
              <Popover
                id="totalInterestsPopoverMobile"
                linkName="Total intereses"
                popoverContent="Este es el valor total de intereses generados durante el
                    periódo de tu crédito."
              />
            </div>
            <div>
              <pichincha-typography
                variant="smallText"
                color="blue"
                weight_color="500"
                weight="bold"
              >
                ${this.props.interests}
              </pichincha-typography>
            </div>
          </div>
          <div className="register bp-mb-8">
            <div>
              <Popover
                id="totalInsurancesPopoverMobile"
                linkName="Total seguros"
                popoverContent="Esta es la suma de los valores totales que pagarás por el
                    seguro de desgravamen y el seguro contra incendio y
                    terremoto."
              />
            </div>
            <div>
              <pichincha-typography
                variant="smallText"
                color="blue"
                weight_color="500"
              >
                ${this.props.insurances}
              </pichincha-typography>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
