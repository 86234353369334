import React, { Component } from "react";
import "./Navbar.scss";
import Toolbar from "./Toolbar";
import SideDrawer from "./SideDrawer";
import Backdrop from "./Backdrop";

interface IProps {
  currentPage?: boolean;
  conecctionFailNavbar?: boolean;
  currentLogin?: boolean;
  
}

interface IState {
  sideDrawerOpen: boolean;
}

class Navbar extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      sideDrawerOpen: false,
    };
    this.drawerToogleClickHandler = this.drawerToogleClickHandler.bind(this);
  }

  drawerToogleClickHandler() {
    this.setState(() => {
      return { sideDrawerOpen: !this.state.sideDrawerOpen };
    });
  }

  render() {
    let sideDrawer;
    let backdrop;

    if (this.state.sideDrawerOpen) {
      sideDrawer = (
        <SideDrawer animation=" side-drawer wow animate__animated animate__fadeInRightBig animate__fadeIn animate__faster" currentPage={this.props.currentPage} />
      );
      backdrop = <Backdrop click={this.drawerToogleClickHandler} />;
    }

    return (
      <div className="navbar-container">
        <Toolbar
          buttonstate={this.state.sideDrawerOpen}
          click={this.drawerToogleClickHandler}
          currentPage={this.props.currentPage}
          conecctionFailNavbar={this.props.conecctionFailNavbar}
          loginNavbar = {this.props.currentLogin}
        />
        {sideDrawer}
        {backdrop}
      </div>
    );
  }
}

export default Navbar;
