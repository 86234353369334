import React, { Component } from "react";

import { connect, ConnectedProps } from "react-redux";
import { ReduxState } from "../../../common/store";

import actions from "../../../actions/";

import WelcomePage from "../WelcomePage/WelcomePage";
import PersonalData from "../PersonalData/PersonalData";
import WorkData from "../WorkData/WorkData";

import "./FormContainer.scss";

import imageExpress from "../../../assets/img/img-express-background.svg";
import CreditData from "../CreditData/CreditData";
import ThankYouPage from "../ThankYouPage/ThankYouPage";
import CalificationThanksPage from "../CalificationThanksPage/CalificationThanksPage";
import RecaptchaDisclaimer from "../../RecaptchaDisclaimer/RecaptchaDisclaimer";
import SuccessFacephi from "../SuccessFacephi/SuccessFacephi";
import {
  PichinchaModalProps,
  UseModalBp,
} from "../../Shared/hooks/UseModalBP/use-modal-bp";
import { AuthDataProtection } from "../PersonalData/auth-data-protection/auth-data-protection";
import {
  GUID,
  REACT_APP_RECAPTCHA_TOKEN,
  PATH_HOME,
  LDPD_DOC,
} from "../../../common/helpers/constants";
import AuthDataProtectionError from "./auth-data-protection-error/auth-data-protection-error";
import {
  getIdentification,
  getModalSize,
  getPhone,
  openDataProtectionModalValidation,
} from "../../../common/helpers/functions";

class FormContainer extends Component<ReduxProps> {
  state = {
    execBtn: false,
    error: false,
    authorizeDataProtection: false,
  };
  render() {
    const {
      channel,
      userType,
      jwt,
      idCardLogin,
      cif,
      acceptLdpd,
      currentStep,
      dataProtectionModalOpen,
      ldpdError,
      payload,
    } = this.props;

    const { phone } = this.props.personalData;

    const pages = {
      0: (
        <div className="box-contain welcome-page">
          <WelcomePage />
        </div>
      ),
      1: (
        <div className="box-contain form-inputs">
          <PersonalData />
        </div>
      ),

      2: (
        <div className="box-contain form-inputs">
          <WorkData />
        </div>
      ),
      3: (
        <div className="box-contain form-inputs">
          <CreditData />
        </div>
      ),
      4: <ThankYouPage />,
      6: <CalificationThanksPage />,
      7: <SuccessFacephi />,
    };

    const isClient = userType === "cliente";

    const handleCheckBoxChange = () => {
      this.setState({ error: false });
      this.setState({
        authorizeDataProtection: !this.state.authorizeDataProtection,
      });
    };

    const onClickExitModal = () => {
      if (!this.state.authorizeDataProtection) {
        this.setState({ error: true });
        return;
      }
      const customerInfo = payload !== "" ? JSON.parse(payload) : "";
      const body = {
        cif: cif,
        identification: getIdentification(idCardLogin, customerInfo),
        identificationType: "0001",
        isClient: isClient,
        phone: getPhone(phone),
        hasConsent: true,
        acceptedTermsConditions: true,
        url: PATH_HOME + LDPD_DOC,
      };
      if (!this.state.execBtn) {
        //@ts-ignore
        grecaptcha
          .execute(REACT_APP_RECAPTCHA_TOKEN)
          .then((recaptchaToken: string) => {
            this.props.dataProtectionAuth(
              recaptchaToken,
              GUID,
              channel,
              jwt,
              body
            );
          });
        this.setState({ execBtn: true });
      }
    };

    const handleCloseModal = () => {
      if (ldpdError) {
        this.props.handleDataProtectionModalError(false);
      }
      this.props.handleDataProtectionModalOpen(false);
      this.setState({
        execBtn: false,
        error: false,
        authorizeDataProtection: false,
      });
    };

    const handleAcceptOnError = () => {
      if (!isClient) {
        window.location.href = "/login";
        return;
      }
      this.props.handleDataProtectionModalOpen(false);
      this.props.handleDataProtectionModalError(false);
      this.setState({ execBtn: false });
    };

    const propsModal: PichinchaModalProps = {
      primLabel: "",
      secLabel: "",
      disablePrinBtn: "false",
      size: getModalSize(ldpdError),
      open: openDataProtectionModalValidation(
        acceptLdpd,
        dataProtectionModalOpen,
        isClient,
        currentStep
      ),
      href: "workDataFormModal",
      chil: ldpdError ? (
        <AuthDataProtectionError buttonClick={handleAcceptOnError} />
      ) : (
        <AuthDataProtection
          error={this.state.error}
          authorizeDataProtection={this.state.authorizeDataProtection}
          handleCheckBoxChange={handleCheckBoxChange}
          exitModal={onClickExitModal}
          loadingState={this.state.execBtn}
        />
      ),
      closeButton: currentStep === 1,
      hadleExitClick: handleCloseModal,
    };

    //@ts-ignore
    const currentPage = pages[currentStep];

    let containDesktop;
    let recaptchaClassName = "";

    if (currentStep < 4 && currentStep > 0) {
      containDesktop = (
        <img
          className="image-side"
          data-testid="backImage"
          src={imageExpress}
          alt="callme-back"
        />
      );
      recaptchaClassName = "ml-22";
    }
    if ([4, 6].includes(currentStep)) {
      recaptchaClassName = "d-none";
    }
    return (
      <div>
        <div className="desktop-large" data-testid="desktop-class">
          <div
            className={currentStep > 3 ? "contain-size-feed" : "contain-size"}
          >
            {containDesktop}
            {currentPage}
            <div className={recaptchaClassName}>
              <RecaptchaDisclaimer />
            </div>
          </div>
        </div>
        <div className="mobile-large mt-5">
          {currentPage}
          <RecaptchaDisclaimer />
        </div>
        <UseModalBp {...propsModal}></UseModalBp>
      </div>
    );
  }
}

const mapStateToProps = (state: ReduxState) => {
  const { expressRequest, site, civilRegistration, login } = state;
  const {
    currentStep,
    personalData,
    dataProtectionModalOpen,
    ldpdError,
    payload,
  } = expressRequest;
  const { channel, userType, jwt } = site;
  const { idCardLogin } = login;
  const { cif, acceptLdpd } = civilRegistration;
  return {
    currentStep,
    channel,
    userType,
    jwt,
    cif,
    acceptLdpd,
    idCardLogin,
    personalData,
    dataProtectionModalOpen,
    ldpdError,
    payload,
  };
};

const {
  setStep,
  dataProtectionAuth,
  handleDataProtectionModalOpen,
  handleDataProtectionModalError,
} = actions;

const connector = connect(mapStateToProps, {
  setStep,
  dataProtectionAuth,
  handleDataProtectionModalOpen,
  handleDataProtectionModalError,
});

type ReduxProps = ConnectedProps<typeof connector>;

export default connector(FormContainer);
