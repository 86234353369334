import styled, { css } from 'styled-components'
import { ButtonHTMLAttributes } from 'react'
import { COLORS } from '../../theme'

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  disable?: boolean
  loading?: boolean
  width?: string
  variant?: 'primary' | 'secondary'
}

export const ButtonWrapper = styled.div<ButtonProps>`
  display: flex;
  width: ${({ width }) => width};
  button {
    ${({ variant }) => {
    switch (variant) {
      case 'primary':
        return css`
            background-color: ${COLORS.yellow500};
            color: ${COLORS.blue500};
          `
      case 'secondary':
        return css`
            background-color: ${COLORS.darkGrayishBlue200};
            border: solid 1px ${COLORS.darkGrayishBlue300};
            color: ${COLORS.blue500};
          `
    }
  }}
    display: flex;
    align-items: center;
    justify-content: center;
    border: solid 1px transparent;
    border-radius: 4px;
    width: 100%;
    padding: 0 24px;
    cursor: pointer;
    text-align: center;
    height: 48px;
    font-size: 14px;
    font-family: prelo-semibold, Helvetica, Arial, Sans, sans-serif;
  }

  :active {
    opacity: 0.7;
  }

  .disabled {
    cursor:  not-allowed;
    opacity: 0.55;
    background-color: ${({ variant }) => variant === 'primary' ? COLORS.yellow200 : COLORS.darkGrayishBlue100};
    color: ${COLORS.blue200};
  }
`
