import React, { Component } from "react";

import "./CheckboxInput.scss";

interface CheckboxInputProps {
  id: string;
  checked: boolean;
  handleClick: () => void;
  label?: string;
  tabindex?: number;
  state?: string;
}

class CheckboxInput extends Component<CheckboxInputProps> {
  render() {
    return (
      <div
        id="1container"
        className="checkbox-container"
        onClick={this.props.handleClick}
      >
        <input
          className={`input-checkbox ${this.props.state || ""}`}
          id={this.props.id}
          type="checkbox"
          value="1"
          checked={this.props.checked}
          onChange={this.props.handleClick}
          data-testid={`${this.props.id}TestId`}
          tabIndex={this.props.tabindex}
        />
        <label className="checkbox-label">{this.props.label}</label>
      </div>
    );
  }
}

export default CheckboxInput;
