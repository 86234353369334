import { AnyAction } from 'redux';
import actionTypes from '../../actions/actionTypes';
import { getDecriptedData } from '../../actions/utils/helperActionCreator';

interface CivilRegistrationState {
  firstName: string;
  secondName: string;
  secondLastName: string;
  firstLastName: string;
  thirdName: string;
  loading: boolean;
  error: boolean;
  segmentation: string;
  cif: string;
  acceptLdpd: boolean;
}

export const INITIAL_STATE = {
  firstName: '',
  secondName: '',
  secondLastName: '',
  firstLastName: '',
  thirdName: '',
  loading: false,
  error: false,
  segmentation: '',
  cif: '',
  acceptLdpd: false,
};

const civilRegistration = (
  state: CivilRegistrationState = INITIAL_STATE,
  action: AnyAction = { type: null }
): CivilRegistrationState => {
  switch (action.type) {
    case actionTypes.CIVIL_REGISTRATION_RESET_STATE:
      return INITIAL_STATE;
    case actionTypes.CIVIL_REGISTRATION_NAMES_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case actionTypes.CIVIL_REGISTRATION_NAMES_SUCCESS:
      const {
        firstName,
        secondName,
        secondLastName,
        firstLastName,
        thirdName,
        segmentation,
        cif,
        acceptLdpd,
      } = action.data.customer;
      const getIsMVPActivePrecalification = () => {
        const isMVP = segmentation.toLowerCase() === 'mvp';
        if (isMVP) window.location.href = '/portal/solicitud';
        return isMVP;
      };
      const isMVPActivePrecalification = getIsMVPActivePrecalification();
      return {
        ...state,
        firstName,
        secondName,
        secondLastName,
        firstLastName,
        thirdName,
        segmentation,
        cif,
        acceptLdpd,
        loading: isMVPActivePrecalification,
        error: false,
      };
    case actionTypes.CIVIL_REGISTRATION_NAMES_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case actionTypes.CIVIL_REGISTRATION_HIDE_LOADER:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.CIVIL_REGISTRATION_UPDATE_LDPD:
      const decryptedResponse: any = getDecriptedData(action.data)
      return { ...state, acceptLdpd: decryptedResponse.status === 200 };

    default:
      return state;
  }
};

export default civilRegistration;
