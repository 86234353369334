export const checkDates = (workData: any, workSeniority: any) => {
  if (workData.workStatus === "dependent") {
    if (
      ["between_3_and_5_months", "between_6_and_11_months"].includes(
        workSeniority
      )
    ) {
      if (
        workData.previousInitWork !== null &&
        workData.previousInitWork !== ""
      )
        return workData.previousEndWork === null;
      return false;
    }
    return false;
  }
  if (
    ["Profesional Independiente Bill", "independent", "rentier"].includes(
      workData.workStatus
    ) &&
    (workData.previousInitWork === null || workData.previousInitWork === "")
  )
    return true;
  return false;
};
