import React from 'react'

type ThumbnailProps = {
  url: string;
  name: string;
  className?: string;
}

function Thumbnail({ url, name, className = '' } : ThumbnailProps) {
  return (
    <img src={url} alt={name} className={className} />
  );
}

export default Thumbnail;
