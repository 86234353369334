import React, { Component } from "react";
import identificationExample from "../../assets/img/img-cedula-celeste.svg";
import successInput from "../../assets/icons/successInput.svg";

import "./InputField.scss";

interface InputFieldProps {
  label: string;
  id: string;
  placeholder: string;
  tabState?: boolean;
  errorHelper: string;
  normalHelper: string;
  state: string;
  stateRightTab?: string;
  iconOrientation?: string;
  iconContent?: string;
  tooltip?: string;
  disabled: boolean;
  currentValue: string;
  handleChange: (e: any) => void;
  testId: string;
  lg?: string;
  xs?: string;
  phonePrefix?: boolean;
  classWrap?: string;
  classIcon?: string;
  list?: any;
  dropdownInput?: boolean;
  handleKeyUp?: any;
  type?: string;
  rows?: number;
  handleFocus?: () => void;
  tabindex?: number;
  tooltipOrientation?: string;
  customTooltipIcon?: any;
  statusIcon?: string;
  handleRetry?: any;
  disablePaste?: boolean;
}

class InputField extends Component<InputFieldProps> {
  componentDidMount() {
    const { disablePaste, id } = this.props;

    if (disablePaste) {
      const myInput = document.getElementById(id);
      myInput?.addEventListener("paste", (e) => e.preventDefault());
    }
  }

  handleInputClassName(
    iconOrientation: string,
    disabled: boolean,
    currentValue: string,
    tooltip: string
  ) {
    let inputClassName = `large ${
      iconOrientation === "prepend" ? "icon-prepend" : ""
    }`;
    if (disabled && currentValue) {
      inputClassName += " disabled";
    }
    if (tooltip) {
      inputClassName += " tooltip";
    }
    return inputClassName;
  }

  handleWrapperClassName(classWrap: string, state: string, disabled: boolean) {
    return `wrapper${classWrap} ${state} ${disabled ? "disabled" : ""}`;
  }

  handleRenderHelperAccordingState(
    state: string,
    errorHelper: string,
    normalHelper: string
  ) {
    if (state === "error") {
      return (
        <pichincha-typography
          variant="tinyText"
          color="danger"
          weight_color="400"
          data-testid="error-helper"
          class="typography hydrated error-message"
        >
          {errorHelper}
        </pichincha-typography>
      );
    }
    if (state === "normal") {
      return (
        <pichincha-typography
          variant="tinyText"
          color="darkGrey"
          weight_color="400"
          data-testid="normal-helper"
          class="typography hydrated"
        >
          {normalHelper}
        </pichincha-typography>
      );
    }
  }

  handleScreenSize(sizeType: string, sizeNumber: string) {
    if (sizeNumber) {
      return sizeNumber;
    } else {
      switch (sizeType) {
        case "lg":
          return "3";

        case "xs":
          return "12";

        default:
          break;
      }
    }
  }

  handleRenderLabel(tabState: boolean, label: string) {
    return !tabState && label + " ";
  }

  render() {
    const {
      label,
      errorHelper,
      normalHelper,
      state,
      iconOrientation,
      iconContent,
      tooltip,
      disabled,
      currentValue,
      placeholder,
      handleChange,
      tabState,
      testId,
      classWrap,
      id,
      type,
      rows,
      tooltipOrientation,
      customTooltipIcon,
      statusIcon,
      handleRetry,
    } = this.props;

    let helper;
    let inputClassName = this.handleInputClassName(
      //@ts-ignore
      iconOrientation,
      disabled,
      currentValue,
      tooltip
    );

    const wrapperClassName = this.handleWrapperClassName(
      //@ts-ignore
      classWrap,
      state,
      disabled
    );

    const statusIconMap: { [key: string]: any } = {
      loading: (
        <pichincha-spinner
          background="#0F265C"
          border={4}
          color="#F3F4F7"
          size={18}
        ></pichincha-spinner>
      ),
      success: (
        <img
          width={20}
          height={20}
          alt="cedula"
          className=""
          src={successInput}
        />
      ),
      retry: (
        <div className="action-link" onClick={handleRetry}>
          <pichincha-link class="hydrated">
            <pichincha-typography
              id="homeCardMyPichinchaHouseViewMoreLink"
              variant="actionText"
              color="info"
              weight="normal"
              weight_color="500"
              align="inherit"
              class="typography hydrated"
            >
              Reintentar
            </pichincha-typography>
          </pichincha-link>
        </div>
      ),
    };

    const iconPrepend = `icon icon-prepend${this.props.classIcon}`;

    helper = this.handleRenderHelperAccordingState(
      state,
      errorHelper,
      normalHelper
    );

    let input = (
      <input
        id={id}
        className={inputClassName}
        type="text"
        onChange={handleChange}
        disabled={disabled}
        value={currentValue}
        placeholder={placeholder}
        data-testid={testId}
        onKeyUp={this.props.handleKeyUp}
        onFocus={this.props.handleFocus}
        tabIndex={this.props.tabindex}
      />
    );

    if (type === "textarea") {
      input = (
        <textarea
          id={id}
          className={inputClassName}
          onChange={handleChange}
          disabled={disabled}
          value={currentValue}
          placeholder={placeholder}
          data-testid={testId}
          onKeyUp={this.props.handleKeyUp}
          rows={rows}
          onFocus={this.props.handleFocus}
        ></textarea>
      );
    }

    const listPlaces = this.props.list;

    return (
      <pichincha-grid
        item
        //@ts-ignore
        lg={this.handleScreenSize("lg", this.props.lg)}
        //@ts-ignore
        xs={this.handleScreenSize("xg", this.props.xs)}
      >
        <div className={wrapperClassName} data-testid={`wrapper-${testId}`}>
          <div className="label mobile">
            <div className="text-label">
              {this.handleRenderLabel(
                //@ts-ignore
                tabState,
                label
              )}
              {tooltip && (
                <pichincha-tooltip
                  position={tooltipOrientation || "bottom"}
                  data-testid="tooltip-helper"
                >
                  <span slot="item-text">{tooltip}</span>
                  <span slot="item-body">
                    {customTooltipIcon || (
                      <pichincha-icon
                        size="16px"
                        color="darkGrey"
                        weight_color="900"
                        type="--sharp"
                      >
                        info
                      </pichincha-icon>
                    )}
                  </span>
                </pichincha-tooltip>
              )}
            </div>
          </div>
          <div className="label desktop">
            <div className="text-label">
              {!tabState && label + " "}
              {tooltip && (
                <pichincha-tooltip position={tooltipOrientation || "bottom"}>
                  <span slot="item-text">{tooltip}</span>
                  <span slot="item-body">
                    {customTooltipIcon || (
                      <pichincha-icon
                        size="medium"
                        color="darkGrey"
                        weight_color="900"
                        type="--sharp"
                      >
                        info
                      </pichincha-icon>
                    )}
                  </span>
                </pichincha-tooltip>
              )}
            </div>
          </div>
          {iconOrientation === "prepend" && currentValue && (
            <i className={iconPrepend}>
              <pichincha-typography
                variant="tinyText"
                color="darkGrey"
                weight_color="400"
              >
                {iconContent}
              </pichincha-typography>
            </i>
          )}

          {this.props.phonePrefix && (
            <div className="phone-wrapper" data-testid={`${testId}PhonePrefix`}>
              <div className="prefix-num">09</div>
              {input}
            </div>
          )}

          {!this.props.phonePrefix && input}
          {this.props.dropdownInput && listPlaces}

          {iconOrientation === "append" && currentValue && (
            <i className="icon icon-append" data-testid="append-icon">
              <pichincha-typography
                variant="smallText"
                color="darkGrey"
                weight_color="400"
              >
                {iconContent}
              </pichincha-typography>
            </i>
          )}
          {iconOrientation === "append_tooltip" && (
            <i className="icon icon-append icon-tooltip">
              <pichincha-tooltip position="bottom" data-testid="tooltip-helper">
                <span slot="item-text">
                  <img
                    alt="cedula"
                    className="bp-mt-8"
                    src={identificationExample}
                  />
                  <br />
                  Encuéntralo en la parte
                  <br />
                  posterior de tu cédula
                </span>
                <span slot="item-body">
                  <pichincha-icon
                    size="20px"
                    color="darkGrey"
                    weight_color="500"
                    type="--sharp"
                  >
                    help
                  </pichincha-icon>
                </span>
              </pichincha-tooltip>
            </i>
          )}
          {statusIcon && (
            <i className="icon icon-append icon-tooltip loader">
              {statusIconMap[statusIcon]}
            </i>
          )}
          {helper}
        </div>
      </pichincha-grid>
    );
  }
}

export default InputField;
