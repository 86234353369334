export const ENABLED_CHANNELS = [
  'kam',
  'agencia',
  'app',
  'bancaweb',
  'portal_publico',
  'privada',
  'feria',
  'kam_oficina',
  'proyectos',
  'plusvalia',
];

export const AGENCY_CHANNELS = ['kam oficina', 'agencia'];

export const CHANNELS_WITH_NO_LOGIN = [''];
export const PUBLIC_CHANNELS = ['direct', 'portal publico'];

export const CHANNELS_WITH_WELCOME_PAGE = [
  'kam',
  'agencia',
  'banca privada',
  'feria',
  'kam oficina',
  'proyectos',
];

export enum CHANNEL {
  Kam = 'kam',
  KamOffice = 'kam_oficina',
  Agency = 'agencia',
  Portal = 'direct',
  App = 'app',
  WebBanking = 'bancaweb',
  PublicPortal = 'portal_publico',
  PrivateBanking = 'privada',
  Fair = 'feria',
  Projects = 'proyectos',
  Plusvalia = 'plusvalia',
}

export const CHANNEL_ROUTES = {
  kam: 'kam',
  kam_oficina: 'kam oficina',
  agencia: 'agencia',
  direct: 'direct',
  app: 'app',
  bancaweb: 'bancaweb',
  portal_publico: 'portal publico',
  privada: 'banca privada',
  feria: 'feria',
  proyectos: 'proyectos',
  plusvalia: 'plusvalia',
};

export const GENERIC_ERROR_MESSAGE_TEXT =
  'Los datos no se enviaron correctamente, por favor vuelve a intentarlo.';
export const INCORRECT_CONTACT_ERROR_MESSAGE_TEXT =
  'Tu celular o correo electrónico no coincide con nuestros registros. Por favor, verifícalo e inténtalo nuevamente';
export const VIP_VIS_MAX_MONTHLY_INCOME = '$2,536';

export const workOptions = [
  {
    value: 'empty',
    display: 'Selecciona una opción',
  },
  {
    value: 'dependent',
    display: 'Trabajo como dependiente para una empresa',
  },
  {
    value: 'Profesional Independiente Bill',
    display: 'Trabajo como profesional independiente y emito facturas',
  },
  {
    value: 'independent',
    display: 'Tengo una empresa / negocio',
  },
  {
    value: 'rentier',
    display: 'Soy rentista, alquilo mis propiedades',
  },
  {
    value: 'pensioner',
    display: 'Soy pensionista / Me he jubilado',
  },
];

export const partnerWorkOptions = [
  {
    value: 'empty',
    display: 'Selecciona una opción',
  },
  {
    value: 'dependent',
    display: 'Trabaja como dependiente para una empresa',
  },
  {
    value: 'Profesional Independiente Bill',
    display: 'Trabaja como profesional independiente y emite facturas',
  },
  {
    value: 'independent',
    display: 'Tiene una empresa / negocio',
  },
  {
    value: 'pensioner',
    display: 'Es pensionista / Se ha jubilado',
  },
  {
    value: 'rentier',
    display: 'Es rentista, alquila sus propiedades',
  },
  {
    value: 'unemployed',
    display: 'No trabaja',
  },
];

type IStringKeyObject = { [key: string]: any };

const optionsWorkTime = [
  {
    value: 'empty',
    display: 'Selecciona una opción',
    invalid: true,
  },
  {
    value: 'between_3_and_5_months',
    display: 'Entre 3 y 5 meses',
  },
  {
    value: 'between_6_and_11_months',
    display: 'Entre 6 y 11 meses',
  },
  {
    value: 'gt_1_year',
    display: 'Igual o mayor a 1 año',
  },
];

const creditHolder: IStringKeyObject = {
  dependent: {
    questionTitle: '¿Cuánto tiempo llevas en tu trabajo actual?',
    options: optionsWorkTime,
  },
  empty: {
    questionTitle: '',
    options: [
      {
        value: '',
        display: '',
      },
    ],
  },
};


const partner: IStringKeyObject = {
  dependent: {
    questionTitle: '¿Cuánto tiempo lleva tu cónyuge en su trabajo actual?',
    options: optionsWorkTime,
  },
  empty: {
    questionTitle: '',
    options: [
      {
        value: '',
        display: '',
      },
    ],
  },
};

export const workSeniorityMap: IStringKeyObject = {
  creditHolder,
  partner,
};

export const buyingIntentionTimeOptions = [
  {
    value: 'empty',
    display: 'Selecciona una opción',
    invalid: true,
  },
  {
    value: 'have_not_decided_yet',
    display: 'Aún no lo decido',
  },
  {
    value: 'within_1_month',
    display: 'Dentro de 1 mes',
  },
  {
    value: 'between_1_and_3_months',
    display: 'De 1 a 3 meses',
  },
  {
    value: 'between_3_and_6_months',
    display: 'De 3 a 6 meses',
  },
  {
    value: 'more_than_6_months',
    display: 'Más de 6 meses',
  },
];
