import React, { Component } from "react";
import Navbar from "../navbar/Navbar";
import { connect, ConnectedProps } from "react-redux";
import { ReduxState } from "../../common/store";

import actions from "../../actions";
import actionTypes from "../../actions/actionTypes";
import * as CONSTANTS from "../../common/helpers/constants";

import Footer from "../Footer/Footer";
import ProductsBox from "../ProductsBox/ProductsBox";
import AmortizationBox from "../AmortizationBox/AmortizationBox";
import ModalProducts from "../ModalProducts";
import LivingPlaceLoanSection from "./LivingPlaceLoanSection/LivingPlaceLoanSection";
import ButtonSection from "./ButtonSection/ButtonSection";
import ResultsSection from "./ResultsSection/ResultsSection";
import FinalButtonsSection from "./FinalButtonsSection/FinalButtonsSection";
import Unauthorized from "../Errors/Unauthorized";
import SomethingWentWrong from "../Errors/SomethingWentWrong";
import RecaptchaDisclaimer from "../RecaptchaDisclaimer/RecaptchaDisclaimer";

class Calculator extends Component<ReduxProps> {
  constructor(props: ReduxProps) {
    super(props);
    this.closeModal = this.closeModal.bind(this);
    this.calculate = this.calculate.bind(this);
    this.resetCalculation = this.resetCalculation.bind(this);
  }

  componentDidMount() {
    this.props.getMortgageProduct("vip-vis");
    this.props.getMortgageProduct("new-used");
    const clickFunction = (e: any) => {
      if (
        e.target.className !== "popover-content popover-content-visible" &&
        e.target.className !== "title-popover" &&
        e.target.className !== "contain-popover" &&
        e.target.className !== "text popover-link" &&
        e.target.className !== "poporver-title-section"
      ) {
        let popover = document.getElementsByClassName(
          "popover-content popover-content-visible"
        )[0];
        popover?.classList.remove("popover-content-visible");
        popover?.classList.add("popover-content-hidden");
      }
    };
    document.addEventListener("click", clickFunction);
  }

  closeModal() {
    this.props.toggleModal(false, "");
  }

  resetCalculation() {
    this.props.triggerAction(actionTypes.RESET_CALCULATION);
    window.scrollTo(0, 0);
  }
  notAllowCalculate() {
    return (
      this.props.calculate_loading ||
      this.props.house_price_error ||
      this.props.requested_amount_error ||
      this.props.loan_term_years_error ||
      this.props.amortization_type === ""
    );
  }

  calculate() {
    const payload = {
      months: this.props.months,
      amount: this.props.amount,
      requestedAmount: this.props.requested_amount,
      type: this.props.amortization_type,
      houseType: this.props.selected_product_type,
      appraisal: this.props.appraisal,
      legalExpenses: this.props.legal_expenses,
      insurance: this.props.insurance,
      solca: this.props.solca,
    };
    if (this.notAllowCalculate()) {
      return;
    }
    const requestConfig = {
      payload,
      guid: CONSTANTS.GUID,
      channel: this.props.channel,
    };
    const action = this.props.calculate;
    //@ts-ignore
    grecaptcha.ready(function () {
      //@ts-ignore
      grecaptcha
        .execute(CONSTANTS.REACT_APP_RECAPTCHA_TOKEN)
        .then(function (recaptchaToken: string) {
          action({ ...requestConfig, recaptchaToken });
        });
    });
  }

  render() {
    const { calculate_success, calculate_loading } = this.props;

    const disableButton = this.notAllowCalculate();

    const isMobile = window.innerWidth < 992;
    let showInputs;

    if (calculate_success && isMobile) {
      showInputs = false;
    }

    if (calculate_success && !isMobile) {
      showInputs = true;
    }

    if (!calculate_success) {
      showInputs = true;
    }

    let content = (
      <div className="page-content fit-footer">
        {showInputs && (
          <div
            className="inputs-section calculator-section-test calculator-content"
            id="inputsSection"
            data-testid="inputs-section"
          >
            <ProductsBox />
            <LivingPlaceLoanSection />
            <AmortizationBox />
            {!calculate_success && (
              <ButtonSection
                loading={calculate_loading}
                handleClick={this.calculate}
                disabled={disableButton}
              />
            )}
          </div>
        )}
        {calculate_success && !isMobile && <div className="separator"></div>}
        {calculate_success && (
          <div
            id="resultsSection"
            data-testid="resultsSectionTestId"
            className="calculator-section-test"
          >
            <ResultsSection />
            <FinalButtonsSection handleClick={this.resetCalculation} />
          </div>
        )}
      </div>
    );

    if (this.props.showSomethingWentWrongPage) {
      content = (
        <div
          className="error-page-margin wentwrong-page"
          data-testid="somethingWentWrongPageTestId"
        >
          <SomethingWentWrong handleClick={() => window.location.reload()} />
        </div>
      );
    }

    if (this.props.showUnauthorizedPage) {
      content = (
        <div className="error-page-margin" data-testid="unauthorizedPageTestId">
          <Unauthorized handleClick={() => window.location.reload()} />
        </div>
      );
    }

    return (
      <div className="wrapper-content" id="calculatorPage">
        {this.props.modal.show && (
          <ModalProducts
            close={this.closeModal}
            section={this.props.modal.section}
          />
        )}
        <Navbar />
        <div className="page-content fit-footer" id="pageContentId">
          {content}

          <RecaptchaDisclaimer />
          <Footer />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: ReduxState) => {
  const { calculator, site } = state;
  const {
    selected_product_type,
    product,
    modal,
    calculate_success,
    calculate_loading,
    house_price_error,
    requested_amount_error,
    loan_term_years_error,
    amortization_type,
    months,
    amount,
    requested_amount,
    appraisal,
    legal_expenses,
    insurance,
    solca,
    showUnauthorizedPage,
    showSomethingWentWrongPage,
  } = calculator;
  const { channel } = site;
  return {
    selected_product_type,
    product,
    modal,
    calculate_success,
    calculate_loading,
    house_price_error,
    requested_amount_error,
    loan_term_years_error,
    amortization_type,
    months,
    amount,
    requested_amount,
    appraisal,
    legal_expenses,
    insurance,
    solca,
    showUnauthorizedPage,
    showSomethingWentWrongPage,
    channel,
  };
};

const { calculate, toggleModal, getMortgageProduct, triggerAction } = actions;

const connector = connect(mapStateToProps, {
  calculate,
  toggleModal,
  getMortgageProduct,
  triggerAction,
});

type ReduxProps = ConnectedProps<typeof connector>;

export default connector(Calculator);
