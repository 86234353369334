import React, { Component } from "react";

import Button from "../../Button";

interface ButtonsI {
  backText: string;
  backClickHandler: () => void;
  backId: string;
  continueText: string;
  continueClickHandler: () => void;
  continueId: string;
  specialClass?: string;
  size?: string
}

class Buttons extends Component<ButtonsI> {
  render() {
    const {
      backText,
      backClickHandler,
      continueText,
      continueClickHandler,
      backId,
      continueId,
      specialClass,
      size = 'large'
    } = this.props;

    const backButton = (
      <Button
        color="secondary"
        size={size}
        text={backText}
        id={backId}
        testId={`${backId}TestId`}
        handleClick={backClickHandler}
        disabled={false}
      />
    );

    const continueButton = (
      <Button
        color="primary"
        size={size}
        text={continueText}
        id={continueId}
        testId={`${continueId}TestId`}
        handleClick={continueClickHandler}
        disabled={false}
      />
    );

    return (
      <div className={`action-buttons ${specialClass} `}>
        <div className={size === "extra-large" ? "back-xl" : "back"}>{backButton}</div>
        <div className={size === "extra-large" ? "continue-xl" : "continue"}>{continueButton}</div>
      </div>
    );
  }
}

export default Buttons;
