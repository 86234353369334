export const SET_STEP = '@@dreambuilders/SET_STEP';
export const RESET_VALUES = '@@dreambuilders/RESET_VALUES';
export const SET_FIRST_PRODUCT = '@@dreambuilders/SET_FIRST_PRODUCT';
export const SET_SECOND_PRODUCT = '@@dreambuilders/SET_SECOND_PRODUCT';
export const TOGGLE_PRODUCTS_STATE = '@@dreambuilders/TOGGLE_PRODUCTS_STATE';
export const TOGGLE_PAGE_STATUS = '@@dreambuilders/TOGGLE_PAGE_STATUS';
export const TOGGLE_INPUTS_STATE = '@@dreambuilders/TOGGLE_INPUTS_STATE';
export const TOGGLE_PERSONAL_DATA_STATE = '@@dreambuilders/TOGGLE_PERSONAL_DATA_STATE';
export const TOGGLE_WORK_DATA_STATE = '@@dreambuilders/TOGGLE_WORK_DATA_STATE';
export const TOGGLE_CREDIT_DATA_STATE = '@@dreambuilders/TOGGLE_CREDIT_DATA_STATE';

export const UPDATE_INPUT_PERSONAL_DATA_NAME = '@@dreambuilders/UPDATE_INPUT_PERSONAL_DATA_NAME';
export const UPDATE_INPUT_PERSONAL_DATA_LAST_NAME = '@@dreambuilders/UPDATE_INPUT_PERSONAL_DATA_LAST_NAME';
export const UPDATE_INPUT_PERSONAL_DATA_PHONE = '@@dreambuilders/UPDATE_INPUT_PERSONAL_DATA_PHONE';
export const UPDATE_INPUT_PERSONAL_DATA_EMAIL = '@@dreambuilders/UPDATE_INPUT_PERSONAL_DATA_EMAIL';
export const UPDATE_INPUT_PROVINCE_RESIDENCE = '@@dreambuilders/UPDATE_INPUT_PROVINCE_RESIDENCE';
export const UPDATE_INPUT_CAPITAL_RESIDENCE = '@@dreambuilders/UPDATE_INPUT_CAPITAL_RESIDENCE';

export const UPDATE_INPUT_PERSONAL_DATA_MARITAL_STATUS = '@@dreambuilders/UPDATE_INPUT_PERSONAL_DATA_MARITAL_STATUS';
export const UPDATE_INPUT_PERSONAL_DATA_SEPARATION_OF_PROPERTY = '@@dreambuilders/UPDATE_INPUT_PERSONAL_DATA_SEPARATION_OF_PROPERTY';
export const UPDATE_INPUT_PERSONAL_DATA_ID_CARD = '@@dreambuilders/UPDATE_INPUT_PERSONAL_DATA_ID_CARD';
export const UPDATE_INPUT_PERSONAL_DATA_ID_CARD_PARTNER = '@@dreambuilders/UPDATE_INPUT_PERSONAL_DATA_ID_CARD_PARTNER';
export const EXPRESS_REQUEST_RESET_STATE = '@@dreambuilders/EXPRESS_REQUEST_RESET_STATE';

export const UPDATE_INPUT_WORK_DATA_WORK_STATUS = '@@dreambuilders/UPDATE_INPUT_WORK_DATA_WORK_STATUS';
export const UPDATE_CALENDAR_WORK_DATA_PREVIOUS_INIT_WORK = '@@dreambuilders/UPDATE_CALENDAR_WORK_DATA_PREVIOUS_INIT_WORK';
export const UPDATE_CALENDAR_WORK_DATA_PREVIOUS_END_WORK = '@@dreambuilders/UPDATE_CALENDAR_WORK_DATA_PREVIOUS_END_WORK';
export const UPDATE_CALENDAR_WORK_DATA_PARTNER_PREVIOUS_INIT_WORK = '@@dreambuilders/UPDATE_CALENDAR_WORK_DATA_PARTNER_PREVIOUS_INIT_WORK';
export const UPDATE_CALENDAR_WORK_DATA_PARTNER_PREVIOUS_END_WORK = '@@dreambuilders/UPDATE_CALENDAR_WORK_DATA_PARTNER_PREVIOUS_END_WORK';
export const UPDATE_INPUT_WORK_DATA_PARTNER_WORK_STATUS = '@@dreambuilders/UPDATE_INPUT_WORK_DATA_PARTNER_WORK_STATUS';
export const UPDATE_INPUT_WORK_DATA_MONTHLY_INCOME = '@@dreambuilders/UPDATE_INPUT_WORK_DATA_MONTHLY_INCOME';
export const UPDATE_INPUT_WORK_DATA_PARTNER_MONTHLY_INCOME = '@@dreambuilders/UPDATE_INPUT_WORK_DATA_PARTNER_MONTHLY_INCOME';
export const UPDATE_INPUT_WORK_DATA_SENIORITY = '@@dreambuilders/UPDATE_INPUT_WORK_DATA_SENIORITY';
export const UPDATE_INPUT_WORK_DATA_PARTNER_SENIORITY = '@@dreambuilders/UPDATE_INPUT_WORK_DATA_PARTNER_SENIORITY';
export const UPDATE_SHOW_DATA_PROTECTION_MODAL = '@@dreambuilders/UPDATE_SHOW_DATA_PROTECTION_MODAL';
export const UPDATE_DATA_PROTECTION_ERROR = '@@dreambuilders/UPDATE_DATA_PROTECTION_ERROR';




export const UPDATE_INPUT_BUYING_INTENTIONS_TIME = '@@dreambuilders/UPDATE_INPUT_BUYING_INTENTIONS_TIME';

export const EXPRESS_APPLICATIONS_REQUEST = '@@dreambuilders/EXPRESS_APPLICATIONS_REQUEST';
export const EXPRESS_APPLICATIONS_SUCCESS = '@@dreambuilders/EXPRESS_APPLICATIONS_SUCCESS';
export const EXPRESS_APPLICATIONS_FAILED = '@@dreambuilders/EXPRESS_APPLICATIONS_FAILED';

export const SET_RATING = '@@dreambuilders/SET_RATING';
export const SET_COMMENT = '@@dreambuilders/SET_COMMENT';
export const RESET_FEEDBACK = '@@dreambuilders/RESET_FEEDBACK';

export const FEEDBACK_REGISTRATION_REQUEST = '@@dreambuilders/FEEDBACK_REGISTRATION_REQUEST';
export const FEEDBACK_REGISTRATION_SUCCESS = '@@dreambuilders/FEEDBACK_REGISTRATION_SUCCESS';
export const FEEDBACK_REGISTRATION_FAILED = '@@dreambuilders/FEEDBACK_REGISTRATION_FAILED';

export const TOGGLE_ALERT = '@@dreambuilders/TOGGLE_ALERT';

export const PREQUALIFICATION_REQUEST = '@@dreambuilders/PREQUALIFICATION_REQUEST';
export const PREQUALIFICATION_SUCCESS = '@@dreambuilders/PREQUALIFICATION_SUCCESS';
export const PREQUALIFICATIONS_FAILED = '@@dreambuilders/PREQUALIFICATIONS_FAILED';

export const FACEPHI_REDIRECTION = '@@dreambuilders/FACEPHI_REDIRECTION';

export const SHOW_SUCCESS_FACEPHI = '@@dreambuilders/SHOW_SUCCESS_FACEPHI';