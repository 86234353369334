export const CALL_ME_BACK_WORKABLE_TIME_SUCCESS =
  '@@dreambuilders/CALL_ME_BACK_WORKABLE_TIME_SUCCESS';
export const CALL_ME_BACK_RESET_STATE =
  '@@dreambuilders/CALL_ME_BACK_RESET_STATE';
export const CALL_ME_BACK_UPDATE_FIRST_NAME =
  '@@dreambuilders/CALL_ME_BACK_UPDATE_FIRST_NAME';
export const CALL_ME_BACK_UPDATE_LAST_NAME =
  '@@dreambuilders/CALL_ME_BACK_UPDATE_LAST_NAME';
export const CALL_ME_BACK_UPDATE_PHONE =
  '@@dreambuilders/CALL_ME_BACK_UPDATE_PHONE';
  export const CALL_ME_BACK_SUBMIT_REQUEST =
  '@@dreambuilders/CALL_ME_BACK_SUBMIT_REQUEST';
  export const CALL_ME_BACK_SUBMIT_SUCCESS =
  '@@dreambuilders/CALL_ME_BACK_SUBMIT_SUCCESS';
  export const CALL_ME_BACK_SUBMIT_FAILED =
  '@@dreambuilders/CALL_ME_BACK_SUBMIT_FAILED';
