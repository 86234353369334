import React, { Component } from "react";

import { connect, ConnectedProps } from "react-redux";
import { ReduxState } from "../../../common/store";

import actions from "../../../actions";

import ResultsTitle from "../ResultsTitle/ResultsTitle";
import CalculatedDataMobile from "../CalculatedData/CalculatedDataMobile";
import CalculatedDataDesktop from "../CalculatedData/CalculatedDataDesktop";
import MortgageDetailsMobile from "../MortgageDetails/MortgageDetailsMobile";
import MortgageDetailsDesktop from "../MortgageDetails/MortgageDetailsDesktop";
import TotalValuesDesktop from "../TotalValuesCard/TotalValuesDesktop";
import TotalValuesMobile from "../TotalValuesCard/TotalValuesMobile";

import GraphicResults from "../../GraphicResults";

import "./ResultsSection.scss";

class ResultsSection extends Component<ReduxProps> {
  render() {
    const list = [
      `
      Las estimaciones de la calculadora son referenciales. Por tanto, no constituyen una pre-aprobación del crédito ni otorgamiento del mismo por parte de Banco Pichincha.
      `,
      `
      Los seguros son obligatorios de acuerdo a la normativa vigente y la prima mensual que se verán reflejados en la tabla de amortización.
      `,
    ];

    let chosenProduct = "";
    let chosenProductName = "";
    let chosenProductPercentage = "";
    if (this.props.selected_product_type === "vip-vis") {
      chosenProduct = "VIP/VIS";
      chosenProductName = "Vivienda de interés público y social (VIP y VIS)";
      chosenProductPercentage = "100% del valor de la vivienda";
    }
    if (this.props.selected_product_type === "new-used") {
      chosenProduct = "Vivienda nueva o usada";
      chosenProductName = "Vivienda nueva o usada";
      chosenProductPercentage = "80% del valor de la vivienda";
    }

    const calculatedDataProps = {
      registers: [
        {
          hasPopover: false,
          key: "Precio de la vivienda",
          value: `$${this.props.house_price}`,
        },
        {
          hasPopover: false,
          key: "Monto solicitado",
          value: `$${this.props.requested_amount_display}`,
        },
        {
          hasPopover: false,
          key: "Plazo de pago",
          value: `${this.props.loan_term_years} años`,
        },
        {
          hasPopover: false,
          key: "Producto elegido",
          value: `${chosenProduct}`,
        },
        {
          hasPopover: true,
          key: "Tasa de interés",
          value: `${this.props.product[0].interestRate}%`,
        },
      ],
    };

    const mortgageDetailsProps = {
      requestedAmount:
        this.props.calculation_summary.requestedAmount.toLocaleString("es-ec"),
      appraisal:
        this.props.calculation_summary.appraisal.toLocaleString("es-ec"),
      legalFee: this.props.calculation_summary.legalFee.toLocaleString("es-ec"),
      solca: this.props.calculation_summary.solca.toLocaleString("es-ec"),
      mainFee: this.props.calculation_summary.mainFee.toLocaleString("es-ec"),
      chosenProductPercentage,
      chosenProductName,
    };

    const totalValuesProps = {
      mainFee: this.props.calculation_summary.mainFee.toLocaleString("es-ec"),
      interests: this.props.calculation_total_interests.toLocaleString("es-ec"),
      insurances:
        this.props.calculation_total_insurance.toLocaleString("es-ec"),
    };

    return (
      <div className="results-section" id="results">
        <ResultsTitle />
        <div className="results-container">
          <pichincha-grid container justify="space-between" spacing="2">
            <pichincha-grid item lg="5" xs="12">
              <div className="grafica-container">
                <GraphicResults />
              </div>
            </pichincha-grid>
            <pichincha-grid item lg="5" xs="12">
              <pichincha-grid container direction="row">
                <pichincha-grid item xs="12">
                  <CalculatedDataMobile {...calculatedDataProps} />
                  <div className="">
                    <CalculatedDataDesktop {...calculatedDataProps} />
                  </div>
                </pichincha-grid>
                <pichincha-grid item xs="12">
                  <MortgageDetailsMobile {...mortgageDetailsProps} />
                  <div>
                    <MortgageDetailsDesktop {...mortgageDetailsProps} />
                  </div>
                </pichincha-grid>
              </pichincha-grid>
            </pichincha-grid>
          </pichincha-grid>
          <div className="">
            <pichincha-grid container justify="space-between" spacing="1">
              <pichincha-grid item lg="6" xs="12">
                <div className="list desktop bp-mt-48">
                  <ul>
                    {list.map((item, index) => (
                      <li key={index}>{`${item}`}</li>
                    ))}
                  </ul>
                </div>
              </pichincha-grid>
              <pichincha-grid item lg="5" xs="12">
                <pichincha-grid container direction="row">
                  <pichincha-grid item xs="12">
                    <TotalValuesMobile {...totalValuesProps} />
                    <div className="bp-mb-24">
                      <TotalValuesDesktop {...totalValuesProps} />
                    </div>
                  </pichincha-grid>
                </pichincha-grid>
              </pichincha-grid>
            </pichincha-grid>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: ReduxState) => {
  const { calculator } = state;
  const {
    house_price,
    requested_amount_display,
    loan_term_years,
    selected_product_type,
    product,
    calculation_summary,
    calculation_total_interests,
    calculation_total_insurance,
  } = calculator;
  return {
    house_price,
    requested_amount_display,
    loan_term_years,
    selected_product_type,
    product,
    calculation_summary,
    calculation_total_interests,
    calculation_total_insurance,
  };
};

const { updateInputContent, validateInput } = actions;

const connector = connect(mapStateToProps, {
  updateInputContent,
  validateInput,
});

type ReduxProps = ConnectedProps<typeof connector>;

export default connector(ResultsSection);
