import {Component} from "react";

import Navbar from "../navbar/Navbar";
import Footer from "../Footer/Footer";
import HeroImage from "./HeroImage/HeroImage";
import SideMenu from "./SideMenu/SideMenu";
import Box from "../BenefitsBox/Box";
import CheckListElement from "./CheckListElement/CheckListElement";
import FloatButton from "../FloatButton/FloatButton";

import arrowUpward from "../../assets/img/arrowUpward.svg";

import "./MortgageGuide.scss";
import CallToActionSecondary from "../CallToActionSecondary/CallToActionSecondary";
import FooterBP from "../FooterBP/FooterBP";
import {
  MAX_AMOUNT_VIP,
  MAX_AMOUNT_VIS,
  MIN_AMOUNT_VIP,
  MIN_AMOUNT_VIS,
  NEW_USED_RATE,
  SQUARE_METER_VAL,
  MAX_INCOME_VIP
} from '../../common/helpers/constants';

class MortgageGuide extends Component {
  render() {
    const isMobile = window.innerWidth < 992;
    const extraSubtitles = [
      {
        strongTag: "Seguro de desgravámen:",
        normalText:
          "Busca garantizar tu tranquilidad y la de tu familia, cubriendo el saldo adeudado de tu crédito en caso de muerte natural o accidental, invalidez total y permanente por accidente o enfermedad.",
      },
      {
        strongTag: "Seguro contra incendio y terremoto:",
        normalText: "Busca proteger tu vivienda de eventos catastróficos.",
      },
    ];
    let content = (
      <div className="page-content fit-footer mortgage-guide" id="topsection">
        <HeroImage />
        <main>
          {!isMobile && (
            <div className="sidebar">
              <div className="component">
                <SideMenu />
              </div>
            </div>
          )}
          <div
            className="article text-line-height-26"
            data-testid="sidebarMobileTestId"
          >
            {isMobile && (
              <div className="bp-px-16">
                <SideMenu />
              </div>
            )}
            <div
              id="introduccion"
              className="section-title bp-mb-56 mobile-width bp-px-16"
            >
              <p className="text-prelo-slab-semibold text-blue-500 main-title">
                Introducción
              </p>
              <p className="text-dark-grey-500 text-prelo-medium bp-mt-16">
                En Banco Pichincha nos preocupamos por ti y por ofrecerte la
                mejor propuesta de valor.
              </p>
              <div className="benefits">
                <div className="box-container">
                  <Box
                    icon="access_time"
                    text="Proceso ágil"
                    subtext="Solicita tu crédito de manera simple y rápida"
                  />
                </div>
                <div className="box-container">
                  <Box
                    icon="notifications"
                    text="Monitorea el avance"
                    subtext="Te informaremos en todo momento del estado de tu solicitud"
                  />
                </div>
                <div className="">
                  <Box
                    icon="people_outline"
                    text="Acompañamiento"
                    subtext="Contarás con nuestra asesoría personalizada a lo largo del camino"
                  />
                </div>
              </div>
              <p className="text-dark-grey-500 text-prelo-medium bp-mt-40 desktop">
                Por esa razón creemos que esta guía te ayudará a tomar las
                mejores decisiones para que logres el sueño de tu casa.
              </p>
            </div>
            <div id="opciones_credito" className="section-title mobile-width">
              <div className="bp-px-16">
                <p className="text-prelo-slab-semibold text-blue-500 main-title">
                  Nuestras opciones para que alcances tu sueño
                </p>
                <p className="text-dark-grey-500 text-prelo-medium bp-mt-16">
                  Si necesitas comprar una casa y/o departamento, estás en el
                  lugar correcto. Te ofrecemos 2 caminos para hacer realidad tu
                  sueño.
                </p>
                <p className="text-prelo-medium bp-mt-16 section-subtitle">
                  El camino 1: Vivienda nueva o usada
                </p>
                <p className="text-dark-grey-500 text-prelo-medium bp-mt-16">
                  Si deseas adquirir una vivienda, ya sea nueva o usada, este es
                  el crédito ideal para ti.
                </p>
                <div className="checklist bp-mt-16">
                  <CheckListElement
                    text="Tasa de interés anual referencial"
                    percentage={`${NEW_USED_RATE}%.`}
                  />
                  <CheckListElement text="Te financiamos hasta el 80%  del valor de la vivienda, incluido gastos legales, avalúos y contribución especial SOLCA." />
                  <CheckListElement text="El valor mínimo  de la vivienda debe ser $15.000." />
                  <CheckListElement text="Plazo de pago de 3 a 20 años." />
                </div>
                <p className="text-prelo-medium bp-mt-16 section-subtitle">
                  El camino 2: Vivienda de interés Público y Social (VIP y VIS)
                </p>
                <p className="text-dark-grey-500 text-prelo-medium bp-mt-16">
                  Si no posees una vivienda y deseas adquirir tu primera casa
                  con una tasa especial, estos son los créditos para ti:
                </p>
                <div className="checklist bp-mt-16">
                  <CheckListElement
                    text="Tasa de interés anual referencial de"
                    percentage="4.87%."
                  />
                  <CheckListElement text="Te financiamos hasta el 100% del valor de la vivienda, incluido gastos legales, avalúos y contribución especial SOLCA." />
                  <CheckListElement text="Plazo de pago de 20 a 25 años." />
                </div>
              </div>
              <div className="message-box bp-mt-32">
                <p className="text-dark-grey-500 text-title text-prelo-bold title">
                  Condiciones a tomar en cuenta en ambos créditos
                </p>
                <div className="list">
                  <ul className="bp-pl-24">
                    <li>
                      La vivienda que deseas adqurir debe estar calificada como
                      VIP (Vivienda de Interés Público) o VIS (Vivienda de
                      Interés Social).
                    </li>
                    <li>La vivienda debe ser nueva por estrenar.</li>
                    <li>
                      El ingreso mensual del solicitante no debe superar los {MAX_INCOME_VIP}.
                      En caso solicite con su cónyuge, los ingresos
                      mensuales combinados no deberán superar dicho monto.
                    </li>
                    <li>Sobre el valor de la vivienda a financiar:</li>
                  </ul>
                </div>
                <div className="list">
                  <ul className="bp-pl-38">
                    <li>
                      <strong>Vivienda de Interés Social:</strong> Viviendas
                      desde {MIN_AMOUNT_VIS} hasta {MAX_AMOUNT_VIS} (el área mínima de la
                      vivienda debe ser de 57m2).
                    </li>
                    <li>
                      <strong>Vivienda de Interés Público:</strong> Viviendas
                      desde {MIN_AMOUNT_VIP} hasta {MAX_AMOUNT_VIP}.
                    </li>
                  </ul>
                </div>
                <p className="text-dark-grey-500 text-prelo-medium bp-mt-16 text-font-size-12 bp-ml-8">
                  *En ambos créditos el valor del m2 debe ser menor a {SQUARE_METER_VAL}.
                </p>
              </div>
            </div>
            <div id="requisitos" className="section-title bp-mt-56">
              <div className="bp-px-16 mobile-width">
                <p className="text-prelo-slab-semibold text-blue-500 main-title">
                  Los requisitos
                </p>
                <p className="text-dark-grey-500 text-prelo-medium bp-mt-16">
                  Debes tomar en cuenta los siguientes requisitos para solicitar
                  tu crédito que aplican para todos los intervinientes:
                </p>
                <div className="checklist bp-mt-16">
                  <CheckListElement text="Solicitud completa y firmada" />
                  <CheckListElement text="Copia de cédula de identidad y papeleta de votación." />
                  <CheckListElement text="Declaración del Impuesto a la Renta de los 3 últimos años." />
                  <CheckListElement text="Copias de impuestos prediales y/o copias de matrículas de vehículos en caso de poseerlos." />
                  <CheckListElement text="En el caso de poseer ingresos adicionales, estos deben estar justificados con documentos." />
                </div>
              </div>
              <div className="message-box-row mobile-width bp-mt-16">
                <div className="message-box double-box bp-mr-16">
                  <p className="text-dark-grey-500 text-title text-prelo-bold text-center">
                    Si trabajas como dependiente
                  </p>
                  <div className="list">
                    <ul className="bp-pl-24">
                      <li>
                        Último rol de pagos con firma y sello de tu empleador.
                      </li>
                      <li>
                        Certificado de ingresos actualizado con firma, sello,
                        tiempo de trabajo y cargo.
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="message-box double-box bp-ml-16">
                  <p className="text-dark-grey-500 text-title text-prelo-bold text-center">
                    Si trabajas como independiente
                  </p>
                  <div className="list">
                    <ul className="bp-pl-24">
                      <li>Copia de RUC o RISE.</li>
                      <li>
                        Declaraciones del IVA de los últimos 3 meses o última
                        declaración semestral.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="bp-px-16 mobile-width bp-mt-96">
                <p className="text-prelo-medium bp-mt-16 section-subtitle">
                  Beneficios que te brindamos al escoger nuestros créditos
                </p>
                <div className="benefits">
                  <div className="box-container">
                    <Box
                      icon="monetization_on"
                      subtext="Incluye el financiamiento de avalúo, impuestos de ley y gastos legales"
                    />
                  </div>
                  <div className="box-container">
                    <Box
                      icon="timelapse"
                      subtext="3 meses de gracia para empezar a pagar tu crédito. Aplica para Vivienda nueva o usada"
                    />
                  </div>
                  <div className="">
                    <Box
                      icon="calendar_today"
                      subtext="Podrás realizar abonos y pre-cancelaciones sin recargo"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div id="amortizaciones" className="section-title bp-mt-72">
              <div className="bp-px-16 mobile-width">
                <p className="text-prelo-slab-semibold text-blue-500 main-title">
                  Elige cómo amortizar tu deuda
                </p>
                <p className="text-dark-grey-500 text-prelo-medium bp-mt-16">
                  Es importante que definas cómo pagarás la deuda que vas a
                  contraer al obtener tu crédito, siendo clave aquí el concepto
                  de amortización.
                </p>
                <p className="text-dark-grey-500 text-prelo-medium bp-mt-32 bp-mb-4">
                  <strong>¿Qué es la amortización?</strong>
                </p>
                <p className="text-dark-grey-500 text-prelo-medium bp-mt-4 bp-mb-4">
                  Es el proceso de distribución de una deuda en un período de
                  tiempo determinado, hasta que esta sea pagada en su totalidad.
                </p>
                <p className="text-dark-grey-500 text-prelo-medium bp-mt-4 desktop">
                  En Banco Pichincha te brindamos 2 caminos para amortizar tu
                  deuda:
                </p>
                <p className="text-dark-grey-500 text-prelo-medium bp-mt-4 mobile">
                  En Banco Pichincha te brindamos <strong>2 caminos</strong>{" "}
                  para amortizar tu deuda:
                </p>
              </div>
              <div className="message-box mobile-width bp-mt-24 bp-py-4">
                <div className="checklist bp-mt-16 pl-0">
                  <CheckListElement
                    text="El valor de las cuotas que pagarás se mantendrá fijo en el tiempo."
                    boldStart="Sistema de amortización francés:"
                    customIcon="1"
                  />
                  <CheckListElement
                    text="El valor de las cuotas que pagarás será variable e irá decreciendo en el tiempo."
                    boldStart="Sistema de amortización alemán:"
                    customIcon="2"
                  />
                </div>
                <p className="text-dark-grey-500 text-prelo-medium bp-mt-16 text-font-size-12 bp-ml-56 bp-mb-8 text-line-height-19">
                  *El reajuste en la tasa de interés puede variar el monto de
                  las cuotas en ambos tipos de amortización.
                </p>
              </div>
              <div className="bp-mx-16 mobile-width action-text text-prelo-strong">
                <a
                  href="https://www.pichincha.com/portal/blog/post/tabla-amortizacion-francesa-alemana-diferencias"
                  target="_blank"
                  rel="noreferrer"
                >
                  Conoce más sobre los sistemas de amortización
                </a>
              </div>
            </div>
            <div id="gastos_valores" className="section-title">
              <div className="bp-px-16 mobile-width">
                <p className="text-prelo-slab-semibold text-blue-500 main-title">
                  Gastos y valores a considerar
                </p>
                <p className="text-dark-grey-500 text-prelo-medium bp-mt-16">
                  Hay una serie de gastos que debes considerar al solicitar un
                  crédito para la compra de una vivienda. Estos son:
                </p>
                <div className="benefits column">
                  <div className="box-container">
                    <Box
                      icon="monetization_on"
                      text="Gastos de avalúo"
                      subtext="Se genera al elaborarse el informe pericial que determina el valor del inmueble. El costo puede variar de acuerdo a la ubicación de la vivienda que deseas adquirir."
                      twoColumns={true}
                    />
                  </div>
                  <div className="box-container">
                    <Box
                      icon="work_outline"
                      text="Gastos legales"
                      subtext="El trámite de compraventa e hipoteca genera costos legales adicionales: impuestos de transferencia de dominio, notaría, estudio jurídico, inscripciones y otros.."
                      twoColumns={true}
                    />
                  </div>
                  <div className="box-container">
                    <Box
                      icon="child_care"
                      text="Contribución SOLCA"
                      subtext="Impuesto obligatorio del 0.5% que se aplica al valor del monto total del crédito para financiar  la atención integral del cáncer en Ecuador."
                      twoColumns={true}
                    />
                  </div>
                  <div className="box-container">
                    <Box
                      icon="beenhere"
                      text="Seguros"
                      subtext="Son obligatorios de acuerdo a normativa vigente y se cobran mensualmente. Estos son los siguientes:"
                      twoColumns={true}
                      extraSubtitles={extraSubtitles}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
        <CallToActionSecondary />
        <div className="mobile">
          <FloatButton icon={arrowUpward} target="#topsection" />
        </div>
      </div>
    );

    return (
      <div className="wrapper-content">
        <Navbar currentPage={true} />
        {content}
        <FooterBP />
        <Footer />
      </div>
    );
  }
}

export default MortgageGuide;
