import React, { useState } from "react";
import {
  FOOTERBP_CONTENT,
  PHONE_MIN_WIDTH,
} from "../../common/helpers/constants";
import RecaptchaDisclaimer from "../RecaptchaDisclaimer/RecaptchaDisclaimer";
import useWindowSize from "../Shared/hooks/UseWindowSize";

import "./FooterBP.scss";

let data = FOOTERBP_CONTENT;
function FooterBP() {
  const [sectionContent, setSectionContent] = useState(data);
  const [width] = useWindowSize();

  const handleOpenState = (i: number) => {
    let newArr = [...data];
    newArr[i].openState = !sectionContent[i].openState;
    setSectionContent(newArr);
  };

  const handleMobileRender = (index: number) => {
    if (width < PHONE_MIN_WIDTH) {
      if (sectionContent[index].openState) {
        return <pichincha-icon>keyboard_arrow_up</pichincha-icon>;
      } else {
        return <pichincha-icon>keyboard_arrow_down</pichincha-icon>;
      }
    }
  };

  return (
    <div className="footer-Bp" data-testid="footerBp-id">
      <div className="body">
        <div className="content">
          <div className="section-0">
            <pichincha-typography
              variant="subtitle"
              color="darkGrey"
              weight="bold"
            >
              Contáctanos
            </pichincha-typography>
            <a className="contact-number" href="tel:+593 22999 999">
              <pichincha-icon color="blue" weight_color="500" size="14px">
                phone
              </pichincha-icon>
              <label>(02) 2999 999</label>
            </a>
            <div className="link-section">
              <pichincha-link
                href="https://www.pichincha.com/portal/sugerencias-y-reclamos"
                target="_blank"
              >
                Sugerencias y reclamos
              </pichincha-link>
            </div>
            <div className="link-section">
              <pichincha-link
                href="https://www.pichincha.com/portal/geolocalizacion"
                target="_blank"
              >
                Ubica una agencia
              </pichincha-link>
            </div>
          </div>
          {sectionContent.map((object, i) => {
            return (
              <div className={`section-${i + 1}`} key={`section-${i + 1}`}>
                {width < PHONE_MIN_WIDTH && <hr />}
                <div
                  className="title-section"
                  onClick={() => handleOpenState(i)}
                  data-testid={`tiltesection${i}TestId`}
                >
                  <pichincha-typography
                    variant="subtitle"
                    color="darkGrey"
                    weight="bold"
                  >
                    {object.title}
                  </pichincha-typography>
                  {handleMobileRender(i)}
                </div>
                {width < PHONE_MIN_WIDTH ? (
                  sectionContent[i].openState && (
                    <div className="links-section">
                      {object.link.map((link, linkcount) => {
                        return (
                          <div
                            className="link-section"
                            key={`link-${linkcount}`}
                          >
                            <pichincha-link href={link.href} target={"_blank"}>
                              {link.title}
                            </pichincha-link>
                          </div>
                        );
                      })}
                    </div>
                  )
                ) : (
                  <div className="links-section" key={`link-section-${i}`}>
                    {object.link.map((link, linkcount) => {
                      return (
                        <div className="link-section" key={`link-${linkcount}`}>
                          <pichincha-link href={link.href} target={"_blank"}>
                            {link.title}
                          </pichincha-link>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            );
          })}
        </div>
        <RecaptchaDisclaimer />
      </div>
    </div>
  );
}

export default FooterBP;
