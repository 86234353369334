import { PHONE_MIN_WIDTH } from "../../common/helpers/constants";
import Button from "../Button";
import useWindowSize from "../Shared/hooks/UseWindowSize";
import LivingPlaceCardDesktop from "./LivingPlaceCardDesktop";
import LivingPlaceCardMobile from "./LivingPlaceCardMobile";

interface LivingPlace {
  id: string;
  listId: string;
  title: string;
  description: string;
  subtitle: string;
  percentage: string;
  benefits: Array<string>;
  animation: string;
}

function LivingPlaceCard({
  id,
  listId,
  title,
  description,
  subtitle,
  percentage,
  benefits,
  animation,
}: LivingPlace) {
  const [width] = useWindowSize();
  return (
    <div
      className={`card wow animated ${animation} `}
      style={{
        transitionDelay: "0.6s",
        transitionDuration: "0.4s",
        animationDuration: "1s",
        animationFillMode: "both",
        animationDelay: "0s",
      }}
      data-wow-duration="3s"
    >
      <div className="body">
        {width > PHONE_MIN_WIDTH ? (
          <LivingPlaceCardDesktop
            title={title}
            description={description}
            benefits={benefits}
            listId={listId}
          />
        ) : (
          <LivingPlaceCardMobile
            title={title}
            description={description}
            subtitle={subtitle}
            percentage={percentage}
            benefits={benefits}
            listId={listId}
          />
        )}
      </div>
      <div className="button-section">
        <Button
          id={id}
          size="large"
          color="secondary"
          text="Ver más"
          handleClick={() =>
            (window.location.href =
              "/guia_hipotecaria?section=opciones_credito")
          }
        />
      </div>
    </div>
  );
}

export default LivingPlaceCard;
