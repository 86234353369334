import React, { Component } from "react";
import { connect, ConnectedProps } from "react-redux";

import actions from "../../../actions/";
import { ReduxState } from "../../../common/store";
import Button from "../../Button";
import Box from "../../BenefitsBox/Box";
import AdviserField from "./AdviserField";
import BuilderFields from "./BuilderFields";
import "./WelcomePage.scss";
import AgencyFields from "./AgencyFields";
import { sendGTMUserEvent } from "../../../common/helpers/gtmEvents";

class WelcomePage extends Component<ReduxProps> {
  constructor(props: ReduxProps) {
    super(props);
    this.start = this.start.bind(this);
  }
  componentDidMount() {
    sendGTMUserEvent(this.props.channel, "asesor", this.props.userType);
  }

  start() {
    this.props.setStep(1);
    window.scrollTo(0, 0);
  }

  render() {
    const {
      channel,
      builderStateError,
      projectStateError,
      agencyStateError,
      adviserStateError,
    } = this.props;
    const twoColumnsFields = ["proyectos", "agencia"].includes(channel);

    const fieldsMapper: any = {
      proyectos: {
        firstFieldState: builderStateError,
        secondFieldState: projectStateError,
      },
      agencia: {
        firstFieldState: adviserStateError,
        secondFieldState: agencyStateError,
      },
    };

    const disableStartButton =
      twoColumnsFields &&
      (fieldsMapper[channel].firstFieldState ||
        fieldsMapper[channel].secondFieldState);
    let fieldsWrapperClassName = "";
    let buttonContainerClassName = "";

    if (twoColumnsFields) {
      fieldsWrapperClassName = "lg-ml-40";
      buttonContainerClassName = "lg-mt-24 px-0 md-pt-48";
    }

    const handleClick = disableStartButton ? undefined : this.start;
    return (
      <div className="welcome-page-express-request step-page">
        <pichincha-grid container justify="center">
          <pichincha-grid item xs="12">
            <div className="titleCheck">
              {"El camino a tu sueño comienza aquí"}
            </div>
          </pichincha-grid>
          <pichincha-grid item xs="12">
            <div className="subtitle">
              Completa el formulario en tan solo <strong>3 pasos</strong> y un
              asesor se contactará contigo para guiarte hacia tu nuevo hogar. Te
              pediremos los siguientes datos:
            </div>
          </pichincha-grid>
          <pichincha-grid item xs="12">
            <div className="steps-container">
              <pichincha-grid container justify="space-between" spacing={1}>
                <pichincha-grid item xs="12" lg="3">
                  <Box
                    icon="person"
                    text="Datos personales"
                    subtext=""
                    animateClass="-express"
                  />
                </pichincha-grid>
                <pichincha-grid item xs="12" lg="3">
                  <Box
                    icon="work_outline"
                    text="Datos laborales"
                    subtext=""
                    animateClass="-express"
                  />
                </pichincha-grid>
                <pichincha-grid item xs="12" lg="3">
                  <Box
                    icon="house"
                    text="Datos del crédito"
                    subtext=""
                    animateClass="-express"
                  />
                </pichincha-grid>
              </pichincha-grid>
            </div>
          </pichincha-grid>
          <div className={`adviser-field ${fieldsWrapperClassName}`}>
            {channel !== "agencia" && <AdviserField />}
            <BuilderFields />
            <AgencyFields />
          </div>
          <pichincha-grid item xs="12">
            <div className={`button-container ${buttonContainerClassName}`}>
              <pichincha-grid container justify="center">
                <pichincha-grid item xs="12" md="4">
                  <Button
                    size="large"
                    text="Empezar"
                    handleClick={handleClick}
                    id="startExpressRequestButton"
                    color="primary"
                    testId="startExpressRequestButtonTestId"
                    disabled={disableStartButton}
                  ></Button>
                </pichincha-grid>
              </pichincha-grid>
            </div>
          </pichincha-grid>
        </pichincha-grid>
      </div>
    );
  }
}

const mapStateToProps = (state: ReduxState) => {
  const { expressRequest, site, channels } = state;
  const { currentStep } = expressRequest;

  const {
    builderStateError,
    projectStateError,
    agencyStateError,
    adviserStateError,
  } = channels;
  const { channel, userType } = site;
  return {
    currentStep,
    channel,
    builderStateError,
    projectStateError,
    agencyStateError,
    adviserStateError,
    userType,
  };
};

const { setStep, updateInputContent } = actions;
const connector = connect(mapStateToProps, { setStep, updateInputContent });
type ReduxProps = ConnectedProps<typeof connector>;
export default connector(WelcomePage);
