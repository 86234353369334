import React, { Component } from "react";

const titlePage = "Los resultados de tu cálculo";

export class ResultsTitle extends Component {
  render() {
    return (
      <div className="title-container bp-mb-24" id="title-results">
        <div className="titleCheck">{titlePage}</div>
      </div>
    );
  }
}

export default ResultsTitle;
