import React, { Component } from "react";
import UnauthorizedChannel from "../ExpressRequest/AgencyResponsePages/UnauthorizedChannel";
import Footer from "../Footer/Footer";
import Navbar from "../navbar/Navbar";

export class UnauthorizedPage extends Component {
  render() {
    return (
      <div className="wrapper-content">
        <Navbar />
        <div className="page-content fit-footer">
          <div className="mt-176">
            <UnauthorizedChannel />
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}

export default UnauthorizedPage;
