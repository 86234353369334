import React from "react";
import "./LivingPlaces.scss";
import LivingPlaceCard from "./LivingPlaceCard";
import {MAX_AMOUNT_VIP, NEW_USED_RATE} from "../../common/helpers/constants";

const title = "Descubre la opción de crédito que se ajusta más a tu necesidad";
const livingPlaces = [
  {
    listId: "newUsedList",
    id: "homeCardMyPichinchaHouseViewMoreLink",
    title: "Vivienda nueva o usada",
    description:
      "Crédito destinado a la compra de una casa o departamento, ya sea nuevo o usado.",
    subtitle: "Tasa de interés anual referencial:",
    percentage: `${NEW_USED_RATE}%`,
    benefits: [
      `Tasa de interés anual referencial: ${NEW_USED_RATE}%`,
      "Te financiamos hasta el 80%  del valor de la vivienda, incluido gastos legales, avalúos y contribución especial SOLCA.",
      "Plazo de pago de 3 a 20 años.",
    ],
    animation: "fadeInLeftSmall",
  },
  {
    listId: "vipVisList",
    id: "homeCardVipVisViewMoreLink",
    title: "Vivienda de interés público y social",
    description:
      `Créditos destinados a la compra de tu primera vivienda, nueva por estrenar, de hasta ${MAX_AMOUNT_VIP}.`,
    subtitle: "Tasa de interés anual referencial:",
    percentage: "4.87%",
    benefits: [
      "Tasa de interés anual referencial: 4.87%",
      "Te financiamos hasta el 100% del valor de la vivienda, incluido gastos legales, avalúos y contribución especial SOLCA.",
      "Plazo de pago de 20 a 25 años.",
    ],
    animation: "fadeInRightSmall",
  },
];

function LivingPlaces() {
  return (
    <div className="living-places">
      <h2
        style={{ textAlign: "center", animationDuration: "1s" , fontWeight:"400", fontFamily:'preloslab-book', margin: "51px 24px 48px 24px"}}
        className="animated fadeIn"
      >
        {title}
      </h2>
      <div className="living-places-body">
        {livingPlaces.map((livingPlace, index) => (
          <LivingPlaceCard {...livingPlace} key={index} />
        ))}
      </div>
    </div>
  );
}

export default LivingPlaces;
