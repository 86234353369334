import React, { Component } from "react";

interface TitleProps {
  content?: string;
  className?: string;
}

class Title extends Component<TitleProps> {
  render() {
    return <h1 className={`title ${this.props.className ? this.props.className : ''}`}>{this.props.content}</h1>;
  }
}

export default Title;
