import React from 'react'
import Spinner from '../Spinner'
import { ButtonWrapper, ButtonProps } from './styles'

const Button: React.FC<ButtonProps> = ({
  children,
  disabled,
  loading,
  width,
  variant,
  ...props
}) => {
  return (
    <ButtonWrapper width={width} variant={variant || 'primary'}>
      <button
        id={props.id}
        disabled={disabled}
        {...props}
        className={`${disabled && 'disabled'} ${loading && 'disabled'} ${props.className
          }`}
      >
        {loading ? <Spinner /> : children}
      </button>
    </ButtonWrapper>
  )
}

export default Button
