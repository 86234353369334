import { STEPS_MODULE } from "../../common/helpers/constants";
import image_step from "../../assets/img/steps-new-home.svg";
import "./VerticalSteps.scss";
import { StepsNumber } from "../Shared/Components/StepsNumber/StepsNumber";
import useWindowSize from "../Shared/hooks/UseWindowSize";
import Button from "../Button";

export interface TextMsgObject {
  first: string;
  second: string;
  third: string;
}

const idHomeRequestCreditButton = "homeRequestCreditButton";
const idHomeSimulateCreditButton = "homeSimulateCreditButton";
const Section = (props: {
  title: string;
  msg: string[];
  stepNumber: string;
}) => {
  const TextStep = (prop: { msg: string[] }) => {
    if (prop.msg.length > 1) {
      return (
        <div className="steps-container__msgs-container__flex__steps__step-section__content-msg">
          <pichincha-typography weight_color="400">
            {prop.msg[0]}
            <span
              className="link-bp"
              onClick={() => (window.location.href = "/guia_hipotecaria")}
            >
              {prop.msg[1]}
            </span>
            {prop.msg[2]}
          </pichincha-typography>
        </div>
      );
    }
    return (
      <div className="steps-container__msgs-container__flex__steps__step-section__content-msg">
        <pichincha-typography weight_color="400">
          {props.msg}
        </pichincha-typography>
      </div>
    );
  };

  return (
    <div className="steps-container__msgs-container__flex__steps__step-section">
      <div className="steps-container__msgs-container__flex__steps__step-section__title">
        <StepsNumber stepNumber={props.stepNumber}></StepsNumber>
        <div className="steps-container__msgs-container__flex__steps__step-section__title__step_text">
          <pichincha-typography
            variant="title"
            color="blue"
            weight="normal"
            weight_color="500"
          >
            {props.title}
          </pichincha-typography>
        </div>
      </div>
      <TextStep msg={props.msg} />
    </div>
  );
};

export default function VerticalSteps() {
  const [width] = useWindowSize();

  return (
    <div data-testid="stepsContentTestId">
      <div
        id="stepsContainer"
        className="steps-container wow animated fadeIn"
        data-wow-duration="4s"
      >
        {width >= 1200 && (
          <div className="steps-container__img">
            <img
              src={image_step}
              width={width > 1200 ? 605 : width - 600}
              alt=""
            />
          </div>
        )}
        <div className="steps-container__msgs-container">
          <div className="steps-container__msgs-container__title">
            <pichincha-typography variant="h2">
              El viaje al sueño de tu hogar en 5 pasos
            </pichincha-typography>
          </div>
          <div className="steps-container__msgs-container__flex">
            {width >= 1200 && (
              <div className="steps-container__msgs-container__flex__steps-line"></div>
            )}
            <div className="steps-container__msgs-container__flex__steps">
              {STEPS_MODULE.map((step, index) => (
                <Section
                  key={index + 1}
                  title={`${step.TITLE}`}
                  msg={step.MSG}
                  stepNumber={`${(index + 1).toString()}`}
                />
              ))}
            </div>
          </div>
          <div className="steps-container__msgs-container__buttons">
            <div className="steps-container__msgs-container__buttons__desk-button-left">
              <Button
                id={idHomeRequestCreditButton}
                testId={idHomeRequestCreditButton}
                size="large"
                color="secondary"
                text="Simula tú crédito"
                handleClick={() => (window.location.href = "/calculadora")}
              />
            </div>

            <div className="steps-container__msgs-container__buttons__desk-button-right">
              <Button
                id={idHomeSimulateCreditButton}
                testId={idHomeSimulateCreditButton}
                size="large"
                color="primary"
                text="Comenzar ahora"
                handleClick={() => (window.location.href = "/precalificacion")}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
