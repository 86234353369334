import React, { Component } from 'react';

class HeroImage extends Component {
  render() {
    return (
      <div className="hero">
        <div className="guide-title">
          <div className="desktop">
          <pichincha-typography 
              variant="h2"
              color="blue"
              weight="bold"
              weight_color="500"
            >
              La guía para adquirir tu crédito
            </pichincha-typography>
            <pichincha-typography 
              variant="h2"
              color="blue"
              weight="bold"
              weight_color="500"
            >
              hipotecario con Banco Pichincha
            </pichincha-typography>
          </div>
          <div className="mobile">
          <pichincha-typography 
              variant="h2"
              color="blue"
              weight="bold"
              weight_color="500"
            >
              La guía para adquirir tu crédito hipotecario con Banco Pichincha
            </pichincha-typography>
          </div>
        </div>
      </div>
    )
  }
}

export default HeroImage
