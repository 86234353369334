import { AnyAction } from 'redux';
import {
  fingerprintCodeStepInitialState,
  INITIAL_STATE,
  LoginState,
  transactionalContactStepInitialState,
} from '.';
import { validateInputContent } from '../../common/helpers/functions';
import { PATHS } from '../../common/helpers/paths';
import * as texts from '../../common/texts/expressApplication';
import * as CONSTANTS from '../../common/helpers/constants';

export const handleCifFailed = (action: AnyAction, state: LoginState) => {
  const lastTry = state.cifFailuresCount > 3;
  const { requestStatus } = action;
  let redirectToNotClientPage = 0;
  let idCardInputStatus = 'retry';
  let cifSuccess = false;
  const clientNotFound = requestStatus === 404 || requestStatus === 204;
  if (clientNotFound) {
    redirectToNotClientPage = 2;
    idCardInputStatus = 'success';
    cifSuccess = true;
  }
  return {
    ...state,
    loading: false,
    cif_error: true,
    stepLogin: redirectToNotClientPage,
    showBlockPage: requestStatus === 423,
    showSomethingWentWrongPage:
      [201, 423, 404, 204].indexOf(requestStatus) < 0 && lastTry,
    cifFailuresCount: clientNotFound
      ? state.cifFailuresCount
      : state.cifFailuresCount + 1,
    startButtonText: clientNotFound ? state.startButtonText : 'Reenviar',
    idCardLoginStateError: clientNotFound ? state.idCardLoginStateError : true,
    fingerprintCode: '',
    fingerprintCodeErrorHelper: '',
    fingerprintCodeState: 'normal',
    fingerprintCodeStateError: true,
    authorizeFingerprintCode: true,
    idCardInputStatus,
    cifSuccess,
    idCardLoginState: clientNotFound ? 'normal' : 'error',
    idCardLoginErrorHelper: clientNotFound
      ? ''
      : 'No hemos podido validar tu número de cédula, vuelve a intentarlo',
    completedLoginIdCard: clientNotFound ? state.completedLoginIdCard : false,
  };
};

export const handleSetLoginStep = (action: AnyAction, state: LoginState) => {
  return {
    ...state,
    stepLogin: action.newStep,
    completedClientData:
      action.newStep === 1 && !state.completedClientData
        ? true
        : state.completedClientData,
    completedNotClientData:
      action.newStep === 2 && !state.completedNotClientData
        ? true
        : state.completedNotClientData,
    showAlert: false,
    showAlertTransactional: false,

    cifFailuresCount: 0,
    startButtonText: INITIAL_STATE.startButtonText,
    transactionalRequestFailuresCount: 0,
  };
};

export const handleTransactionalContactFailed = (
  action: AnyAction,
  state: LoginState
) => {
  const lastTryTransactionalContact =
    state.transactionalRequestFailuresCount > 3;
  const errorService = action.requestStatus !== 206;
  const getTransactionalRequestFailuresCount = () => {
    if (errorService) {
      state.transactionalRequestFailuresCount += 1;
    }
    return state.transactionalRequestFailuresCount;
  };
  return {
    ...state,
    phoneLogin: '',
    phoneLoginStateError: true,
    emailLogin: '',
    emailLoginStateError: true,
    showSomethingWentWrongPage:
      [206].indexOf(action.requestStatus) < 0 && lastTryTransactionalContact,
    transactionalRequestFailuresCount: getTransactionalRequestFailuresCount(),
    showAlertTransactional: true,
    transactionalRequestFailureMessage: !errorService
      ? texts.INCORRECT_CONTACT_ERROR_MESSAGE_TEXT
      : texts.GENERIC_ERROR_MESSAGE_TEXT,
    loading: false,
    clientContinueButtonText: errorService
      ? 'Reenviar'
      : INITIAL_STATE.clientContinueButtonText,
  };
};

export const hanldeResirectToExpressApplication = (
  action: AnyAction,
  state: LoginState
) => {
  if (state.otpJWTValidated || state.fingerprintCodeValidated) {
    return { ...state, redirectTo: PATHS.expressApplication };
  }
  return {
    ...state,
    showBlock: action.requestStatus === 429 ? true : false,
    wrongCode: '1',
    otpStateButton: 'normal',
    showAlert: true,
    showAlertTransactional: false,
    otpStatus: 'error',
  };
};

export const handleUpdateInputIdCardLogin = (
  action: AnyAction,
  state: LoginState
) => {
  const {
    state: idCardLoginState,
    stateError: idCardLoginStateError,
    errorHelper: idCardLoginErrorHelper,
  } = validateInputContent(CONSTANTS.IDENTIFICATION_FIELD, action.newValue);

  if (state.completedLoginIdCard) {
    return INITIAL_STATE;
  }

  return {
    ...state,
    idCardLogin: action.newValue,
    idCardLoginState,
    idCardLoginStateError,
    idCardLoginErrorHelper,
    completedLoginIdCard:
      idCardLoginState === 'error' ? false : state.completedLoginIdCard,
    stepLogin: 0,
    ...transactionalContactStepInitialState,
    ...fingerprintCodeStepInitialState,
    cifSuccess: false,
    idCardInputStatus: '',
    showAlert: false,
    showAlertTransactional: false,
  };
};
