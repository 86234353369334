import React, { Component } from "react";
import { connect, ConnectedProps } from "react-redux";
import Cookies from "js-cookie";

import { ReduxState } from "../../common/store";
import actions from "../../actions";

import Navbar from "../navbar/Navbar";
import Footer from "../Footer/Footer";
import FormContainer from "./FormContainer/FormContainer";
import ModalProducts from "../ModalProducts";
import SomethingWentWrong from "../Errors/SomethingWentWrong";
import Unauthorized from "../Errors/Unauthorized";
import AgencyExpressRequestFailed from "./AgencyResponsePages/AgencyExpressRequestFailed";
import AgencyExpressRequestSuccess from "./AgencyResponsePages/AgencyExpressRequestSuccess";
import LoadingPage from "./LoadingPage";
import OfferPage from "./PrequalificationResponses/OfferPage";
import RejectedPage from "./PrequalificationResponses/RejectedPage";
import {
  GUID,
  CLIENT_IDENTIFICATION_TYPE,
  FACEPHI_VALIDATED_IMAGE_KEY,
} from "../../common/helpers/constants";
import { PATHS } from "../../common/helpers/paths";
import { CHANNELS_WITH_WELCOME_PAGE } from "../../common/texts/expressApplication";
import { capitalizeName } from "../../common/helpers/functions";
import { sendGTMUserEvent } from "../../common/helpers/gtmEvents";

class ExpressRequest extends Component<ReduxProps> {
  constructor(props: ReduxProps) {
    super(props);
    this.closeModal = this.closeModal.bind(this);
    // @ts-ignore
    this.pageContentRef = React.createRef();
  }

  componentDidMount() {
    this.props.getMortgageProduct("vip-vis");
    this.props.getMortgageProduct("new-used");

    const {
      channel,
      jwt,
      otpSuccess,
      fingeprintCodeSuccess,
      payload: sentPayload,
    } = this.props;
    const nextStep = Number(!CHANNELS_WITH_WELCOME_PAGE.includes(channel));
    const validSession = jwt && (otpSuccess || fingeprintCodeSuccess);
    const facephiValidImageToken = Cookies.get(FACEPHI_VALIDATED_IMAGE_KEY);

    if (validSession && !facephiValidImageToken) {
      this.props.getNamesCivilRegistration(
        this.props.idCardLogin || window.sessionStorage.getItem("id") || "",
        CLIENT_IDENTIFICATION_TYPE,
        channel,
        jwt,
        GUID,
        nextStep
      );
    }

    const actualHeight = window.innerHeight;
    //@ts-ignore
    const pageContentElement = this.pageContentRef.current;
    const elementHeight = pageContentElement?.clientHeight;
    const actualWidth = window.innerWidth;
    if (
      actualWidth < 992 &&
      pageContentElement?.classList.contains("fit-footer")
    ) {
      // @ts-ignore
      const barHeight = elementHeight - actualHeight;
      // @ts-ignore
      pageContentElement.style.setProperty(
        "min-height",
        `calc(100vh - ${barHeight}px)`
      );
    }

    if (facephiValidImageToken && validSession && fingeprintCodeSuccess) {
      this.props.showSuccessFacephi();
    }

    if (
      !facephiValidImageToken &&
      validSession &&
      fingeprintCodeSuccess &&
      sentPayload
    ) {
      window.location.href = PATHS.login;
    }
  }

  closeModal() {
    this.props.toggleModal(false, "");
  }
  mangeEventToSend = (
    showOfferPage: boolean,
    showLoadingPage: boolean,
    showCounterofferPage: boolean,
    channel: string,
    userType: string
  ) => {
    if (showOfferPage && !showLoadingPage) {
      sendGTMUserEvent(channel, "oferta", userType);
    }
    if (showCounterofferPage && !showLoadingPage) {
      sendGTMUserEvent(channel, "contraoferta", userType);
    }
  };

  render() {
    const {
      loading,
      showOfferPage,
      showCounterofferPage,
      showRejectedPage,
      expressApplication_loading,
      channel,
      userType,
    } = this.props;

    const showLoadingPage = loading || expressApplication_loading;
    let content = <FormContainer />;
    let emptyNavbar;

    if (this.props.showSomethingWentWrongPage) {
      content = (
        <div
          className="error-page-margin wentwrong-page"
          data-testid="somethingWentWrongPageTestId"
        >
          <SomethingWentWrong
            handleClick={() => (window.location.href = "/")}
          />
        </div>
      );
    }

    if (this.props.currentStep === 5 || this.props.currentStep === 6) {
      emptyNavbar = true;
    }

    if (
      this.props.expressApplicationAgencyError &&
      !this.props.expressApplication_success
    ) {
      content = (
        <div
          className="bp-mt-120"
          data-testid="agencyExpressRequestFailedPageTestId"
        >
          <AgencyExpressRequestFailed />
        </div>
      );
    }

    if (this.props.expressApplicationAgencySuccess) {
      content = (
        <div
          className="agency-express-request-success-container"
          data-testid="agencyExpressRequestSuccessTestId"
        >
          <AgencyExpressRequestSuccess />
        </div>
      );
      sendGTMUserEvent(channel, "felicitaciones", userType);
    }

    if (this.props.showUnauthorizedPage) {
      content = (
        <div className="error-page-margin" data-testid="unauthorizedPageTestId">
          <Unauthorized handleClick={() => (window.location.href = "/")} />
        </div>
      );
    }

    if (showOfferPage || showCounterofferPage) {
      this.mangeEventToSend(
        showOfferPage,
        showLoadingPage,
        showCounterofferPage,
        channel,
        userType
      );
      content = <OfferPage {...this.props} />;
    }

    if (showRejectedPage) {
      if (!showLoadingPage) {
        sendGTMUserEvent(channel, "rechazadoFiltros", userType);
      }

      content = <RejectedPage name={capitalizeName(this.props.firstName)} />;
    }

    if (showLoadingPage) {
      content = (
        <div data-testid="loadingPageTestId">
          <LoadingPage />
        </div>
      );
    }
    
    return (
      <div className="wrapper-content" data-testid="wrapper-container">
        {this.props.modal.show && (
          <ModalProducts
            close={this.closeModal}
            section={this.props.modal.section}
          />
        )}
        <Navbar conecctionFailNavbar={emptyNavbar} />
        <div
          className="page-content fit-footer"
          id="pageContentId"
          data-testid="pageContentIdTestId"
          // @ts-ignore
          ref={this.pageContentRef}
        >
          {content}
          <Footer />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: ReduxState) => {
  const { calculator, expressRequest, site, civilRegistration, login } = state;
  const { modal } = calculator;
  const {
    showSomethingWentWrongPage,
    showUnauthorizedPage,
    expressApplicationAgencyError,
    expressApplicationAgencySuccess,
    currentStep,
    expressApplication_success,
    showOfferPage,
    showCounterofferPage,
    showRejectedPage,
    expressApplication_loading,
    payload,
  } = expressRequest;
  const { channel, jwt, otpSuccess, fingeprintCodeSuccess, userType } = site;
  const { loading, firstName } = civilRegistration;
  const { idCardLogin } = login;
  return {
    modal,
    showSomethingWentWrongPage,
    showUnauthorizedPage,
    currentStep,
    channel,
    jwt,
    expressApplicationAgencyError,
    expressApplicationAgencySuccess,
    expressApplication_success,
    loading,
    idCardLogin,
    showOfferPage,
    showCounterofferPage,
    showRejectedPage,
    expressApplication_loading,
    otpSuccess,
    fingeprintCodeSuccess,
    firstName,
    userType,
    payload,
  };
};

const {
  toggleModal,
  getMortgageProduct,
  cleanSession,
  getNamesCivilRegistration,
  toggleLoader,
  showSuccessFacephi,
} = actions;

const connector = connect(mapStateToProps, {
  toggleModal,
  getMortgageProduct,
  cleanSession,
  getNamesCivilRegistration,
  toggleLoader,
  showSuccessFacephi,
});

type ReduxProps = ConnectedProps<typeof connector>;

export default connector(ExpressRequest);
