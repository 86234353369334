import React, { Component } from "react";
import Button from "../../Button";

interface SetpsButtonsProps {
  loadingContinue?: boolean;
  currentPage: string;
  textContinue: string;
  disabledContinue: boolean;
  textBack: string;
  disabledBack: boolean;
  idContinue?: string;
  idBack?: string;
  continueHandler: (e?: any) => void;
  backHandler: () => void;
}

class StepsButtons extends Component<SetpsButtonsProps> {
  render() {
    const {
      loadingContinue,
      textContinue,
      disabledContinue,
      textBack,
      disabledBack,
      idContinue,
      idBack,
      continueHandler,
      backHandler,
    } = this.props;

    const continueButtonProps = {
      color: "primary",
      size: "large",
      text: textContinue,
      id: idContinue ? idContinue : `desktopContinue${this.props.currentPage}`,
      handleClick: continueHandler,
      disabled: disabledContinue,
      loading: loadingContinue,
      testId: `${idContinue}TestId`
    };

    const continueButtonMobileProps = {
      ...continueButtonProps,
      id: `mobileContinue${this.props.currentPage}`,
    };

    const continueButton = <Button {...continueButtonProps}></Button>;
    const continueButtonMobile = (
      <Button {...continueButtonMobileProps}></Button>
    );

    const backButtonProps = {
      color: "secondary",
      size: "large",
      text: textBack,
      id: idBack ? idBack : `desktopBack${this.props.currentPage}`,
      handleClick: backHandler,
      testId: "backButtonId",
      disabled: disabledBack,
    };

    const backButtonMobileProps = {
      ...backButtonProps,
      id: `mobileBack${this.props.currentPage}`,
    };

    const backButton = <Button {...backButtonProps}></Button>;
    const backButtonMobile = <Button {...backButtonMobileProps}></Button>;

    return (
      <pichincha-grid container justify="space-between">
        <pichincha-grid item xs="12" lg="6">
          <div className="mobile bp-mb-16" data-testid="continueButtonMobile">
            {continueButtonMobile}
          </div>
          <div className="desktop bp-pr-8" data-testid="backButtonDesktop">
            {backButton}
          </div>
        </pichincha-grid>
        <pichincha-grid item xs="12" lg="6">
          <div className="desktop bp-pl-8" data-testid="continueButtonDesktop">
            {continueButton}
          </div>
          <div className="mobile" data-testid="backButtonMobile">
            {backButtonMobile}
          </div>
        </pichincha-grid>
      </pichincha-grid>
    );
  }
}

export default StepsButtons;
