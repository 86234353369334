import React, { useEffect, useState } from 'react'
import Icon from '../Icon'
import Input from '../Input'
import { SelectOptions, SelectSeparator, SelectWrapper } from './styles'

export interface SelectProps {
  selectOptionList?: Array<OptionInterface>
  onSelect: (item: OptionInterface) => void
  defaultKey?: string
  defaultValue?: string
  label: string
  defaultOption?: OptionInterface
  showNoSelectOption?: boolean
  id?: string
  testId?: string
  disabled?: boolean
  error?: boolean
  errorMessage?: string
  placeHolder?: string
}

export interface OptionInterface {
  value: string
  label: string
}

const Select: React.FC<SelectProps> = ({
  selectOptionList = [],
  onSelect,
  label,
  showNoSelectOption = true,
  defaultOption,
  testId,
  id,
  disabled,
  error,
  errorMessage,
  placeHolder,
  ...props
}) => {
  const [showSelectOptions, setShowSelectOptions] = useState(false)
  const [currentIndex, setCurrentIndex] = useState<number>(0)
  const [optionArray, setOptionArray] = useState([
    {
      label: showNoSelectOption ? 'Seleccione una opción ' : '',
      value: 'NO_SELECTION',
    },
    ...selectOptionList,
  ])

  useEffect(() => {
    if (currentIndex > selectOptionList.length) {
      setCurrentIndex(selectOptionList.length)
      onSelect && onSelect(selectOptionList[selectOptionList.length - 1])
    }

    if (selectOptionList.length > 0) {
      setOptionArray([
        {
          label: showNoSelectOption ? 'Seleccione una opción ' : '',
          value: 'NO_SELECTION',
        },
        ...selectOptionList,
      ])
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectOptionList])

  useEffect(() => {
    optionArray.forEach((item, idx) => {
      if (JSON.stringify(item) === JSON.stringify(defaultOption)) {
        setCurrentIndex(idx)
      }
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultOption])

  return (
    <SelectWrapper {...props}>
      <Input
        labelColor="grayscale500"
        disabled={disabled}
        label={label}
        data-testid={testId}
        testid={testId}
        placeholder={placeHolder ? placeHolder : 'Seleccione una opción'}
        onChange={() => {
          return false
        }}
        onClick={() => {
          !disabled && setShowSelectOptions((val) => !val)
        }}
        onBlur={() => setShowSelectOptions(false)}
        value={optionArray[currentIndex].label}
        id={id}
        readOnly={true}
        error={error}
        errorMessage={errorMessage}
        type="select"
        icon={{
          iconName: 'keyboard_arrow_down',
          variant: '',
        }}
      />
      {!disabled && showSelectOptions && (
        <SelectOptions>
          <SelectSeparator />
          <ul>
            {optionArray &&
              // eslint-disable-next-line array-callback-return
              optionArray.map((item, index) => {
                if (item.label.length > 0) {
                  return (
                    <li
                      data-testid={'mouse-down' + index}
                      key={'SelectItem' + index + item}
                      className={currentIndex === index ? 'selected' : ''}
                      onMouseDown={() => {
                        setShowSelectOptions((val) => !val)
                        setCurrentIndex(index)
                        onSelect && onSelect(item)
                      }}
                    >
                      <div className="options-item">
                        <div>{item.label}</div>
                        {currentIndex === index && (
                          <div className="Icon_place center_icon">
                            <Icon iconName="done">done</Icon>
                          </div>
                        )}
                      </div>
                    </li>
                  )
                }
              })}
          </ul>
        </SelectOptions>
      )}
    </SelectWrapper>
  )
}

export default Select
