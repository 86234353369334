import React, { useEffect } from "react";

import "./CallToAction.scss";

import Title from "../Title";
import Text from "../Text";
import Thumbnail from "../Thumbnail";
import Button from "../Button";

import imageBanner from "../../assets/img/1-landing.svg";

const title = "Haz realidad el sueño de tu casa propia en poco tiempo";
const titleMobile = "Haz realidad el sueño de tu casa propia";
const text =
  "Infórmate, simula e inicia el camino a tu nuevo hogar de forma simple y rápida.";

const idHomeRequestCreditButton = "homeRequestCreditButton";
const idHomeSimulateCreditButton = "homeSimulateCreditButton";

function CallToAction() {
  let steps = document.getElementById("stepsContainer");
  useEffect(() => {
    setTimeout(() => {
      // eslint-disable-next-line
      steps = document.getElementById("stepsContainer");
    }, 30);
  }, []);

  return (
    <div className="cta">
      <Thumbnail
        url={imageBanner}
        name={"landing-banner"}
        className="banner animated fadeIn"
      />
      <div
        className="icon-button animated fadeInDown"
        onClick={() => {
          steps?.scrollIntoView();
        }}
      >
        <span style={{ margin: "3px" }}></span>
        <pichincha-icon color="white">keyboard_arrow_down</pichincha-icon>
      </div>

      <div className="section section--left">
        <div className="desktop">
          <Title content={title} className="animated fadeInLeftSmall" />
        </div>
        <div className="mobile">
          <Title content={titleMobile} className="animated  fadeInLeftSmall" />
        </div>
        <Text content={text} className="animated fadeIn bp-mt-8" />
        <div className="buttons animated fadeInUp">
          <div className="desk-button-left">
            <Button
              id={idHomeRequestCreditButton}
              size="large"
              color="secondary"
              text="Simular crédito"
              handleClick={() => (window.location.href = "/calculadora")}
            />
          </div>

          <div className="desk-button-right">
            <Button
              id={idHomeSimulateCreditButton}
              size="large"
              color="primary"
              text="Comenzar ahora"
              handleClick={() => (window.location.href = "/precalificacion")}
            />
          </div>
          <div className="section-container-mobile">
            <pichincha-grid
              container
              direction="column"
              wrap="wrap-reverse"
              spacing={4}
            >
              <pichincha-grid item>
                <Button
                  id={idHomeRequestCreditButton}
                  size="large"
                  color="secondary"
                  text="Simula tu crédito"
                  handleClick={() => (window.location.href = "/calculadora")}
                />
              </pichincha-grid>
              <pichincha-grid item>
                <Button
                  id={idHomeSimulateCreditButton}
                  size="large"
                  color="primary"
                  text="Comenzar ahora"
                  handleClick={() =>
                    (window.location.href = "/precalificacion")
                  }
                />
              </pichincha-grid>
            </pichincha-grid>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CallToAction;
