import React, { FunctionComponent, useEffect, useState } from "react";

import { connect, ConnectedProps } from "react-redux";
import { ReduxState } from "../../common/store";
import actionTypes from "../../actions/actionTypes";
import actions from "../../actions";
import * as CONSTANTS from "../../common/helpers/constants";

import InfoCard from "../ExpressRequest/PrequalificationResponses/InfoCard";
import Button from "../Button";
import CheckboxInput from "../CheckboxInput/CheckboxInput";
import InputField from "../InputField/InputField";
import Modal from "../Modal/Modal";
import { sendGTMUserEvent } from "../../common/helpers/gtmEvents";

import image from "../../assets/img/fingerprintCodeImageHelper.svg";

const FingerprintCode: FunctionComponent<ReduxProps> = (props) => {
  const [showModal, setshowModal] = useState(false);

  useEffect(() => {
    sendGTMUserEvent(props.channel, "dactilar", "noCliente");
  });

  const handleContinue = () => {
    if (!props.authorizeFingerprintCode) {
      props.updateStateValue(
        actionTypes.TOGGLE_FINGERPRINT_CODE_AUTHORIZATION,
        false
      );
      return;
    }

    const payload = {
      identificationNumber: props.idCardLogin,
      identificationType: CONSTANTS.CLIENT_IDENTIFICATION_TYPE,
      fingerPrintCode: props.fingerprintCode,
    };

    const { sendFingerprintCode: action, channel } = props;

    if (!props.isSendingRequest) {
      props.setSeindingRequest(true);
      //@ts-ignore
      grecaptcha
        .execute(CONSTANTS.REACT_APP_RECAPTCHA_TOKEN)
        .then(function (recaptchaToken: string) {
          const headers = {
            GUID: CONSTANTS.GUID,
            token: recaptchaToken,
            channel,
          };
          action(payload, headers);
        });
    }
  };

  const handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    const { value, id } = event.currentTarget;
    const targetInputMap: any = {
      identificationNumber: {
        actionType: actionTypes.UPDATE_INPUT_ID_CARD_LOGIN,
        maxLength: CONSTANTS.IDENTIFICATION_MIN_LENGTH,
      },
      fingerprintCode: {
        actionType: actionTypes.UPDATE_INPUT_FINGERPRINT_CODE,
        maxLength: CONSTANTS.FINGERPRINT_CODE_MIN_LENGTH,
      },
    };
    const inputAttributes = targetInputMap[id];
    if (value.length > inputAttributes.maxLength) {
      return;
    }
    props.updateInputContent(value, inputAttributes.actionType);
  };

  const handleFocusInput = () => {
    props.updateStateValue(actionTypes.TOGGLE_ALERT_LOGIN, false);
  };

  const closeModal = (e: any) => {
    const confirmClose = () => {
      setshowModal(false);
      document.removeEventListener("keydown", closeModal);
    };

    const clickableElementIds = [
      "confirm",
      "closeButton",
      "cancel",
      "fingerprintCodeModal",
    ];
    const clickedId = e.target.id;
    if (clickableElementIds.includes(clickedId)) {
      confirmClose();
    }

    if (e.type === "keydown" && e.key === "Escape") {
      confirmClose();
    }
  };

  const openModal = () => {
    document.addEventListener("keydown", closeModal);
    setshowModal(true);
  };

  const {
    idCardLoginStateError,
    authorizeFingerprintCode,
    fingerprintCodeErrorHelper,
    fingerprintCodeState,
    fingerprintCodeStateError,
    fingerprintCode,
    loading,
  } = props;

  const disableContinueButton =
    idCardLoginStateError || fingerprintCodeStateError;

  const continueRequestButtonProps = {
    color: "primary",
    size: "large",
    text: "Continuar",
    id: `buttonStartClient`,
    testId: "buttonStartClientTestId",
    disabled: disableContinueButton,
    handleClick: disableContinueButton ? undefined : handleContinue,
    tabindex: 0,
    loading: loading,
  };

  const inforCardProps = {
    icon: "camera_front",
    title: "Recuerda",
    titleWeight: "bold",
    content: (
      <div className="content-card">
        Usa un dispositivo con cámara, ya que validaremos tu identidad durante
        el proceso.
      </div>
    ),
    extraClassNames: "login",
  };

  const modalContent = (
    <div className="fingerprint-modal text-prelo-medium">
      <p className="title">¿Dónde está mi código dactilar?</p>
      <p className="description">
        Encuéntralo al reverso de tu cédula de identidad, en la esquina superior
        derecha.
      </p>
      <img src={image} alt="Codigo dactilar" />
    </div>
  );

  return (
    <div>
      <Modal
        idElement="fingerprintCodeModal"
        borderHeader={true}
        disabledPrimaryButton={false}
        open={showModal}
        primaryButtonLabel="Entendido"
        showCloseButton={true}
        size="sm"
        handleClose={closeModal}
        handleConfirm={closeModal}
        buttonCustomClass="modal__button--long"
        content={modalContent}
      />
      <InputField
        id="fingerprintCode"
        testId="fingerprintCode"
        label="Código dactilar"
        placeholder="Ej.: V5435V8789"
        errorHelper={fingerprintCodeErrorHelper}
        normalHelper=""
        classWrap="-login mb-0"
        state={fingerprintCodeState}
        xs="12"
        lg="12"
        disabled={false}
        currentValue={fingerprintCode}
        handleChange={handleChange}
        tabindex={0}
        handleFocus={handleFocusInput}
        disablePaste={true}
      />
      <pichincha-link class="hydrated" onClick={openModal}>
        <pichincha-typography
          id="homeCardMyPichinchaHouseViewMoreLink"
          variant="actionText"
          color="info"
          weight="normal"
          weight_color="500"
          align="right"
          class="typography hydrated"
        >
          ¿Dónde está?
        </pichincha-typography>
      </pichincha-link>
      <InfoCard {...inforCardProps} />
      <CheckboxInput
        data-idtest="contactInformationAuthorization"
        id="contactInformationAuthorization"
        checked={authorizeFingerprintCode}
        handleClick={() =>
          props.updateStateValue(
            actionTypes.TOGGLE_FINGERPRINT_CODE_AUTHORIZATION,
            !authorizeFingerprintCode
          )
        }
        label="Autorizo a Banco Pichincha a consultar mis datos personales y/o 
        crediticios de fuentes legales de información y su respectivo tratamiento."
        tabindex={0}
        state={props.authorizeFingerprintCodeState}
      />

      <div className="button-container-login">
        <div className="button-sizes" data-testid="startButtonLogin">
          <Button {...continueRequestButtonProps} />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: ReduxState) => {
  const { login, site } = state;
  const { channel, jwt } = site;

  const {
    idCardLogin,
    idCardLoginState,
    idCardLoginErrorHelper,
    idCardLoginStateError,
    fingerprintCode,
    fingerprintCodeState,
    fingerprintCodeStateError,
    fingerprintCodeFailuresCount,
    fingerprintCodeFailureMessage,
    fingerprintCodeErrorHelper,
    authorizeFingerprintCode,
    authorizeFingerprintCodeState,
    showAlert,
    loading,
    isSendingRequest,
  } = login;
  return {
    idCardLogin,
    idCardLoginState,
    idCardLoginErrorHelper,
    idCardLoginStateError,
    fingerprintCode,
    fingerprintCodeState,
    fingerprintCodeStateError,
    fingerprintCodeFailuresCount,
    fingerprintCodeFailureMessage,
    fingerprintCodeErrorHelper,
    authorizeFingerprintCode,
    authorizeFingerprintCodeState,
    channel,
    showAlert,
    loading,
    jwt,
    isSendingRequest,
  };
};

const {
  updateInputContent,
  setLoginStep,
  toggleCompleteIdCard,
  updateStateValue,
  sendFingerprintCode,
  setSeindingRequest,
} = actions;

const connector = connect(mapStateToProps, {
  updateInputContent,
  setLoginStep,
  toggleCompleteIdCard,
  updateStateValue,
  sendFingerprintCode,
  setSeindingRequest,
});

type ReduxProps = ConnectedProps<typeof connector>;

export default connector(FingerprintCode);
