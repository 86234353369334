
import actionTypes from '../actionTypes';
import apiActionCreator from '../utils/apiActionCreator';
import { getEncriptedData } from '../utils/helperActionCreator';
import { Services } from '../utils/services';

export const getNamesCivilRegistration =
  (
    identification: string,
    identificationType: string,
    channel: string,
    jwt: string,
    GUID: string,
    nextStep: number
  ) => {
    const endpoint = '/v1/loan-application/get-state';
    const body = {
      identification: identification,
      identificationType: identificationType,
      opportunityId: null
    }
    const encryptedBody: any = getEncriptedData(body)
    return apiActionCreator({
      service: Services.CIVIL_REGISTRATION,
      endpoint: endpoint,
      method: 'POST',
      body: JSON.stringify(encryptedBody),
      headers: {
        'Content-Type': 'application/json',
        'X-GUID': GUID,
        authorization: jwt,
        'X-Channel': channel,
      },
      onRequestActions: [{ type: actionTypes.CIVIL_REGISTRATION_NAMES_REQUEST }],
      onSuccessActions: [
        { type: actionTypes.CIVIL_REGISTRATION_NAMES_SUCCESS },
        { type: actionTypes.SET_STEP, newStep: nextStep }
      ],
      onFailureActions: [
        { type: actionTypes.CIVIL_REGISTRATION_NAMES_FAILED },
        { type: actionTypes.SET_STEP, newStep: nextStep }
      ],
      verifyState: true,
      publicKey: 'prequalification',
      encrypted: true
    });
  };

export const toggleLoader = (newState: boolean) => ({
  type: actionTypes.CIVIL_REGISTRATION_HIDE_LOADER,
  newState,
});

export const resetCivilRegistration = () => ({
  type: actionTypes.CIVIL_REGISTRATION_RESET_STATE,
});
