import React from "react";
import "./Modal.scss";

interface ModalProps {
  borderHeader: boolean;
  disabledPrimaryButton: boolean;
  idElement?: string;
  open: boolean;
  primaryButtonLabel: string;
  secondaryButtonLabel?: string;
  showCloseButton: boolean;
  size: string;
  handleClose: (e: any) => void;
  handleConfirm: (e: any) => void;
  buttonCustomClass?: string;
  content: any;
}

const Modal = (props: ModalProps) => {
  const {
    idElement,
    borderHeader,
    disabledPrimaryButton,
    open,
    primaryButtonLabel,
    secondaryButtonLabel,
    showCloseButton,
    size,
    handleClose,
    handleConfirm,
    buttonCustomClass,
    content,
  } = props;

  const hasButtons = !!(primaryButtonLabel || secondaryButtonLabel);
  let id = idElement || "container-modal";
  return (
    <div
      id={id}
      className={open ? "modal" : "none"}
      onClick={(e: any) => handleClose(e)}
      onKeyDown={(e: any) => handleClose(e)}
    >
      <div className={`modal__box modal__box--${size}`}>
        <div
          className={`modal__header ${borderHeader && "modal__header--border"}`}
        >
          {showCloseButton && (
            <pichincha-icon
              class="modal__header--close"
              onClick={(e: any) => handleClose(e)}
              type="--round"
              color="darkGrey"
              size="24px"
              weight_color="400"
              id="closeButton"
            >
              close
            </pichincha-icon>
          )}
        </div>
        <div
          className={`modal__body modal__body--${size} ${
            !hasButtons && "modal__body--hb"
          }`}
        >
          {content}
        </div>
        {primaryButtonLabel && (
          <div className={`modal__footer modal__footer--${size}`}>
            {secondaryButtonLabel && (
              <div
                className={`modal__button--${size} ${buttonCustomClass || ""}`}
              >
                <pichincha-button
                  id="cancel"
                  color="secondary"
                  onClick={(e: any) => handleClose(e)}
                >
                  {secondaryButtonLabel}
                </pichincha-button>
              </div>
            )}
            <div
              className={`modal__button--${size} ${buttonCustomClass || ""} ${
                !secondaryButtonLabel && "mt-0"
              } `}
            >
              <pichincha-button
                id="confirm"
                color="primary"
                disabled={disabledPrimaryButton}
                onClick={(e: any) => handleConfirm(e)}
              >
                {primaryButtonLabel}
              </pichincha-button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Modal;
