import React, { Component, KeyboardEvent } from "react";
import InputField from "../InputField/InputField";
import * as KEYS from "../../common/helpers/keyCodes";

interface DropdownFieldProps {
  label: string;
  id: string;
  placeholder: string;
  errorHelper: string;
  normalHelper: string;
  state: string;
  iconOrientation?: string;
  iconContent?: string;
  tooltip?: string;
  disabled: boolean;
  currentValue: string;
  testId: string;
  lg?: string;
  xs?: string;
  phonePrefix?: boolean;
  classWrap?: string;
  classIcon?: string;
  list: Array<{
    id: string;
    value: string;
  }>;
  minLengthToFilter?: number;
  stateUpdater: (newValue: string, actionType: string) => void;
  actionTypeValue: string;
  actionTypeId: string;
  textPattern?: RegExp;
}

class DropdownField extends Component<DropdownFieldProps> {
  constructor(props: DropdownFieldProps) {
    super(props);

    this.handleInputChange = this.handleInputChange.bind(this);

    this.state = {
      filteredList: [],
    };
  }

  handleInputChange = (e: React.FormEvent<HTMLInputElement>) => {
    const newValue = this.props.textPattern
      ? e.currentTarget.value.replace(this.props.textPattern, "")
      : e.currentTarget.value;
    const minLengthToFilter = this.props.minLengthToFilter || 1;
    if (newValue.length >= minLengthToFilter) {
      this.props.list.sort();
      const filtertList = this.props.list.filter((item) =>
        item.value.toLowerCase().includes(newValue.toLowerCase())
      );
      this.setState(() => ({
        filteredList: filtertList,
      }));
      if (filtertList[0]) {
        if (newValue.toLowerCase() === filtertList[0].value.toLowerCase()) {
          this.updateContentDropdown(filtertList[0].id, filtertList[0].value);
          this.props.stateUpdater(
            filtertList[0].value,
            this.props.actionTypeValue
          );
          return;
        }
      }
    }

    this.props.stateUpdater(newValue, this.props.actionTypeValue);

    if (!newValue) {
      this.updateContentDropdown("", newValue);
    }
  };

  renderSuggestions() {
    // @ts-ignore
    if (!this.state.filteredList.length) {
      return null;
    }
    return (
      <div className="dropdown-input" data-testid="suggestionsVisible">
        <ul>
          {
            // @ts-ignore
            this.state.filteredList.map((item, index) => (
              <li
                data-testid={`${this.props.testId}Dropdown${index}`}
                onClick={() => this.updateContentDropdown(item.id, item.value)}
                tabIndex={index}
                onKeyDown={(e) =>
                  this.onKeyDownSuggestion(e, item.id, item.value)
                }
                key={index}
              >
                {item.value}
              </li>
            ))
          }
        </ul>
      </div>
    );
  }

  isSelectingOption(key: string) {
    return key === KEYS.ENTER || key === KEYS.SPACEBAR;
  }

  onKeyDownSuggestion(e: KeyboardEvent, id: string, value: string) {
    const { key } = e;
    if (this.isSelectingOption(key)) {
      this.updateContentDropdown(id, value);
      return;
    }
    e.preventDefault();
    this.focusLi(e.target, key);
  }

  focusLi(currentLi: EventTarget, key: string) {
    let nextLi;
    if (key === KEYS.ARROW_UP) {
      // @ts-ignore
      nextLi = currentLi.previousSibling;
      if (!nextLi) {
        // @ts-ignore
        currentLi.parentElement.parentElement.previousSibling.focus();
        return;
      }
    }
    if (key === KEYS.ARROW_DOWN) {
      // @ts-ignore
      nextLi = currentLi.nextSibling;
    }
    if (nextLi) {
      nextLi.focus();
    }
  }

  focusListElement(e: KeyboardEvent) {
    const target = e.target as Element;
    const { key } = e;
    if (key !== KEYS.ARROW_DOWN) {
      return;
    }
    const childNodes = target.nextSibling?.childNodes;
    // @ts-ignore
    if (!childNodes?.length || childNodes[0].tagName !== "UL") {
      return;
    }
    const firstLi = target.nextSibling!.childNodes[0].childNodes[0];
    // @ts-ignore
    firstLi.focus();
  }

  updateContentDropdown(id: string, newValue: string) {
    this.props.stateUpdater(newValue, this.props.actionTypeValue);
    this.props.stateUpdater(id, this.props.actionTypeId);

    this.setState(() => ({
      filteredList: [],
    }));
  }

  render() {
    const inputFieldProps = {
      label: this.props.label,
      placeholder: this.props.placeholder,
      normalHelper: this.props.normalHelper,
      errorHelper: this.props.errorHelper,
      iconContent: this.props.iconContent,
      lg: this.props.lg,
      xs: this.props.xs,
      classWrap: this.props.classWrap,
      dropdownInput: true,
      currentValue: this.props.currentValue,
      handleChange: this.handleInputChange,
      state: this.props.state,
      id: this.props.id,
      testId: this.props.testId,
      list: this.renderSuggestions(),
      disabled: this.props.disabled,
      handleKeyUp: this.focusListElement,
    };

    return <InputField {...inputFieldProps} />;
  }
}

export default DropdownField;
