import { Component } from "react";
import {
  sendGTMClickEvent,
  sendGTMCustomEvent,
} from "../../common/helpers/gtmEvents";

import { connect, ConnectedProps } from "react-redux";
import { ReduxState } from "../../common/store";
import actions from "../../actions/";
import {
  GUID,
  REACT_APP_RECAPTCHA_TOKEN,
} from "../../common/helpers/constants";

import "./CallMeBackButton.scss";
import "../Modal/Modal.scss";
import InputField from "../InputField/InputField";
import { NAMES, NUMBERS } from "../../common/helpers/patterns";
import actionTypes from "../../actions/actionTypes";
import {
  capitalizeName,
  openModal,
  closeModal,
} from "../../common/helpers/functions";

import successImage from "../../assets/img/successCMB.svg";

interface CallMeBackButtonProps {
  isOpened: boolean;
  setStatus: (isOpened: boolean) => void;
}

class CallMeBackButton extends Component<ReduxProps> {
  constructor(props: ReduxProps) {
    super(props);
    this.onConfirmClose = this.onConfirmClose.bind(this);
  }

  componentDidMount() {
    this.props.getWorkableTimeCMB(GUID);
  }

  handleInputChange(field: string, targetValue: string) {
    const fieldsMapper: { [key: string]: any } = {
      firstName: {
        pattern: NAMES,
        actionType: actionTypes.CALL_ME_BACK_UPDATE_FIRST_NAME,
        maxLength: 30,
      },
      lastName: {
        pattern: NAMES,
        actionType: actionTypes.CALL_ME_BACK_UPDATE_LAST_NAME,
        maxLength: 30,
      },
      phone: {
        pattern: NUMBERS,
        actionType: actionTypes.CALL_ME_BACK_UPDATE_PHONE,
        maxLength: 8,
      },
    };

    const {
      maxLength,
      pattern: allowedPattern,
      actionType,
    } = fieldsMapper[field];

    if (targetValue.length > maxLength) {
      return;
    }

    const clearedString = targetValue.replace(allowedPattern, "");
    this.props.updateInputContent(clearedString, actionType);
  }

  handleSubmitForm(disabled: boolean) {
    if (disabled) {
      return;
    }

    sendGTMClickEvent("confirmCall");

    const { firstName, lastName, phone } = this.props.callMeBack;
    const { submitCallBackRequest: handleSubmitAction, channel } = this.props;

    const payload = {
      identification: null,
      fullName: capitalizeName(`${firstName} ${lastName}`),
      phone: `09${phone}`,
    };

    //@ts-ignore
    grecaptcha
      .execute(REACT_APP_RECAPTCHA_TOKEN)
      .then(function (recaptchaToken: string) {
        handleSubmitAction(payload, {
          recaptchaToken,
          guid: GUID,
          channel,
        });
      });
  }

  onConfirmClose() {
    this.props.resetStateCallMeBack();
    this.props.setStatus(false);
    sendGTMClickEvent("callMeBackAnimation");
  }

  render() {
    const {
      workableTimeSuccess,
      firstName,
      firstNameState,
      firstNameStateError,
      lastName,
      lastNameState,
      lastNameStateError,
      phone,
      phoneState,
      phoneStateError,
      submitButtonText,
      loading,
      formSubmitted,
    } = this.props.callMeBack;

    if (!workableTimeSuccess) {
      return null;
    }

    const {
      isOpened,
      resetStateCallMeBack: resetCallMeBackForm,
      setStatus,
    } = this.props;
    const size = "sm";
    const hasButtons = true;
    const form = (
      <div className="text-center text-prelo-medium">
        <div className="title">
          Un asesor te llamará para resolver tus dudas
        </div>
        <div className="subtitle">
          Coloca tus datos y nos contactaremos contigo pronto.
        </div>
        <InputField
          id="firstNameCmb"
          testId="firstNameCmb"
          label="Nombres"
          placeholder=""
          errorHelper="Por favor, completa el campo"
          normalHelper=""
          classWrap="-login cmb"
          state={firstNameState}
          xs="12"
          lg="12"
          disabled={false}
          currentValue={firstName}
          handleChange={(e) =>
            this.handleInputChange("firstName", e.target.value)
          }
        />
        <InputField
          id="lastNameCmb"
          testId="lastNameCmbTestId"
          label="Apellidos"
          placeholder=""
          errorHelper="Por favor, completa el campo"
          normalHelper=""
          classWrap="-login cmb"
          state={lastNameState}
          xs="12"
          lg="12"
          disabled={false}
          currentValue={lastName}
          handleChange={(e) =>
            this.handleInputChange("lastName", e.target.value)
          }
        />
        <InputField
          id="phoneCmb"
          testId="phoneCmb"
          label="Número celular"
          placeholder=""
          errorHelper="Por favor, completa el campo"
          normalHelper=""
          classWrap="-login cmb"
          state={phoneState}
          xs="12"
          lg="12"
          disabled={false}
          currentValue={phone}
          handleChange={(e) => this.handleInputChange("phone", e.target.value)}
          phonePrefix={true}
        />
      </div>
    );

    const formHasErrors =
      firstNameStateError || lastNameStateError || phoneStateError;
    const disableButton = formHasErrors || loading;

    //@ts-ignore
    if (!disableButton) {
      sendGTMCustomEvent("completeCallmebackForm");
    }

    const formFooter = (
      <>
        <div className="disclaimer">
          Al presionar ¨Solicitar llamada¨ autorizas a Banco Pichincha el uso de
          la información ingresada en este formulario para brindarte la asesoría
          requerida.
        </div>
        <div className={`modal__button--${size} mt-0`}>
          <pichincha-button
            data-testid="submitCallBackTestId"
            color="primary"
            onClick={() => this.handleSubmitForm(disableButton)}
            disabled={disableButton}
            loading={loading}
            onKeyDown={(e: any) => {
              closeModal(e, this.onConfirmClose, []);
            }}
            tabIndex={0}
          >
            {submitButtonText}
          </pichincha-button>
        </div>
      </>
    );

    const successContent = (
      <div className="text-center text-prelo-medium">
        <img
          src={successImage}
          alt="sucess icon"
          height={64}
          width={64}
          className="bp-mt-12 bp-mb-16"
        />
        <div className="title bp-mb-8 bp-pb-4">
          {capitalizeName(firstName)}, pronto nos comunicaremos contigo al
        </div>
        <div className="subtitle">{`09${phone}`}</div>
      </div>
    );

    const validIds: any = ["closeButton", "callMeBackModal"];

    const successFooter = (
      <div className={`modal__button--${size} mt-0`}>
        <pichincha-button
          id="successSubmitCallMeBack"
          color="primary"
          onClick={() => {
            setStatus(!isOpened);
            sendGTMClickEvent("callMeBackAnimation");
            resetCallMeBackForm();
          }}
        >
          Entendido
        </pichincha-button>
      </div>
    );

    return (
      <div
        className={`cbb-wrapper cbb-wrapper--${isOpened ? "opened" : "closed"}`}
        id="callMeBackModal"
        onClick={(e) => closeModal(e, this.onConfirmClose, validIds)}
        onKeyDown={(e) => closeModal(e, this.onConfirmClose, [])}
        tabIndex={0}
      >
        <div className={`cbb-list cbb-list--${isOpened ? "opened" : "closed"}`}>
          <div className="cbb-item" id="callMeBackAnimation">
            <div
              data-testid="cbb-item__line"
              className={`cbb-item__line`}
              onClick={() => {
                openModal(this.props.setStatus);
                sendGTMClickEvent("callMeBackAnimation");
              }}
            >
              <span className="cbb-item__call" />
              <div className="cbb-item__title">¿Necesitas ayuda?</div>
            </div>
            <div className="cbb-item__inner">
              <div className="cbb-item__content">
                <div id="modalCallMeBack" className="modal">
                  <div className={`modal__box modal__box--${size}`}>
                    <div className={`modal__header ${"modal__header--border"}`}>
                      <pichincha-icon
                        class="modal__header--close"
                        onClick={() => {
                          resetCallMeBackForm();
                          openModal(this.props.setStatus);
                          sendGTMClickEvent("callMeBackAnimation");
                        }}
                        type="--round"
                        color="darkGrey"
                        size="24px"
                        weight_color="400"
                        id="closeButton"
                        data-testid="modal__header--close"
                      >
                        close
                      </pichincha-icon>
                    </div>
                    <div
                      className={`modal__body modal__body--${size} ${
                        !hasButtons && "modal__body--hb"
                      }`}
                    >
                      {!formSubmitted && form}
                      {formSubmitted && successContent}
                    </div>
                    <div className={`modal__footer modal__footer--${size}`}>
                      {!formSubmitted && formFooter}
                      {formSubmitted && successFooter}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (
  state: ReduxState,
  componentProps: CallMeBackButtonProps
) => {
  const { site, callMeBack } = state;
  const { isOpened, setStatus } = componentProps;
  const { channel } = site;
  return {
    channel,
    isOpened,
    setStatus,
    callMeBack,
  };
};

const {
  getWorkableTimeCMB,
  updateInputContent,
  submitCallBackRequest,
  resetStateCallMeBack,
} = actions;

const connector = connect(mapStateToProps, {
  getWorkableTimeCMB,
  updateInputContent,
  submitCallBackRequest,
  resetStateCallMeBack,
});

type ReduxProps = ConnectedProps<typeof connector>;

export default connector(CallMeBackButton);
