import React, { Component } from "react";

import Popover from "../../Popover";

interface CalculatedDataContainProps {
  registers: Array<{
    hasPopover: boolean;
    key: string;
    value: string;
  }>;
  firstClass: string;
  secondClass: string;
  idName: string;
  thirdClass: string;
  popoverId: string;
}
class CalculatedDataContent extends Component<CalculatedDataContainProps> {
  render() {
    return (
      <div
        className={this.props.firstClass}
        data-testid={this.props.firstClass}
      >
        <button
          className={this.props.secondClass}
          id={this.props.idName}
          data-testid={this.props.idName}
        >
          Lo que calculaste
        </button>
        <div
          className={this.props.thirdClass}
          data-testid={this.props.thirdClass}
        >
          {this.props.registers.map(
            (register, index) =>
              !register.hasPopover && (
                <div className="register bp-mb-8" key={index}>
                  <div>
                    <pichincha-typography
                      variant="smallText"
                      color="blue"
                      weight_color="500"
                    >
                      {register.key}
                    </pichincha-typography>
                  </div>
                  <div>
                    <pichincha-typography
                      variant="smallText"
                      color="blue"
                      weight_color="500"
                    >
                      {register.value}
                    </pichincha-typography>
                  </div>
                </div>
              )
          )}
          {this.props.registers.map(
            (register, index) =>
              register.hasPopover && (
                <div className="register bp-mb-8" key={index}>
                  <Popover
                    id={this.props.popoverId}
                    linkName={register.key}
                    popoverContent="Tasa de interés anual, la cual puede ser reajustable de acuerdo a la ley."
                  />
                  <div>
                    <pichincha-typography
                      variant="smallText"
                      color="blue"
                      weight_color="500"
                    >
                      {register.value}
                    </pichincha-typography>
                  </div>
                </div>
              )
          )}
        </div>
      </div>
    );
  }
}

export default CalculatedDataContent;
