import React, { Component } from "react";

import { connect, ConnectedProps } from "react-redux";
import { ReduxState } from "../../../common/store";
import actionTypes from "../../../actions/actionTypes";
import * as CONSTANTS from "../../../common/helpers/constants";
import { GENERIC_ERROR_MESSAGE_TEXT } from "../../../common/texts/expressApplication";
import actions from "../../../actions";
import ClientPage from "../ClientPage/ClientPage";
import FingerprintCode from "../FingerprintCode";
import InputField from "../../InputField/InputField";
import Button from "../../Button";
import MessageBar from "../../MessageBar/MessageBar";
import * as patterns from "../../../common/helpers/patterns";
import { sendGTMUserEvent } from "../../../common/helpers/gtmEvents";
import { validateInputContent } from "../../../common/helpers/functions";

class StartPage extends Component<ReduxProps> {
  constructor(props: ReduxProps) {
    super(props);
    this.handleChangeIdCard = this.handleChangeIdCard.bind(this);
    this.continue = this.continue.bind(this);
    this.cancelExpressRequest = this.cancelExpressRequest.bind(this);
  }
  componentDidMount() {
    setTimeout(() => {
      sendGTMUserEvent(this.props.channel, "login", this.props.userType);
    }, 1);
  }
  continue(latestIdCard: any) {
    this.props.toggleCompleteIdCard(true);
    const payload = {
      identificationNumber: latestIdCard || this.props.idCardLogin,
      identificationType: CONSTANTS.CLIENT_IDENTIFICATION_TYPE,
    };

    const { sendCifVerification: action, channel } = this.props;

    //@ts-ignore
    grecaptcha
      .execute(CONSTANTS.REACT_APP_RECAPTCHA_TOKEN)
      .then(function (recaptchaToken: string) {
        action(payload, recaptchaToken, CONSTANTS.GUID, channel);
      });
    window.sessionStorage.setItem("id", this.props.idCardLogin);
    return false;
  }

  cancelExpressRequest() {
    window.location.href = "/";
  }

  handleChangeIdCard = (e: React.FormEvent<HTMLInputElement>) => {
    const newValue = e.currentTarget.value;
    if (newValue.length > 10 || this.props.idCardInputStatus === "loading") {
      return;
    }
    const clearedString = newValue.replace(patterns.NUMBERS, "");
    this.props.updateInputContent(
      clearedString,
      actionTypes.UPDATE_INPUT_ID_CARD_LOGIN
    );
    const { stateError: invalidIdCard } = validateInputContent(
      CONSTANTS.IDENTIFICATION_FIELD,
      clearedString
    );
    if (invalidIdCard) {
      return;
    }
    this.continue(clearedString);
  };

  handleFocusInput = () => {
    this.props.updateStateValue(actionTypes.TOGGLE_ALERT_LOGIN, false);
  };

  render() {
    const {
      idCardLoginErrorHelper,
      idCardInputStatus,
      cifSuccess,
      stepLogin,
      showAlertTransactional,
      showAlert,
      transactionalRequestFailureMessage,
    } = this.props;

    const idCardFieldProps = {
      label: "Cédula",
      placeholder: "Ingresa tu número de cédula",
      state: this.props.idCardLoginState,
      normalHelper: "",
      errorHelper: idCardLoginErrorHelper,
      currentValue: this.props.idCardLogin,
      disabled: false,
      handleChange: this.handleChangeIdCard,
      testId: "personalDataIdCardTestId",
      xs: "12",
      lg: "6",
      placesInput: false,
      id: "idCardLoginFieldInput",
      tabindex: 0,
      statusIcon: idCardInputStatus,
      handleRetry: () => this.continue(""),
      disablePaste: true,
    };
    const continueButtonProps = {
      color: "primary",
      testId: "continueButtonLoginStart",
      size: "large",
      text: "Continuar",
      loading: false,
      id: `buttonStartLogin`,
      disabled: true,
      tabindex: 0,
    };

    let alertErrorMessage = "";
    let alertErrorId = "";

    if (stepLogin === 1) {
      alertErrorMessage = transactionalRequestFailureMessage;
      alertErrorId = "transactionalContactError";
    }

    if (stepLogin === 2) {
      alertErrorMessage = GENERIC_ERROR_MESSAGE_TEXT;
      alertErrorId = "fingerprintCodeError";
    }

    const errorMessageBarProps = {
      id: alertErrorId,
      message: alertErrorMessage,
      variant: "light",
      color: "error",
      extraClasses: "invalid-request error-message-login bp-mb-16",
      handleClose: this.handleFocusInput,
    };

    const authStepMap: { [key: string]: any } = {
      1: <ClientPage />,
      2: <FingerprintCode />,
    };

    return (
      <div className="step-login-container">
        {(showAlert || showAlertTransactional) && (
          <MessageBar {...errorMessageBarProps} />
        )}
        <p className="text-header-login">
          Precalifica y solicita tu crédito de vivienda en línea
        </p>
        <InputField {...idCardFieldProps} classWrap="-login" />
        {authStepMap[stepLogin]}
        <div className="button-container-login">
          <div className="button-sizes" data-testid="startButtonLogin">
            {!cifSuccess && <Button {...continueButtonProps} />}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: ReduxState) => {
  const { login, site } = state;
  const { channel, userType } = site;
  const {
    idCardLogin,
    idCardLoginState,
    idCardLoginStateError,
    completedLoginIdCard,
    idCardLoginErrorHelper,
    startButtonText,
    loading,
    showAlert,
    idCardInputStatus,
    cifSuccess,
    stepLogin,
    showAlertTransactional,
    transactionalRequestFailureMessage,
  } = login;
  return {
    idCardLogin,
    idCardLoginState,
    idCardLoginStateError,
    completedLoginIdCard,
    idCardLoginErrorHelper,
    channel,
    startButtonText,
    loading,
    showAlert,
    userType,
    idCardInputStatus,
    cifSuccess,
    stepLogin,
    showAlertTransactional,
    transactionalRequestFailureMessage,
  };
};

const {
  updateInputContent,
  setLoginStep,
  toggleCompleteIdCard,
  sendCifVerification,
  updateStateValue,
} = actions;

const connector = connect(mapStateToProps, {
  updateInputContent,
  setLoginStep,
  toggleCompleteIdCard,
  sendCifVerification,
  updateStateValue,
});

type ReduxProps = ConnectedProps<typeof connector>;

export default connector(StartPage);
