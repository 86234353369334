import { AnyAction } from 'redux';

import {
  GUID,
  PUBLIC_KEY_FACEPHI
} from '../../common/helpers/constants';

import actionTypes from '../../actions/actionTypes';
import {
  capitalizeName,
  formatCurrency,
  getUtmHeaders,
  partnerDataRequired,
  removeCommas,
  updateMonthlyIncomeStatus,
} from '../../common/helpers/functions';
import { ExpressRequestState } from '../../common/helpers/integration';

import { PATHS } from '../../common/helpers/paths';
import {
  getEncryptedItemFromCookie,
  setEncryptedItemToCookie
} from '../../common/helpers/storage-utils';
import {
  completedWorkData,
  expressAplicationFailed,
  expressAplicationSuccess,
  getCurrentWorkSeniorityStatus,
  setStep,
  updateBuyingIntentionTime,
  updateCapitalResidence,
  updatePersonalDataEmail,
  updatePersonalDataIdCard,
  updatePersonalDataIdCardPartner,
  updatePersonalDataLastName,
  updatePersonalDataMaritalStatus,
  updatePersonalDataName,
  updatePersonalDataPhone,
  updatePersonalDataSeparationOfProperty,
  updateProvinceResidence,
  updateWorkDataPartnerWorkStatus,
  updateWorkDataWorkStatus,
} from './utils';

export const INITIAL_STATE = {
  currentStep: 1,
  failuresCount: 0,
  failuresPrequalificationCount: 0,
  showAlert: false,
  showUnauthorizedPage: false,
  showSomethingWentWrongPage: false,
  expressApplication_error: false,
  expressApplication_success: false,
  prequalificationSuccess: false,
  expressApplication_loading: false,
  expressApplicationAgencyError: false,
  expressApplicationAgencySuccess: false,
  completedPersonalData: false,
  completedWorkData: false,
  completedLoanData: false,
  selected_button_product: '',
  selected_credit_product_type: '',
  title_products_enable: false,
  inputs_enable: false,
  nut: '',
  error422: false,
  showOfferPage: false,
  showCounterofferPage: false,
  showRejectedPage: false,
  dataProtectionModalOpen: true,
  ldpdError: false,
  personalData: {
    name: '',
    lastName: '',
    phone: '',
    email: '',
    provinceResidence: '',
    capitalResidence: '',
    idCard: '',
    idCardPartner: '',
    maritalStatus: 'empty',
    nameState: 'normal',
    lastNameState: 'normal',
    phoneState: 'normal',
    emailState: 'normal',
    provinceResidenceState: 'normal',
    capitalResidenceState: 'normal',
    idCardState: 'normal',
    idCardPartnerState: 'normal',
    nameStateError: true,
    lastNameStateError: true,
    phoneStateError: true,
    emailStateError: true,
    provinceResidenceStateError: true,
    capitalResidenceStateError: true,
    idCardStateError: true,
    idCardPartnerStateError: true,
    maritalStatusStateError: true,
    separationOfPropertyStateError: true,
    separationOfProperty: '',
  },
  workData: {
    workStatus: '',
    workSeniority: '',
    partnerWorkSeniority: '',
    workSeniorityStateErrorHelper: '',
    partnerWorkStatus: '',
    monthlyIncome: '',
    monthlyIncomeNumber: 0,
    partnerMonthlyIncome: '',
    partnerMonthlyIncomeNumber: 0,
    workStatusState: '',
    partnerWorkStatusState: '',
    monthlyIncomeState: '',
    monthlyIncomeErrorHelper: '',
    partnerMonthlyIncomeState: '',
    workSeniorityState: 'normal',
    partnerWorkSeniorityState: 'normal',
    workSeniorityStateError: true,
    partnerWorkSeniorityStateError: true,
    workStatusError: true,
    partnerWorkStatusError: true,
    monthlyIncomeError: true,
    partnerMonthlyIncomeError: true,
    partnerMonthlyIncomeErrorHelper: '',
    previousEndWork: null,
    previousInitWork: null,
    partnerPreviousEndWork: null,
    partnerPreviousInitWork: null,
  },
  creditData: {
    buyingIntentionTime: '',
    buyingIntentionTimeState: '',
    buyingIntentionTimeStateError: true,
  },
  prequalificationData: {
    totalAmount: 0,
    monthlyFee: 0,
    annualRate: 0,
    paymentTerm: '',
    estimatedDebt: 0,
    isMVP: false,
  },
  payload: '',
  facephiToken: '',
  calculator: {
    product: [{ basicSalary: 460 }],
  },
};

const expressRequest = (
  state: ExpressRequestState = INITIAL_STATE,
  action: AnyAction = { type: null }
): ExpressRequestState => {
  switch (action.type) {
    case actionTypes.EXPRESS_REQUEST_RESET_STATE:
      return {
        ...INITIAL_STATE,
      };
    case actionTypes.SET_STEP:
      return setStep(action, state);

    case actionTypes.UPDATE_INPUT_BUYING_INTENTIONS_TIME:
      return updateBuyingIntentionTime(action, state);

    case actionTypes.FACEPHI_REDIRECTION:
      const dataCustom = getEncryptedItemFromCookie('facephi-t');
      const { firstName, firstLastName, identification, authorization } =
        dataCustom.customer;
      const facephiCookies: { [key: string]: any } = {
        token: state.facephiToken,
        customer: {
          firstName: capitalizeName(firstName),
          firstLastName: capitalizeName(firstLastName),
          identification,
        },
        guid: GUID,
        authorization,
        utmHeaders: getUtmHeaders(),
        channel: action.channel,
        dff28737611dc60ef2555c94c5aefd55: PUBLIC_KEY_FACEPHI
      };
      setEncryptedItemToCookie('facephi', facephiCookies);
      window.location.href = PATHS.noClientValidation;
      return state;
    case actionTypes.SHOW_SUCCESS_FACEPHI:
      return {
        ...state,
        currentStep: 7,
        expressApplication_loading: false,
      };

    case actionTypes.RESET_VALUES:
      return {
        ...state,
        creditData: {
          buyingIntentionTime: '',
          buyingIntentionTimeState: '',
          buyingIntentionTimeStateError: true,
        },
      };
    case actionTypes.SET_FIRST_PRODUCT:
      return {
        ...state,
        selected_button_product: 'new',
      };
    case actionTypes.SET_SECOND_PRODUCT:
      return {
        ...state,
        selected_button_product: 'used',
      };

    case actionTypes.TOGGLE_PERSONAL_DATA_STATE:
      return {
        ...state,
        completedPersonalData: action.newState,
      };
    case actionTypes.TOGGLE_PRODUCTS_STATE:
      return {
        ...state,
        title_products_enable: action.newState,
      };
    default:
      break;
  }

  switch (action.type) {
    case actionTypes.TOGGLE_INPUTS_STATE:
      return {
        ...state,
        inputs_enable: action.newState,
      };
    case actionTypes.UPDATE_INPUT_PERSONAL_DATA_NAME:
      return updatePersonalDataName(action, state);

    case actionTypes.UPDATE_INPUT_PERSONAL_DATA_LAST_NAME:
      return updatePersonalDataLastName(action, state);

    case actionTypes.UPDATE_INPUT_PERSONAL_DATA_PHONE:
      return updatePersonalDataPhone(action, state);

    case actionTypes.UPDATE_INPUT_PERSONAL_DATA_EMAIL:
      return updatePersonalDataEmail(action, state);

    case actionTypes.UPDATE_INPUT_PERSONAL_DATA_MARITAL_STATUS:
      return updatePersonalDataMaritalStatus(action, state);

    case actionTypes.UPDATE_INPUT_PERSONAL_DATA_SEPARATION_OF_PROPERTY:
      return updatePersonalDataSeparationOfProperty(action, state);

    case actionTypes.UPDATE_INPUT_PERSONAL_DATA_ID_CARD:
      return updatePersonalDataIdCard(action, state);

    case actionTypes.UPDATE_INPUT_PERSONAL_DATA_ID_CARD_PARTNER:
      return updatePersonalDataIdCardPartner(action, state);

    case actionTypes.UPDATE_INPUT_WORK_DATA_WORK_STATUS:
      return updateWorkDataWorkStatus(action, state);
    default:
      break;
  }
  switch (action.type) {
    case actionTypes.UPDATE_INPUT_WORK_DATA_PARTNER_WORK_STATUS:
      return updateWorkDataPartnerWorkStatus(action, state);

    case actionTypes.UPDATE_INPUT_WORK_DATA_MONTHLY_INCOME:
      const monthlyIncomeInputState = updateMonthlyIncomeStatus(
        action.newValue,
        true,
        action.basicSalary
      );
      return {
        ...state,
        workData: {
          ...state.workData,
          monthlyIncome: formatCurrency(action.newValue),
          monthlyIncomeNumber: Number(removeCommas(action.newValue)),
          monthlyIncomeState: monthlyIncomeInputState.state,
          monthlyIncomeError: monthlyIncomeInputState.error,
          monthlyIncomeErrorHelper: monthlyIncomeInputState.errorHelper,
          partnerWorkStatus: 'empty',
          partnerWorkStatusState: 'normal',
          partnerWorkStatusError: partnerDataRequired(
            state.personalData.maritalStatus,
            state.personalData.separationOfProperty
          ),
          partnerWorkSeniority: 'empty',
          partnerWorkSeniorityState: 'normal',
          partnerWorkSeniorityStateError: partnerDataRequired(
            state.personalData.maritalStatus,
            state.personalData.separationOfProperty
          ),
          partnerMonthlyIncome: '',
          partnerMonthlyIncomeError: partnerDataRequired(
            state.personalData.maritalStatus,
            state.personalData.separationOfProperty
          ),
          partnerMonthlyIncomeState: 'normal',
        },
        completedWorkData: completedWorkData(
          monthlyIncomeInputState.error,
          state.completedWorkData
        ),
      };
    case actionTypes.UPDATE_INPUT_WORK_DATA_PARTNER_MONTHLY_INCOME:
      const partnerMonthlyIncomeInputState = updateMonthlyIncomeStatus(
        action.newValue,
        false,
        action.basicSalary
      );
      return {
        ...state,
        workData: {
          ...state.workData,
          partnerMonthlyIncomeState: partnerMonthlyIncomeInputState.state,
          partnerMonthlyIncomeError: partnerMonthlyIncomeInputState.error,
          partnerMonthlyIncome: formatCurrency(action.newValue),
          partnerMonthlyIncomeNumber: Number(removeCommas(action.newValue)),
        },
        completedWorkData: completedWorkData(
          partnerMonthlyIncomeInputState.error,
          state.completedWorkData
        ),
      };

    case actionTypes.UPDATE_SHOW_DATA_PROTECTION_MODAL:
      return { ...state, dataProtectionModalOpen: action.open };

    case actionTypes.UPDATE_DATA_PROTECTION_ERROR:
      return { ...state, ldpdError: action.error };

    case actionTypes.UPDATE_INPUT_PROVINCE_RESIDENCE:
      return updateProvinceResidence(action, state);

    case actionTypes.UPDATE_INPUT_CAPITAL_RESIDENCE:
      return updateCapitalResidence(action, state);
    default:
      break;
  }

  switch (action.type) {
    case actionTypes.EXPRESS_APPLICATIONS_REQUEST:
      return {
        ...state,
        expressApplication_loading: true,
        expressApplication_success: false,
        showAlert: false,
      };
    case actionTypes.EXPRESS_APPLICATIONS_SUCCESS:
      return expressAplicationSuccess(action, state);

    case actionTypes.EXPRESS_APPLICATIONS_FAILED:
      return expressAplicationFailed(action, state);

    case actionTypes.TOGGLE_ALERT:
      return {
        ...state,
        showAlert: action.state,
      };
    case actionTypes.UPDATE_INPUT_WORK_DATA_SENIORITY:
      const {
        workSeniorityState: workSeniorityStateUpdated,
        workSeniorityStateError: workSeniorityStateErrorUpdated,
        workSeniorityStateErrorHelper: workSeniorityStateErrorHelperUpdated,
      } = getCurrentWorkSeniorityStatus(
        'creditHolder',
        state.workData.workStatus,
        false,
        action.newValue
      );
      return {
        ...state,
        workData: {
          ...state.workData,
          workSeniority: action.newValue,
          workSeniorityState: workSeniorityStateUpdated,
          workSeniorityStateError: workSeniorityStateErrorUpdated,
          workSeniorityStateErrorHelper: workSeniorityStateErrorHelperUpdated,
          monthlyIncome: '',
          monthlyIncomeError: true,
          monthlyIncomeErrorHelper: '',
          partnerWorkStatus: 'empty',
          partnerWorkStatusState: 'normal',
          previousEndWork: null,
          previousInitWork: null,
          partnerWorkStatusError: partnerDataRequired(
            state.personalData.maritalStatus,
            state.personalData.separationOfProperty
          ),
          partnerWorkSeniority: 'empty',
          partnerWorkSeniorityState: 'normal',
          partnerWorkSeniorityStateError: partnerDataRequired(
            state.personalData.maritalStatus,
            state.personalData.separationOfProperty
          ),
          partnerMonthlyIncome: '',
          partnerMonthlyIncomeError: partnerDataRequired(
            state.personalData.maritalStatus,
            state.personalData.separationOfProperty
          ),
          partnerMonthlyIncomeState: 'normal',
        },
      };
    case actionTypes.UPDATE_INPUT_WORK_DATA_PARTNER_SENIORITY:
      const {
        workSeniorityState: partnerWorkSeniorityStateUpdated,
        workSeniorityStateError: partnerWorkSeniorityStateErrorUpdated,
      } = getCurrentWorkSeniorityStatus(
        'partner',
        state.workData.partnerWorkStatus,
        false,
        action.newValue
      );
      return {
        ...state,
        workData: {
          ...state.workData,
          partnerWorkSeniority: action.newValue,
          partnerWorkSeniorityState: partnerWorkSeniorityStateUpdated,
          partnerWorkSeniorityStateError: partnerWorkSeniorityStateErrorUpdated,
          partnerMonthlyIncome: '',
          partnerMonthlyIncomeState: 'normal',
          partnerMonthlyIncomeError: true,
          partnerPreviousEndWork: null,
          partnerPreviousInitWork: null,
        },
      };
    case actionTypes.UPDATE_CALENDAR_WORK_DATA_PREVIOUS_INIT_WORK:
      return {
        ...state,
        workData: {
          ...state.workData,
          previousInitWork: action.newValue,
        },
      };
    case actionTypes.UPDATE_CALENDAR_WORK_DATA_PREVIOUS_END_WORK:
      return {
        ...state,
        workData: {
          ...state.workData,
          previousEndWork: action.newValue,
        },
      };
    case actionTypes.UPDATE_CALENDAR_WORK_DATA_PARTNER_PREVIOUS_INIT_WORK:
      return {
        ...state,
        workData: {
          ...state.workData,
          partnerPreviousInitWork: action.newValue,
        },
      };
    case actionTypes.UPDATE_CALENDAR_WORK_DATA_PARTNER_PREVIOUS_END_WORK:
      return {
        ...state,
        workData: {
          ...state.workData,
          partnerPreviousEndWork: action.newValue,
        },
      };
    default:
      return state;
  }
};

export default expressRequest;
