import React from 'react';


interface IconWithTextProps {
    icon: string;
    text: string;
    class: string;
}



class IconWithText extends React.Component<IconWithTextProps> {

    render() {
        return (
            <div className="text-icon">
                <div className="icon-container">
                    <pichincha-icon
                        size="1.5rem"
                        color="darkGrey"
                        weight_color="500"
                        type="--outlined"
                    >
                        {this.props.icon}
                    </pichincha-icon>
                </div>

                <label className={this.props.class}>{this.props.text}</label>
            </div>
        );
    }
}

export default IconWithText;