import React, { Component } from "react";
import "./StepContainer.scss";

interface StepContainerProps {
  class?: string;
  number: any;
  name: string;
  checked?: boolean;
}

class StepContainer extends Component<StepContainerProps> {
  render() {
    let circleClassName;
    let nameClassName;
    let numberContain;

  
    if (this.props.class === "selected" && this.props.checked === false) {
      circleClassName = "circle-step-selected";
      nameClassName = "name-step-selected";
    }
    if (this.props.class === "disabled" && this.props.checked === false){
      circleClassName = "circle-step-disabled";
      nameClassName = "name-step-disabled";
    }

    if (this.props.checked) {
      circleClassName = "circle-step-checked";
      nameClassName = "name-step-checked";
      numberContain = (
        <div className="icon-align">
          <pichincha-icon
            size="15px"
            color="white"
            weight_color="900"
            type="--outlined"
          >
            check
          </pichincha-icon>
        </div>
      );
    } else {
      numberContain = this.props.number;
    }
    nameClassName += " desktop";
    return (
      <pichincha-grid
        container
        justify="center"
        align_items="center"
        direction="column"
        spacing={2}
      >
        <pichincha-grid item>
          <div className="width-adjust">
            <div
              className={circleClassName}
              data-testid="stepGuideNumberContainerTestId"
            >
              {numberContain}
            </div>
          </div>
        </pichincha-grid>
        <pichincha-grid item>
          <div className={nameClassName}>{this.props.name}</div>
        </pichincha-grid>
      </pichincha-grid>
    );
  }
}
export default StepContainer;
