import { FunctionComponent, useCallback, useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { ReduxState } from "../../common/store";

import actions from "../../actions";
import actionTypes from "../../actions/actionTypes";
import * as CONSTANTS from "../../common/helpers/constants";
import {
  sendGTMClickEvent,
  sendGTMUserEvent
} from "../../common/helpers/gtmEvents";
import Blocked from "../Errors/Blocked";

const OtpPage: FunctionComponent<ReduxProps> = (props) => {
  const {
    maskedEmail,
    maskedPhone,
    otpStatus,
    otpStateButton,
    otpStateResend,
    idCardLogin,
    otp,
    channel,
    wrongCode,
    showBlock,
    otpTime,
    jwt,
    updateInputContent: updateInput,
    loginResetState: resetState,
    requestOtpResend: resendOtp,
    sendOtpCode: sendOtp,
    resetOtpError: resetError,
    resetTokenSession: resetJwt,
    isSendingRequest,
  } = props;

  let description = "";
  if (maskedEmail && maskedPhone) {
    description = `Confirma tu identidad ingresando el código de 6 números que llegará a tu correo electrónico ${maskedEmail} y/o celular ${maskedPhone}`;
  }

  if (!maskedEmail) {
    description = `Confirma tu identidad ingresando el código de 6 números que llegará a tu celular ${maskedPhone}`;
  }

  if (!maskedPhone) {
    description = `Confirma tu identidad ingresando el código de 6 números que llegará a tu correo electrónico ${maskedEmail}`;
  }

  const handleSendOTP = useCallback(() => {
    if (!isSendingRequest) {
      setSeindingRequest(true);
      sendGTMClickEvent("continue");

      //@ts-ignore
      grecaptcha.ready(function () {
        //@ts-ignore
        grecaptcha
          .execute(CONSTANTS.REACT_APP_RECAPTCHA_TOKEN)
          .then(function (recaptchaToken: string) {
            const headers = {
              token: recaptchaToken,
              GUID: CONSTANTS.GUID,
              channel,
              auth: jwt,
            };
            sendOtp(headers, idCardLogin, otp);
          });
      });
    }
  }, [channel, idCardLogin, otp, jwt, sendOtp, isSendingRequest]);

  const handleRequestResendOTP = useCallback(() => {
    if (!isSendingRequest) {
      setSeindingRequest(true);
      sendGTMClickEvent("resendCode");

      const payload = {
        identificationNumber: idCardLogin,
        identificationType: CONSTANTS.CLIENT_IDENTIFICATION_TYPE,
      };

      //@ts-ignore
      grecaptcha.ready(function () {
        //@ts-ignore
        grecaptcha
          .execute(CONSTANTS.REACT_APP_RECAPTCHA_TOKEN)
          .then(function (recaptchaToken: string) {
            const headers = {
              token: recaptchaToken,
              GUID: CONSTANTS.GUID,
              channel,
              auth: jwt,
            };
            resendOtp(payload, headers);
          });
      });
    }
  }, [channel, idCardLogin, jwt, resendOtp, isSendingRequest]);

  useEffect(() => {
    const handleChangeInput = (event: any) => {
      updateInput(event.detail, actionTypes.UPDATE_OTP_VALUE);
    };
    document.addEventListener("eventChangeInputOtp", handleChangeInput);
    return () =>
      document.removeEventListener("eventChangeInputOtp", handleChangeInput);
  }, [updateInput]);

  useEffect(() => {
    document.addEventListener("clickContinue", handleSendOTP);
    return () => document.removeEventListener("clickContinue", handleSendOTP);
  }, [handleSendOTP]);

  useEffect(() => {
    const handleClickGoBack = () => {
      sendGTMClickEvent("goBack");
      resetState();
      resetError();
      resetJwt();
    };
    document.addEventListener("clickGoBack", handleClickGoBack);
    return () => document.removeEventListener("clickGoBack", handleClickGoBack);
  }, [resetState, resetError, resetJwt]);

  useEffect(() => {
    document.addEventListener("clickResendCode", handleRequestResendOTP);
    return () =>
      document.removeEventListener("clickResendCode", handleRequestResendOTP);
  }, [handleRequestResendOTP]);

  useEffect(() => {
    return () => {
      resetError();
    };
  }, [resetError]);

  useEffect(() => {
    window.history.pushState(null, "", window.location.href);
    window.onpopstate = function () {
      window.history.pushState(null, "", window.location.href);
    };
    let otpContent = document
      .getElementById("otpPage")
      ?.shadowRoot?.getElementById("info");
    otpContent?.addEventListener("click", () => sendGTMClickEvent("info"));
    sendGTMUserEvent(props.channel, "otp", props.userType);
  });
  let blockingTimeMessage = "Reinténtalo en 1 hora.";

  let content = (
    <pichincha-otp
      title-otp="Validación"
      description={description}
      status={otpStatus}
      state-button={otpStateButton}
      state-resend={otpStateResend}
      type-password={true}
      error-code={wrongCode}
      data-testid="otpComponentTestId"
      timer={otpTime}
      id="otpPage"
    />
  );

  if (showBlock) {
    content = (
      <div>
        <Blocked
          handleClick={() => window.location.reload()}
          blockingTimeMessage={blockingTimeMessage}
        />
      </div>
    );
  }

  return <div className="otp-inner">{content}</div>;
};

const mapStateToProps = (state: ReduxState) => {
  const { login, site } = state;
  const { channel, jwt, userType } = site;
  const {
    loading,
    maskedEmail,
    maskedPhone,
    otpStatus,
    otpStateButton,
    otpStateResend,
    idCardLogin,
    otp,
    wrongCode,
    otpTime,
    showBlock,
    isSendingRequest,
  } = login;
  return {
    channel,
    loading,
    maskedEmail,
    maskedPhone,
    otpStatus,
    otpStateButton,
    otpStateResend,
    idCardLogin,
    otp,
    wrongCode,
    showBlock,
    otpTime,
    userType,
    jwt,
    isSendingRequest,
  };
};

const {
  updateInputContent,
  loginResetState,
  toggleCompleteIdCard,
  sendCifVerification,
  updateStateValue,
  sendOtpCode,
  requestOtpResend,
  resetOtpError,
  resetTokenSession,
  getNamesCivilRegistration,
  setSeindingRequest,
} = actions;

const connector = connect(mapStateToProps, {
  updateInputContent,
  loginResetState,
  toggleCompleteIdCard,
  sendCifVerification,
  updateStateValue,
  sendOtpCode,
  requestOtpResend,
  resetOtpError,
  resetTokenSession,
  getNamesCivilRegistration,
  setSeindingRequest,
});

type ReduxProps = ConnectedProps<typeof connector>;

export default connector(OtpPage);
