import { useEffect } from 'react';


export default function ClosePopOver() {

    const clickFunction = (e: any) => {
        if (
            e.target.className !== "popover-content popover-content-visible" &&
            e.target.className !== "title-popover" &&
            e.target.className !== "contain-popover" &&
            e.target.className !== "text popover-link" &&
            e.target.className !== "poporver-title-section"
        ) {
            let popover = document.getElementsByClassName(
                "popover-content popover-content-visible"
            )[0];
            popover?.classList.remove("popover-content-visible");
            popover?.classList.add("popover-content-hidden");
        }
    };
    useEffect(() => {
        document.addEventListener("click", clickFunction);
        return () => document.removeEventListener('click', clickFunction);
    }, []);

}