import React, { Component } from "react";

import { connect, ConnectedProps } from "react-redux";
import { ReduxState } from "../../common/store";

import StepContainer from "./StepContainer";
import "./StepsGuide.scss";

class StepsGuide extends Component<ReduxProps> {
  render() {
    const {
      currentStep,
      completedPersonalData,
      completedWorkData,
      completedLoanData,
    } = this.props;
    return (
      <div>
        <div className="align-step" data-testid="stepsContainerId">
          <StepContainer
            number="1"
            name="Datos personales"
            checked={completedPersonalData}
            class={
              currentStep === 1 && !completedPersonalData
                ? "selected"
                : "disabled"
            }
          />
          <StepContainer
            number="2"
            name="Datos laborales"
            checked={currentStep !== 1 && completedWorkData}
            class={
              currentStep === 2 && !completedWorkData ? "selected" : "disabled"
            }
          />
          <StepContainer
            number="3"
            name="Datos de crédito"
            checked={completedLoanData}
            class={
              currentStep === 3 && !completedLoanData ? "selected" : "disabled"
            }
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: ReduxState) => {
  const { expressRequest } = state;
  const {
    currentStep,
    completedPersonalData,
    completedWorkData,
    completedLoanData,
  } = expressRequest;
  return {
    currentStep,
    completedPersonalData,
    completedWorkData,
    completedLoanData,
  };
};

const connector = connect(mapStateToProps);

type ReduxProps = ConnectedProps<typeof connector>;

export default connector(StepsGuide);
