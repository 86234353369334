import React, { Component } from "react";
import { connect, ConnectedProps } from "react-redux";
import { ReduxState } from "../../../common/store";
import Button from "../../Button";

import successIconCircle from "../../../assets/img/img-success.svg";
import actions from "../../../actions";

import "./SuccessFacephi.scss";
import {
  FACEPHI_VALIDATED_IMAGE_KEY,
  REACT_APP_RECAPTCHA_TOKEN,
} from "../../../common/helpers/constants";
import Cookies from "js-cookie";
import MessageBar from "../../MessageBar/MessageBar";
import actionTypes from "../../../actions/actionTypes";
import { getEncryptedItemFromCookie } from "../../../common/helpers/storage-utils";

class SuccessFacephi extends Component<ReduxProps> {
  constructor(props: any) {
    super(props);
    this.state = { execBtn: true };
    this.sendApplication = this.sendApplication.bind(this);
    this.closeAlert = this.closeAlert.bind(this);
  }

  sendApplication() {
    const { sendExpressRegistration: requestPrequalification } = this.props;
    const { payload } = this.props.expressRequest;
    const { channel, jwt } = this.props.site;

    const facephiValidImageToken =
      Cookies.get(FACEPHI_VALIDATED_IMAGE_KEY) || "{}";
    const parsedFacephiData = getEncryptedItemFromCookie("facephi") || "{}";

    const { token: facephiToken } = JSON.parse(facephiValidImageToken);
    const { guid } = parsedFacephiData;
    const { utmHeaders } = parsedFacephiData;

    //@ts-ignore
    if (this.state.execBtn) {
      this.setState({ execBtn: false });
      //@ts-ignore
      grecaptcha
        .execute(REACT_APP_RECAPTCHA_TOKEN)
        .then(function (recaptchaToken: string) {
          const headers = {
            token: recaptchaToken,
            GUID: guid,
            jwt: jwt,
            imageAuthToken: facephiToken,
          };
          requestPrequalification(
            JSON.parse(payload),
            "prequalification",
            utmHeaders,
            channel,
            headers
          );
        });
    }
  }

  closeAlert() {
    this.props.updateStateValue(actionTypes.TOGGLE_ALERT, false);
  }

  render() {
    const { failuresPrequalificationCount, showAlert } =
      this.props.expressRequest;
    const { acceptLdpd } = this.props.civilRegistration;
    const continueButtonProps = {
      color: "primary",
      size: "large",
      text: failuresPrequalificationCount
        ? "Reintentar"
        : "Ver precalificación",
      id: `facephiSucceessContinueButton`,
      handleClick: this.sendApplication,
      disabled: !acceptLdpd,
      testId: "facephiSucceessContinueButtonTestId",
    };
    const errorMessageBarProps = {
      id: "errorAtSendExpressRequest",
      message: "Los datos no se enviaron correctamente, vuelve a intentarlo",
      variant: "light",
      color: "error",
      extraClasses: "invalid-request error-message-login bp-mb-16 bp-mt-8 ",
      handleClose: this.closeAlert,
    };
    return (
      <div className="error-page something-went-wrong bp-mb-120">
        {showAlert ? (
          <div className="message-bar-container success-facephi">
            <MessageBar {...errorMessageBarProps} />
          </div>
        ) : null}
        <div className="ct__confirmation">
          <div className="bp-mb-40">
            <img alt="" src={successIconCircle} />
          </div>

          <div className="ct__confirmation-typography bp-mb-8">
            <pichincha-typography variant="h3" color="blue" weight="normal">
              Validación exitosa
            </pichincha-typography>
          </div>

          <div className="ct__confirmation-typography bp-mb-32">
            <pichincha-typography>
              Hemos comprobado tu identidad. Ya puedes el revisar el resultado
              de tu precalifación.
            </pichincha-typography>
          </div>

          <div className="ct__confirmation-button-container">
            <div className="ct__confirmation-button">
              <Button {...continueButtonProps} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: ReduxState) => {
  const { expressRequest, site, civilRegistration } = state;
  return {
    expressRequest,
    site,
    civilRegistration,
  };
};

const { sendExpressRegistration, updateStateValue } = actions;

const connector = connect(mapStateToProps, {
  sendExpressRegistration,
  updateStateValue,
});

type ReduxProps = ConnectedProps<typeof connector>;

export default connector(SuccessFacephi);
