import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

import reportWebVitals from "./reportWebVitals";
import store, { persistor } from "./common/store";
import App from "./App";

import "./index.scss";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { REACT_APP_RECAPTCHA_TOKEN } from "./common/helpers/constants";

declare global {
  namespace JSX {
    interface IntrinsicElements {
      "pichincha-message-bar": any;
      "pichincha-button": any;
      "pichincha-brand": any;
      "pichincha-link": any;
      "pichincha-icon": any;
      "pichincha-avatar": any;
      "pichincha-typography": any;
      "pichincha-check-box": any;
      "pichincha-grid": any;
      "pichincha-input": any;
      "pichincha-tooltip": any;
      "pichincha-spinner": any;
      "pichincha-radio-buttom": any;
      "pichincha-tabs": any;
      "pichincha-tab": any;
      "pichincha-tab-content": any;
      "pichincha-otp": any;
      "pichincha-modal": any;
      "pichincha-list": any;
    }
  }
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <GoogleReCaptchaProvider reCaptchaKey={REACT_APP_RECAPTCHA_TOKEN}>
          <App />
        </GoogleReCaptchaProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
