import React,{Component} from "react";

interface BackdropProps{
    click:any,
}

class Backdrop extends Component<BackdropProps>{
    
    render() {
        return (
            <div className="backdrop-style" onClick={this.props.click}></div>        
        );
    }
}

export default Backdrop;