import React, { useEffect, useState } from 'react'
import { STYLE_DEFAULT, STYLE_ACTIVE } from '../../../../common/helpers/constants'
import useWindowSize from "../../../Shared/hooks/UseWindowSize";
import "./StepsNumbers.scss";

export const StepsNumber = (props: {stepNumber: string}) => {

    const [fromFirstStepPx, setFromFirstStepPx] = useState(730)
    const [toLastStepPx, setToLastStepPx ] = useState(4280)
    const [rangeFirtsLastPx, setRangeFirtsLastPx] = useState(500)
    const [rangeOtherSteps, setRangeOtherSteps] = useState(200)
    const [endFirstPx, setEndFirstPx] = useState( fromFirstStepPx + rangeFirtsLastPx)
    
    const [width] = useWindowSize();
    const [info, setInfo] = useState(STYLE_DEFAULT)
    const [simulate, setSimulate] = useState(STYLE_DEFAULT)
    const [prequali, setPrequali] = useState(STYLE_DEFAULT)
    const [obtain, setObtain] = useState(STYLE_DEFAULT)
    const [sign, setSign] = useState(STYLE_DEFAULT)

     /**
     * Set default styles for number of vertical steps
     */
      const resetValues = () => {
        setInfo(STYLE_DEFAULT)
        setSimulate(STYLE_DEFAULT)
        setPrequali(STYLE_DEFAULT)
        setObtain(STYLE_DEFAULT)
        setSign(STYLE_DEFAULT)
    }

    useEffect(()=> {
        if(width > 1199) {
            setFromFirstStepPx(900)
            setToLastStepPx(4280)
            setRangeFirtsLastPx(400)
            setRangeOtherSteps(150)
        }
        if(width > 601 && width < 1199){
            setFromFirstStepPx(400)
            setToLastStepPx(4280)
            setRangeFirtsLastPx(600)
            setRangeOtherSteps(200)
        }
        if(width < 600){
            setFromFirstStepPx(1026)
            setToLastStepPx(4280)
            setRangeFirtsLastPx(500)
            setRangeOtherSteps(200)
        }
        
    }, [width])

    useEffect(()=> {
        setEndFirstPx(fromFirstStepPx + rangeFirtsLastPx)
    }, [fromFirstStepPx, rangeFirtsLastPx])

    const setStep = (stepNumber: string) => {
        switch (stepNumber) {
            case '1':
                return info
            case '2':
                return simulate
            case '3':
                return prequali
            case '4':
                return obtain
            case '5':
                return sign
        }
    }

    


    /**
     * Set the active style according to the scroll position
     * @returns Object - active state 
     */
    const getOtherColors = () => {
        if (window.scrollY > (endFirstPx) + (rangeOtherSteps) + 2 && window.scrollY <= toLastStepPx) {
            if ((window.scrollY > (endFirstPx) + (rangeOtherSteps) + 2 && window.scrollY <= ((endFirstPx)  + (rangeOtherSteps * 2) + 2)))  return setPrequali(STYLE_ACTIVE)
            if ((window.scrollY > ((endFirstPx)  + (rangeOtherSteps * 2) + 2) && window.scrollY <= ((endFirstPx)  + (rangeOtherSteps * 3) + 2))) return setObtain(STYLE_ACTIVE)
            if ((window.scrollY > ((endFirstPx)  + (rangeOtherSteps * 3) + 2) && window.scrollY <= toLastStepPx)) return setSign(STYLE_ACTIVE)
        }

    }

    const getColor = () => {
        if (window.scrollY >= fromFirstStepPx && window.scrollY <= toLastStepPx) {
            resetValues()
            
            if ((window.scrollY >= fromFirstStepPx && window.scrollY <= endFirstPx)) return setInfo(STYLE_ACTIVE)
            if ((window.scrollY > (endFirstPx) + 1 && window.scrollY <= (endFirstPx) + 1 + (rangeOtherSteps))) return setSimulate(STYLE_ACTIVE)
            return getOtherColors()
        }
        return setInfo(STYLE_DEFAULT)
    }

    useEffect(() => {
        window.addEventListener('scroll', getColor);
        return () =>
            window.removeEventListener('scroll', getColor);
    })


    return (

        <span className='step-number' style={setStep(props.stepNumber)}>{props.stepNumber}
        </span>
    )
}
