import React, { Component } from "react";
import { connect, ConnectedProps } from "react-redux";
import { ReduxState } from "../../../common/store";
import actionTypes from "../../../actions/actionTypes";
import actions from "../../../actions/";
import TitlePage from "../TitlePage/TitlePage";
import StepsGuide from "../../StepsGuide/StepsGuide";
import { capitalizeName } from "../../../common/helpers/functions";
import Button from "../../Button";
import Thumbnail from "../../Thumbnail";

import Exito from "../../../assets/img/Exito.svg";
import { sendGTMUserEvent } from "../../../common/helpers/gtmEvents";
import { setItemToCookie } from "../../../common/helpers/storage-utils";

class ThankYouPage extends Component<ReduxProps> {
  constructor(props: ReduxProps) {
    super(props);
    this.showFeedback = this.showFeedback.bind(this);
  }
  showFeedback() {
    this.props.cleanSession();
    this.props.resetExpressRequest(actionTypes.EXPRESS_REQUEST_RESET_STATE);
    this.props.resetCivilRegistration();
    this.props.loginResetState();
    this.props.resetTokenSession();
    window.location.href = "/"
  }

  render() {
    const { name } = this.props.expressRequest.personalData;
    const { error422 } = this.props.expressRequest;
    const { isMVP } = this.props.expressRequest.prequalificationData;
    const { firstName } = this.props;
    const displayName = name || firstName;
    let content: any;
    let imageId = "successRequest";
    let stepId = "felicitaciones";
    if (error422) {
      imageId = "errorAtHardFilter"
      stepId = "rechazadoFiltros"
    }

    sendGTMUserEvent(this.props.channel, stepId, this.props.userType);

    const continueButtonProps = {
      color: "primary",
      size: "large",
      text: "Finalizar",
      testId: "backStartButtonExpressRequestTestId",
      id: `backStartButtonExpressRequest`,
      handleClick: this.showFeedback,
      disabled: false,
    };


    const getReturn = () => {
      if (isMVP) {
        setItemToCookie("firstPrequalification", "true" );
        setTimeout(() => {
          window.location.href = "/portal/solicitud"
        }, 3000);
        return
      }
      return (
        <div
          className="data-form-express-request step-page"
          data-testid="requestSuccessContainerTestId"
        >
          <pichincha-grid container justify="center">
            <TitlePage
              title="Solicitud de crédito finalizada con éxito"
              name=""
            />
            <pichincha-grid item xs="12">
              <StepsGuide />
            </pichincha-grid>
            <div className="form-input bp-mt-16 bp-mb-72 express-request thank-you-page">
              <pichincha-grid item xs="12">
                <div className="image-wrapper" id={imageId}>
                  <Thumbnail url={Exito} name={"imagen"} />
                </div>
              </pichincha-grid>
              <pichincha-grid item xs="12">
                <pichincha-grid container justify="center">
                  <p className="text-prelo-slab-semibold text-dark-grey-500 text-font-size-28 bp-mt-32">
                    {`¡Gracias, ${capitalizeName(displayName)}!`}
                  </p>
                  <pichincha-grid item xs="12">
                    <div className="final-text">
                      {!error422 && (
                        <>
                          <pichincha-typography
                            variant="bodyText"
                            color="darkGrey"
                            align="center"
                            id="successRequest"
                          >
                            Puedes seguir adelante con tu solicitud de crédito.
                          </pichincha-typography>
                          <pichincha-typography
                            variant="bodyText"
                            color="darkGrey"
                            align="center"
                          >
                            En las próximas horas un asesor especializado en
                            créditos hipotecarios se contactará contigo vía
                            telefónica y/o mail para guiarte en los siguientes
                            pasos.
                          </pichincha-typography>
                        </>
                      )}
                      {error422 && (
                        <>
                          <pichincha-typography
                            variant="bodyText"
                            color="darkGrey"
                            align="center"
                            id="successRequest"
                          >
                            Tu solicitud ha sido enviada con exito. Nos
                          </pichincha-typography>
                          <pichincha-typography
                            variant="bodyText"
                            color="darkGrey"
                            align="center"
                          >
                            pondremos en contacto contigo muy pronto
                          </pichincha-typography>
                        </>
                      )}
                      {content}
                    </div>
                  </pichincha-grid>
                </pichincha-grid>
              </pichincha-grid>
              <pichincha-grid item xs="12">
                <div className="button-container">
                  <Button {...continueButtonProps} />
                </div>
              </pichincha-grid>
            </div>
          </pichincha-grid>
        </div>
      )
    }

    return getReturn();
  }
}

const mapStateToProps = (state: ReduxState) => {
  const { expressRequest, civilRegistration, site, login } = state;

  const { channel, userType, jwt } = site;
  const { firstName } = civilRegistration;
  return { firstName, userType, channel, expressRequest, jwt, login };
};


const { setStep, triggerAction, cleanSession, resetCivilRegistration, resetExpressRequest, loginResetState, resetTokenSession } = actions;

const connector = connect(mapStateToProps, {
  setStep,
  triggerAction,
  cleanSession,
  resetCivilRegistration,
  resetExpressRequest,
  loginResetState,
  resetTokenSession
});

type ReduxProps = ConnectedProps<typeof connector>;

export default connector(ThankYouPage);
