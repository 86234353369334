import React, { useEffect, useState } from "react";

import {
  LABELS_CALENDAR,
  MONTHS_CALENDAR,
  RADIX_PARAMETER,
} from "../../common/helpers/constants";
import { MonthsI } from "../../interfaces/app";
import Button from "../Shared/Components/Button";
import Input from "../Shared/Components/Input";
import Select, { OptionInterface } from "../Shared/Components/Select";
import Typography from "../Shared/Components/Typography";
import { CalendarMonth, CalendarOptions, CalendarWrapper } from "./styles";

const months = MONTHS_CALENDAR;

export interface CalendarProps {
  onSelect: (item: string) => void;
  defaultValue?: string;
  label: string;
  disabled?: boolean;
  error?: boolean;
  errorMsg?: string;
  listYears: Array<OptionInterface>;
  listDisabledMoths?: Array<MonthsI>;
  cleanDate?: boolean;
}

const Calendar: React.FC<CalendarProps> = ({
  defaultValue,
  onSelect,
  label = "",
  disabled,
  error,
  errorMsg,
  listYears,
  listDisabledMoths,
  cleanDate,
}) => {
  const [year, setYear] = useState("");
  const [errorYear, setErrorYear] = useState(false);
  const [showCalendar, setShowCalendar] = useState(false);
  const currentDate = new Date();
  const disabledFutureMonths: any = [];

  const handleYearChange = (event: any) => {
    setYear(event.value);
    setErrorYear(false);
  };

  const handleClean = () => {
    setSelectedOption("");
    setYear("");
    onSelect("/");
  };

  const handleCleanAndExit = () => {
    handleClean();
    setShowCalendar(false);
  };

  const handleMouseDown = (monthsObj: MonthsI) => {
    if (year === listYears[listYears.length - 1].value) {
      if (!listDisabledMoths?.includes(monthsObj)) {
        selectDate(monthsObj);
      }
    } else {
      if (currentDate.getFullYear().toString() === year) {
        if (!disabledFutureMonths?.includes(monthsObj)) {
          selectDate(monthsObj);
        }
      } else {
        selectDate(monthsObj);
      }
    }
    if (year === "") setErrorYear(true);
  };

  const getClassMonths = (monthsObj: MonthsI) => {
    let classDisabled = "day";
    if (listYears[listYears.length - 1].value === year) {
      classDisabled = `${
        listDisabledMoths?.includes(monthsObj) ? "day disabled-day" : "day"
      }`;
    }

    if (currentDate.getMonth() < parseInt(monthsObj.key, RADIX_PARAMETER)) {
      disabledFutureMonths.push(monthsObj);
    }
    if (currentDate.getFullYear().toString() === year) {
      classDisabled = `${
        disabledFutureMonths.includes(monthsObj) ? "day disabled-day" : "day"
      }`;
    }
    return `${classDisabled} ${
      +selectedOption === +monthsObj.value ? " selected" : ""
    }`;
  };
  const selectDate = (monthsObj: MonthsI) => {
    setSelectedOption(monthsObj.value);
    onSelect(monthsObj.key + "/" + year);
    if (year !== "") setShowCalendar(false);
  };

  const handleCalendarClick = () => {
    setShowCalendar(true);
    handleClean();
  };

  const [selectedOption, setSelectedOption] = useState<any>();

  /* istanbul ignore next */
  useEffect(() => {
    setSelectedOption("");
  }, []);

  useEffect(() => {
    if (disabled) handleClean();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disabled]);

  useEffect(() => {
    if (cleanDate) return handleClean();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cleanDate]);

  return (
    <CalendarWrapper>
      <Input
        labelColor="grayscale500"
        disabled={disabled}
        placeholder="mm/aaaa"
        label={label}
        value={defaultValue}
        id="day"
        onClick={() => {
          !disabled && handleCalendarClick();
        }}
        onBlur={() => {}}
        readOnly={true}
        icon={{
          iconName: "calendar_today",
        }}
        error={error}
        errorMessage={errorMsg}
        testid={
          label === LABELS_CALENDAR.INIT_ACTIVITIES ||
          label === LABELS_CALENDAR.INIT_DATE
            ? "calendar-init"
            : "calendar-end"
        }
      />
      {!disabled && showCalendar && (
        <CalendarOptions>
          <div className="calendar-options">
            <div className="year-select">
              <Select
                id="select-calendar"
                label=""
                showNoSelectOption={false}
                placeHolder="Año"
                disabled={false}
                error={errorYear}
                errorMessage={"Selecciona un año"}
                onSelect={(e: any) => handleYearChange(e)}
                selectOptionList={listYears}
              ></Select>
            </div>
            <div className="clean-button">
              <Button variant="secondary" onClick={handleCleanAndExit}>
                Limpiar
              </Button>
            </div>
          </div>
          <CalendarMonth>
            <div className="days_container">
              {months &&
                months.map((monthsObj: MonthsI) => (
                  <Typography
                    key={monthsObj.key}
                    fontSize={"12"}
                    className={getClassMonths(monthsObj)}
                    onMouseDown={() => handleMouseDown(monthsObj)}
                    font="prelo-medium"
                  >
                    {monthsObj.value}
                  </Typography>
                ))}
            </div>
          </CalendarMonth>
        </CalendarOptions>
      )}
    </CalendarWrapper>
  );
};

export default Calendar;
