import { AnyAction } from 'redux';
import Cookies from 'js-cookie';
import actionTypes from '../../actions/actionTypes';
import {
  focusAlertMessage,
  setUtmHeaders,
} from '../../common/helpers/functions';
import { FACEPHI_VALIDATED_IMAGE_KEY } from '../../common/helpers/constants';

interface CallMeBackButtonStatus {
  isOpened: boolean;
}

interface UtmHeaders {
  source?: string;
  campaign?: string;
  medium?: string;
  content?: string;
}

interface SiteState {
  channel: string;
  jwt: string;
  fingeprintCodeSuccess: boolean;
  otpSuccess: boolean;
  callMeBackButtonStatus: CallMeBackButtonStatus;
  utmHeaders: UtmHeaders;
  userType: string;
}

export const INITIAL_STATE = {
  channel: '',
  jwt: '',
  fingeprintCodeSuccess: false,
  otpSuccess: false,
  callMeBackButtonStatus: {
    isOpened: false,
  },
  utmHeaders: {
    source: '',
    campaign: '',
    medium: '',
    content: '',
  },
  userType: '',
};

const site = (
  state: SiteState = INITIAL_STATE,
  action: AnyAction = { type: null }
): SiteState => {
  switch (action.type) {
    case actionTypes.SET_CHANNEL:
      return {
        ...INITIAL_STATE,
        channel: action.channel,
      };
    case actionTypes.SET_USER_TYPE:
      const { requestStatus } = action;
      const validRequestStatus = [201, 404, 204].includes(requestStatus);
      const userType = validRequestStatus ? action.userType : state.userType;
      return {
        ...state,
        userType,
      };
    case actionTypes.FOCUS_ALERT_MESSAGE:
      focusAlertMessage('container-message');
      return state;
    case actionTypes.SET_JWT:
      const jwt = action.requestHeaders.get('authorization');
      return {
        ...state,
        jwt: jwt,
      };
    case actionTypes.RESET_JWT:
      return {
        ...state,
        jwt: '',
      };
    case actionTypes.CLEAN_SESSION:
      window.sessionStorage.removeItem('id');
      Cookies.remove(FACEPHI_VALIDATED_IMAGE_KEY);
      Cookies.remove('facephi');
      return {
        ...state,
        jwt: '',
        otpSuccess: false,
        fingeprintCodeSuccess: false,
        userType: '',
      };
    case actionTypes.SET_FINGEPRINT_SUCCESS:
      return {
        ...state,
        fingeprintCodeSuccess: action.state,
      };
    case actionTypes.SET_OTP_SUCCESS:
      return {
        ...state,
        otpSuccess: action.state,
      };
    case actionTypes.SET_UTM_HEADERS:
      const { query, referer, channel } = action.state;
      const keys = Object.keys(query);
      const utmHeaders: any = {};

      if (keys.length > 0) {
        keys.forEach((key: string) => {
          switch (key) {
            case 'utm_source':
            case 'utm_medium':
            case 'utm_campaign':
            case 'utm_content': {
              utmHeaders[key.slice(4)] = query[key];
              break;
            }
            default: {
              break;
            }
          }
        });
      }

      setUtmHeaders(utmHeaders, referer, channel);
      return {
        ...state,
        utmHeaders,
      };
    default:
      return state;
  }
};

export default site;
