import { FC, useEffect } from "react";

import { connect, ConnectedProps } from "react-redux";
import { ReduxState } from "../../../common/store";

import actions from "../../../actions/";
import actionTypes from "../../../actions/actionTypes";

import {
  isMarried as isMarriedFunction,
  isNumber,
  isSingle as isSingleFunction,
} from "../../../common/helpers/functions";

import StepsGuide from "../../StepsGuide/StepsGuide";
import StepsButtons from "../StepsButtons.tsx/StepsButtons";
import TitlePage from "../TitlePage/TitlePage";

import InputField from "../../InputField/InputField";
import SelectField from "../../InputField/SelectField";
import RadioButton from "../../RadioButton/RadioButton";

import tooltipIcon from "../../../assets/icons/tooltipIcon.svg";

import { sendGTMUserEvent } from "../../../common/helpers/gtmEvents";
import { ALLOWED_LOCATIONS_CHARACTERS } from "../../../common/helpers/patterns";
import DropdownField from "../../DropdownField/DropdownField";
import { locationsMap, provincesMap } from "../CreditData/locations";
import "./PersonalData.scss";

type PropFromRedux = ConnectedProps<typeof connector>;

export const PersonalData: FC<PropFromRedux> = ({ ...props }) => {
  const { channel, userType } = props;
  useEffect(() => {
    sendGTMUserEvent(channel, "datosPersonales", userType);
  }, [channel, userType]);

  const handleChangePhone = (e: React.FormEvent<HTMLInputElement>) => {
    const newValue = e.currentTarget.value;
    if (!newValue) {
      props.updateInputContent(
        newValue,
        actionTypes.UPDATE_INPUT_PERSONAL_DATA_PHONE
      );
      return;
    }
    if (newValue.length > 8 || !isNumber(newValue[newValue.length - 1])) {
      return;
    }
    props.updateInputContent(
      newValue,
      actionTypes.UPDATE_INPUT_PERSONAL_DATA_PHONE
    );
  };

  const handleChangeEmail = (e: React.FormEvent<HTMLInputElement>) => {
    const newValue = e.currentTarget.value.trim();
    props.updateInputContent(
      newValue,
      actionTypes.UPDATE_INPUT_PERSONAL_DATA_EMAIL
    );
  };

  const handleChangeMaritalStatus = (e: React.FormEvent<HTMLInputElement>) => {
    const newValue = e.currentTarget.value;
    props.updateInputContent(
      newValue,
      actionTypes.UPDATE_INPUT_PERSONAL_DATA_MARITAL_STATUS
    );
  };

  const handleCheckYesSeparationOfProperty = () => {
    props.updateInputContent(
      "yes",
      actionTypes.UPDATE_INPUT_PERSONAL_DATA_SEPARATION_OF_PROPERTY
    );
  };

  const handleCheckNoSeparationOfProperty = () => {
    props.updateInputContent(
      "no",
      actionTypes.UPDATE_INPUT_PERSONAL_DATA_SEPARATION_OF_PROPERTY
    );
  };

  const handleChangeIdCardPartner = (e: React.FormEvent<HTMLInputElement>) => {
    const newValue = e.currentTarget.value;
    if (!newValue) {
      props.updateInputContent(
        newValue,
        actionTypes.UPDATE_INPUT_PERSONAL_DATA_ID_CARD_PARTNER
      );
      return;
    }
    if (newValue.length > 10 || !isNumber(newValue[newValue.length - 1])) {
      return;
    }
    props.updateInputContent(
      newValue,
      actionTypes.UPDATE_INPUT_PERSONAL_DATA_ID_CARD_PARTNER
    );
  };

  const commonRequiredFields = () => {
    return (
      props.personalData.phoneStateError ||
      props.personalData.emailStateError ||
      props.personalData.maritalStatusStateError ||
      props.personalData.provinceResidenceStateError ||
      props.personalData.capitalResidenceStateError
    );
  };

  const marriedRequiredFields = () => {
    if (isSingleFunction(props.personalData.maritalStatus)) {
      return false;
    }
    return (
      props.personalData.idCardPartnerStateError ||
      props.idCardLogin === props.personalData.idCardPartner
    );
  };

  const continuePD = () => {
    if (notAllowContinueFirst()) {
      return;
    }
    props.setStep(2);
    props.toggleCompletePersonalData(true);
    window.scrollTo(0, 0);
  };

  const notAllowContinueFirst = () => {
    return (
      props.personalData.phone === "" ||
      props.personalData.email === "" ||
      props.personalData.provinceResidence === "" ||
      props.personalData.capitalResidence === "" ||
      props.personalData.provinceResidenceStateError ||
      props.personalData.capitalResidenceStateError ||
      props.personalData.phoneStateError ||
      props.personalData.emailStateError ||
      props.personalData.idCardPartnerStateError ||
      props.personalData.maritalStatusStateError ||
      marriedRequiredFields()
    );
  };
  const cancelExpressRequest = () => {
    window.location.href = "/";
  };

  const {
    phone,
    email,
    maritalStatus,
    phoneState,
    emailState,
    separationOfProperty,
    idCardPartner,
    idCardPartnerState,
  } = props.personalData;

  const { firstName } = props;

  const emailFieldProps = {
    label: "Correo electrónico",
    placeholder: "Ej.: nombre@mail.com",
    state: emailState,
    normalHelper: "",
    errorHelper: "Ingresa un correo válido",
    disabled: false,
    currentValue: email,
    handleChange: handleChangeEmail,
    testId: "personalDataEmailTestId",
    lg: "12",
    xs: "12",
    classWrap: "",
    id: "emailInput",
  };

  const phoneFieldProps = {
    label: "Número celular",
    placeholder: "",
    state: phoneState,
    normalHelper: "",
    errorHelper: "Ingresa un número de celular válido",
    disabled: false,
    currentValue: phone,
    handleChange: handleChangePhone,
    testId: "personalDataPhoneTestId",
    lg: "12",
    xs: "12",
    phonePrefix: true,
    classWrap: "",
    id: "phoneInput",
  };

  const idCardPartnerFieldProps = {
    label: "Número de cédula de tu cónyuge",
    placeholder: "Ej.: 1719419788",
    state: idCardPartnerState,
    normalHelper: "",
    errorHelper: "Ingresa un número de cédula válido",
    disabled: false,
    currentValue: idCardPartner,
    handleChange: handleChangeIdCardPartner,
    testId: "personalDataIdCardPartnerTestId",
    lg: "12",
    xs: "12",
    id: "idCardPartnerInput",
  };

  const maritalStatusFieldProps = {
    label: "¿Cuál es tu estado civil actual?",
    currentValue: maritalStatus,
    disabled: false,
    handleChange: handleChangeMaritalStatus,
    testId: "maritalStatusFieldProps",
    lg: "12",
    xs: "12",
    options: [
      {
        value: "empty",
        display: "Selecciona una opción",
      },
      {
        value: "single",
        display: "Soltero/a",
      },
      {
        value: "married",
        display: "Casado/a",
      },
      {
        value: "divorced",
        display: "Divorciado/a",
      },
      {
        value: "widower",
        display: "Viudo/a",
      },
      {
        value: "free union",
        display: "Unión de hecho",
      },
    ],
  };

  const isMarried = isMarriedFunction(maritalStatus);
  const isSingle = isSingleFunction(maritalStatus);
  const errorInCommonFields = commonRequiredFields();
  const errorInMarriedFields = marriedRequiredFields();

  let disableContinueButton = errorInCommonFields;

  if (isMarried) {
    disableContinueButton = errorInCommonFields || errorInMarriedFields;
  }

  return (
    <div
      className="data-form-express-request step-page"
      data-testid="personalDataContainerTestId"
    >
      <pichincha-grid container justify="center">
        <TitlePage
          title="Hola"
          name={firstName}
          startingSymbol="¡"
          endingSymbol="!"
        />
        <pichincha-grid item xs="12">
          <StepsGuide />
        </pichincha-grid>
        <div className="form-input bp-mt-56 express-request">
          <pichincha-grid item xs="12">
            <div className="subtitle-section text-prelo-semibold">
              ¿Cómo nos podemos contactar contigo?
            </div>
          </pichincha-grid>
          <pichincha-grid item xs="12">
            <pichincha-grid container>
              <InputField {...phoneFieldProps} />
              <InputField {...emailFieldProps} />
            </pichincha-grid>
          </pichincha-grid>
          <pichincha-grid item xs="12">
            <div className="subtitle-section text-prelo-semibold">
              ¿En dónde vives actualmente?
            </div>
          </pichincha-grid>
          <pichincha-grid item xs="12">
            <pichincha-grid container>
              <pichincha-grid item xs="12" lg="6">
                <DropdownField
                  label="Provincia"
                  placeholder="Ej.:Pichincha"
                  normalHelper=""
                  errorHelper="Campo incompleto"
                  classWrap=""
                  id="propertyProvince"
                  testId="buyingProvinceFieldTestId"
                  state={props.personalData.provinceResidenceState}
                  disabled={false}
                  currentValue={props.personalData.provinceResidence}
                  list={provincesMap}
                  stateUpdater={props.updateInputContent}
                  actionTypeValue={actionTypes.UPDATE_INPUT_PROVINCE_RESIDENCE}
                  actionTypeId=""
                  textPattern={ALLOWED_LOCATIONS_CHARACTERS}
                />
              </pichincha-grid>

              <pichincha-grid item xs="12" lg="6">
                <DropdownField
                  label="Ciudad"
                  placeholder="Ej.:Quito"
                  normalHelper=""
                  errorHelper="Campo incompleto"
                  classWrap=""
                  currentValue={props.personalData.capitalResidence}
                  state={props.personalData.capitalResidenceState}
                  list={
                    locationsMap[
                      props.personalData.provinceResidence.toLowerCase()
                    ] || []
                  }
                  id="propertyCity"
                  disabled={
                    props.personalData.provinceResidence === "" ||
                    props.personalData.provinceResidenceStateError
                  }
                  testId="buyingTownFieldTestId"
                  stateUpdater={props.updateInputContent}
                  actionTypeValue={actionTypes.UPDATE_INPUT_CAPITAL_RESIDENCE}
                  actionTypeId=""
                  textPattern={ALLOWED_LOCATIONS_CHARACTERS}
                />
              </pichincha-grid>
            </pichincha-grid>
          </pichincha-grid>

          <pichincha-grid item xs="12">
            <div className="subtitle-section text-prelo-semibold bp-mt-24">
              Cuéntanos más sobre ti
            </div>
          </pichincha-grid>
          {!isSingle && (
            <pichincha-grid item xs="12">
              <pichincha-grid container>
                <SelectField {...maritalStatusFieldProps} classWrap="" />
              </pichincha-grid>
            </pichincha-grid>
          )}
          {isSingle && (
            <pichincha-grid item xs="12">
              <pichincha-grid container align_items="baseline">
                <SelectField {...maritalStatusFieldProps} classWrap="" />
              </pichincha-grid>
            </pichincha-grid>
          )}
          {isMarried && (
            <div>
              <pichincha-grid item xs="12">
                <div className="text-label bp-my-8 has-tooltip">
                  <label className="bp-mr-8">
                    Con respecto a tu estado civil, ¿tienes separación de
                    bienes?
                  </label>
                  <pichincha-tooltip
                    position="bottom"
                    data-testid="tooltip-helper"
                  >
                    <span slot="item-text">
                      Separación de bienes: régimen económico matrimonial que
                      permite que los patrimonios de cada uno de los cónyuges
                      estén diferenciados durante el matrimonio. <br />
                      Disolución conyugal: Terminación de la sociedad de bienes
                      que nace con el matrimonio.
                    </span>
                    <span slot="item-body">
                      <img src={tooltipIcon} alt="" />
                    </span>
                  </pichincha-tooltip>
                </div>
              </pichincha-grid>
              <pichincha-grid item xs="12">
                <pichincha-grid container spacing="6">
                  <pichincha-grid item lg="1" xs="2">
                    <RadioButton
                      checked={separationOfProperty === "yes" ? true : false}
                      handleClick={handleCheckYesSeparationOfProperty}
                      label="Si"
                      testId="separationOfPropertyYesTestId"
                    />
                  </pichincha-grid>
                  <pichincha-grid item lg="1" xs="1">
                    <RadioButton
                      checked={separationOfProperty === "no" ? true : false}
                      handleClick={handleCheckNoSeparationOfProperty}
                      label="No"
                      testId="separationOfPropertyNoTestId"
                    />
                  </pichincha-grid>
                </pichincha-grid>
              </pichincha-grid>
            </div>
          )}
          <div className="bp-mt-16">
            {isMarried && separationOfProperty === "no" && (
              <pichincha-grid item xs="12">
                <pichincha-grid container>
                  <InputField {...idCardPartnerFieldProps} classWrap="" />
                </pichincha-grid>
              </pichincha-grid>
            )}
            {isMarried && separationOfProperty === "yes" && (
              <pichincha-grid item xs="12">
                <pichincha-grid container></pichincha-grid>
              </pichincha-grid>
            )}
          </div>
          <pichincha-grid item xs="12">
            <div className="button-container">
              <StepsButtons
                currentPage="PersonalData"
                textContinue="Continuar"
                disabledContinue={disableContinueButton}
                textBack="Cancelar"
                disabledBack={false}
                continueHandler={() => continuePD()}
                backHandler={cancelExpressRequest}
              />
            </div>
          </pichincha-grid>
        </div>
      </pichincha-grid>
    </div>
  );
};

const mapStateToProps = (state: ReduxState) => {
  const { expressRequest, site, civilRegistration, login } = state;
  const { channel, userType } = site;
  const { firstName } = civilRegistration;
  const { idCardLogin } = login;

  const { currentStep, personalData, completedPersonalData } = expressRequest;
  return {
    currentStep,
    personalData,
    completedPersonalData,
    channel,
    firstName,
    idCardLogin,
    userType,
  };
};

const {
  setStep,
  updateInputContent,
  triggerAction,
  toggleCompletePersonalData,
} = actions;

const connector = connect(mapStateToProps, {
  setStep,
  updateInputContent,
  triggerAction,
  toggleCompletePersonalData,
});

export default connector(PersonalData);
