import React from 'react'
import { IconWrapper, IconWrapperProps } from './styles'
import '../../fonts.css'
import '../../icons.css'
export interface IconProps extends IconWrapperProps {
  variant?: 'filled' | 'outlined' | ''
  iconName?: string
}

const Icon: React.FC<IconProps> = ({
  variant = 'outlined',
  iconName = 'done',
  ...props
}) => {
  return (
    <IconWrapper
      {...props}
      className={`${variant === 'outlined' ? 'material-icons-outlined' : 'material-icons'
        } ${props.className}`}
    >
      {iconName}
    </IconWrapper>
  )
}

export default Icon
