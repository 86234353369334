export const autocompleteData = [
  { code: 10260, province: 'Azuay', city: 'Cuenca' },
  { code: 10261, province: 'Azuay', city: 'Asuncion' },
  { code: 10262, province: 'Azuay', city: 'Bulan' },
  { code: 10263, province: 'Azuay', city: 'Chican' },
  { code: 10264, province: 'Azuay', city: 'Chumblin' },
  { code: 10265, province: 'Azuay', city: 'Gualaceo' },
  { code: 10266, province: 'Azuay', city: 'Cochapata' },
  { code: 10267, province: 'Azuay', city: 'Cumbe' },
  { code: 10268, province: 'Azuay', city: 'Cutchil' },
  { code: 10269, province: 'Azuay', city: 'Daniel Cordova' },
  { code: 10270, province: 'Azuay', city: 'Giron' },
  { code: 10271, province: 'Azuay', city: 'Dug Dug' },
  { code: 10272, province: 'Azuay', city: 'Jima' },
  { code: 10273, province: 'Azuay', city: 'La Union' },
  { code: 10274, province: 'Azuay', city: 'Lentag' },
  { code: 10275, province: 'Azuay', city: 'Paute' },
  { code: 10276, province: 'Azuay', city: 'Nulti' },
  { code: 10277, province: 'Azuay', city: 'Paccha' },
  { code: 10278, province: 'Azuay', city: 'Palmas' },
  { code: 10279, province: 'Azuay', city: 'Principal' },
  { code: 10280, province: 'Azuay', city: 'Sigsig' },
  { code: 10281, province: 'Azuay', city: 'Quigeo' },
  { code: 10282, province: 'Azuay', city: 'San Bartolome' },
  { code: 10283, province: 'Azuay', city: 'San Gerardo' },
  { code: 10284, province: 'Azuay', city: 'San Juan' },
  { code: 10285, province: 'Azuay', city: 'Santa Isabel' },
  { code: 10286, province: 'Azuay', city: 'San Pedro De Shaglle' },
  { code: 10287, province: 'Azuay', city: 'Santa Ana' },
  { code: 10288, province: 'Azuay', city: 'Santa Teresita' },
  { code: 10289, province: 'Azuay', city: 'Guel' },
  { code: 10290, province: 'Azuay', city: 'Ludo' },
  { code: 10291, province: 'Azuay', city: 'Remigio Crespo' },
  { code: 10292, province: 'Azuay', city: 'San Cristobal' },
  { code: 10293, province: 'Azuay', city: 'San Rafael De Sharung' },
  { code: 10294, province: 'Azuay', city: 'Zapata' },
  { code: 10295, province: 'Azuay', city: 'Shidmad' },
  { code: 10296, province: 'Azuay', city: 'Tomebamba' },
  { code: 10297, province: 'Azuay', city: 'San Vicente' },
  { code: 10298, province: 'Azuay', city: 'San Jose De Raranga' },
  { code: 10299, province: 'Azuay', city: 'Progreso' },
  { code: 10300, province: 'Azuay', city: 'Mariano Moreno' },
  { code: 10301, province: 'Azuay', city: 'Jadan' },
  { code: 10302, province: 'Azuay', city: 'Guarainag' },
  { code: 10303, province: 'Azuay', city: 'Delegsol' },
  { code: 10304, province: 'Azuay', city: 'Llacao' },
  { code: 10555, province: 'Azuay', city: 'Pucara' },
  { code: 10780, province: 'Azuay', city: 'Onia' },
  { code: 10855, province: 'Azuay', city: 'San Fernando' },
  { code: 10885, province: 'Azuay', city: 'Nabon' },
  { code: 10915, province: 'Azuay', city: 'Chordeleg' },
  { code: 10955, province: 'Azuay', city: 'Sevilla De Oro' },
  { code: 10960, province: 'Azuay', city: 'El Pan' },
  { code: 10990, province: 'Azuay', city: 'Guachapala' },
  { code: 20165, province: 'Bolivar', city: 'Guaranda' },
  { code: 20170, province: 'Bolivar', city: 'Chimbo' },
  { code: 20175, province: 'Bolivar', city: 'San Miguel' },
  { code: 20179, province: 'Bolivar', city: 'La Asuncion' },
  { code: 20180, province: 'Bolivar', city: 'Chillanes' },
  { code: 20181, province: 'Bolivar', city: 'La Magdalena' },
  { code: 20185, province: 'Bolivar', city: 'Echeandia' },
  { code: 20760, province: 'Bolivar', city: 'Caluma' },
  { code: 20950, province: 'Bolivar', city: 'Las Naves' },
  { code: 30235, province: 'Cañar', city: 'Azogues' },
  { code: 30240, province: 'Cañar', city: 'Caniar' },
  { code: 30241, province: 'Cañar', city: 'Suscal' },
  { code: 30242, province: 'Cañar', city: 'Cochancay' },
  { code: 30243, province: 'Cañar', city: 'San Miguel' },
  { code: 170060, province: 'Pichincha', city: 'Quito' },
  { code: 30245, province: 'Cañar', city: 'Biblian' },
  { code: 30250, province: 'Cañar', city: 'La Troncal' },
  { code: 30253, province: 'Cañar', city: 'Gualleturo' },
  { code: 30254, province: 'Cañar', city: 'Honorato Vasquez' },
  { code: 30255, province: 'Cañar', city: 'Turupamba' },
  { code: 30765, province: 'Cañar', city: 'El Tambo' },
  { code: 30905, province: 'Cañar', city: 'Deleg' },
  { code: 40005, province: 'Carchi', city: 'Tulcan' },
  { code: 40009, province: 'Carchi', city: 'Julio Andrade' },
  { code: 40010, province: 'Carchi', city: 'Montufar' },
  { code: 40011, province: 'Carchi', city: 'Maldonado' },
  { code: 40015, province: 'Carchi', city: 'Espejo' },
  { code: 40016, province: 'Carchi', city: 'Urbina' },
  { code: 40017, province: 'Carchi', city: 'San Gabriel' },
  { code: 40020, province: 'Carchi', city: 'Mira' },
  { code: 40023, province: 'Carchi', city: 'La Paz' },
  { code: 40025, province: 'Carchi', city: 'Bolivar' },
  { code: 40030, province: 'Carchi', city: 'El Angel' },
  { code: 40995, province: 'Carchi', city: 'San Pedro De Huaca' },
  { code: 50100, province: 'Cotopaxi', city: 'Latacunga' },
  { code: 50102, province: 'Cotopaxi', city: 'Poalo' },
  { code: 50103, province: 'Cotopaxi', city: 'Guaytacama' },
  { code: 50104, province: 'Cotopaxi', city: 'Lasso' },
  { code: 50105, province: 'Cotopaxi', city: 'Pujili' },
  { code: 50109, province: 'Cotopaxi', city: 'San Juan De Pastocalle' },
  { code: 50110, province: 'Cotopaxi', city: 'Salcedo' },
  { code: 50111, province: 'Cotopaxi', city: 'Tanicuchi' },
  { code: 50112, province: 'Cotopaxi', city: 'Toacazo' },
  { code: 50115, province: 'Cotopaxi', city: 'Saquisili' },
  { code: 50116, province: 'Cotopaxi', city: 'Juan Montalvo' },
  { code: 50120, province: 'Cotopaxi', city: 'Pangua' },
  { code: 50123, province: 'Cotopaxi', city: 'Pilalo' },
  { code: 50132, province: 'Cotopaxi', city: 'Chantilin' },
  { code: 50137, province: 'Cotopaxi', city: 'El Corazon' },
  { code: 50825, province: 'Cotopaxi', city: 'La Mana' },
  { code: 50930, province: 'Cotopaxi', city: 'Sigchos' },
  { code: 60190, province: 'Chimborazo', city: 'Chambo' },
  { code: 60195, province: 'Chimborazo', city: 'Riobamba' },
  { code: 60200, province: 'Chimborazo', city: 'Guano' },
  { code: 60202, province: 'Chimborazo', city: 'San Juan' },
  { code: 60205, province: 'Chimborazo', city: 'Colta' },
  { code: 60210, province: 'Chimborazo', city: 'Guamote' },
  { code: 60215, province: 'Chimborazo', city: 'Alausi' },
  { code: 60218, province: 'Chimborazo', city: 'San Andres' },
  { code: 60220, province: 'Chimborazo', city: 'Chunchi' },
  { code: 60225, province: 'Chimborazo', city: 'Penipe' },
  { code: 60229, province: 'Chimborazo', city: 'Cajabamba' },
  { code: 60830, province: 'Chimborazo', city: 'Pallatanga' },
  { code: 60900, province: 'Chimborazo', city: 'Cumanda' },
  { code: 70350, province: 'El Oro', city: 'Machala' },
  { code: 70351, province: 'El Oro', city: 'Paccha' },
  { code: 70352, province: 'El Oro', city: 'El Cambio' },
  { code: 70354, province: 'El Oro', city: 'Puerto Bolivar' },
  { code: 70355, province: 'El Oro', city: 'Santa Rosa' },
  { code: 70357, province: 'El Oro', city: 'Bellavista' },
  { code: 70360, province: 'El Oro', city: 'Zaruma' },
  { code: 70361, province: 'El Oro', city: 'La Avanzada' },
  { code: 70365, province: 'El Oro', city: 'Pasaje' },
  { code: 70370, province: 'El Oro', city: 'Pinias' },
  { code: 70375, province: 'El Oro', city: 'Arenillas' },
  { code: 70378, province: 'El Oro', city: 'Buenavista' },
  { code: 70380, province: 'El Oro', city: 'El Guabo' },
  { code: 70585, province: 'El Oro', city: 'Chilla' },
  { code: 70690, province: 'El Oro', city: 'Portovelo' },
  { code: 70695, province: 'El Oro', city: 'Huaquillas' },
  { code: 70705, province: 'El Oro', city: 'Atahualpa' },
  { code: 70755, province: 'El Oro', city: 'Las Lajas' },
  { code: 70860, province: 'El Oro', city: 'Marcabeli' },
  { code: 70875, province: 'El Oro', city: 'Balsas' },
  { code: 80560, province: 'Esmeraldas', city: 'Esmeraldas' },
  { code: 80561, province: 'Esmeraldas', city: 'Rio Verde' },
  { code: 80562, province: 'Esmeraldas', city: 'Limones' },
  { code: 80565, province: 'Esmeraldas', city: 'Eloy Alfaro' },
  { code: 80570, province: 'Esmeraldas', city: 'Muisne' },
  { code: 80575, province: 'Esmeraldas', city: 'Quininde' },
  { code: 80580, province: 'Esmeraldas', city: 'San Lorenzo' },
  { code: 80608, province: 'Esmeraldas', city: 'La Union' },
  { code: 80624, province: 'Esmeraldas', city: 'Tonsupa' },
  { code: 80626, province: 'Esmeraldas', city: 'Sua' },
  { code: 80785, province: 'Esmeraldas', city: 'Atacames' },
  { code: 90095, province: 'Guayas', city: 'Balao' },
  { code: 90096, province: 'Guayas', city: 'Punta Carnero' },
  { code: 90097, province: 'Guayas', city: 'Cabuyal' },
  { code: 90099, province: 'Guayas', city: 'El Salitre' },
  { code: 90100, province: 'Guayas', city: 'Ayangue' },
  { code: 90101, province: 'Guayas', city: 'Posorja' },
  { code: 90102, province: 'Guayas', city: 'Palmar' },
  { code: 90103, province: 'Guayas', city: 'Tenguel' },
  { code: 90104, province: 'Guayas', city: 'Tres Postes' },
  { code: 90105, province: 'Guayas', city: 'Juan Bautista Aguirre' },
  { code: 90106, province: 'Guayas', city: 'Punta Blanca' },
  { code: 90118, province: 'Guayas', city: 'Chongon' },
  { code: 90120, province: 'Guayas', city: 'Gral Pedro Montero' },
  { code: 90125, province: 'Guayas', city: 'Colimes' },
  { code: 90128, province: 'Guayas', city: 'El Laurel' },
  { code: 90129, province: 'Guayas', city: 'Ancon' },
  { code: 90136, province: 'Guayas', city: 'Chanduy' },
  { code: 90137, province: 'Guayas', city: 'Manglaralto' },
  { code: 90138, province: 'Guayas', city: 'Ballenita' },
  { code: 90142, province: 'Guayas', city: 'Roberto Astudillo' },
  { code: 90143, province: 'Guayas', city: 'Banife' },
  { code: 90145, province: 'Guayas', city: 'Junquillal' },
  { code: 90146, province: 'Guayas', city: 'La Victoria' },
  { code: 90155, province: 'Guayas', city: 'Taura' },
  { code: 90158, province: 'Guayas', city: 'Crnel Lorenzo De Garaycoa' },
  { code: 90250, province: 'Guayas', city: 'La Troncal' },
  { code: 90385, province: 'Guayas', city: 'El Empalme' },
  { code: 90395, province: 'Guayas', city: 'Yaguachi' },
  { code: 90400, province: 'Guayas', city: 'Daule' },
  { code: 90415, province: 'Guayas', city: 'Balzar' },
  { code: 90420, province: 'Guayas', city: 'Milagro' },
  { code: 90425, province: 'Guayas', city: 'Samborondon' },
  { code: 90430, province: 'Guayas', city: 'Urbina Jado' },
  { code: 90435, province: 'Guayas', city: 'Naranjal' },
  { code: 90505, province: 'Guayas', city: 'El Triunfo' },
  { code: 90506, province: 'Guayas', city: 'Bucay' },
  { code: 90510, province: 'Guayas', city: 'Naranjito' },
  { code: 90675, province: 'Guayas', city: 'Palestina' },
  { code: 90715, province: 'Guayas', city: 'Pedro Carbo' },
  { code: 90735, province: 'Guayas', city: 'Gral Villamil Playas' },
  { code: 90790, province: 'Guayas', city: 'Simon Bolivar' },
  { code: 90820, province: 'Guayas', city: 'Duran' },
  {
    code: 90835,
    province: 'Guayas',
    city: 'Alfredo Baquerizo Moreno (Jujan)',
  },
  { code: 90870, province: 'Guayas', city: 'Santa Lucia' },
  {
    code: 90890,
    province: 'Guayas',
    city: 'Crnel Marcelino Mariduenias',
  },
  { code: 90920, province: 'Guayas', city: 'Lomas De Sargentillo' },
  { code: 90940, province: 'Guayas', city: 'Nobol Piedrahita' },
  { code: 90985, province: 'Guayas', city: 'General A Elizalde' },
  { code: 90986, province: 'Guayas', city: 'Isidro Ayora' },
  { code: 90965, province: 'Guayas', city: 'La Libertad' },
  { code: 90405, province: 'Guayas', city: 'Salinas' },
  { code: 100030, province: 'Imbabura', city: 'Ibarra' },
  { code: 100035, province: 'Imbabura', city: 'Otavalo' },
  { code: 100038, province: 'Imbabura', city: 'San Antonio' },
  { code: 100040, province: 'Imbabura', city: 'Cotacachi' },
  { code: 100045, province: 'Imbabura', city: 'Antonio Ante' },
  { code: 100046, province: 'Imbabura', city: 'Ambuqui' },
  { code: 100047, province: 'Imbabura', city: 'Chavezpamba' },
  { code: 100050, province: 'Imbabura', city: 'Pimampiro' },
  { code: 100053, province: 'Imbabura', city: 'San Pablo' },
  { code: 100055, province: 'Imbabura', city: 'Urcuqui' },
  { code: 100073, province: 'Imbabura', city: 'Atuntaqui' },
  { code: 100081, province: 'Imbabura', city: 'Tumbabiro' },
  { code: 100082, province: 'Imbabura', city: 'Perucho' },
  { code: 110110, province: 'Loja', city: 'Loja' },
  { code: 110290, province: 'Loja', city: 'Catamayo' },
  { code: 110291, province: 'Loja', city: 'Alamor' },
  { code: 110292, province: 'Loja', city: 'Casanga' },
  { code: 110293, province: 'Loja', city: 'Cariamanga' },
  { code: 110294, province: 'Loja', city: 'Catacocha' },
  { code: 110295, province: 'Loja', city: 'Zapotillo' },
  { code: 110296, province: 'Loja', city: 'Changaimina' },
  { code: 110297, province: 'Loja', city: 'Colaisaca' },
  { code: 110298, province: 'Loja', city: 'El Cisne' },
  { code: 110299, province: 'Loja', city: 'El Lucero' },
  { code: 110301, province: 'Loja', city: 'El Tambo' },
  { code: 110302, province: 'Loja', city: 'Jimbura' },
  { code: 110303, province: 'Loja', city: 'La Victoria' },
  { code: 110304, province: 'Loja', city: 'Mangaurco' },
  { code: 110305, province: 'Loja', city: 'Saraguro' },
  { code: 110306, province: 'Loja', city: 'Mercadillo' },
  { code: 110307, province: 'Loja', city: 'Nueva Fatima' },
  { code: 110308, province: 'Loja', city: 'Paletilla' },
  { code: 110309, province: 'Loja', city: 'Potrerillos' },
  { code: 110310, province: 'Loja', city: 'Paltas' },
  { code: 110311, province: 'Loja', city: 'Olmedo' },
  { code: 110312, province: 'Loja', city: 'Purunuma' },
  { code: 110313, province: 'Loja', city: 'Sabanilla' },
  { code: 110314, province: 'Loja', city: 'Sacapalca' },
  { code: 110315, province: 'Loja', city: 'Celica' },
  { code: 110316, province: 'Loja', city: 'San Antonio De Las Aradas' },
  { code: 110317, province: 'Loja', city: 'San Pedro De La Bendita' },
  { code: 110318, province: 'Loja', city: 'Urdaneta' },
  { code: 110319, province: 'Loja', city: 'Vilcabamba' },
  { code: 110320, province: 'Loja', city: 'Calvas' },
  { code: 110321, province: 'Loja', city: 'Yangana' },
  { code: 110322, province: 'Loja', city: 'Chuquiribamba' },
  { code: 110323, province: 'Loja', city: 'Cruzpamba' },
  { code: 110324, province: 'Loja', city: 'El Ingenio' },
  { code: 110325, province: 'Loja', city: 'Macara' },
  { code: 110326, province: 'Loja', city: 'El Naranjo' },
  { code: 110327, province: 'Loja', city: 'La Ceiba' },
  { code: 110328, province: 'Loja', city: 'Lauro Guerrero' },
  { code: 110329, province: 'Loja', city: 'Malacatos' },
  { code: 110330, province: 'Loja', city: 'Puyango' },
  { code: 110331, province: 'Loja', city: 'Manu' },
  { code: 110332, province: 'Loja', city: 'Nambacola' },
  { code: 110333, province: 'Loja', city: 'Orianga' },
  { code: 110334, province: 'Loja', city: 'Pozul' },
  { code: 110335, province: 'Loja', city: 'Gonzanama' },
  { code: 110336, province: 'Loja', city: 'Sabianco' },
  { code: 110337, province: 'Loja', city: 'San Lucas' },
  { code: 110338, province: 'Loja', city: 'Susudel' },
  { code: 110339, province: 'Loja', city: 'Tacamoros' },
  { code: 110340, province: 'Loja', city: 'Espindola' },
  { code: 110341, province: 'Loja', city: 'Selva Alegre' },
  { code: 110342, province: 'Loja', city: 'Torata' },
  { code: 110343, province: 'Loja', city: 'Veinte Y Siete De Abril' },
  { code: 110344, province: 'Loja', city: 'San Antonio' },
  { code: 110345, province: 'Loja', city: 'Sozoranga' },
  { code: 110730, province: 'Loja', city: 'Pindal' },
  { code: 110731, province: 'Loja', city: 'Lalamor' },
  { code: 110740, province: 'Loja', city: 'Quilanga' },
  { code: 110815, province: 'Loja', city: 'Chaguarpamba' },
  { code: 120515, province: 'Los Rios', city: 'Babahoyo' },
  { code: 120516, province: 'Los Rios', city: 'Valencia' },
  { code: 120517, province: 'Los Rios', city: 'Banepo' },
  { code: 120518, province: 'Los Rios', city: 'Guare' },
  { code: 120519, province: 'Los Rios', city: 'La Union' },
  { code: 120520, province: 'Los Rios', city: 'Baba' },
  { code: 120521, province: 'Los Rios', city: 'Pimocha' },
  { code: 120522, province: 'Los Rios', city: 'Ricaurte' },
  { code: 120523, province: 'Los Rios', city: 'San Juan' },
  { code: 120524, province: 'Los Rios', city: 'Antonio Sotomayor' },
  { code: 120525, province: 'Los Rios', city: 'Vinces' },
  { code: 120526, province: 'Los Rios', city: 'Bagatela' },
  { code: 120527, province: 'Los Rios', city: 'Isla De Bejucal' },
  { code: 120528, province: 'Los Rios', city: 'Mata De Cacao' },
  { code: 120529, province: 'Los Rios', city: 'Patricia Pilar' },
  { code: 120530, province: 'Los Rios', city: 'Puebloviejo' },
  { code: 120531, province: 'Los Rios', city: 'Quinsaloma' },
  { code: 120532, province: 'Los Rios', city: 'San Carlos' },
  { code: 120533, province: 'Los Rios', city: 'Catarama' },
  { code: 120535, province: 'Los Rios', city: 'Urdaneta' },
  { code: 120540, province: 'Los Rios', city: 'Quevedo' },
  { code: 120545, province: 'Los Rios', city: 'Ventanas' },
  { code: 120710, province: 'Los Rios', city: 'Montalvo' },
  { code: 120750, province: 'Los Rios', city: 'Palenque' },
  { code: 120945, province: 'Los Rios', city: 'Buena Fe' },
  { code: 120946, province: 'Los Rios', city: 'Mocache' },
  { code: 130231, province: 'Manabi', city: 'Calderon' },
  { code: 130232, province: 'Manabi', city: 'Canuto' },
  { code: 130233, province: 'Manabi', city: 'Campuzano' },
  { code: 130234, province: 'Manabi', city: 'Charapoto' },
  { code: 130235, province: 'Manabi', city: 'Cojimies' },
  { code: 130236, province: 'Manabi', city: 'Crucita' },
  { code: 130237, province: 'Manabi', city: 'Calceta' },
  { code: 130440, province: 'Manabi', city: 'Portoviejo' },
  { code: 130445, province: 'Manabi', city: 'Montecristi' },
  { code: 130446, province: 'Manabi', city: 'Jaramijo' },
  { code: 130450, province: 'Manabi', city: 'Manta' },
  { code: 130455, province: 'Manabi', city: 'Jipijapa' },
  { code: 130460, province: 'Manabi', city: 'Veinte Y Cuatro De Mayo' },
  { code: 130465, province: 'Manabi', city: 'Rocafuerte' },
  { code: 130470, province: 'Manabi', city: 'Santa Ana' },
  { code: 130475, province: 'Manabi', city: 'Sucre' },
  { code: 130476, province: 'Manabi', city: 'Jama' },
  { code: 130477, province: 'Manabi', city: 'San Vicente' },
  { code: 130480, province: 'Manabi', city: 'Chone' },
  { code: 90390, province: 'Guayas', city: 'Guayaquil' },
  { code: 130490, province: 'Manabi', city: 'Junin' },
  { code: 130495, province: 'Manabi', city: 'Pajan' },
  { code: 130500, province: 'Manabi', city: 'El Carmen' },
  { code: 130550, province: 'Manabi', city: 'Tosagua' },
  { code: 130840, province: 'Manabi', city: 'Pichincha' },
  { code: 130910, province: 'Manabi', city: 'Pedernales' },
  { code: 130970, province: 'Manabi', city: 'Olmedo' },
  { code: 130975, province: 'Manabi', city: 'Puerto Lopez' },
  { code: 130976, province: 'Manabi', city: 'Bahia De Caraquez' },
  { code: 130230, province: 'Manabi', city: 'Flavio Alfaro' },
  { code: 140590, province: 'Morona Santiago', city: 'Morona' },
  { code: 140591, province: 'Morona Santiago', city: 'Macas' },
  { code: 140592, province: 'Morona Santiago', city: 'Chinimbini' },
  { code: 140593, province: 'Morona Santiago', city: 'Gral Proanio' },
  { code: 140594, province: 'Morona Santiago', city: 'Huambi' },
  { code: 140595, province: 'Morona Santiago', city: 'Gualaquiza' },
  { code: 140596, province: 'Morona Santiago', city: 'Patuca' },
  { code: 140597, province: 'Morona Santiago', city: 'Rio Blanco' },
  {
    code: 140598,
    province: 'Morona Santiago',
    city: 'Sevilla Don Bosco',
  },
  { code: 140599, province: 'Morona Santiago', city: 'Chiguinda' },
  { code: 140600, province: 'Morona Santiago', city: 'Limon Indanza' },
  {
    code: 140601,
    province: 'Morona Santiago',
    city: 'Gral Leonidas Plaza',
  },
  { code: 140602, province: 'Morona Santiago', city: 'Plan Demilagro' },
  { code: 140603, province: 'Morona Santiago', city: 'Rio San Jose' },
  { code: 140604, province: 'Morona Santiago', city: 'Santa Ana' },
  {
    code: 140605,
    province: 'Morona Santiago',
    city: 'Santiago De Mendez',
  },
  { code: 140610, province: 'Morona Santiago', city: 'Sucua' },
  { code: 140611, province: 'Morona Santiago', city: 'Logronio' },
  { code: 140615, province: 'Morona Santiago', city: 'Palora' },
  { code: 140795, province: 'Morona Santiago', city: 'Huamboya' },
  { code: 140925, province: 'Morona Santiago', city: 'San Juan Bosco' },
  { code: 140926, province: 'Morona Santiago', city: 'Taisha' },
  { code: 150255, province: 'Napo', city: 'El Chaco' },
  { code: 150256, province: 'Napo', city: 'Baeza' },
  { code: 150620, province: 'Napo', city: 'Tena' },
  { code: 150621, province: 'Napo', city: 'Carlos Julio Arosemena Tola' },
  { code: 150635, province: 'Napo', city: 'Quijos' },
  { code: 150700, province: 'Napo', city: 'Archidona' },
  { code: 160680, province: 'Pastaza', city: 'Pastaza' },
  { code: 160681, province: 'Pastaza', city: 'Puyo' },
  { code: 160682, province: 'Pastaza', city: 'Shell' },
  { code: 160685, province: 'Pastaza', city: 'Mera' },
  { code: 160880, province: 'Pastaza', city: 'Santa Clara' },
  { code: 160881, province: 'Pastaza', city: 'Arajuno' },
  { code: 170061, province: 'Pichincha', city: 'La Independencia' },
  { code: 170062, province: 'Pichincha', city: 'Valle Hermoso' },
  { code: 170063, province: 'Pichincha', city: 'Alluriquin' },
  { code: 170064, province: 'Pichincha', city: 'Luz De America' },
  { code: 170065, province: 'Pichincha', city: 'Cayambe' },
  { code: 170066, province: 'Pichincha', city: 'El Quinche' },
  { code: 170067, province: 'Pichincha', city: 'Guayllabamba' },
  { code: 170068, province: 'Pichincha', city: 'Pifo' },
  { code: 170070, province: 'Pichincha', city: 'Mejia' },
  { code: 170071, province: 'Pichincha', city: 'Machachi' },
  { code: 170075, province: 'Pichincha', city: 'Pedro Moncayo' },
  { code: 170076, province: 'Pichincha', city: 'Tabacundo' },
  { code: 170077, province: 'Pichincha', city: 'Tumbaco' },
  { code: 170080, province: 'Pichincha', city: 'Ruminiahui' },
  { code: 170081, province: 'Pichincha', city: 'Sangolqui' },
  { code: 170082, province: 'Pichincha', city: 'San Rafael' },
  { code: 170083, province: 'Pichincha', city: 'Conocoto' },
  { code: 170086, province: 'Pichincha', city: 'La Concordia' },
  { code: 170087, province: 'Pichincha', city: 'Santa Martha' },
  { code: 170545, province: 'Pichincha', city: 'Ventanas' },
  { code: 170101, province: 'Pichincha', city: 'Cumbaya' },
  {
    code: 170770,
    province: 'Pichincha',
    city: 'San Miguel De Los Bancos',
  },
  {
    code: 170895,
    province: 'Pichincha',
    city: 'Pedro Vicente Maldonado',
  },
  { code: 170896, province: 'Pichincha', city: 'Puerto Quito' },
  { code: 170320, province: 'Pichincha', city: 'No Definido' },
  {
    code: 170085,
    province: 'Pichincha',
    city: 'Sto Dgo De Los Colorados',
  },
  { code: 180090, province: 'Tungurahua', city: 'Tisaleo' },
  { code: 180091, province: 'Tungurahua', city: 'Izamba' },
  { code: 180092, province: 'Tungurahua', city: 'Quisapincha' },
  { code: 180135, province: 'Tungurahua', city: 'Ambato' },
  { code: 180140, province: 'Tungurahua', city: 'Pillaro Viejo' },
  { code: 180145, province: 'Tungurahua', city: 'Pillaro' },
  { code: 180147, province: 'Tungurahua', city: 'Pelileo' },
  { code: 180150, province: 'Tungurahua', city: 'Banios' },
  { code: 180155, province: 'Tungurahua', city: 'Patate' },
  { code: 180160, province: 'Tungurahua', city: 'Quero' },
  { code: 180845, province: 'Tungurahua', city: 'Cevallos' },
  { code: 180850, province: 'Tungurahua', city: 'Mocha' },
  { code: 190130, province: 'Zamora Chinchipe', city: 'Nangaritza' },
  {
    code: 190131,
    province: 'Zamora Chinchipe',
    city: 'San Carlos De Las Minas',
  },
  { code: 190132, province: 'Zamora Chinchipe', city: 'Zumba' },
  { code: 190655, province: 'Zamora Chinchipe', city: 'Zamora' },
  { code: 190660, province: 'Zamora Chinchipe', city: 'Chinchipe' },
  { code: 190661, province: 'Zamora Chinchipe', city: 'Palanda' },
  { code: 190665, province: 'Zamora Chinchipe', city: 'Yacuambi' },
  { code: 190670, province: 'Zamora Chinchipe', city: 'Yanzatza' },
  { code: 190775, province: 'Zamora Chinchipe', city: 'El Pangui' },
  {
    code: 190980,
    province: 'Zamora Chinchipe',
    city: 'Centinela Del Condor',
  },
  { code: 200800, province: 'Galapagos', city: 'San Cristobal' },
  { code: 200805, province: 'Galapagos', city: 'Santa Cruz' },
  { code: 200810, province: 'Galapagos', city: 'Isabela' },
  { code: 210625, province: 'Sucumbios', city: 'Lago Agrio' },
  { code: 210626, province: 'Sucumbios', city: 'Cuyabeno' },
  { code: 210627, province: 'Sucumbios', city: 'Nueva Loja' },
  { code: 210640, province: 'Sucumbios', city: 'Sucumbios' },
  { code: 210650, province: 'Sucumbios', city: 'Putumayo' },
  { code: 210720, province: 'Sucumbios', city: 'Shushufindi' },
  { code: 210745, province: 'Sucumbios', city: 'Cascales' },
  { code: 210865, province: 'Sucumbios', city: 'Gonzalo Pizarro' },
  { code: 220630, province: 'Orellana', city: 'Aguarico' },
  { code: 220631, province: 'Orellana', city: 'Nuevo Rocafuerte' },
  { code: 220645, province: 'Orellana', city: 'Francisco De Orellana' },
  { code: 220725, province: 'Orellana', city: 'Joya De Los Sagchas' },
  { code: 220935, province: 'Orellana', city: 'Loreto' },
  {
    code: 230085,
    province: 'Santo Domingo de los Tsachilas',
    city: 'Santo Domingo De Los Colorados',
  },
  { code: 240405, province: 'Santa Elena', city: 'Salinas' },
  { code: 240410, province: 'Santa Elena', city: 'Santa Elena' },
  { code: 240965, province: 'Santa Elena', city: 'La Libertad' },
];

export const provincesMap = [
  { id: 'Azuay', value: 'Azuay' },
  { id: 'Bolivar', value: 'Bolivar' },
  { id: 'Cañar', value: 'Cañar' },
  { id: 'Pichincha', value: 'Pichincha' },
  { id: 'Carchi', value: 'Carchi' },
  { id: 'Cotopaxi', value: 'Cotopaxi' },
  { id: 'Chimborazo', value: 'Chimborazo' },
  { id: 'El Oro', value: 'El Oro' },
  { id: 'Esmeraldas', value: 'Esmeraldas' },
  { id: 'Guayas', value: 'Guayas' },
  { id: 'Imbabura', value: 'Imbabura' },
  { id: 'Loja', value: 'Loja' },
  { id: 'Los Rios', value: 'Los Rios' },
  { id: 'Manabi', value: 'Manabi' },
  { id: 'Morona Santiago', value: 'Morona Santiago' },
  { id: 'Napo', value: 'Napo' },
  { id: 'Pastaza', value: 'Pastaza' },
  { id: 'Tungurahua', value: 'Tungurahua' },
  { id: 'Zamora Chinchipe', value: 'Zamora Chinchipe' },
  { id: 'Galapagos', value: 'Galapagos' },
  { id: 'Sucumbios', value: 'Sucumbios' },
  { id: 'Orellana', value: 'Orellana' },
  {
    id: 'Santo Domingo de los Tsachilas',
    value: 'Santo Domingo de los Tsachilas',
  },
  { id: 'Santa Elena', value: 'Santa Elena' },
];

export const locationsMap: { [key: string]: any } = {
  azuay: [
    { id: 10260, value: 'Cuenca' },
    { id: 10261, value: 'Asuncion' },
    { id: 10262, value: 'Bulan' },
    { id: 10263, value: 'Chican' },
    { id: 10264, value: 'Chumblin' },
    { id: 10265, value: 'Gualaceo' },
    { id: 10266, value: 'Cochapata' },
    { id: 10267, value: 'Cumbe' },
    { id: 10268, value: 'Cutchil' },
    { id: 10269, value: 'Daniel Cordova' },
    { id: 10270, value: 'Giron' },
    { id: 10271, value: 'Dug Dug' },
    { id: 10272, value: 'Jima' },
    { id: 10273, value: 'La Union' },
    { id: 10274, value: 'Lentag' },
    { id: 10275, value: 'Paute' },
    { id: 10276, value: 'Nulti' },
    { id: 10277, value: 'Paccha' },
    { id: 10278, value: 'Palmas' },
    { id: 10279, value: 'Principal' },
    { id: 10280, value: 'Sigsig' },
    { id: 10281, value: 'Quigeo' },
    { id: 10282, value: 'San Bartolome' },
    { id: 10283, value: 'San Gerardo' },
    { id: 10284, value: 'San Juan' },
    { id: 10285, value: 'Santa Isabel' },
    { id: 10286, value: 'San Pedro De Shaglle' },
    { id: 10287, value: 'Santa Ana' },
    { id: 10288, value: 'Santa Teresita' },
    { id: 10289, value: 'Guel' },
    { id: 10290, value: 'Ludo' },
    { id: 10291, value: 'Remigio Crespo' },
    { id: 10292, value: 'San Cristobal' },
    { id: 10293, value: 'San Rafael De Sharung' },
    { id: 10294, value: 'Zapata' },
    { id: 10295, value: 'Shidmad' },
    { id: 10296, value: 'Tomebamba' },
    { id: 10297, value: 'San Vicente' },
    { id: 10298, value: 'San Jose De Raranga' },
    { id: 10299, value: 'Progreso' },
    { id: 10300, value: 'Mariano Moreno' },
    { id: 10301, value: 'Jadan' },
    { id: 10302, value: 'Guarainag' },
    { id: 10303, value: 'Delegsol' },
    { id: 10304, value: 'Llacao' },
    { id: 10555, value: 'Pucara' },
    { id: 10780, value: 'Onia' },
    { id: 10855, value: 'San Fernando' },
    { id: 10885, value: 'Nabon' },
    { id: 10915, value: 'Chordeleg' },
    { id: 10955, value: 'Sevilla De Oro' },
    { id: 10960, value: 'El Pan' },
    { id: 10990, value: 'Guachapala' },
  ],
  bolivar: [
    { id: 20165, value: 'Guaranda' },
    { id: 20170, value: 'Chimbo' },
    { id: 20175, value: 'San Miguel' },
    { id: 20179, value: 'La Asuncion' },
    { id: 20180, value: 'Chillanes' },
    { id: 20181, value: 'La Magdalena' },
    { id: 20185, value: 'Echeandia' },
    { id: 20760, value: 'Caluma' },
    { id: 20950, value: 'Las Naves' },
  ],
  cañar: [
    { id: 30235, value: 'Azogues' },
    { id: 30240, value: 'Caniar' },
    { id: 30241, value: 'Suscal' },
    { id: 30242, value: 'Cochancay' },
    { id: 30243, value: 'San Miguel' },
    { id: 30245, value: 'Biblian' },
    { id: 30250, value: 'La Troncal' },
    { id: 30253, value: 'Gualleturo' },
    { id: 30254, value: 'Honorato Vasquez' },
    { id: 30255, value: 'Turupamba' },
    { id: 30765, value: 'El Tambo' },
    { id: 30905, value: 'Deleg' },
  ],
  pichincha: [
    { id: 170060, value: 'Quito' },
    { id: 170061, value: 'La Independencia' },
    { id: 170062, value: 'Valle Hermoso' },
    { id: 170063, value: 'Alluriquin' },
    { id: 170064, value: 'Luz De America' },
    { id: 170065, value: 'Cayambe' },
    { id: 170066, value: 'El Quinche' },
    { id: 170067, value: 'Guayllabamba' },
    { id: 170068, value: 'Pifo' },
    { id: 170070, value: 'Mejia' },
    { id: 170071, value: 'Machachi' },
    { id: 170075, value: 'Pedro Moncayo' },
    { id: 170076, value: 'Tabacundo' },
    { id: 170077, value: 'Tumbaco' },
    { id: 170080, value: 'Ruminiahui' },
    { id: 170081, value: 'Sangolqui' },
    { id: 170082, value: 'San Rafael' },
    { id: 170083, value: 'Conocoto' },
    { id: 170086, value: 'La Concordia' },
    { id: 170087, value: 'Santa Martha' },
    { id: 170545, value: 'Ventanas' },
    { id: 170101, value: 'Cumbaya' },
    { id: 170770, value: 'San Miguel De Los Bancos' },
    { id: 170895, value: 'Pedro Vicente Maldonado' },
    { id: 170896, value: 'Puerto Quito' },
    { id: 170320, value: 'No Definido' },
    { id: 170085, value: 'Sto Dgo De Los Colorados' },
  ],
  carchi: [
    { id: 40005, value: 'Tulcan' },
    { id: 40009, value: 'Julio Andrade' },
    { id: 40010, value: 'Montufar' },
    { id: 40011, value: 'Maldonado' },
    { id: 40015, value: 'Espejo' },
    { id: 40016, value: 'Urbina' },
    { id: 40017, value: 'San Gabriel' },
    { id: 40020, value: 'Mira' },
    { id: 40023, value: 'La Paz' },
    { id: 40025, value: 'Bolivar' },
    { id: 40030, value: 'El Angel' },
    { id: 40995, value: 'San Pedro De Huaca' },
  ],
  cotopaxi: [
    { id: 50100, value: 'Latacunga' },
    { id: 50102, value: 'Poalo' },
    { id: 50103, value: 'Guaytacama' },
    { id: 50104, value: 'Lasso' },
    { id: 50105, value: 'Pujili' },
    { id: 50109, value: 'San Juan De Pastocalle' },
    { id: 50110, value: 'Salcedo' },
    { id: 50111, value: 'Tanicuchi' },
    { id: 50112, value: 'Toacazo' },
    { id: 50115, value: 'Saquisili' },
    { id: 50116, value: 'Juan Montalvo' },
    { id: 50120, value: 'Pangua' },
    { id: 50123, value: 'Pilalo' },
    { id: 50132, value: 'Chantilin' },
    { id: 50137, value: 'El Corazon' },
    { id: 50825, value: 'La Mana' },
    { id: 50930, value: 'Sigchos' },
  ],
  chimborazo: [
    { id: 60190, value: 'Chambo' },
    { id: 60195, value: 'Riobamba' },
    { id: 60200, value: 'Guano' },
    { id: 60202, value: 'San Juan' },
    { id: 60205, value: 'Colta' },
    { id: 60210, value: 'Guamote' },
    { id: 60215, value: 'Alausi' },
    { id: 60218, value: 'San Andres' },
    { id: 60220, value: 'Chunchi' },
    { id: 60225, value: 'Penipe' },
    { id: 60229, value: 'Cajabamba' },
    { id: 60830, value: 'Pallatanga' },
    { id: 60900, value: 'Cumanda' },
  ],
  'el oro': [
    { id: 70350, value: 'Machala' },
    { id: 70351, value: 'Paccha' },
    { id: 70352, value: 'El Cambio' },
    { id: 70354, value: 'Puerto Bolivar' },
    { id: 70355, value: 'Santa Rosa' },
    { id: 70357, value: 'Bellavista' },
    { id: 70360, value: 'Zaruma' },
    { id: 70361, value: 'La Avanzada' },
    { id: 70365, value: 'Pasaje' },
    { id: 70370, value: 'Pinias' },
    { id: 70375, value: 'Arenillas' },
    { id: 70378, value: 'Buenavista' },
    { id: 70380, value: 'El Guabo' },
    { id: 70585, value: 'Chilla' },
    { id: 70690, value: 'Portovelo' },
    { id: 70695, value: 'Huaquillas' },
    { id: 70705, value: 'Atahualpa' },
    { id: 70755, value: 'Las Lajas' },
    { id: 70860, value: 'Marcabeli' },
    { id: 70875, value: 'Balsas' },
  ],
  esmeraldas: [
    { id: 80560, value: 'Esmeraldas' },
    { id: 80561, value: 'Rio Verde' },
    { id: 80562, value: 'Limones' },
    { id: 80565, value: 'Eloy Alfaro' },
    { id: 80570, value: 'Muisne' },
    { id: 80575, value: 'Quininde' },
    { id: 80580, value: 'San Lorenzo' },
    { id: 80608, value: 'La Union' },
    { id: 80624, value: 'Tonsupa' },
    { id: 80626, value: 'Sua' },
    { id: 80785, value: 'Atacames' },
  ],
  guayas: [
    { id: 90095, value: 'Balao' },
    { id: 90096, value: 'Punta Carnero' },
    { id: 90097, value: 'Cabuyal' },
    { id: 90099, value: 'El Salitre' },
    { id: 90100, value: 'Ayangue' },
    { id: 90101, value: 'Posorja' },
    { id: 90102, value: 'Palmar' },
    { id: 90103, value: 'Tenguel' },
    { id: 90104, value: 'Tres Postes' },
    { id: 90105, value: 'Juan Bautista Aguirre' },
    { id: 90106, value: 'Punta Blanca' },
    { id: 90118, value: 'Chongon' },
    { id: 90120, value: 'Gral Pedro Montero' },
    { id: 90125, value: 'Colimes' },
    { id: 90128, value: 'El Laurel' },
    { id: 90129, value: 'Ancon' },
    { id: 90136, value: 'Chanduy' },
    { id: 90137, value: 'Manglaralto' },
    { id: 90138, value: 'Ballenita' },
    { id: 90142, value: 'Roberto Astudillo' },
    { id: 90143, value: 'Banife' },
    { id: 90145, value: 'Junquillal' },
    { id: 90146, value: 'La Victoria' },
    { id: 90155, value: 'Taura' },
    { id: 90158, value: 'Crnel Lorenzo De Garaycoa' },
    { id: 90250, value: 'La Troncal' },
    { id: 90385, value: 'El Empalme' },
    { id: 90395, value: 'Yaguachi' },
    { id: 90400, value: 'Daule' },
    { id: 90415, value: 'Balzar' },
    { id: 90420, value: 'Milagro' },
    { id: 90425, value: 'Samborondon' },
    { id: 90430, value: 'Urbina Jado' },
    { id: 90435, value: 'Naranjal' },
    { id: 90505, value: 'El Triunfo' },
    { id: 90506, value: 'Bucay' },
    { id: 90510, value: 'Naranjito' },
    { id: 90675, value: 'Palestina' },
    { id: 90715, value: 'Pedro Carbo' },
    { id: 90735, value: 'Gral Villamil Playas' },
    { id: 90790, value: 'Simon Bolivar' },
    { id: 90820, value: 'Duran' },
    { id: 90835, value: 'Alfredo Baquerizo Moreno (Jujan)' },
    { id: 90870, value: 'Santa Lucia' },
    { id: 90890, value: 'Crnel Marcelino Mariduenias' },
    { id: 90920, value: 'Lomas De Sargentillo' },
    { id: 90940, value: 'Nobol Piedrahita' },
    { id: 90985, value: 'General A Elizalde' },
    { id: 90986, value: 'Isidro Ayora' },
    { id: 90965, value: 'La Libertad' },
    { id: 90405, value: 'Salinas' },
    { id: 90390, value: 'Guayaquil' },
  ],
  imbabura: [
    { id: 100030, value: 'Ibarra' },
    { id: 100035, value: 'Otavalo' },
    { id: 100038, value: 'San Antonio' },
    { id: 100040, value: 'Cotacachi' },
    { id: 100045, value: 'Antonio Ante' },
    { id: 100046, value: 'Ambuqui' },
    { id: 100047, value: 'Chavezpamba' },
    { id: 100050, value: 'Pimampiro' },
    { id: 100053, value: 'San Pablo' },
    { id: 100055, value: 'Urcuqui' },
    { id: 100073, value: 'Atuntaqui' },
    { id: 100081, value: 'Tumbabiro' },
    { id: 100082, value: 'Perucho' },
  ],
  loja: [
    { id: 110110, value: 'Loja' },
    { id: 110290, value: 'Catamayo' },
    { id: 110291, value: 'Alamor' },
    { id: 110292, value: 'Casanga' },
    { id: 110293, value: 'Cariamanga' },
    { id: 110294, value: 'Catacocha' },
    { id: 110295, value: 'Zapotillo' },
    { id: 110296, value: 'Changaimina' },
    { id: 110297, value: 'Colaisaca' },
    { id: 110298, value: 'El Cisne' },
    { id: 110299, value: 'El Lucero' },
    { id: 110301, value: 'El Tambo' },
    { id: 110302, value: 'Jimbura' },
    { id: 110303, value: 'La Victoria' },
    { id: 110304, value: 'Mangaurco' },
    { id: 110305, value: 'Saraguro' },
    { id: 110306, value: 'Mercadillo' },
    { id: 110307, value: 'Nueva Fatima' },
    { id: 110308, value: 'Paletilla' },
    { id: 110309, value: 'Potrerillos' },
    { id: 110310, value: 'Paltas' },
    { id: 110311, value: 'Olmedo' },
    { id: 110312, value: 'Purunuma' },
    { id: 110313, value: 'Sabanilla' },
    { id: 110314, value: 'Sacapalca' },
    { id: 110315, value: 'Celica' },
    { id: 110316, value: 'San Antonio De Las Aradas' },
    { id: 110317, value: 'San Pedro De La Bendita' },
    { id: 110318, value: 'Urdaneta' },
    { id: 110319, value: 'Vilcabamba' },
    { id: 110320, value: 'Calvas' },
    { id: 110321, value: 'Yangana' },
    { id: 110322, value: 'Chuquiribamba' },
    { id: 110323, value: 'Cruzpamba' },
    { id: 110324, value: 'El Ingenio' },
    { id: 110325, value: 'Macara' },
    { id: 110326, value: 'El Naranjo' },
    { id: 110327, value: 'La Ceiba' },
    { id: 110328, value: 'Lauro Guerrero' },
    { id: 110329, value: 'Malacatos' },
    { id: 110330, value: 'Puyango' },
    { id: 110331, value: 'Manu' },
    { id: 110332, value: 'Nambacola' },
    { id: 110333, value: 'Orianga' },
    { id: 110334, value: 'Pozul' },
    { id: 110335, value: 'Gonzanama' },
    { id: 110336, value: 'Sabianco' },
    { id: 110337, value: 'San Lucas' },
    { id: 110338, value: 'Susudel' },
    { id: 110339, value: 'Tacamoros' },
    { id: 110340, value: 'Espindola' },
    { id: 110341, value: 'Selva Alegre' },
    { id: 110342, value: 'Torata' },
    { id: 110343, value: 'Veinte Y Siete De Abril' },
    { id: 110344, value: 'San Antonio' },
    { id: 110345, value: 'Sozoranga' },
    { id: 110730, value: 'Pindal' },
    { id: 110731, value: 'Lalamor' },
    { id: 110740, value: 'Quilanga' },
    { id: 110815, value: 'Chaguarpamba' },
  ],
  'los rios': [
    { id: 120515, value: 'Babahoyo' },
    { id: 120516, value: 'Valencia' },
    { id: 120517, value: 'Banepo' },
    { id: 120518, value: 'Guare' },
    { id: 120519, value: 'La Union' },
    { id: 120520, value: 'Baba' },
    { id: 120521, value: 'Pimocha' },
    { id: 120522, value: 'Ricaurte' },
    { id: 120523, value: 'San Juan' },
    { id: 120524, value: 'Antonio Sotomayor' },
    { id: 120525, value: 'Vinces' },
    { id: 120526, value: 'Bagatela' },
    { id: 120527, value: 'Isla De Bejucal' },
    { id: 120528, value: 'Mata De Cacao' },
    { id: 120529, value: 'Patricia Pilar' },
    { id: 120530, value: 'Puebloviejo' },
    { id: 120531, value: 'Quinsaloma' },
    { id: 120532, value: 'San Carlos' },
    { id: 120533, value: 'Catarama' },
    { id: 120535, value: 'Urdaneta' },
    { id: 120540, value: 'Quevedo' },
    { id: 120545, value: 'Ventanas' },
    { id: 120710, value: 'Montalvo' },
    { id: 120750, value: 'Palenque' },
    { id: 120945, value: 'Buena Fe' },
    { id: 120946, value: 'Mocache' },
  ],
  manabi: [
    { id: 130231, value: 'Calderon' },
    { id: 130232, value: 'Canuto' },
    { id: 130233, value: 'Campuzano' },
    { id: 130234, value: 'Charapoto' },
    { id: 130235, value: 'Cojimies' },
    { id: 130236, value: 'Crucita' },
    { id: 130237, value: 'Calceta' },
    { id: 130440, value: 'Portoviejo' },
    { id: 130445, value: 'Montecristi' },
    { id: 130446, value: 'Jaramijo' },
    { id: 130450, value: 'Manta' },
    { id: 130455, value: 'Jipijapa' },
    { id: 130460, value: 'Veinte Y Cuatro De Mayo' },
    { id: 130465, value: 'Rocafuerte' },
    { id: 130470, value: 'Santa Ana' },
    { id: 130475, value: 'Sucre' },
    { id: 130476, value: 'Jama' },
    { id: 130477, value: 'San Vicente' },
    { id: 130480, value: 'Chone' },
    { id: 130490, value: 'Junin' },
    { id: 130495, value: 'Pajan' },
    { id: 130500, value: 'El Carmen' },
    { id: 130550, value: 'Tosagua' },
    { id: 130840, value: 'Pichincha' },
    { id: 130910, value: 'Pedernales' },
    { id: 130970, value: 'Olmedo' },
    { id: 130975, value: 'Puerto Lopez' },
    { id: 130976, value: 'Bahia De Caraquez' },
    { id: 130230, value: 'Flavio Alfaro' },
  ],
  'morona santiago': [
    { id: 140590, value: 'Morona' },
    { id: 140591, value: 'Macas' },
    { id: 140592, value: 'Chinimbini' },
    { id: 140593, value: 'Gral Proanio' },
    { id: 140594, value: 'Huambi' },
    { id: 140595, value: 'Gualaquiza' },
    { id: 140596, value: 'Patuca' },
    { id: 140597, value: 'Rio Blanco' },
    { id: 140598, value: 'Sevilla Don Bosco' },
    { id: 140599, value: 'Chiguinda' },
    { id: 140600, value: 'Limon Indanza' },
    { id: 140601, value: 'Gral Leonidas Plaza' },
    { id: 140602, value: 'Plan Demilagro' },
    { id: 140603, value: 'Rio San Jose' },
    { id: 140604, value: 'Santa Ana' },
    { id: 140605, value: 'Santiago De Mendez' },
    { id: 140610, value: 'Sucua' },
    { id: 140611, value: 'Logronio' },
    { id: 140615, value: 'Palora' },
    { id: 140795, value: 'Huamboya' },
    { id: 140925, value: 'San Juan Bosco' },
    { id: 140926, value: 'Taisha' },
  ],
  napo: [
    { id: 150255, value: 'El Chaco' },
    { id: 150256, value: 'Baeza' },
    { id: 150620, value: 'Tena' },
    { id: 150621, value: 'Carlos Julio Arosemena Tola' },
    { id: 150635, value: 'Quijos' },
    { id: 150700, value: 'Archidona' },
  ],
  pastaza: [
    { id: 160680, value: 'Pastaza' },
    { id: 160681, value: 'Puyo' },
    { id: 160682, value: 'Shell' },
    { id: 160685, value: 'Mera' },
    { id: 160880, value: 'Santa Clara' },
    { id: 160881, value: 'Arajuno' },
  ],
  tungurahua: [
    { id: 180090, value: 'Tisaleo' },
    { id: 180091, value: 'Izamba' },
    { id: 180092, value: 'Quisapincha' },
    { id: 180135, value: 'Ambato' },
    { id: 180140, value: 'Pillaro Viejo' },
    { id: 180145, value: 'Pillaro' },
    { id: 180147, value: 'Pelileo' },
    { id: 180150, value: 'Banios' },
    { id: 180155, value: 'Patate' },
    { id: 180160, value: 'Quero' },
    { id: 180845, value: 'Cevallos' },
    { id: 180850, value: 'Mocha' },
  ],
  'zamora chinchipe': [
    { id: 190130, value: 'Nangaritza' },
    { id: 190131, value: 'San Carlos De Las Minas' },
    { id: 190132, value: 'Zumba' },
    { id: 190655, value: 'Zamora' },
    { id: 190660, value: 'Chinchipe' },
    { id: 190661, value: 'Palanda' },
    { id: 190665, value: 'Yacuambi' },
    { id: 190670, value: 'Yanzatza' },
    { id: 190775, value: 'El Pangui' },
    { id: 190980, value: 'Centinela Del Condor' },
  ],
  galapagos: [
    { id: 200800, value: 'San Cristobal' },
    { id: 200805, value: 'Santa Cruz' },
    { id: 200810, value: 'Isabela' },
  ],
  sucumbios: [
    { id: 210625, value: 'Lago Agrio' },
    { id: 210626, value: 'Cuyabeno' },
    { id: 210627, value: 'Nueva Loja' },
    { id: 210640, value: 'Sucumbios' },
    { id: 210650, value: 'Putumayo' },
    { id: 210720, value: 'Shushufindi' },
    { id: 210745, value: 'Cascales' },
    { id: 210865, value: 'Gonzalo Pizarro' },
  ],
  orellana: [
    { id: 220630, value: 'Aguarico' },
    { id: 220631, value: 'Nuevo Rocafuerte' },
    { id: 220645, value: 'Francisco De Orellana' },
    { id: 220725, value: 'Joya De Los Sagchas' },
    { id: 220935, value: 'Loreto' },
  ],
  'santo domingo de los tsachilas': [
    { id: 230085, value: 'Santo Domingo De Los Colorados' },
  ],
  'santa elena': [
    { id: 240405, value: 'Salinas' },
    { id: 240410, value: 'Santa Elena' },
    { id: 240965, value: 'La Libertad' },
  ],
};
