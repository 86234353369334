import React, { Component } from "react";
import alertIcon from "../../assets/icons/alertIcon.svg";
import "./MessageBar.scss";

interface MessageBarProps {
  id: string;
  message: string;
  variant: string;
  color: string;
  extraClasses: string;
  handleClose?: (e: any) => void;
}
class MessageBar extends Component<MessageBarProps> {
  render() {
    const icons = {
      warning: (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1 21H23L12 2L1 21ZM13 18H11V16H13V18ZM13 14H11V10H13V14Z"
            fill="#F76800"
          ></path>
        </svg>
      ),
      error: <img src={alertIcon} alt="" />,
    };

    const { id, variant, color, message, extraClasses, handleClose } =
      this.props;
    //@ts-ignore
    const icon = icons[color];

    return (
      <div
        id={id}
        className={`container-message container-message--${variant}--${color} container-message--adjustin ${extraClasses}`}
        data-testid="svg-message-bar"
      >
        <div className="align-message-bar">
          <div className="icon-and-message">
            {icon}
            <div className="text-error">{message}</div>
          </div>
          <div className="icon-close">
            <pichincha-icon
              size="20px"
              color="darkGrey"
              weight_color="400"
              type="--outlined"
              onClick={handleClose}
              data-testid="modalCloseButtonTestId"
            >
              close
            </pichincha-icon>
          </div>
        </div>
      </div>
    );
  }
}

export default MessageBar;
