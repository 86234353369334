import React, { useCallback, useEffect, useState } from "react";
//Redux
import { connect } from "react-redux";
import { ReduxState } from "../../../common/store";
import actions from "../../../actions/";
import actionTypes from "../../../actions/actionTypes";

//Utils
import Cookies from "js-cookie";
import {
  capitalizeName,
  getUtmHeaders,
  numberFormatCurrency,
} from "../../../common/helpers/functions";
import MessageBar from "../../MessageBar/MessageBar";
import useWindowSize from "../../Shared/hooks/UseWindowSize";
import {
  FACEPHI_VALIDATED_IMAGE_KEY,
  GUID,
  REACT_APP_RECAPTCHA_TOKEN,
} from "../../../common/helpers/constants";

//Components
import InfoCard from "./InfoCard";
import Buttons from "./Buttons";
import RecaptchaDisclaimer from "../../RecaptchaDisclaimer/RecaptchaDisclaimer";
import SuccessIcon from "../../../assets/icons/success-icon.svg";

//Styles
import "./PrequalificationResponses.scss";
import Popover from "../../Popover";
import ClosePopOver from "../../Shared/hooks/ClosePopOver";
import { getEncryptedItemFromCookie } from "../../../common/helpers/storage-utils";

function OfferPage(props: any) {
  ClosePopOver();
  const {
    totalAmount,
    monthlyFee,
    annualRate,
    paymentTerm,
    estimatedDebt,
    isMVP,
  } = props.expressRequest.prequalificationData;

  const [execBtn, setExecBtn] = useState(true);
  const { monthlyIncome } = props.expressRequest.workData;

  const [width] = useWindowSize();
  const [openInformation, setOpenInformation] = useState(false);
  const [tittleMsg, setTittleMsg] = useState("");
  const [contentMsg, setContentMsg] = useState("");
  const {
    showOfferPage,
    showCounterofferPage,
    showAlert,
    failuresCount,
    payload: sentPayload,
  } = props.expressRequest;

  const { loan } = JSON.parse(sentPayload);
  const { productType: selected_product_type, propertyCost: amount } = loan;
  const { firstName } = props.civilRegistration;
  const capitalizedFirstName = capitalizeName(firstName);

  const handleUserName = useCallback(() => {
    return capitalizedFirstName ? capitalizedFirstName + ", d" : "D";
  }, [capitalizedFirstName]);

  const title = `${handleUserName()}e acuerdo a la información que has proporcionado, puedes acceder a un crédito de hasta:`;

  useEffect(() => {
    let tittleMsgToSet = "",
      contentMsgToSet = "";

    if (showOfferPage) {
      if (isMVP) {
        tittleMsgToSet = "Empieza a hacer realidad el sueño de tu casa propia";
        contentMsgToSet =
          "Completa algunos detalles más sobre ti y te asignaremos un asesor hipotecario, quien se contactará contigo para personalizar tu oferta y guiarte de inicio a fin en el viaje a tu nuevo hogar. ¡Estamos para ti!";
      } else {
        tittleMsgToSet = "Empieza a hacer realidad el sueño de tu casa propia";
        contentMsgToSet =
          "Nos contactaremos contigo para personalizar tu oferta y guiarte de inicio a fin en el viaje hacia tu nuevo hogar. Estamos para ti.";
      }
    }

    if (showCounterofferPage) {
      if (isMVP) {
        tittleMsgToSet = "Logremos juntos el sueño de tu casa propia";
        contentMsgToSet =
          "Esta oferta puede ser mejorada dentro del proceso de solicitud con nuestra asesoría. Completa algunos detalles más sobre ti y te asignaremos un asesor hipotecario personal, quien te guiará de inicio a fin. ¡Estamos para ti!";
      } else {
        tittleMsgToSet = "Logremos juntos el sueño de tu casa propia";
        contentMsgToSet =
          "Esta oferta puede ser mejorada dentro del proceso de solicitud con nuestra asesoría. Comienza ahora y nos pondremos en contacto contigo para guiarte de inicio a fin en el viaje a tu nuevo hogar.";
      }
    }

    setTittleMsg(tittleMsgToSet);
    setContentMsg(contentMsgToSet);
  }, [
    showCounterofferPage,
    showOfferPage,
    capitalizedFirstName,
    isMVP,
    handleUserName,
  ]);

  useEffect(() => {
    if (failuresCount) setExecBtn(true);
  }, [failuresCount]);

  /**
   * handles the opening of the messages section
   */
  const toggleInformationSection = () => {
    setOpenInformation(!openInformation);
  };

  const execSendApplication = () => {
    if (execBtn) {
      setExecBtn(false);
      sendApplication();
    }
  };

  /**
   * handle the start now button
   */
  const sendApplication = () => {
    const { payload } = props.expressRequest;
    const { otpSuccess: isClient, fingeprintCodeSuccess: noClient } =
      props.site;
    let utmHeaders: any = null;
    let facephiToken = "";

    if (isClient) {
      utmHeaders = getUtmHeaders();
    }
    if (noClient) {
      const facephiData = getEncryptedItemFromCookie("facephi") || "{}";
      const facephiValidImageToken =
        Cookies.get(FACEPHI_VALIDATED_IMAGE_KEY) || "{}";
      const { utmHeaders: savedUtmHeaders } = facephiData;
      const { token } = JSON.parse(facephiValidImageToken);

      facephiToken = token;
      utmHeaders = savedUtmHeaders;
    }
    const { sendExpressRegistration: action } = props;
    const { channel, jwt } = props.site;
    //@ts-ignore
    grecaptcha
      .execute(REACT_APP_RECAPTCHA_TOKEN)
      .then(function (recaptchaToken: string) {
        action(JSON.parse(payload), "loanRequest", utmHeaders, channel, {
          token: recaptchaToken,
          GUID,
          jwt,
          imageAuthToken: facephiToken,
        });
      });
  };

  /**
   * controls the label of the button that executes the final step
   */

  const handleContinueButtonText = () => {
    if (failuresCount) {
      return "Reintentar";
    }
    if (isMVP) {
      return "Continuar";
    } else {
      return "Empezar ahora";
    }
  };
  const continueButtonText = handleContinueButtonText();
  const texts: { [key: string]: any } = {
    "vip-vis": "Vivienda de interés público y social",
    "new-used": "Vivienda nueva o usada",
  };

  /**
   * Controls the content of the link that shows or hides the detail of the proposal
   */
  const contentLinkShowDetail: { [key: string]: any } = {
    0: {
      arrowIcon: "arrow_drop_down",
      text: "Ver más detalles",
      classNameStatus: "",
      separatorVisibility: "d-none",
    },
    1: {
      arrowIcon: "arrow_drop_up",
      text: "Ver menos detalles",
      classNameStatus: "open",
      separatorVisibility: "d-block",
    },
  };
  const dataToRender = contentLinkShowDetail[Number(openInformation)];

  /**
   * Properties for the inforCard component that displays the message below the detail
   */
  const inforCardProps = {
    icon: "mortgage_credit",
    iconSize: 72,
    title: tittleMsg,
    titleVariant: "h3",
    titleWeight: "bold",
    content: (
      <div className="bp-mt-8">
        <pichincha-typography variant="bodyText">
          {contentMsg}
        </pichincha-typography>
      </div>
    ),
    extraClassNames: "bg-white",
  };

  const handleClickCancel = () => {
    props.cleanSession();
    props.resetExpressRequest(actionTypes.EXPRESS_REQUEST_RESET_STATE);
    props.resetCivilRegistration();
    props.loginResetState();
    props.resetTokenSession();
  };

  /**
   * Component for information and actions buttons
   * @returns
   */
  const infoAndButtons = (opt: string) => {
    return (
      <div className="bp-mt-16">
        <InfoCard {...inforCardProps} />
        <Buttons
          backText="Cancelar"
          backClickHandler={handleClickCancel}
          backId={"backButtonOfferPage" + opt}
          continueText={continueButtonText}
          continueClickHandler={execSendApplication}
          continueId={"continueButtonOfferPage" + opt}
        />
      </div>
    );
  };

  /**
   * Properties for the error message component
   */
  const errorMessageBarProps = {
    id: "errorAtOfferPageRequest",
    message:
      "Los datos no se enviaron correctamente. Por favor, vuelve a intentarlo.",
    variant: "light",
    color: "error",
    extraClasses: "invalid-request error-message-offerpage bp-mb-16 bp-mt-8",
    handleClose: () => props.updateStateValue(actionTypes.TOGGLE_ALERT, false),
  };

  const getOfferCalcSection = () => {
    if (showCounterofferPage) {
      return (
        <>
          <div className="divider w-divider-100 bp-my-8"></div>

          <div className="row">
            <pichincha-typography variant="captionText" weight_color="500">
              ¿Cómo calculamos tu oferta?
            </pichincha-typography>
          </div>
          <div className="row">
            <pichincha-typography variant="legalText" weight_color="400">
              Tomando en cuenta tus ingresos y deudas
            </pichincha-typography>
          </div>

          <div className="row">
            <Popover
              id="pop-over-monthly-income"
              linkName="Tus ingresos mensuales"
              popoverContent="Son los ingresos mensuales que ingresaste al completar el formulario."
            />

            <pichincha-typography variant="legalText" weight_color="600">
              {monthlyIncome}
            </pichincha-typography>
          </div>

          <div className="row">
            <Popover
              id="pop-over-debts"
              linkName="Deuda mensual comprometida en entidades financieras"
              popoverContent="Es la suma total de deudas que tú y/o tu cónyuge poseen en el sistema financiero."
            />

            <pichincha-typography variant="legalText" weight_color="600">
              {numberFormatCurrency(estimatedDebt)}
            </pichincha-typography>
          </div>
        </>
      );
    }
  };

  return (
    <div className="offer-container" data-testid="offerPageTestId">
      {showAlert ? (
        <div className="message-bar-container offer-page">
          <MessageBar {...errorMessageBarProps} />
        </div>
      ) : null}
      <div className="main_header"></div>

      <div className="info-cards bp-mb-48">
        <div className="card">
          <div className="summary-wrapper bp-mb-32">
            <div className="header_content">
              <div className="icon bp-mt-32">
                <img src={SuccessIcon} alt="" height={50} width={50} />
              </div>
              <div className="bp-px-32 bp-py-16">
                <pichincha-typography
                  variant={width > 650 ? "h2" : "h3"}
                  weight="bold"
                  color="blue"
                  weight_color="600"
                  align="center"
                >
                  {title}
                </pichincha-typography>
              </div>

              <pichincha-typography
                variant="hero"
                color="blue"
                weight="bold"
                weight_color="500"
                align="inherit"
              >
                $ {numberFormatCurrency(totalAmount)}
              </pichincha-typography>

              <div className="bp-mt-8">
                <pichincha-typography
                  variant="captionText"
                  weight_color="400"
                  color="darkGrey"
                  weight="normal"
                  align="center"
                >
                  Cuota mensual referencial
                </pichincha-typography>

                <pichincha-typography weight_color="600" align="center">
                  $ {numberFormatCurrency(monthlyFee)}
                </pichincha-typography>
              </div>
              <div className="bp-mt-8">
                <pichincha-typography
                  variant="bodyText"
                  weight="600"
                  weight_color="600"
                  align="center"
                >
                  Financiando a {paymentTerm}
                </pichincha-typography>
              </div>
              <div
                className="toggle-details"
                onClick={toggleInformationSection}
                data-testid="togglerTestId"
              >
                <pichincha-typography
                  variant="actionText"
                  color="info"
                  weight_color="500"
                  data-testid="togglerTextTestId"
                >
                  {dataToRender.text}
                </pichincha-typography>
                <div className="arrow">
                  <pichincha-icon color="info" weight_color="500">
                    {dataToRender.arrowIcon}
                  </pichincha-icon>
                </div>
              </div>
              <div className="divider w-divider-90"></div>
            </div>
            <div
              className={`text-prelo-medium collapsible-section ${dataToRender.classNameStatus} bp-mt-16`}
            >
              <div className="collapsible-content">
                <div className="row">
                  <pichincha-typography variant="legalText" weight_color="400">
                    Producto
                  </pichincha-typography>
                  <pichincha-typography variant="legalText" weight_color="600">
                    {texts[selected_product_type]}
                  </pichincha-typography>
                </div>
                <div className="row">
                  <pichincha-typography variant="legalText" weight_color="400">
                    Motivo del crédito
                  </pichincha-typography>
                  <pichincha-typography variant="legalText" weight_color="600">
                    Compra de vivienda
                  </pichincha-typography>
                </div>
                <div className="row">
                  <pichincha-typography variant="legalText" weight_color="400">
                    Precio de la vivienda
                  </pichincha-typography>
                  <pichincha-typography variant="legalText" weight_color="600">
                    $ {numberFormatCurrency(amount)}
                  </pichincha-typography>
                </div>
                <div className="row">
                  <pichincha-typography variant="legalText" weight_color="400">
                    Tasa de interés referencial
                  </pichincha-typography>
                  <pichincha-typography variant="legalText" weight_color="600">
                    {annualRate}%
                  </pichincha-typography>
                </div>
                <div className="row">
                  <Popover
                    id="pop-over-amortization"
                    linkName="Sistema de amortización"
                    popoverContent="El cálculo de la cuota está basado en el sistema de amortización francés. Una vez inicies el viaje al sueño de tu hogar, podrás elegir el sistema de amortización que más te convenga."
                  />
                  <pichincha-typography variant="legalText" weight_color="600">
                    Francesa
                  </pichincha-typography>
                </div>
                {getOfferCalcSection()}

                <div className="divider w-divider-100 bp-my-8"></div>
              </div>
              {infoAndButtons("0")}
            </div>
            {!openInformation && infoAndButtons("1")}
            <div className="section-legal bp-mb-32">
              <div className="divider w-divider-100 bp-mb-16"></div>
              <pichincha-typography
                variant="legalText"
                weight_color="400"
                align="center"
              >
                Recuerda que la aprobación definitiva del monto de tu crédito
                dependerá del análisis y validación de la información que te
                pediremos durante el proceso de solicitud.
              </pichincha-typography>
            </div>
          </div>
        </div>
      </div>
      <div className="recaptcha">
        <RecaptchaDisclaimer />
      </div>
    </div>
  );
}

const mapStateToProps = (state: ReduxState) => {
  const { expressRequest, civilRegistration, calculator, site } = state;
  return {
    expressRequest,
    civilRegistration,
    calculator,
    site,
  };
};

const {
  sendExpressRegistration,
  updateStateValue,
  cleanSession,
  resetExpressRequest,
  resetCivilRegistration,
  loginResetState,
  resetTokenSession,
} = actions;

const connector = connect(mapStateToProps, {
  sendExpressRegistration,
  updateStateValue,
  cleanSession,
  resetExpressRequest,
  resetCivilRegistration,
  loginResetState,
  resetTokenSession,
});

export default connector(OfferPage);
