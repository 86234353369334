import actionTypes from '../actionTypes';

export const setLoanApplicationAdviser = (value: string) => ({
  type: actionTypes.SET_LOAN_APPLICATION_ADVISER,
  value,
});

export const setProjectInformation = (type: string, value: string) => ({
  type,
  value,
});