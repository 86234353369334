import { Component, Suspense } from "react";
import { connect, ConnectedProps } from "react-redux";
import actions from "./actions";
import "./App.scss";
import { ReduxState } from "./common/store";

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

// @ts-ignore
import WOW from "wowjs";

import TagManager from "react-gtm-module";
import { REACT_APP_GOOGLE_TAG_MANAGER_TOKEN } from "./common/helpers/constants";
import { PATHS } from "./common/helpers/paths";
import Calculator from "./components/Calculator";
import CallMeBackButton from "./components/CallMeBackButton/CallMeBackButton";
import NotFoundPage from "./components/Errors/NotFoundPage";
import UnauthorizedPage from "./components/Errors/UnauthorizedPage";
import ExpressRequest from "./components/ExpressRequest/ExpressRequest";
import Footer from "./components/Footer/Footer";
import Home from "./components/Home/Home";
import HtmlMetaTag from "./components/HtmlMetaTag/HtmlMetaTag";
import Index from "./components/Login/Index";
import MessageBar from "./components/MessageBar/MessageBar";
import MortgageGuide from "./components/MortgageGuide";
import Navbar from "./components/navbar/Navbar";
import ChannelLoader from "./components/Site/ChannelLoader";
import SiteInConstruction from "./components/SiteInConstruction/SiteInConstruction";
import Logout from "./components/Shared/Components/Logout/logout";

const tagManagerArgs = {
  gtmId: REACT_APP_GOOGLE_TAG_MANAGER_TOKEN,
};

TagManager.initialize(tagManagerArgs);

class App extends Component<ReduxProps> {
  componentDidMount() {
    const { toggleInternetConnection } = this.props;
    new WOW.WOW({
      live: false,
    }).init();

    setInterval(() => {
      toggleInternetConnection(window.navigator.onLine);
    }, 500);
  }

  render() {
    const {
      internetConnection,
      callMeBackButtonStatus,
      setCallMeBackButtonStatus,
    } = this.props;

    if (!internetConnection) {
      return (
        <div className="wrapper-content">
          <Navbar conecctionFailNavbar={true} />
          <div className="page-content fit-footer" id="pageContentId">
            <div className="error-page-margin">
              <MessageBar
                id="noInternetConnection"
                message="Sin conexión a Internet"
                variant="light"
                color="warning"
                extraClasses=""
              />
            </div>
            <Footer />
          </div>
        </div>
      );
    }

    return (
      <>
        <Router>
          <Suspense fallback={<>Cargando...</>}>
            <div className="main-container">
              <Switch>
                <Route exact path={[PATHS.home, PATHS.homeWithChannel]}>
                  <CallMeBackButton
                    {...callMeBackButtonStatus}
                    setStatus={this.props.setCallMeBackButtonStatus}
                  />

                  <HtmlMetaTag
                    title="Crédito hipotecario - Banco Pichincha"
                    description="Obtén tu crédito hipotecario de vivienda nueva o usada en Banco Pichincha. Infórmate, simula, solicita y haz realidad el sueño de tu nuevo hogar."
                  />

                  <ChannelLoader />
                  <Home />
                </Route>

                <Route
                  exact
                  path={[PATHS.login, PATHS.loginWithPlusvaliaChannel]}
                >
                  <HtmlMetaTag
                    title="Solicita tu crédito hipotecario - Banco Pichincha"
                    description="Solicita tu crédito hipotecario en línea en tan solo tres pasos, desde donde y cuando quieras. El camino a hacia tu nuevo hogar empieza aquí."
                  />

                  <ChannelLoader />
                  <Index />
                </Route>

                <Route
                  exact
                  path={[
                    PATHS.expressApplication,
                    PATHS.expressApplicationWithChannel,
                  ]}
                >
                  <HtmlMetaTag
                    title="Solicita tu crédito hipotecario - Banco Pichincha"
                    description="Solicita tu crédito hipotecario en línea en tan solo tres pasos, desde donde y cuando quieras. El camino a hacia tu nuevo hogar empieza aquí."
                  />

                  <ChannelLoader />
                  <ExpressRequest />
                </Route>

                <Route path={PATHS.calculator}>
                  <CallMeBackButton
                    {...callMeBackButtonStatus}
                    setStatus={setCallMeBackButtonStatus}
                  />

                  <HtmlMetaTag
                    title="Simulador de crédito hipotecario - Banco Pichincha"
                    description="Conoce rápidamente cuál es la cuota que tendrías que pagar por tu crédito hipotecario."
                  />

                  <ChannelLoader />
                  <Calculator />
                </Route>

                <Route path={PATHS.mortgageGuide}>
                  <CallMeBackButton
                    {...callMeBackButtonStatus}
                    setStatus={setCallMeBackButtonStatus}
                  />

                  <HtmlMetaTag
                    title="Cómo obtener tu crédito hipotecario - Banco Pichincha"
                    description="La guía que te ayudará a tomar las mejores decisiones para adquirir tu crédito hipotecario en Banco Pichincha y así lograr el sueño de tu casa."
                  />

                  <ChannelLoader />
                  <MortgageGuide />
                </Route>

                <Route path={PATHS.inConstruction}>
                  <HtmlMetaTag title="Sitio en construcción - Banco Pichincha" />

                  <SiteInConstruction />
                </Route>

                <Route exact path={[PATHS.error403]}>
                  <HtmlMetaTag title="Acceso no autorizado - Banco Pichincha" />

                  <ChannelLoader />
                  <UnauthorizedPage />
                </Route>

                <Route exact path={PATHS.logout}>
                  <Logout />
                </Route>

                <Route>
                  <HtmlMetaTag title="Página no encontrada - Banco Pichincha" />

                  <NotFoundPage
                    handleClick={() =>
                      window.location.replace(window.location.origin)
                    }
                  />
                </Route>
              </Switch>
            </div>
          </Suspense>
        </Router>
      </>
    );
  }
}

const mapStateToProps = (state: ReduxState) => {
  return {
    internetConnection: state.calculator.internetConnection,
    callMeBackButtonStatus: state.callMeBack.callMeBackButtonStatus,
  };
};

const connector = connect(mapStateToProps, {
  toggleInternetConnection: actions.toggleInternetConnection,
  setCallMeBackButtonStatus: actions.setCallMeBackButtonStatus,
});

type ReduxProps = ConnectedProps<typeof connector>;

export default connector(App);
