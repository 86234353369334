export const GET_PRODUCT_REQUEST = '@@dreambuilders/GET_PRODUCT_REQUEST';
export const GET_PRODUCT_SUCCESS = '@@dreambuilders/GET_PRODUCT_SUCCESS';
export const GET_PRODUCT_FAILED = '@@dreambuilders/GET_PRODUCT_FAILED';

export const SET_SELECTED_PRODUCT = '@@dreambuilders/SET_SELECTED_PRODUCT';

export const UPDATE_INPUT_HOUSE_PRICE = '@@dreambuilders/UPDATE_INPUT_HOUSE_PRICE';
export const UPDATE_INPUT_REQUESTED_AMOUNT = '@@dreambuilders/UPDATE_INPUT_REQUESTED_AMOUNT';
export const UPDATE_INPUT_LOAN_TERM_YEARS = '@@dreambuilders/UPDATE_INPUT_LOAN_TERM_YEARS';

export const VALIDATE_INPUT_HOUSE_PRICE = '@@dreambuilders/VALIDATE_INPUT_HOUSE_PRICE';
export const VALIDATE_INPUT_REQUESTED_AMOUNT = '@@dreambuilders/VALIDATE_INPUT_REQUESTED_AMOUNT';
export const VALIDATE_INPUT_LOAN_TERM_YEARS = '@@dreambuilders/VALIDATE_INPUT_LOAN_TERM_YEARS';

export const SET_GERMAN_AMORTIZATION = '@@dreambuilders/SET_GERMAN_AMORTIZATION';
export const SET_FRENCH_AMORTIZATION = '@@dreambuilders/SET_FRENCH_AMORTIZATION';

export const TOGGLE_MODAL_STATE = '@@dreambuilders/TOGGLE_MODAL_STATE';
export const TOGGLE_POPOVER_STATE = '@@dreambuilders/TOGGLE_POPOVER_STATE';
export const TOGGLE_INPUTS_STYLE = '@@dreambuilders/TOGGLE_INPUTS_STYLE';
export const TOGGLE_INTERNET_CONNECTION = '@@dreambuilders/TOGGLE_INTERNET_CONNECTION';





export const CALCULATE_REQUEST = '@@dreambuilders/CALCULATE_REQUEST';
export const CALCULATE_SUCCESS = '@@dreambuilders/CALCULATE_SUCCESS';
export const CALCULATE_FAILED = '@@dreambuilders/CALCULATE_FAILED';

export const FOCUS_RESULTS_SECTION = '@@dreambuilders/FOCUS_RESULTS_SECTION';
export const FOCUS_INPUTS_SECTION = '@@dreambuilders/FOCUS_INPUTS_SECTION';

export const RESET_CALCULATION = '@@dreambuilders/RESET_CALCULATION';

