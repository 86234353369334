
import { MonthsI } from '../../../../../interfaces/app'
import UseInitWork, { UseInitWorkProps } from '../use-init-work/UseInitWork'
import { OptionInterface } from '../../Select'
import { MSG_FORMS } from '../../../../../common/helpers/constants'
import Typography from '../../Typography'
import Calendar from '../../../../Calendar'
import { FC, useEffect, useState } from 'react'
import { getDateLastJob, getDefaultDate, GetLastJobProps } from './helper'
import './use-last-job.scss'

export interface UseLastJobI {
  validData: boolean;
  data: any;
  setData: (e: any) => void;
  cleanDate?: boolean;
  setcleanDate: (e: any) => void;
}

export const UseLastJob: FC<UseLastJobI> = ({
  validData,
  data,
  setData,
  cleanDate,
  setcleanDate,
}) => {
  const [disabledEndCalendar, setDisabledEndCalendar] = useState(false)
  const [listYearsEnd, setListYearsEnd] = useState<Array<OptionInterface>>([])
  const [listDisabledMonths, setListDisabledMonths] = useState<Array<MonthsI>>(
    []
  );

  const handleEndWork = (item: string, isDefaultValue: boolean = false) => {
    const propsGetDateLasJob: GetLastJobProps = {
      monthAndYear: item,
      setDisabledEndCalendar,
      setListYearsEnd,
      setListDisabledMonths,
      type: isDefaultValue ? 'defaultValue': ''
    }
    setData({
      property: "previousEndWork",
      value: getDateLastJob(propsGetDateLasJob),
    });
  };

  useEffect(() => {
    if (data.previousInitWork === null || data.previousInitWork === '' ) {
      setDisabledEndCalendar(true)
      return 
    } 
    const dateInitWork = data.previousInitWork.split('/')
    handleEndWork(`${dateInitWork[1]}/${dateInitWork[2]}`, true)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const InitWorkPros: UseInitWorkProps = {
    data,
    onlyInit: false,
    setData,
    validData,
    cleanDate,
    setcleanDate,
    setDisabledEndCalendar,
    setListYearsEnd,
    setListDisabledMonths,
  };

  return (
    <>
      <div>
        <Typography
          fontSize="16"
          font="prelo-semibold"
          color="grayscale500"
          fontWeight="600"
          className="bp-mb-8"
        >
          {"Datos del último empleo"}
        </Typography>
      </div>
      <div className="calendar-position">
        <div className="calendar-position__init">
          {<UseInitWork {...InitWorkPros} />}
        </div>

        <div className="calendar-position__end">
          <Calendar
            onSelect={(e: string) => handleEndWork(e)}
            listYears={listYearsEnd}
            disabled={disabledEndCalendar}
            listDisabledMoths={listDisabledMonths}
            label={"Fecha de salida"}
            error={
              validData &&
              data.previousEndWork === null &&
              data.previousInitWork !== null
            }
            errorMsg={MSG_FORMS.INCOMPLETE_SELECT}
            defaultValue={getDefaultDate(data.previousEndWork)}
          />
        </div>
      </div>
    </>
  );
};
