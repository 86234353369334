import { AnyAction } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import * as CONSTANTS from '../../common/helpers/constants';
import actionTypes from '../actionTypes';
import {
  PayloadCalculationProps,
} from '../../common/helpers/integration';

export const getMortgageProduct =
  (productType: string): ThunkAction<void, {}, unknown, AnyAction> =>
  async (dispatch: ThunkDispatch<{}, unknown, AnyAction>) => {
    dispatch({ type: actionTypes.GET_PRODUCT_REQUEST });

    const endpoint = `${CONSTANTS.MORTGAGE_PRODUCT}${productType}`;

    try {
      const response = await fetch(endpoint);
      if (!response.ok) {
        dispatch({ type: actionTypes.GET_PRODUCT_FAILED });
        return;
      }

      const data = await response.json();

      dispatch({
        type: actionTypes.GET_PRODUCT_SUCCESS,
        product: data,
        productType,
      });
    } catch (error) {
      dispatch({ type: actionTypes.GET_PRODUCT_FAILED });
    }
  };

export const setSelectedProduct = (selectedProduct: string) => ({
  type: actionTypes.SET_SELECTED_PRODUCT,
  selectedProduct,
});

export const updateInputContent = (
  newValue: string | null,
  type: string,
  basicSalary?: number
) => ({
  type,
  newValue,
  basicSalary,
});

export const validateInput = (type: string) => ({
  type,
});

export const setAmortizationType = (type: string) => ({
  type,
});

export const toggleModal = (show: boolean, section: string) => ({
  type: actionTypes.TOGGLE_MODAL_STATE,
  show,
  section,
});

export const toggleInputsStyle = (newState: boolean) => ({
  type: actionTypes.TOGGLE_INPUTS_STYLE,
  newState,
});

export const toggleInternetConnection = (status: boolean) => ({
  type: actionTypes.TOGGLE_INTERNET_CONNECTION,
  status,
});

interface CalculateRequestI {
  payload: PayloadCalculationProps;
  recaptchaToken: string;
  guid: string;
  channel: string;
}

export const calculate =
  (
    requestConfig: CalculateRequestI
  ): ThunkAction<void, {}, unknown, AnyAction> =>
  async (dispatch: ThunkDispatch<{}, unknown, AnyAction>) => {
    dispatch({ type: actionTypes.CALCULATE_REQUEST });
    try {
      const response = await fetch(CONSTANTS.MORTGAGE_CALCULATOR, {
        method: 'POST',
        body: JSON.stringify(requestConfig.payload),
        headers: {
          'Content-Type': 'application/json',
          'X-Recaptcha-Token': requestConfig.recaptchaToken,
          'X-GUID': requestConfig.guid,
          'X-Channel': requestConfig.channel,
        },
      });
      if (!response.ok) {
        dispatch({
          type: actionTypes.CALCULATE_FAILED,
          requestStatus: response.status,
        });
        return;
      }
      const data = await response.json();
      dispatch({
        type: actionTypes.CALCULATE_SUCCESS,
        data: data,
      });
      dispatch({
        type: actionTypes.FOCUS_RESULTS_SECTION,
      });
    } catch (error: any) {
      dispatch({
        type: actionTypes.CALCULATE_FAILED,
        requestStatus: error.response,
      });
    }
  };

export const triggerAction = (type: string) => ({
  type,
});

export const setSelectedSchedule = (type: string) => ({
  type,
});

export const setFullName = (type: string) => ({
  type,
});