import React, { Component } from "react";

import Thumbnail from "../../Thumbnail";

import errorIcon from "../../../assets/icons/errorIcon.svg";

class UnauthorizedChannel extends Component {
  render() {

    return (
      <div className="error-page">
        <div className="icon-container">
          <Thumbnail url={errorIcon} name={"imagen"} />
        </div>
        <div className="title-container padding-wrapper">
          <h4 className="title" id="errorUnauthorizedChannel">¡Acceso no autorizado!</h4>
        </div>
        <div className="text-container padding-wrapper">
          <pichincha-typography
            variant="bodyText"
            color="darkGrey"
            align="center"
          >
            No se ha podido validar el ingreso.
          </pichincha-typography>
        </div>
      </div>
    );
  }
}

export default UnauthorizedChannel;
