export enum Services {
  CALL_ME_BACK = 'callMeBack',
  EXPRESS_APPLICATIONS = 'express',
  FEEDBACK_PAGE = 'feedback',
  LOGIN = 'login',
  LOGOUT = 'logout',
  CIVIL_REGISTRATION = 'civilRegistration',
  DATA_PROTECTION = 'dataProtection',
  mokcup = 'mokcup',
}

export const URLS = {
  callMeBack: process.env.REACT_APP_CALL_ME_BACK_SERVICE,
  express: process.env.REACT_APP_EXPRESS_APPLICATIONS_SERVICE,
  feedback: process.env.REACT_APP_FEEDBACK_SERVICE,
  login: process.env.REACT_APP_LOGIN_SERVICE,
  logout: process.env.REACT_APP_LOGOUT_SERVICE,
  civilRegistration: process.env.REACT_APP_CIVIL_REGISTRATION_SERVICE,
  dataProtection: process.env.REACT_APP_CIVIL_DATA_PROTECTION_SERVICE,
  mokcup: 'https://1173dc46-1ab8-46ae-9799-01b9dd948742.mock.pstmn.io/api',
};
