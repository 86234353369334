import React,{Component} from "react";

interface DropButtonClick{
    click:any,
    buttonstate:boolean,
}


class Dropbutton extends Component<DropButtonClick> {
    
    render() {
        let menu="buttonline";
        if(this.props.buttonstate){
            menu="buttoncross"
        }
        

        return (
            <button className={menu} onClick={this.props.click}>
                <div className="line"></div>
                <div className="line"></div>
                <div className="line"></div>
                <div className="cross">
                    <pichincha-icon 
                    size="1.5rem"
                    color="blue"
                    weight_color="500"
                    type="--outlined"
                    >
                    close
                    </pichincha-icon>
                </div>
            </button>        
        );
    }
}

export default Dropbutton;