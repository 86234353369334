import React, { Component } from "react";

interface BoxProps {
  text?: string;
  subtext: string;
  icon: string;
  animateClass?: string;
  twoColumns?: boolean;
  extraSubtitles?: Array<{
    strongTag: string;
    normalText: string;
  }>;
  animateDuration?: string
}
class Box extends Component<BoxProps> {
  render() {
    const { twoColumns, extraSubtitles, animateDuration } = this.props;
    const className = `boxOrder ${this.props.animateClass}`;
    let extraSubtitlesElement;
    if (extraSubtitles) {
      extraSubtitlesElement = extraSubtitles.map((element, index) => (
        <li key={index} className="subtitle-benefit ">
          {" "}
          <strong>{element.strongTag} </strong>
          {element.normalText}{" "}
        </li>
      ));
    }
    const box = (
      <div className="box">
        <i className="circle-icon" />
        <div className="icon-benefit">
          <pichincha-icon
            size="2rem"
            color="blue"
            weight_color="500"
            type="--outlined"
          >
            {this.props.icon}
          </pichincha-icon>
        </div>
      </div>
    );

    return (
      <>
        {twoColumns && box}
        <div className={className} style={{"animationDuration": "1s"}} data-wow-duration={animateDuration}>
          {!twoColumns && box}
          {this.props.text && (

            <div className="title-benefit">
              <pichincha-typography variant="subtitle" color="blue" weight="bold">{this.props.text}</pichincha-typography>
            </div>
          )}
          <div className="subtitle-benefit">
          <pichincha-typography>{this.props.subtext}</pichincha-typography>
            </div>
          {extraSubtitlesElement}
        </div>
      </>
    );
  }
}

export default Box;
